$( document ).ready(function() {
  if($(".dataTables_wrapper").length == 0 && $('#image_category_dataTable').length > 0) {
    table = $('#image_category_dataTable').DataTable({
      dom: "<'row'<'col-6'l><'.col-6 text-right'>>" +
        "<'row'<'col-sm-12'>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'float-right mb-3'p>",
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        lengthMenu: "_MENU_ を表示する",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        },
        emptyTable: "画像カテゴリーは登録されておりません"
      },
      order: [],
      ordering: false,
      columnDefs: [
        {
          targets: 0,
        },
        {
          targets: 2,
        }
      ],
      drawCallback: function(settings) {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);
      },
      stateSave: true
    });
  }

  $('#imageCategoryAddModal .close').on('click', function() {
    $.getJSON("/api/v1/image_categories", function(data) {
      $("#image_category_id").html('<option value="" selected>-- 選択してください --</option>');
      $.each(data, function() {
        $("#image_category_id").append('<option value="' + this.id + '">' + formatContent(this.name, 50) + '</option>');
      })
    })
  });

  function formatContent(value, maxlength) {
    if (value.length > maxlength) {
      value =  `${value.slice(0, maxlength)}...`
    }
    return value;
  }
});
