import React, { Component } from 'react'
import HeadlineComponent from '../HeadlineComponent';
import PropTypes from 'prop-types';
import ConfigablePart from "../ConfigablePart";
import ExplanationComponent from '../ExplanationComponent';
import { APIS } from "../../../../../untils/constants";
import request from "../../../../../untils/request";
import TextField from './TextField'
import FileField from './FileField'
import RadioField from './RadioField'
import TextAreaField from './TextAreaField'
import CheckBoxField from './CheckBoxField'
import SelectField from './SelectField';
import UndefinedField from './UndefinedField'
import {alertContent} from '../../../../../functions/alertContent'
import { parallaxScroll } from '../../../../../functions';
import {connect} from 'react-redux';

export class LoadableFormPart extends Component {
  constructor(props) {
    super(props)
    this.headlineNone = "1"
    this.headlineFull = "2"
    this.state = {
      contactData: {
        contact_content: "",
        privacy_text: "",
        remark_flag: "",
        contact_details: []
      }
    }
    this.showInputElement = this.showInputElement.bind(this)
  }

  static propTypes = {
    isEdit: PropTypes.bool,
    isFavorite: PropTypes.bool
  };

  static defaultProps = {
    isEdit: true,
    isFavorite: false
  };

  async componentDidMount () {
    const {contactData} = this.state
    const {content, clientId} = this.props
    await request({
      method: 'post',
      url: APIS.get_contact_details,
      data: {
        id: content.contact_id,
        client_id: clientId
      }
    }).then(response => {
      const contact_details = response.data.contact_details
      contactData.contact_content = contact_details.contact_content
      contactData.privacy_text = contact_details.privacy_text
      contactData.remark_flag = contact_details.remark_flag
      contactData.contact_details = contact_details.list_details
      contactData.name = contact_details.name
      this.setState({contactData: contactData})
    }).catch(() => {
      this.setState({contactData: {}})
    })
    parallaxScroll();
  }

  showInputElement(item, remarkFlag) {
    const inputTextTypes = ["text", "mail", "date", "datetime", "zip_type", "address"]
    const inputTextFiles = ["image", "zip_type"]
    if(inputTextTypes.indexOf(item.input_type) > -1) {
      return(<TextField item={item} remarkFlag={remarkFlag} />)
    }

    let remarks = ""
    if(remarkFlag === 1) { remarks = item.input_remaks }
    if(inputTextFiles.indexOf(item.input_type) > -1) {
      return( <FileField item={item} remarks={remarks}/>)
    }
    switch (item.input_type) {
      case "radio":
        return(<RadioField item={item} remarks={remarks} />)
      case "textarea":
        return(<TextAreaField item={item} remarkFlag={remarkFlag} />)
      case "checkbox":
        return(< CheckBoxField item={item} remarks={remarks}/>)
      case "select_type":
        return(<SelectField item={item} remarks={remarks} />)
      default:
        return(<UndefinedField item={item} />)
    }
  }

  render() {
    const {content, isEdit, index, handleChangeHeadline, handleChangeAlign, item, sectionStyle, isFavorite} = this.props;
    const {contactData} = this.state
    const contact_details = contactData.contact_details
    return (
      <>
        <section key={item.kind_of_design} id={item.kind_of_design} className={`widget_form align-left block_form_1 delay2 ${content.parallax != 1 ? "parallax" : ""} ${content.width_class} blockarea ${content.top_padding_class}  ${content.bottom_padding_class} edit_outer delay4Active editing block_form without delay2Active`}
          style={sectionStyle}>
          {this.props.isEdit && <ConfigablePart {...this.props} />}
          {
            content.parallax != 1 &&
            <div className="parallax-wrap">
              <div className="parallax_img" style={{backgroundImage: `url(${content.background_image_url})`, height: '100%', transform: 'translateY(-50%)'}} />
            </div>
          }
          <div className="content_wrapper">
            {
              content.headlineType != this.headlineNone &&
              <HeadlineComponent content={content} index={index}
                                isEdit={isEdit}
                                handleChangeHeadline={handleChangeHeadline}
                                handleChangeAlign={handleChangeAlign} />
            }
            {
              content.headlineType == this.headlineFull &&
              <ExplanationComponent content={content} index={index}
                                    isEdit={isEdit}
                                    handleChangeHeadline={handleChangeHeadline}
                                    handleChangeAlign={handleChangeAlign} />
            }
            <div className="wrapper_item">
              <div className="inner_item_txt">
                {
                  contactData.contact_content.split("\n").map((item) => {
                    if(alertContent(item)) {
                      return(
                        <p key={index}>{item}</p>
                      )
                    }
                    return(<p>{item}</p>)
                  })
                }
              </div>
              { contactData.privacy_text != "" &&
                <div className="privacypolicy">
                  <p className='pre-wrap'>{contactData.privacy_text}</p>
                </div>
              }
              <form>
                {
                  contact_details.map((item, _) => {
                    return(this.showInputElement(item, contactData.remark_flag))
                  })
                }
                { isFavorite &&
                  <div className="submit"><span><input type="button" onClick={() => window.history.back()} defaultValue="戻る" /></span></div>
                }
                { contactData.name != "" &&
                  <div className="submit">
                    <span><input type="button" id="{submit_button_id}" defaultValue="入力内容を確認する" /></span>
                  </div>
                }
              </form>
            </div>
          </div>
        </section>
      </>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.clientId = storeState.clientId;
  return newState
}

export default connect(mapStateToProps)(LoadableFormPart)
