import React, {Component} from 'react'
import OptionForHeadLine from '../EmbeddedParts/OptionForHeadLine';
import OptionUrl from '../OptionUrl'
import CategoryOptions from '../../Modal/CategoryOptions';
import NameSelections from '../../Modal/NameSelections';
import ChooseNumberItem from '../../Modal/ChooseNumberItem';
import ModalSettingMenuPart from '../EmbeddedParts/BlockMenu/ModalSettingMenuPart';
import CouponOptions from '../../Modal/EmbeddedParts/BlockCoupon/CouponOptions';
import StaffOptions from '../../Modal/EmbeddedParts/BlockStaff/StaffOptions';
import BlogOptions from '../../Modal/EmbeddedParts/BlockBlog/BlogOptions';
import RecruitOptionSettings from '../../Modal/EmbeddedParts/BlockRecruit/RecruitOptionSettings';
import ModalSettingGalleryPart from '../EmbeddedParts/BlockGallery/ModalSettingGalleryPart';
import FormOption from '../../Modal/EmbeddedParts/BlockForm/FormOption'

export class AddModalCss extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedHeadlineOption: "1",
      selectedPositionOption: "top",
      imageWidth: props.itemChecked.block_image_width
    };
    this.maxLength = 30
    this.blockTypeExtraCss = [
      'blockText', 'blockMovie', 'blockMap', 'blockImageText',
      'blockTextImage', 'blockTel', 'blockImage', 'blockTable',
      'blockLink', 'blockBlog', 'blockImageText2', 'blockComparison',
      'blockQuestion', 'blockButton', 'blockMenu', 'blockHistory',
      'blockShop', 'blockNews', 'blockForm', 'blockCoupon', 'blockStaff',
      'blockRecruit', 'blockGallery'
    ]
  }

  renderExtendSelectHeadline() {
    const {itemChecked} = this.props
    if (this.props.itemChecked.selectedHeadlineType === "2") {
      return (<OptionForHeadLine onlyTitle={false} handleSelectHeadlineOption={this.props.handleSelectHeadlineOption}
                                 itemChecked={itemChecked}/>)
    } else if (this.props.itemChecked.selectedHeadlineType === "3") {
      return (<OptionForHeadLine onlyTitle={true} handleSelectHeadlineOption={this.props.handleSelectHeadlineOption}
                                 itemChecked={itemChecked}/>)
    } else {
      return (<div/>)
    }
  }

  renderSelectHeadlineOption() {
    if (this.blockTypeExtraCss.includes(this.props.blockType)) {
      return (
        <React.Fragment>
          <h3><span>見出し設定</span></h3>
          <div className="item">
            <p>見出しの有無</p>
            <input name="block_text_headline_flag" id="block_text_headline_flag1" value="1" type="radio"
                   onChange={this.props.handleChangeSelectedHeadlineType}
                   defaultChecked={!["2", "3"].includes(this.props.itemChecked.selectedHeadlineType)}/>
            <label htmlFor="block_text_headline_flag1">表示しない</label>

            <input name="block_text_headline_flag" id="block_text_headline_flag2" value="2" type="radio"
                   onChange={this.props.handleChangeSelectedHeadlineType}
                   defaultChecked={this.props.itemChecked.selectedHeadlineType == "2"}/>
            <label htmlFor="block_text_headline_flag2">タイトル・サブタイトルを表示</label>

            <input name="block_text_headline_flag" id="block_text_headline_flag3" value="3" type="radio"
                   onChange={this.props.handleChangeSelectedHeadlineType}
                   defaultChecked={this.props.itemChecked.selectedHeadlineType == "3"}/>
            <label htmlFor="block_text_headline_flag3">タイトルのみを表示</label>
          </div>
          {this.renderExtendSelectHeadline()}
        </React.Fragment>
      )
    }
  }

  renderButtonOption() {
    let {contents} = this.props.itemChecked
    contents = JSON.parse(contents)

    return (
      <React.Fragment>
        <h3><span>ボタン</span></h3>
        <div className="item">
          <p>ボタンラベル</p>
          <input name="comparison_button_label" onKeyUp={this.props.handleChangeContent} placeholder=""
                 defaultValue={contents.comparison_button_label} type="text"/>
          <div className="mt10"><small>ボタンラベルが未入力の場合はボタンが表示されません。</small></div>
        </div>
        <div className="item">
          <p>リンク</p>
          <div className="link">
            <input name="comparison_link1" id="comparison_link11" defaultValue="1" type="radio"
                   onChange={this.props.handleChangeContent}
                   defaultChecked={contents.comparison_link1 == "1"}/>
            <label htmlFor="comparison_link11">内部リンク</label>
            <input name="comparison_link1" id="comparison_link12" defaultValue="2" type="radio"
                   onChange={this.props.handleChangeContent}
                   defaultChecked={contents.comparison_link1 == "2"}/>
            <label htmlFor="comparison_link12">外部リンク</label>
            <input name="comparison_link1" id="comparison_link13" defaultValue="3" type="radio"
                   onChange={this.props.handleChangeContent}
                   defaultChecked={contents.comparison_link1 == "3"}/>
            <label htmlFor="comparison_link13">システム自動出力の一覧</label>

            <div className="comparison_linkinput1" style={contents.comparison_link1 == "1" ? {display: "block"} : {display: "none"}}>
              <span className="select">
                <select name="comparison_link1-select" defaultValue={contents['comparison_link1-select']} onChange={this.props.handleChangeSelectPageLink}>
                  <OptionUrl />
                </select>
              </span>
            </div>

            <div className="comparison_linkinput2"
                 style={contents.comparison_link1 == "2" ? {display: "block"} : {display: "none"}}><input
              name="comparison_link1-input" onKeyUp={this.props.handleChangeContent} placeholder="外部リンクURL"
              defaultValue={contents['comparison_link1-input']} type="text"/></div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderButtonOptionBlockNews() {
    let {contents} = this.props.itemChecked
    contents = JSON.parse(contents)

    return (
      <React.Fragment>
        <h3><span>ボタン</span></h3>
        <div className="item">
          <p>ボタンラベル</p>
          <input name="news_button_label" onKeyUp={this.props.handleChangeContent} placeholder=""
                 defaultValue={contents.news_button_label} type="text"/>
          <div className="mt10"><small>ボタンラベルが未入力の場合はボタンが表示されません。</small></div>
        </div>
        <div className="item">
          <p>リンク</p>
          <div className="link">
            <input name="news_link1" id="news_link11" defaultValue="1" type="radio"
                   onChange={this.props.handleChangeContent}
                   defaultChecked={contents.news_link1 == "1" ? true : false}/>
            <label htmlFor="news_link11">内部リンク</label>
            <input name="news_link1" id="news_link12" defaultValue="2" type="radio"
                   onChange={this.props.handleChangeContent}
                   defaultChecked={contents.news_link1 == "2" ? true : false}/>
            <label htmlFor="news_link12">外部リンク</label>

            <div className="news_linkinput1" style={contents.news_link1 == "1" ? {display: "block"} : {display: "none"}}>
              <span className="select">
                <select name="news_link1-select" defaultValue={contents['news_link1-select']} onChange={this.props.handleChangeSelectPageLink}>
                  <OptionUrl />
                </select>
              </span>
            </div>

            <div className="news_linkinput2"
                 style={contents.news_link1 == "2" ? {display: "block"} : {display: "none"}}>
              <input name="news_link1-input" onKeyUp={this.props.handleChangeContent} placeholder="外部リンクURL"
              defaultValue={contents['news_link1-input']} type="text"/>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderCategoryOption() {
    let {contents} = this.props.itemChecked
    contents = JSON.parse(contents)
    return (
      <React.Fragment>
        <div className="item">
          <p>カテゴリー名の表示</p>
          <input name="comparison_other2" id="comparison_other21" defaultValue="1" type="radio"
                 onChange={this.props.handleChangeContent}
                 defaultChecked={contents.comparison_other2 == "1"}/><label
          htmlFor="comparison_other21">表示しない</label>
          <input name="comparison_other2" id="comparison_other22" defaultValue="2" type="radio"
                 onChange={this.props.handleChangeContent}
                 defaultChecked={contents.comparison_other2 == "2"}/><label
          htmlFor="comparison_other22">表示する</label>
        </div>
        <div className="item">
          <p>表示形式</p>
          <input name="comparison_other3" id="comparison_other31" defaultValue="1" type="radio"
                 onChange={this.props.handleChangeContent}
                 defaultChecked={contents.comparison_other3 == "1"}/><label
          htmlFor="comparison_other31">カテゴリーで分けて表示</label>
          <input name="comparison_other3" id="comparison_other32" defaultValue="2" type="radio"
                 onChange={this.props.handleChangeContent}
                 defaultChecked={contents.comparison_other3 == "2"}/><label
          htmlFor="comparison_other32">カテゴリーで分けずに一括表示</label>
          <input name="comparison_other3" id="comparison_other33" defaultValue="3" type="radio"
                 onChange={this.props.handleChangeContent}
                 defaultChecked={contents.comparison_other3 == "3"}/><label
          htmlFor="comparison_other33">1件表示</label>
        </div>
        <div className="item">
          <p>表示件数</p>
          <input name="comparison_other1" id="comparison_other11" defaultValue="2" type="radio"
                 onChange={this.props.handleChangeContent}
                 defaultChecked={contents.comparison_other1 == "2"}/><label
          htmlFor="comparison_other11">2件</label>
          <input name="comparison_other1" id="comparison_other12" defaultValue="4" type="radio"
                 onChange={this.props.handleChangeContent}
                 defaultChecked={contents.comparison_other1 == "4"}/><label
          htmlFor="comparison_other12">4件</label>
          <input name="comparison_other1" id="comparison_other13" defaultValue="6" type="radio"
                 onChange={this.props.handleChangeContent}
                 defaultChecked={contents.comparison_other1 == "6"}/><label
          htmlFor="comparison_other13">6件</label>
          <input name="comparison_other1" id="comparison_other14" defaultValue="8" type="radio"
                 onChange={this.props.handleChangeContent}
                 defaultChecked={contents.comparison_other1 == "8"}/><label
          htmlFor="comparison_other14">8件</label>
          <input name="comparison_other1" id="comparison_other15" defaultValue="10" type="radio"
                 onChange={this.props.handleChangeContent}
                 defaultChecked={contents.comparison_other1 == "10"}/><label
          htmlFor="comparison_other15">10件</label>
        </div>
      </React.Fragment>
    )
  }

  renderSpecificInput() {
    const { itemChecked } = this.props
    let contents = {}
    if (itemChecked.contents !== undefined) {
      contents = JSON.parse(itemChecked.contents)
    }
    switch (this.props.blockType) {
      case 'blockMovie':
        return (
          <React.Fragment>
            <h3><span>YouTubeID</span></h3>
            <div className="item">
              <p>YouTubeID<small className="required">*</small></p>
              <input placeholder="bIMvC68C9L0" name="block_movie_text" type="text"
                     value={contents.block_movie_text || ''} onChange={this.props.handleChangeContent}/>
              {this.props.errorMessages.movie && <small className="required">{this.props.errorMessages.movie}</small>}
              <p>
                <small>
                  IDはYouTube動画URLの「watch?v=」直後に表記されている文字列です。<br/>
                  例：YouTube動画のURLが「https://www.youtube.com/watch?v=bIMvC68C9L0?（パラメータ）」の場合は、「bIMvC68C9L0」にあたる部分。<br/>
                  ※パラメータの値を入力しないように注意
                </small>
              </p>
            </div>
          </React.Fragment>
        )
        break;
      case 'blockMap':
        return (
          <React.Fragment>
            <h3><span>その他設定</span></h3>
            <div className="item">
              <p>住所<small className="required">*</small></p>
              <input name="block_map_text" placeholder="住所を入力" type="text"
                     value={contents.block_map_text} onChange={this.props.handleChangeContent}/>
              {this.props.errorMessages.map && <small className="required">{this.props.errorMessages.map}</small>}
            </div>
          </React.Fragment>
        )
        break;
      case 'blockImageText':
        return (
          <React.Fragment>
            <h3><span>その他設定</span></h3>
            <div className="item">
              <p>垂直方向配置</p>
              <input name="image_text_css_valign" id="image_text_css_valign1" value="top" type="radio"
                     onChange={this.props.handleChangeAlignment}
                     defaultChecked={itemChecked.block_image_alignment === "top"}/><label
              htmlFor="image_text_css_valign1">上揃え</label>
              <input name="image_text_css_valign" id="image_text_css_valign2" value="center" type="radio"
                     onChange={this.props.handleChangeAlignment}
                     defaultChecked={itemChecked.block_image_alignment === "center"}/><label
              htmlFor="image_text_css_valign2">中央揃え</label>
              <input name="image_text_css_valign" id="image_text_css_valign3" value="bottom" type="radio"
                     onChange={this.props.handleChangeAlignment}
                     defaultChecked={itemChecked.block_image_alignment === "bottom"}/><label
              htmlFor="image_text_css_valign3">下揃え</label>
            </div>
          </React.Fragment>
        )
        break;
      case 'blockImageText2':
        return (
          <React.Fragment>
            <div className="item">
              <p>カラム数</p>
              <span className="select">
                <select name="block_image_text2_other" onChange={this.props.handleChange}
                        defaultValue={itemChecked.block_image_text2_other}>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                </select>
              </span>
            </div>
          </React.Fragment>
        )
        break;
      case 'blockImage':
        return (
          <React.Fragment>
            <h3><span>その他設定</span></h3>
            <div className="item">
              <p>画像サイズ幅<small className="required"> *</small></p>
              <span className="select">
                  <select name="widthImage" onChange={this.props.handleChangeContent} defaultValue={contents.widthImage}>
                    <option value={"w10"}>10％</option>
                    <option value={"w15"}>15％</option>
                    <option value={"w20"}>20％</option>
                    <option value={"w25"}>25％</option>
                    <option value={"w30"}>30％</option>
                    <option value={"w35"}>35％</option>
                    <option value={"w40"}>40％</option>
                    <option value={"w45"}>45％</option>
                    <option value={"w50"}>50％</option>
                    <option value={"w55"}>55％</option>
                    <option value={"w60"}>60％</option>
                    <option value={"w65"}>65％</option>
                    <option value={"w70"}>70％</option>
                    <option value={"w75"}>75％</option>
                    <option value={"w80"}>80％</option>
                    <option value={"w85"}>85％</option>
                    <option value={"w90"}>90％</option>
                    <option value={"w95"}>95％</option>
                    <option value={"w100"}>100％</option>
                  </select>
                </span>
            </div>
          </React.Fragment>
        )
        break;
      case 'blockTel':
        return (
          <React.Fragment>
            <h3><span>TEL</span></h3>
            <div className="item">
              <p>電話番号<small className="required">*</small></p>
              <input name="block_tel_other1" placeholder="電話番号を半角でハイフンをつけて入力してください" maxLength={16}
                     value={contents.block_tel_other1} onChange={this.props.handleChangeContent} type="text"/>
              {this.props.errorMessages.tel && <small className="required">{this.props.errorMessages.tel}</small>}
            </div>
          </React.Fragment>
        )
        break;
      case 'blockTable':
        return (
          <React.Fragment>
            <h3><span>その他設定</span></h3>
            <div className="item">
              <p>セル幅</p>
              <input name="image_text_css_valign" id="table_cell_element" value="wauto" type="radio"
                     onChange={this.props.handleSelectTableCell} defaultChecked={contents.wOption === "wauto"}/><label
              htmlFor="table_cell_element">要素に応じる</label>
              <input name="image_text_css_valign" id="table_cell_fix" value="" type="radio"
                     onChange={this.props.handleSelectTableCell} defaultChecked={contents.wOption === ""}/><label htmlFor="table_cell_fix">均等にする</label>
            </div>
          </React.Fragment>
        )
        break;
      case 'blockLink':
        return (
          <React.Fragment>
            <h3><span>その他設定</span></h3>
            <div className="item">
              <p>URL<small className="required"> *</small></p>
              <input name="block_link_url" placeholder="https://www.google.co.jp/" onChange={this.props.handleChange} value={itemChecked.block_link_url}/>
              {this.props.errorMessages.block_link_url && <small className="required">{this.props.errorMessages.block_link_url}</small>}
            </div>
          </React.Fragment>
        )
        break;
        case 'blockBlog':
          return <BlogOptions {...this.props} contents={contents}/>
        break;
      case 'blockComparison':
        return (
          <React.Fragment>
            <h3><span>その他設定</span></h3>
            <CategoryOptions {...this.props} categories={this.props.comparisonCategories} kindOfItem="comparison" isShowDetail={true} isRequired={true} />
            {this.renderCategoryOption()}
            {this.renderButtonOption()}
          </React.Fragment>
        )
        break;
      case 'blockShop':
        return (
          <React.Fragment>
            <h3><span>その他設定</span></h3>
            <NameSelections {...this.props} itemChecked={this.props.itemChecked} kindOfItem="shop"
                            handleChangeContent={this.props.handleChangeContent} isShowDetail={true}
                            displayDetailData={this.props.displayDetailData}
                            errorMessages={this.props.errorMessages}/>
          </React.Fragment>
        )
        break;
      case 'blockQuestion':
        const numberQuestionValues = [4, 8, 12, 16, 20, -1]
        return (
          <React.Fragment>
            <h3><span>その他設定</span></h3>
            <CategoryOptions {...this.props} categories={this.props.questionCategories}  kindOfItem="question" isShowDetail={true} />
            <div className="item">
              <p>カテゴリー名の表示</p>
              <input name="display_category" id="display_category_1" defaultValue={1} type="radio"
                defaultChecked={contents.display_category == 1} onChange={this.props.handleChangeContent}/>
              <label htmlFor="display_category_1">表示しない</label>
              <input name="display_category" id="display_category_2" defaultValue={2} type="radio"
                defaultChecked={contents.display_category == 2} onChange={this.props.handleChangeContent} />
              <label htmlFor="display_category_2">表示する</label>
            </div>
            <ChooseNumberItem contents={contents} kindOfItem="question" numberValues={numberQuestionValues} handleChangeContent={this.props.handleChangeContent}/>
            <h3><span>ボタン</span></h3>
            <div className="item">
              <p>ボタンラベル</p>
              <input name="button_label" type="text" defaultValue={contents.button_label} onChange={this.props.handleChangeContent} />
              <div className="mt10"><small>ボタンラベルが未入力の場合はボタンが表示されません。</small></div>
            </div>
            <div className="item">
              <p>リンク</p>
              <div className="link">
                <input name="question_link_type" id="question_link_type_1" defaultValue={1} type="radio" defaultChecked={contents.question_link_type == 1} onChange={this.props.handleChangeContent}/>
                <label htmlFor="question_link_type_1">内部リンク</label>
                <input name="question_link_type" id="question_link_type_2" defaultValue={2} type="radio" defaultChecked={contents.question_link_type == 2} onChange={this.props.handleChangeContent} />
                <label htmlFor="question_link_type_2">外部リンク</label>
                <div className="question_linkinput1" style={{display: contents.question_link_type == 1 ? 'block' : 'none'}}>
                  <span className="select">
                    <select name="question_link_1" onChange={this.props.handleChangeSelectPageLink} defaultValue={contents.question_link_1}>
                      <OptionUrl />
                    </select>
                  </span>
                </div>
                <div className="question_link_2" style={{display: contents.question_link_type == 2 ? 'block' : 'none'}}>
                  <input name="question_link_2" placeholder="外部リンクURL" type="text" onChange={this.props.handleChangeContent} defaultValue={contents.question_link_2} />
                </div>
              </div>
            </div>
          </React.Fragment>
        )
        break;
      case 'blockMenu':
        return (
          <ModalSettingMenuPart {...this.props} />
        )
        break;
      case 'blockGallery':
        return (
          <ModalSettingGalleryPart {...this.props} />
        )
        break;
      case 'blockButton':
        return (
          <React.Fragment>
            <h3><span>個数</span></h3>
            <div className="item">
              <span className="select">
                <select name="block_button_other" onChange={this.props.handleChangeContent} defaultValue={contents.block_button_other}>
                  <option value="1">1個</option>
                  <option value="2">2個</option>
                  <option value="3">3個</option>
                  <option value="4">4個</option>
                  <option value="5">5個</option>
                </select>
              </span>
            </div>
          </React.Fragment>
        )
        break;
      case 'blockForm':
        return(<FormOption {...this.props} contents={contents}/>)
        break;
      case 'blockHistory':
        const numberChangeLogValues = [3, 5, 10]
        return (
          <React.Fragment>
            <h3><span>その他設定</span></h3>
            <ChooseNumberItem contents={contents} kindOfItem="change_log" numberValues={numberChangeLogValues} handleChangeContent={this.props.handleChangeContent}/>
          </React.Fragment>
        )
        break;
      case 'blockNews':
        const numberNewsValues = [3, 4, 5, 6, 8, 9, 10, 12]
        return (
          <React.Fragment>
            <h3><span>その他設定</span></h3>
            <CategoryOptions {...this.props} categories={this.props.newsCategories} kindOfItem="news" isShowDetail={false} isRequired={false} />
            <ChooseNumberItem contents={contents} kindOfItem="news" numberValues={numberNewsValues} handleChangeContent={this.props.handleChangeContent}/>

            { this.renderButtonOptionBlockNews() }
          </React.Fragment>
        )
        break;
      case 'blockCoupon':
        return <CouponOptions {...this.props} contents={contents} />
        break;
      case 'blockStaff':
        return <StaffOptions {...this.props} contents={contents} />
        break;
      case 'blockRecruit':
        return <RecruitOptionSettings {...this.props} contents={contents} />
        break;
      default:
        return null;
        break;
    }
  }

  render() {
    return (
      <div className="wrap">
        {this.renderSelectHeadlineOption()}
        {this.renderSpecificInput()}
        <h3><span>CSS</span></h3>
        <div className="item">
            <textarea name="css" onChange={this.props.handleChange} placeholder=".selector{
              color: blue;
              }" className="form-control rightclickarea" rows="5" value={this.props.itemChecked.css}/>
        </div>
      </div>
    )
  }
}

export default AddModalCss
