import React, { useState, useEffect } from 'react';
import Footer from '../components/Layouts/Footer';
import AsideEdit from '../components/ViewEdit/AsideEdit';
import OperableModal from '../components/Parts/Modal/OperableModal';
import { useDispatch } from "react-redux";
import request from "../untils/request";
import { APIS } from '../untils/constants'
import {
  footerSetting,
  footerNav,
  saveDataUi,
  updateSetting,
  homeStructure,
  generalSetting,
  newpageCategories,
  questionCategories,
  contactOptions,
  newsCategories,
  menuCategories,
  loadCoupons,
  staffCategories,
  galleryCategories,
  saveRecuits,
  recruitCategories,
  tagsList,
  loadAllPages,
  loadComparisonCategories,
  loadShopsData,
  saveSettingAdvance,
  loadComparisonData,
  setClientId
} from "../Lib/redux/actions";
import {ToastContainer} from "react-toastify";

const CommonFooterPage = (props) => {
  const [statusPage] = useState('commonFooter');
  const [indexBlockData, setIndexBlockData] = useState(null);
  const [isOpenFavoriteModal, setIsOpenFavoriteModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(async () => {
    dispatch(setClientId(props.client_id))
    const {data: { setting_footer_data }} = await request({
      method: 'post',
      url: APIS.get_setting_footer,
    });

    const {data: { general_setting_data }} = await request({
      method: 'post',
      url: APIS.get_general_seting,
    })

    const {data: { footer_routes }} = await request({
      method: 'post',
      url: APIS.get_common_footer_navigation,
    });

    const {data: { link_for_embedded_parts }} = await request({
      method: 'post',
      url: APIS.get_home_structure,
    });

    const {data: {
      comparison_categories,
      newpage_categories,
      question_categories,
      news_categories,
      menu_categories,
      gallery_categories,
      recruit_categories
    }} = await request({
      method: 'post',
      url: APIS.get_newpage_categories,
      data: { client_id: props.client_id }
    });

    const {data: { contacts }} = await request({
      method: 'post',
      url: APIS.get_contacts_options,
      data: { client_id: props.client_id }
    })

    const {data: { coupons }} = await request({
      method: 'get',
      url: `${APIS.get_coupons}?client_id=${props.client_id}`,
    })

    const {data: { staff_categories }} = await request({
      method: 'get',
      url: `${APIS.get_staff_categories}?client_id=${props.client_id}`,
    })

    const {data: { recruits }} = await request({
      method: 'get',
      url: `${APIS.get_recruits}?client_id=${props.client_id}`,
    })

    const tags_list = await request({
      method: 'get',
      url: `${APIS.get_tags}?newpage_id=${props.settings.newpage_id}&client_id=${props.client_id}`
    })

    let {data: { all_pages }} = await request({
      method: 'get',
      url: APIS.get_all_page
    })

    const {data: { shops }} = await request({
      method: 'post',
      url: APIS.get_shops,
      data: { client_id: props.client_id }
    })

    const {data: { setting_advance }} = await request({
      method: 'get',
      url: APIS.get_setting_advanced
    })

    all_pages = all_pages.filter(page => page.name.toLowerCase() !== 'footer');

    const {data: {categories, comparisons}} = await request({
      method: 'get',
      url: `${APIS.get_comparison_categories}?client_id=${props.client_id}`
    })

    const comparisonData = {
      categories: categories,
      comparisons: comparisons
    }

    dispatch(loadComparisonCategories(comparison_categories))
    dispatch(loadAllPages(all_pages))
    dispatch(footerSetting(setting_footer_data))
    dispatch(generalSetting(general_setting_data))
    dispatch(footerNav(footer_routes))
    dispatch(saveDataUi(props.templateData))
    dispatch(updateSetting(props.settings))
    dispatch(homeStructure(link_for_embedded_parts))
    dispatch(newpageCategories(newpage_categories))
    dispatch(questionCategories(question_categories))
    dispatch(contactOptions(contacts))
    dispatch(newsCategories(news_categories))
    dispatch(menuCategories(menu_categories))
    dispatch(loadCoupons(coupons))
    dispatch(staffCategories(staff_categories))
    dispatch(galleryCategories(gallery_categories))
    dispatch(saveRecuits(recruits))
    dispatch(recruitCategories(recruit_categories))
    dispatch(tagsList({tags: tags_list, page_kind: ''}))
    dispatch(loadShopsData(shops))
    dispatch(saveSettingAdvance(setting_advance))
    dispatch(loadComparisonData(comparisonData))
  }, [])

  const handleShowModalFavorite = (indexBlockData) => {
    setIndexBlockData(indexBlockData);
    setIsOpenFavoriteModal(!isOpenFavoriteModal);
  }

  return (
    <React.Fragment>
      <main id="footer_content">
        <div id="primary" style={{paddingBottom: 0}}>
          <AsideEdit isOpenFavoriteModal={isOpenFavoriteModal}
                      handleShowModalFavorite={handleShowModalFavorite}
                      indexBlockData={indexBlockData}
                      pageStatus={statusPage}
                      />
          <Footer />
          <OperableModal pageKind="common_footer" isNewpageAdd={false} pageStatus={statusPage} />
        </div>
      </main>
      <ToastContainer />
    </React.Fragment>
  )
}

export default CommonFooterPage;
