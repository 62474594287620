import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {connect} from "react-redux";
import { resizeHeader } from '../../functions';

class HeaderMenu extends Component {
  constructor(props) {
    super(props);
    this.renderPageStructure = this.renderPageStructure.bind(this)
  }

  componentDidMount() {
    resizeHeader()
  }

  componentDidUpdate() {
    if (this.props.isDisplay !== undefined) {
      resizeHeader()
    }
  }

  renderPageStructure() {
    if(this.props.h_d_navi_flag === false || this.props.h_d_flag === false ) {
      return (<div />)
    }

    return (
      <ul className="nav_1st">
        {this.props.pageStructureData !== undefined && this.props.pageStructureData.map(item => {
          let link_url = item.url;
          if (link_url == '/home') {
            link_url = '/'
          }
          return (
            <li id={item.id} style={{flexGrow: 1}} key={item.id}>
              <Link to={link_url}><span dangerouslySetInnerHTML={{ __html: item.page_title }}></span></Link>
              <ul className="nav_2nd" style={{left: 0}}>
                {item.children.map(childFirstGeneration => {
                  return (
                    <li id={childFirstGeneration.id} key={childFirstGeneration.id}>
                      <Link to={childFirstGeneration.url}><span dangerouslySetInnerHTML={{ __html: childFirstGeneration.page_title }}></span></Link>
                      <ul className="nav_3rd">
                        {childFirstGeneration.children.map(childSecondGeneration => {
                          return (
                            <li id={childSecondGeneration.id} key={childSecondGeneration.id}>
                              <Link to={childSecondGeneration.url}><span dangerouslySetInnerHTML={{ __html: childSecondGeneration.page_title }}></span></Link>
                            </li>
                          )
                        })}
                      </ul>
                    </li>
                  )
                })}
              </ul>
            </li>
          )
        })}
      </ul>
    )
  }

  render() {
    return (
      <nav>
        {this.renderPageStructure()}
      </nav>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);

  newState.pageStructureData = storeState.pageStructureData;
  newState.headerSetting = storeState.headerSetting;
  newState.h_d_flag = storeState.settings.h_d_flag;
  newState.h_d_navi_flag = storeState.settings.h_d_navi_flag;
  if (storeState.settingsAdvance.footer_content != undefined) {
    newState.isDisplay = storeState.settingsAdvance.header_content.length > 0;
  }

  return newState;
}

export default connect(mapStateToProps)(HeaderMenu);
