import axios from 'axios';
import { is_requesting } from '../Lib/redux/actions'

const service = axios.create({
  timeout: 60000
  // withCredentials: true // send cookies when cross-domain requests
})

export const interceptor = (store) => {
  // Request interceptors
  service.interceptors.request.use(
    (conf) => {
      store.dispatch(is_requesting(true))
      // you can add some information before send it.
      conf.headers['Api-Authorization'] = store.getState().accessToken;
      conf.headers['Type-Page'] = store.getState().pageType
      conf.headers['Id-String'] = store.getState().settings.id_string

      return conf;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Response interceptors
  service.interceptors.response.use(
    (next) => {
      store.dispatch(is_requesting(false))
      // Do some thing after get response
      return Promise.resolve(next);
    },
    (error) => {
      store.dispatch(is_requesting(false))
      if(error.response.status === 401) {
        window.parent.postMessage({
          'func': 'unauthorized',
          'message': '/users/sign_in'
        }, "*");
      }
      // You can handle error here and trigger warning message without get in the code inside
      return Promise.reject(error);
    }
  );
};

export default service
