import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConfigablePart from "../ConfigablePart";
import HeadlineComponent from '../HeadlineComponent';
import ContentEditable from 'react-contenteditable';
import { APIS, FRONT_END_HOST, S3_URL } from "../../../../../untils/constants";
import request from "../../../../../untils/request";
import { comparisonPopup, parallaxScroll } from '../../../../../functions';
import ExplanationComponent from '../ExplanationComponent';
import { resizeImageComparison4 } from '../../../../../functions/resizeImageComparison';
import { connect } from 'react-redux';
import LabelComponent from "../LabelComponent";

class LoadableComparisonPart extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    isEdit: true
  };

  constructor(props) {
    super(props);
    this.headlineNone = "1"
    this.headlineFull = "2"
    this.groupComparsionsByCategory = "1"
    this.onlyShow1Comparison = "3"
    this.showCategoryName = "2"
    this.emptyContent = ""
    this.state = {
      categories: [],
      comparisons: []
    }
    this.categoryFilterProcess = this.categoryFilterProcess.bind(this);
  }

  componentDidMount = () => {
    comparisonPopup(this.props.pageStatus, S3_URL)
    this.getComparisonCategories();
  }

  async getComparisonCategories() {
    if (!this.props.isEdit) {
      await request({
        method: 'get',
        url: `${APIS.get_comparison_categories}?client_id=${this.props.clientId}`
      }).then(response => {
        this.setState({
          categories: response.data.categories,
          comparisons: response.data.comparisons
        })
      })
    }

    parallaxScroll()
  }

  componentDidUpdate = () => {
    resizeImageComparison4()
  };

  async componentDidUpdate() {
    comparisonPopup(this.props.pageStatus, S3_URL)
  }

  renderComparisonItems(item) {
    let after_image_url = "#!"
    let before_image_url = "#!"
    if(this.props.generalSetting != undefined){
      after_image_url = this.props.generalSetting.popup_flag === 0 ? item.after_image_url : "#!"
      before_image_url = this.props.generalSetting.popup_flag === 0 ? item.before_image_url : "#!"
    } else {
      after_image_url = this.props.item.popup_flag === 0 ? item.after_image_url : "#!"
      before_image_url = this.props.item.popup_flag === 0 ? item.before_image_url : "#!"
    }
    return (
      <article className="pop" key={item.id}>
        <div className="inner_item">
          <div className="after">
            <a href={after_image_url}>
              <img src={item.after_image_url} alt="After" />
              <p className="infotxt"><font style={{verticalAlign: "inherit"}}><font style={{verticalAlign: "inherit"}}>After</font></font></p>
            </a>
          </div>
          <div className="before">
            <a href={before_image_url}>
              <img src={item.before_image_url} alt="Before" />
              <p className="infotxt"><font style={{verticalAlign: "inherit"}}><font style={{verticalAlign: "inherit"}}>Before</font></font></p>
            </a>
          </div>
          <div className="inner_item_txt">
            <p className="title">{item.name}</p>
            <p className="pre-wrap">{item.content}</p>
          </div>
        </div>
      </article>
    )
  }

  renderComparisonsDivideByCategory(content) {
    const categories = this.categoryFilterProcess()
    return (
      <React.Fragment>
        {
          categories.map((category, index) => {
            return (
              <React.Fragment key={index}>
                {
                  content.comparison_other2 === this.showCategoryName &&
                  <div className="heading block_header_2 without">
                    <h3 className="h">{category.name}</h3>
                    <p className="pre-wrap">{category.name_alias}</p>
                  </div>
                }

                {
                  <div className="wrapper_item">
                    {
                      category.comparisons.map((item, index) => {
                        if(index >= parseInt(content.comparison_other1)) return
                        return this.renderComparisonItems(item)
                      })
                    }
                  </div>
                }
              </React.Fragment>
            )
          })
        }
      </React.Fragment>
    )
  }

  renderComparisons(content) {
    const comparisons = this.comparisonsFilterProcess()
    let quantityToShow = (content.comparison_other3 === this.onlyShow1Comparison) ? 1 : parseInt(content.comparison_other1)
    return (
      <div className="wrapper_item">
        {
          comparisons.map((item) => {
            if(quantityToShow === 0) return
            quantityToShow--
            return this.renderComparisonItems(item)
          })
        }
      </div>
    )
  }

  categoryFilterProcess() {
    const blockData = this.props.item
    let data = this.props.isEdit ? this.props.comparisonData.categories : this.state.categories
    const dataFilter = data.filter(item => {
      return blockData.categories.includes(item.id);
    });
    return dataFilter
  }

  comparisonsFilterProcess() {
    const blockData = this.props.item
    let data = this.props.isEdit ? this.props.comparisonData.comparisons : this.state.comparisons
    const dataFilter = data.filter(item => {
      return blockData.categories.includes(item.comparison_category_id);
    });
    return dataFilter
  }

  handleClick(event, content) {
    event.preventDefault();
    const {idString, isEdit} = this.props;
    if (isEdit) return

    let url = '';
    const homeUrl = `${FRONT_END_HOST}/${idString}`;

    if (content.comparison_link1 === '1') {
      if (content['comparison_link1-select'] === '/') {
        url = homeUrl
      } else {
        url = `${homeUrl}${content['comparison_link1-select']}.html`
      }
    }
    else if (content.comparison_link1 === '2') {
      url = content['comparison_link1-input']
    }
    else {
      url = `${homeUrl}/comparison_setting.html`
    }

    if (content.comparison_link1 === '1' || content.comparison_link1 === '3') {
      window.open(url, '_self');
    } else {
      window.open(url);
    }
  }

  render() {
    const { content, kindOfDesign, index, isEdit, sectionStyle } = this.props;
    return (
      <section key={kindOfDesign} id={kindOfDesign} className={`beforeafter_archive block_comparison_${kindOfDesign} add_design${kindOfDesign} delay3 ${content.width_class} align-left blockarea ${content.top_padding_class}  ${content.bottom_padding_class} edit_outer without delay4Active editing block_comparison delay3Active`}
        style={sectionStyle}>
        {isEdit && <ConfigablePart {...this.props} />}
        {
          content.parallax != 1 &&
          <div className="parallax-wrap">
            <div className="parallax_img" style={{backgroundImage: `url(${content.background_image_url})`, height: '100%', transform: 'translateY(-50%)'}} />
          </div>
        }
        <div className="content_wrapper">
          {
            content.headlineType !== this.headlineNone &&
            <HeadlineComponent content={content} index={this.props.index}
                              isEdit={isEdit}
                              handleChangeHeadline={this.props.handleChangeHeadline}
                              handleChangeSubHeadline={this.props.handleChangeSubHeadline}
                              handleChangeAlign={this.props.handleChangeAlign} />
          }

          {
            content.headlineType === this.headlineFull &&
            <ExplanationComponent content={content} index={index}
                                  isEdit={isEdit}
                                  handleChangeHeadline={this.props.handleChangeHeadline}
                                  handleChangeAlign={this.props.handleChangeAlign} />
          }

          {
            content.comparison_other3 !== this.groupComparsionsByCategory &&
            this.renderComparisons(content)
          }

          {
            content.comparison_other3 === this.groupComparsionsByCategory &&
            this.renderComparisonsDivideByCategory(content)
          }

          {
            content.comparison_button_label != this.emptyContent &&
            <div className="contents_btn01 block_button_1">
              <div className="content_wrapper">
                <a href="#" onClick={(event) => {this.handleClick(event, content)}}>
                  <span>{content.comparison_button_label.trim()}</span>
                </a>
              </div>
            </div>
          }
        </div>
      </section>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.comparisonData = storeState.comparisonData
  newState.generalSetting = storeState.generalSetting
  newState.clientId = storeState.clientId;
  return newState
}

export default connect(mapStateToProps)(LoadableComparisonPart)
