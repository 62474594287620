import React, { Component } from 'react';

export class MvTypeMovie extends Component {
  render() {
    const { mv_youtubeid } = this.props.settings

    return (
      <div className="mvtype mvtype_mov">
        <div className="item">
          <p>YouTubeID<small className="required"> *</small></p>
          <input placeholder="bIMvC68C9L0" name="mv_youtubeid" value={mv_youtubeid} type="text" onChange={ this.props.handleChange } />
          {this.props.errorMessages.mv_youtubeid.message && <small className="required">{this.props.errorMessages.mv_youtubeid.message}</small>}
          <p><small>IDはYouTube動画URLの「watch?v=」直後に表記されている文字列です。<br />
            例：YouTube動画のURLが「https://www.youtube.com/watch?v=bIMvC68C9L0?（パラメータ）」の場合は、「bIMvC68C9L0」にあたる部分。<br />
            ※パラメータの値を入力しないように注意</small>
          </p>
        </div>
      </div>
    )
  }
}

export default MvTypeMovie
