import React, {Component} from 'react';

export class RecruitInformation extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {recruit} = this.props;
        return (
            <div className="detail">
                <div className="heading recruit-header align-left">
                    <h4 className="h">募集要項</h4>
                </div>
                <div className="block_table_3 width_fixed align-left">
                    <table>
                        <tbody>
                        {(recruit.job_description !== undefined && recruit.job_description !== '') && (
                            <tr>
                                <th>仕事内容</th>
                                <td className="pre-wrap">{recruit.job_description}</td>
                            </tr>
                        )}
                        {(recruit.employment_status !== undefined && recruit.employment_status !== '') && (
                            <tr>
                                <th>雇用形態</th>
                                <td className="pre-wrap">{recruit.employment_status}</td>
                            </tr>
                        )}
                        {(recruit.work_address !== undefined && recruit.work_address !== '') && (
                            <tr>
                                <th>勤務地</th>
                                <td className="pre-wrap">{recruit.work_address}</td>
                            </tr>
                        )}
                        {(recruit.working_hours !== undefined && recruit.working_hours !== '') && (
                            <tr>
                                <th>勤務時間</th>
                                <td className="pre-wrap">{recruit.working_hours}</td>
                            </tr>
                        )}
                        {(recruit.monthly_salary !== undefined && recruit.monthly_salary !== '') && (
                            <tr>
                                <th>給与</th>
                                <td className="pre-wrap">{recruit.monthly_salary}</td>
                            </tr>
                        )}
                        {(recruit.increase_bonus !== undefined && recruit.increase_bonus !== '') && (
                            <tr>
                                <th>昇給・賞与</th>
                                <td className="pre-wrap">{recruit.increase_bonus}</td>
                            </tr>
                        )}
                        {(recruit.allowance !== undefined && recruit.allowance !== '') && (
                            <tr>
                                <th>諸手当</th>
                                <td className="pre-wrap">{recruit.allowance}</td>
                            </tr>
                        )}
                        {(recruit.holiday_vacation !== undefined && recruit.holiday_vacation !== '') && (
                            <tr>
                                <th>休日・休暇</th>
                                <td className="pre-wrap">{recruit.holiday_vacation}</td>
                            </tr>
                        )}
                        {(recruit.mandatory_requirements !== undefined && recruit.mandatory_requirements !== '') && (
                            <tr>
                                <th>応募資格</th>
                                <td className="pre-wrap">{recruit.mandatory_requirements}</td>
                            </tr>
                        )}
                        {(recruit.welfare !== undefined && recruit.welfare !== '') && (
                            <tr>
                                <th>福利厚生</th>
                                <td className="pre-wrap">{recruit.welfare}</td>
                            </tr>
                        )}
                        {(recruit.education_system !== undefined && recruit.education_system !== '') && (
                            <tr>
                                <th>教育制度</th>
                                <td className="pre-wrap">{recruit.education_system}</td>
                            </tr>
                        )}
                        {(recruit.selection_process !== undefined && recruit.selection_process !== '') && (
                            <tr>
                                <th>採用プロセス</th>
                                <td className="pre-wrap"> {recruit.selection_process} </td>
                            </tr>
                        )}
                        {(recruit.recruit_details !== undefined && recruit.recruit_details !== null) && (
                            recruit.recruit_details.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {(item.name !== undefined && item.name !== '' && item.contents !== undefined && item.contents !== '') && (
                                            <tr>
                                                <th>{item.name}</th>
                                                <td className="pre-wrap"> {item.contents} </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                )
                            })
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default RecruitInformation
