import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableComponent from './TableComponent';
import ConfigablePart from "../ConfigablePart";
import { parallaxScroll } from '../../../../../functions';

class LoadableTablePart extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    isEdit: true
  };

  constructor(props) {
    super(props);
    this.state = {}
    this.handleSwitchComponent = this.handleSwitchComponent.bind(this)
  }

  handleSwitchComponent(key, content) {
    const { index, isEdit, kindOfDesign } = this.props;
    if ([1,2,3,5,6,7,8].includes(key)) {
      return <TableComponent content={content} isEdit={isEdit} index={this.props.index}
                             handleChangeText={this.props.handleChangeText}
                             handleChangeHeadline={this.props.handleChangeHeadline}
                             handleChangeAlign={this.props.handleChangeAlign}
                             handleChangeTable={this.props.handleChangeTable}
                             handleChangeWidth={this.props.handleChangeWidth}
                             blockType={this.props.blockType} blockId={`80${index}0${kindOfDesign}`} />
    }
  }

  componentDidMount() {
    parallaxScroll();
  }

  render() {
    const { content, kindOfDesign, index, sectionStyle, isEdit } = this.props;
    return (
      <React.Fragment>
        <section key={kindOfDesign} id={`80${index}0${kindOfDesign}`} className={`block_table_${kindOfDesign} delay3 ${content.parallax != 1 ? "parallax" : ""} ${content.width_class} ${content.widthTable} ${content.wOption} blockarea ${content.top_padding_class}  ${content.bottom_padding_class} edit_outer delay4Active editing block_table delay3Active`}
          style={sectionStyle}>
          {isEdit && <ConfigablePart {...this.props} />}
          {
            content.parallax != 1 &&
            <div className="parallax-wrap">
              <div className="parallax_img" style={{backgroundImage: `url(${content.background_image_url})`, height: '100%', transform: 'translateY(-50%)'}} />
            </div>
          }
          <div className="content_wrapper">
            {this.handleSwitchComponent(kindOfDesign, content)}
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default (LoadableTablePart)
