require('datatables.net-bs4');

$( document ).ready(function() {
  if($('[id^=galleries_datatable_wrapper]').length == 0 && $('#galleries_datatable').length > 0) {
    table = $('#galleries_datatable').DataTable({
      dom: "<'row'<'col-6'l><'#gallery_btn_add.col-6 text-right'>>" +
        "<'row'<'#gallery_btn_batch_delete.col-sm-12'>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'float-right mb-3'p>",
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        emptyTable: "ギャラリーは登録されておりません",
        zeroRecords: "ギャラリーは登録されておりません",
        lengthMenu: "_MENU_ を表示する",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        }
      },
      order: [],
      ordering: false,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
          width: "10%",
        },
        {
          targets: 3,
          orderable: false,
          width: "10%",
        },
        {
          targets: 4,
          orderable: false,
        },
        {
          targets: 5,
          orderable: false,
        },
        {
          targets: 6,
          visible: false,
        },
        {
          targets: 7,
          visible: false,
        },
      ],
      drawCallback: function() {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);
      },
      stateSave: true
    });

    $('#galleries_datatable_length').before('<label class="mr-2">' +
      '<select id="gallery_select_display_flag" class="custom-select custom-select-sm form-control form-control-sm">' +
      '<option value="">-- 表示・非表示 --</option>' +
      '<option value="1">表示</option>' +
      '<option value="0">非表示</option>' +
      '</select>' +
      '</label>');
    selectGalleryCategoryElement($('#galleries_datatable').data("gallery-categories"));
    if (!/iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent)) {
      $('#select_category').select2({
        width: 'element',
        minimumResultsForSearch: -1
      });
    }
    $('#gallery_btn_add').append(`<a href='${$('#galleries_datatable').data("add-new-link")}' class='btn btn-info'>新規追加</a>`);

    setTimeout(function() {
      search = table.state().columns[6].search.search
      $('#gallery_select_display_flag').val("");
      switch(search) {
        case "true": $('#gallery_select_display_flag').val("1"); break;
        case "false": $('#gallery_select_display_flag').val("0"); break;
      }
    }, 450);
    $('#gallery_select_display_flag').on( 'change', function () {
      if (this.value == "") {
        valueSearch = "";
      } else if (this.value == 1) {
        valueSearch = "true";
      } else {
        valueSearch = "false";
      }

      table.columns(6).search(valueSearch).draw();
    } );

    $('#select_category').on('change', function () {
      const val = $.fn.dataTable.util.escapeRegex(
        $(this).val()
      );
      table.column(7).search(val ? '^'+val+'$' : '', true, false).draw();
    });
  }
});

function selectGalleryCategoryElement(galleryCategories) {
  var $labelElm = $("<label>", {class: "mr-2"});
  var $selectElm = $("<select>", {class: "custom-select custom-select-sm form-control form-control-sm", id: "select_category"});
  var $option = $("<option>", {text: "カテゴリー一覧", value: ""});
  $selectElm.append($option);
  $.each(galleryCategories, function( index, value ) {
    var $option = $("<option>", {text: value["name"], value: value["id"]});
    $selectElm.append($option);
  });
  // $selectElm.on('change', filterGalleryByCategory);
  $labelElm.append($selectElm);
  $("#gallery_select_display_flag").parent().before($labelElm);
}
