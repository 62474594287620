import {createAction} from 'redux-actions';

export const is_requesting = createAction('IS_REQUESTING');
export const isDisplayModalAdd = createAction('IS_DISPLAY_MODAL_ADD');
export const addDataUi = createAction('ADD_DATA_UI');
export const updateSetting = createAction('UPDATE_SETTING');
export const saveDataUi = createAction('SAVE_DATA_UI');
export const pageStructureData = createAction('PAGE_STRUCTURE_DATA');
export const pageStructureInSetting = createAction('PAGE_STRUCTURE_IN_SETTING');
export const headerSetting = createAction('HEADER_SETTING');
export const generalSetting = createAction('GENERAL_SETTING');
export const footerSetting = createAction('FOOTER_SETTING');
export const footerNav = createAction('FOOTER_NAV');
export const addPageDataUi = createAction('ADD_PAGE_DATA_UI');
export const editPageDataUi = createAction('EDIT_PAGE_DATA_UI');
export const editDataUi = createAction('EDIT_DATA_UI');
export const savePageDataUi = createAction('SAVE_PAGE_DATA_UI');
export const saveImageUrl = createAction('SAVE_IMAGE_URL');
export const isOpenImageModal = createAction('IS_OPEN_IMAGE_MODAL');
export const saveBlockImage = createAction('SAVE_BLOCK_IMAGE');
export const saveSideNavigation = createAction('SAVE_SIDE_NAVIGATION');
export const saveSideNavigationPage = createAction('SAVE_SIDE_NAVIGATION_PAGE');
export const isOpenImageSettingModal = createAction('IS_OPEN_IMAGE_SETTING_MODAL')
export const homeStructure = createAction('HOME_STRUCTURE')
export const newpageCategories = createAction('NEWPAGE_CATEGORIES')
export const loadComparisonCategories = createAction('LOAD_COMPARISON_CATEGORIES')
export const questionCategories = createAction('QUESTION_CATEGORIES')
export const galleryCategories = createAction('GALLERY_CATEGORIES')
export const menuCategories = createAction('MENU_CATEGORIES')
export const contactOptions = createAction('CONTACT_OPTIONS')
export const saveSubNavigation = createAction('SAVE_SUB_NAVIGATION')
export const saveBlockHistory = createAction('SAVE_BLOCK_HISTORY')
export const newsCategories = createAction('NEWS_CATEGORIES')
export const loadCoupons = createAction('LOAD_COUPONS')
export const staffCategories = createAction('STAFF_CATEGORIES')
export const recruitCategories = createAction('RECRUIT_CATEGORIES')
export const saveReleaseDate = createAction('SAVE_RELEASE_DATE')
export const isCssSetting = createAction('IS_CSS_SETTING')
export const saveRecuits = createAction('SAVE_RECRUITS')
export const tagsList = createAction('TAGS_LIST')
export const saveNewpageTags = createAction('SAVE_NEWPAGE_TAGS')
export const loadAllPages = createAction('LOAD_ALL_PAGES')
export const updateErrorMessages = createAction('UPDATE_ERROR_MESSAGES');
export const loadComparisonData = createAction('LOAD_COMPARISON_DATA');
export const savePageCategoryIds = createAction('SAVE_PAGE_CATEGORY_IDS');
export const loadShopsData = createAction('LOAD_SHOPS_DATA');
export const saveSettingAdvance = createAction('SAVE_SETTING_ADVANCE');
export const saveSettingIdString = createAction('SAVE_SETTING_ID_STRING');
export const localSideNavigations = createAction('LOCAL_SIDE_NAVIGATIONS');
export const setTokenHttpClient = createAction('SET_TOKEN_HTTP_CLIENT');
export const setTenants = createAction('SET_TENANTS');
export const setClientId = createAction('SET_CLIENT_ID');
