import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConfigablePart from "../ConfigablePart";
import HeadlineComponent from '../HeadlineComponent';
import request from "../../../../../untils/request";
import { APIS } from "../../../../../untils/constants";
import { parallaxScroll, convertDateTimeLocalContent } from '../../../../../functions';
import {connect} from 'react-redux';

class LoadableChangeLogPart extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    isEdit: true
  };

  constructor(props) {
    super(props);
    this.state = { change_logs: [] }
  }

  async componentDidMount () {
    const thisComponent = this
    await request({
      method: 'post',
      url: APIS.get_change_logs,
      data: { client_id: this.props.clientId }
    }).then(response => {
      thisComponent.setState({change_logs: response.data.change_logs})

    })
    parallaxScroll()
    convertDateTimeLocalContent();
  }

  showChangeLogsByLine(change_logs_slice) {
    const {isEdit} = this.props

    return (
      change_logs_slice.map((item, index) => {
        return (
          <li key={index} className='change_log'>
            <a href={ isEdit ? '#!' : item.url }>
              <p className="release_time">{ item.updated_at }</p>
              <p>{ item.parameters.trackable_name }を更新しました</p>
            </a>
          </li>
        )
      })
    )
  }

  showChangeLogs() {
    const {item} = this.props;
    const kind_of_block = item.kind_of_design === 2 ? "2" : "1"
    const {change_logs} = this.state

    let groupByN = (n, data) => {
      let result = [];
      for (let i = 0; i < data.length; i += n) result.push(data.slice(i, i + n));
      return result;
    };
    let changeLogsData;
    if (kind_of_block === "2") {
      changeLogsData = groupByN(4, change_logs.slice(0, this.props.content.change_log_number))
    } else {
      changeLogsData = [change_logs.slice(0, this.props.content.change_log_number)]
    }

    return (
      changeLogsData.map((change_logs_slice) => {
        return (
          <ul>
            {
              this.showChangeLogsByLine(change_logs_slice)
            }
          </ul>
        )
      })
    )
  }

  render() {
    const {isEdit, item, sectionStyle} = this.props;
    const content = JSON.parse(item.contents)
    const kind_of_block = item.kind_of_design === 2 ? "2" : "1"
    return (
      <section key={item.kind_of_design} id={item.kind_of_design} className={`block_news_${kind_of_block} delay3 ${content.width_class} blockarea ${content.top_padding_class}  ${content.bottom_padding_class} edit_outer without delay4Active editing block_change_log delay3Active`}
        style={sectionStyle}>
        {isEdit && <ConfigablePart {...this.props} />}
        {
          content.parallax != 1 &&
          <div className="parallax-wrap">
            <div className="parallax_img" style={{backgroundImage: `url(${content.background_image_url})`, height: '100%', transform: 'translateY(-50%)'}} />
          </div>
        }
        <div className="content_wrapper">
          {
            content.headlineType !== this.headlineNone &&
            <HeadlineComponent content={content} index={this.props.index}
                              isEdit={isEdit}
                              handleChangeHeadline={this.props.handleChangeHeadline}
                              handleChangeSubHeadline={this.props.handleChangeSubHeadline}
                              handleChangeAlign={this.props.handleChangeAlign} />
          }
          {
            this.showChangeLogs()
          }
        </div>
      </section>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.clientId = storeState.clientId;
  return newState;
}

export default connect(mapStateToProps, null)(LoadableChangeLogPart)