import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from "react-loader-spinner";

class Loading extends Component {
  constructor (props) {
    super(props);
  }

  render () {
    const { isRequesting } = this.props;
    return (
      isRequesting && (
      <div id="loading_modal" className="active loaded">
        <div className="wrapper">
          <div id="contents">
            <div className="spinner">
              <Loader type="ThreeDots" color="#09b7de" height="100" width="100" />
            </div>
          </div>
        </div>
      </div>
      )
    );
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.isRequesting = storeState.isRequesting;

  return newState;
};
export default connect(
  mapStateToProps
)(Loading);
