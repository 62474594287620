import React, { Component } from 'react';
import { IMAGES } from '../../../../../untils/constants'

export class ImageSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    const { settings, images } = this.props;
    let no_image_url = IMAGES.no_image

    return (
      <div className="step" data-step="#block_mv" style={{ width: '33.3333%' }}>
        <div className="wrap">
          <h3><span>画像設定</span></h3>
          <div className="item mvimg">
            <p>MV1枚目</p>
            <div className="img" onClick={this.props.handleScreenImage}>
              <img id="image01" data-step="2" data-name="image01" src={ images.mv_image01_url.length > 0 ? images.mv_image01_url : no_image_url } alt="" />
              <span className="admin_edit_img" data-name="image01" data-step="2"><i className="material-icons">collections</i></span>
            </div>
            <div className="pos">
              <span className="select">
                <select name="image01_position" defaultValue={settings.image01_position} className="form-control" onChange={this.props.handleChange}>
                  <option value="">画像配置</option>
                  <option value="pos_lt">左上</option>
                  <option value="pos_ct">中央上</option>
                  <option value="pos_rt">右上</option>
                  <option value="pos_l">左</option>
                  <option value="pos_c">中央</option>
                  <option value="pos_r">右</option>
                  <option value="pos_lb">左下</option>
                  <option value="pos_cb">中央下</option>
                  <option value="pos_rb">右下</option>
                </select>
              </span>
            </div>
          </div>

          <div className="item mvimg">
            <p>MV2枚目</p>
            <div className="img" onClick={this.props.handleScreenImage}>
              <img id="image02" data-step="2" data-name="image02" src={ images.mv_image02_url ? images.mv_image02_url : no_image_url }  alt="" />
              <span className="admin_edit_img" data-name="image02" data-step="2"><i className="material-icons">collections</i></span>
            </div>
            <div className="pos">
              <span className="select">
                <select name="image02_position" defaultValue={settings.image02_position} className="form-control" onChange={this.props.handleChange}>
                  <option value="">画像配置</option>
                  <option value="pos_lt">左上</option>
                  <option value="pos_ct">中央上</option>
                  <option value="pos_rt">右上</option>
                  <option value="pos_l">左</option>
                  <option value="pos_c">中央</option>
                  <option value="pos_r">右</option>
                  <option value="pos_lb">左下</option>
                  <option value="pos_cb">中央下</option>
                  <option value="pos_rb">右下</option>
                </select>
              </span>
            </div>
          </div>

          <div className="item mvimg">
            <p>MV3枚目</p>
            <div className="img" onClick={this.props.handleScreenImage}>
              <img id="image03" data-step="2" data-name="image03" src={ images.mv_image03_url ? images.mv_image03_url : no_image_url }  alt="" />
              <span className="admin_edit_img" data-name="image03" data-step="2"><i className="material-icons">collections</i></span>
            </div>
            <div className="pos">
              <span className="select">
                <select name="image03_position" defaultValue={settings.image03_position} className="form-control" onChange={this.props.handleChange}>
                  <option value="">画像配置</option>
                  <option value="pos_lt">左上</option>
                  <option value="pos_ct">中央上</option>
                  <option value="pos_rt">右上</option>
                  <option value="pos_l">左</option>
                  <option value="pos_c">中央</option>
                  <option value="pos_r">右</option>
                  <option value="pos_lb">左下</option>
                  <option value="pos_cb">中央下</option>
                  <option value="pos_rb">右下</option>
                </select>
              </span>
            </div>
          </div>

          <div className="item mvimg">
            <p>MV4枚目</p>
            <div className="img" onClick={this.props.handleScreenImage}>
              <img id="image04" data-step="2" data-name="image04" src={ images.mv_image04_url ? images.mv_image04_url : no_image_url }  alt="" />
              <span className="admin_edit_img" data-name="image04" data-step="2"><i className="material-icons">collections</i></span>
            </div>
            <div className="pos">
              <span className="select">
                <select name="image04_position" defaultValue={settings.image04_position} className="form-control" onChange={this.props.handleChange}>
                  <option value="">画像配置</option>
                  <option value="pos_lt">左上</option>
                  <option value="pos_ct">中央上</option>
                  <option value="pos_rt">右上</option>
                  <option value="pos_l">左</option>
                  <option value="pos_c">中央</option>
                  <option value="pos_r">右</option>
                  <option value="pos_lb">左下</option>
                  <option value="pos_cb">中央下</option>
                  <option value="pos_rb">右下</option>
                </select>
              </span>
            </div>
          </div>

          <div className="item mvimg">
            <p>MV5枚目</p>
            <div className="img" onClick={this.props.handleScreenImage}>
              <img id="image05" data-step="2" data-name="image05" src={ images.mv_image05_url ? images.mv_image05_url : no_image_url }  alt="" />
              <span className="admin_edit_img" data-name="image05" data-step="2"><i className="material-icons">collections</i></span>
            </div>
            <div className="pos">
              <span className="select">
                <select name="image05_position" defaultValue={settings.image05_position} className="form-control" onChange={this.props.handleChange}>
                  <option value="">画像配置</option>
                  <option value="pos_lt">左上</option>
                  <option value="pos_ct">中央上</option>
                  <option value="pos_rt">右上</option>
                  <option value="pos_l">左</option>
                  <option value="pos_c">中央</option>
                  <option value="pos_r">右</option>
                  <option value="pos_lb">左下</option>
                  <option value="pos_cb">中央下</option>
                  <option value="pos_rb">右下</option>
                </select>
              </span>
            </div>
          </div>

          <div className="item mvimg">
            <p>MV6枚目</p>
            <div className="img" onClick={this.props.handleScreenImage}>
              <img id="image06" data-step="2" data-name="image06" src={ images.mv_image06_url ? images.mv_image06_url : no_image_url }  alt="" />
              <span className="admin_edit_img" data-name="image06" data-step="2"><i className="material-icons">collections</i></span>
            </div>
            <div className="pos">
              <span className="select">
                <select name="image06_position" defaultValue={settings.image06_position} className="form-control" onChange={this.props.handleChange}>
                  <option value="">画像配置</option>
                  <option value="pos_lt">左上</option>
                  <option value="pos_ct">中央上</option>
                  <option value="pos_rt">右上</option>
                  <option value="pos_l">左</option>
                  <option value="pos_c">中央</option>
                  <option value="pos_r">右</option>
                  <option value="pos_lb">左下</option>
                  <option value="pos_cb">中央下</option>
                  <option value="pos_rb">右下</option>
                </select>
              </span>
            </div>
          </div>

          <div className="item mvimg">
            <p>MV7枚目</p>
            <div className="img" onClick={this.props.handleScreenImage}>
              <img id="image07" data-step="2" data-name="image07" src={ images.mv_image07_url ? images.mv_image07_url : no_image_url }  alt="" />
              <span className="admin_edit_img" data-name="image07" data-step="2"><i className="material-icons">collections</i></span>
            </div>
            <div className="pos">
              <span className="select">
                <select name="image07_position" defaultValue={settings.image07_position} className="form-control" onChange={this.props.handleChange}>
                  <option value="">画像配置</option>
                  <option value="pos_lt">左上</option>
                  <option value="pos_ct">中央上</option>
                  <option value="pos_rt">右上</option>
                  <option value="pos_l">左</option>
                  <option value="pos_c">中央</option>
                  <option value="pos_r">右</option>
                  <option value="pos_lb">左下</option>
                  <option value="pos_cb">中央下</option>
                  <option value="pos_rb">右下</option>
                </select>
              </span>
            </div>
          </div>
        </div>

        <div className="modal_footer" style={{display: 'flex'}}><a href="#" data-step="0" onClick={this.props.handleScreenImage}>« MV設定へ</a></div>
      </div>
    )
  }
}

export default ImageSetting
