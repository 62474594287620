import React, { Component } from 'react';
import { connect } from 'react-redux';

export class SideNavigationBottom extends Component {
  constructor(props) {
    super(props);
    this.SideNavigationTop = [1, 2, 3, 4, 5, 6, 7, 8]
  }
  handleshowWithoutLink(imageUrl) {
    return <li><img src={imageUrl} alt="サイドバナー" /></li>
  }

  handleshowWithLink(link, imageUrl) {
    return (
      <li>
        <a href={link}>
          <img src={imageUrl} alt="サイドバナー" />
        </a>
      </li>
    )
  }
  render() {
    const { sideNavigations, isShow } = this.props;
    if (isShow) {
      return (
        <div className="sidebanner">
          <ul>
            {this.SideNavigationTop.map(index => {
              let imageNameUrl = `b_snavi_image0${index}_url`
              let link = `b_snavi_linktext0${index}`
  
              if (sideNavigations[imageNameUrl].length == 0) return;
  
              if (sideNavigations[link].length > 0) {
                return this.handleshowWithLink(sideNavigations[link], sideNavigations[imageNameUrl])
              }
  
              return this.handleshowWithoutLink(sideNavigations[imageNameUrl])
            })}
          </ul>
        </div>
      )
    }
    
    return null
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.sideNavigations = storeState.sideNavigations;
  newState.isShow = [1, 2, 3, 4, 5, 6, 7, 8].filter(index => {
    let name = `b_snavi_image0${index}_url`

    return newState.sideNavigations[name];
  }).length > 0
  return newState;
}

export default connect(mapStateToProps)(SideNavigationBottom);
