import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import HeaderContent from "./HeaderContent";
import HeaderMenu from "./HeaderMenu";
import { resizeHeader } from '../../functions';

export class HeaderComponent extends Component {
  static propTypes = {
    headerSetting: PropTypes.object,
    isDisplay: PropTypes.bool
  };

  static defaultProps = {
    headerSetting: {},
    isDisplay: false
  };

  componentDidUpdate() {
    if(this.props.isDisplay) {
      resizeHeader()
    }
  }

  render() {
    const { headerSetting, isDisplay, settingsAdvance } = this.props;
    let css2 = ''
    if (headerSetting.css2 == 1) css2 = 'over' 
  
    let css3 = ''
    if (headerSetting.css3 == 1) css3 = 'nofixed' 
  
    let css4 = ''
    if (headerSetting.css4 == 1) css4 = 'width_full'
    return (
      <React.Fragment>
        {isDisplay && <div dangerouslySetInnerHTML={{__html: settingsAdvance.header_content}}></div>}
        {(!isDisplay && settingsAdvance.header_content != undefined) && (
          <React.Fragment>
            <div dangerouslySetInnerHTML={{__html: settingsAdvance.header_html}}></div>
            <header id={`pattern${headerSetting.css1 === null ? '' : headerSetting.css1}`} className={`header_class ${css2} ${css3} ${css4}`}>
              <HeaderContent />

              {headerSetting.css1 != 4 && <HeaderMenu />}
            </header>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.headerSetting = storeState.headerSetting;
  newState.settingsAdvance = storeState.settingsAdvance;
  if (storeState.settingsAdvance.footer_content != undefined) {
    newState.isDisplay = storeState.settingsAdvance.header_content.length > 0;
  }

  return newState;
}

export default connect(mapStateToProps)(HeaderComponent)
