import React, {Component} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";

import {
  faCode,
} from "@fortawesome/free-solid-svg-icons";

export default class HightLevelBlocks extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {dataKind, optionBlockShow} = this.props
    const isOpened = optionBlockShow.value === "hightLevel" && optionBlockShow.isOpen
    return (
      window.innerWidth > 480 ?
        <li>
          <a href="#!" style={{backgroundColor: isOpened ? "#eee" : "#fff"}}
             onClick={() => this.props.handleOpenBlockOptions("hightLevel")}>
            <FontAwesomeIcon icon={isOpened ? faAngleDown : faAngleRight}/>
            <p>高度な設定</p>
          </a>
          {isOpened &&
          <ul>
            <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockHtml"
                   className={dataKind == 'blockHtml' ? 'selected' : ''}>
              <FontAwesomeIcon icon={faCode}/>HTML入力</a>
            </li>
          </ul>
          }
        </li>
        :
        <>
          <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockHtml"
                 className={dataKind == 'blockHtml' ? 'selected' : ''}>
            <FontAwesomeIcon icon={faCode}/>HTML入力</a>
          </li>
        </>
    )
  }
}
