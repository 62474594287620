import React, { Component } from 'react'
import ConfigablePart from "../ConfigablePart";
import PropTypes from 'prop-types';
import { APIS, S3_URL, HTML_REGEX } from "../../../../../untils/constants";
import request from "../../../../../untils/request";
import HeadlineComponent from '../HeadlineComponent';
import ExplanationComponent from '../ExplanationComponent';
import { comparisonPopup } from '../../../../../functions';
import { parallaxScroll } from '../../../../../functions';
import { connect } from 'react-redux';

class LoadableShopPart extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    isEdit: true,
  };

  constructor(props) {
    super(props)
    this.state = { shops: {} }
  }

  componentDidMount() {
    parallaxScroll();
    comparisonPopup(this.props.pageStatus, S3_URL)
    this.getShops();
  }

  async getShops() {
    await request({
      method: 'post',
      url: APIS.get_shops,
      data: { client_id: this.props.clientId }
    }).then(response => {
      this.setState({shops: response.data.shops})
    })
  }

  render() {
    const { content, kindOfDesign, sectionStyle, generalSetting } = this.props;
    const showMap = this.props.content.map_flag && this.props.content.address1
    const popup_flag = this.props.item.popup_flag
    const { shops } = this.state

    let image_url = "#!"
    if(generalSetting != undefined){
      image_url = generalSetting.popup_flag === 0 ? this.props.content.image_url : "#!"
    } else {
      image_url = popup_flag === 0 ? this.props.content.image_url : "#!"
    }

    let imageOrMap = this.props.content.image_url || (showMap && this.props.kindOfDesign == 2)

    return (
      <section key={kindOfDesign} id={kindOfDesign}
               className={`widget_shop01 block_map_1 delay3 ${content.width_class} blockarea  ${content.top_padding_class} ${content.parallax != 1 ? "parallax" : ""} ${content.bottom_padding_class} edit_outer without delay4Active editing block_shop delay3Active`}
               style={sectionStyle}>
        {this.props.isEdit && <ConfigablePart {...this.props} />}
        {
          content.parallax != 1 &&
          <div className="parallax-wrap">
            <div className="parallax_img" style={{backgroundImage: `url(${content.background_image_url})`, height: '100%', transform: 'translateY(-50%)'}} />
          </div>
        }
        <div className="content_wrapper">
          <HeadlineComponent content={this.props.content} index={this.props.index}
                             isEdit={this.props.isEdit}
                             handleChangeHeadline={this.props.handleChangeHeadline}
                             handleChangeAlign={this.props.handleChangeAlign}
                             handleChangeText={this.props.handleChangeText}
                             blockType={this.props.blockType} />
          {
            this.props.content.headlineType == "2" &&
            <ExplanationComponent content={this.props.content} index={this.props.index}
                                  isEdit={this.props.isEdit}
                                  handleChangeHeadline={this.props.handleChangeHeadline}
                                  handleChangeAlign={this.props.handleChangeAlign} />
          }
          {
            Object.keys(shops).length > 0 &&
            <div className="wrapper_item">
              {imageOrMap &&
                <div className="inner_item_img">
                  {this.props.content.image_url &&
                    <a href={image_url}>
                      <img src={this.props.content.image_url} alt={this.props.content.name}/>
                    </a>
                  }
                  {showMap && this.props.kindOfDesign == 2 &&
                    <div className="map">
                      <iframe className="lozad"
                              src={`https://maps.google.co.jp/maps?q=${this.props.content.address1}&output=embed&t=m`}></iframe>
                    </div>
                  }
                </div>
              }
              <div className="inner_item_txt">
                <div className="heading block_header_4 align-left">
                  <h3 className="h">{this.props.content.name}</h3>
                </div>
                {
                  this.props.content.shop_details && this.props.content.shop_details.map((shop_detail) => {
                    if (shop_detail.name === 'fixed_details_address') {
                      return ((content.zip3 || content.address1 || content.address2) &&
                        <dl>
                          <dt>所在地</dt>
                          <dd>
                            {content.zip3 &&
                              <div>
                                {`〒${this.props.content.zip3}-${this.props.content.zip4}`}
                              </div>
                            }
                            {(content.address1 || content.address2) &&
                              <div>
                                {this.props.content.address1}{this.props.content.address2}
                              </div>
                            }
                          </dd>
                        </dl>
                      )
                    }
                    else if (shop_detail.name === 'fixed_details_tel') {
                      return (content.tel &&
                        <dl>
                          <dt>電話番号</dt>
                          <dd>
                            <div className="tel" x-ms-format-detection="none">{this.props.content.tel}</div>
                          </dd>
                        </dl>
                      )
                    }
                    else if (shop_detail.name === 'fixed_details_fax') {
                      return (content.fax &&
                        <dl>
                          <dt>FAX番号</dt>
                          <dd>
                            <div className="tel" x-ms-format-detection="none">{this.props.content.fax}</div>
                          </dd>
                        </dl>
                      )
                    }
                    else if (shop_detail.name && shop_detail.content) {
                      return (
                        <dl key={shop_detail.id}>
                          <dt>{shop_detail.name}</dt>
                          <dd style={{ whiteSpace: HTML_REGEX.test(shop_detail.content) ? "" : "pre-line" }}>
                            { shop_detail.content }
                          </dd>
                        </dl>
                      )
                    }
                  })
                }
              </div>
            </div>
          }

          {showMap && this.props.kindOfDesign == 1 && Object.keys(shops).length > 0 &&
            <div className="map">
              <iframe className="lozad"
                      src={`https://maps.google.co.jp/maps?q=${this.props.content.address1}&output=embed&t=m`}></iframe>
            </div>
          }
        </div>
      </section>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.generalSetting = storeState.generalSetting
  newState.clientId = storeState.clientId;
  return newState
}

export default connect(mapStateToProps)(LoadableShopPart)
