import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faTwitter, faInstagram, faBloggerB} from "@fortawesome/free-brands-svg-icons";
import {connect} from "react-redux";
import {IMAGES} from '../../untils/constants';
import {fixbtn} from '../../functions';

class FooterMenu extends Component {
    static propTypes = {
        footerSetting: PropTypes.object,
        footerNav: PropTypes.array
    };

    static defaultProps = {
        footerSetting: {},
        footerNav: []
    };

    constructor(props) {
        super(props);
        this.renderSnsButton = this.renderSnsButton.bind(this);
    }

    componentDidUpdate() {
        fixbtn()
    }

    renderSnsButton() {
        let footerSetting = this.props.footerSetting
        if (this.props.f_d_sns_flag === false) {
            return (<div/>)
        }

        return (
            <ul className="sns">
                {(footerSetting.css_footer != '2' || footerSetting.f_pc_sns_flag) &&
                <React.Fragment>
                    {
                        footerSetting.ameblo_id !== '' &&
                        <li><a href={footerSetting.ameblo_id} title="Blog" target="_blank"><FontAwesomeIcon
                            icon={faBloggerB}/></a></li>
                    }
                    {
                        footerSetting.facebook_account !== '' &&
                        <li><a href={footerSetting.facebook_account} title="Facebook" target="_blank"><FontAwesomeIcon
                            icon={faFacebook}/></a></li>
                    }
                    {
                        footerSetting.twitter_account !== '' &&
                        <li><a href={footerSetting.twitter_account} title="Twitter" target="_blank"><FontAwesomeIcon
                            icon={faTwitter}/></a></li>
                    }
                    {
                        footerSetting.instagram_account !== '' &&
                        <li><a href={footerSetting.instagram_account} title="Instagram" target="_blank"><FontAwesomeIcon
                            icon={faInstagram}/></a></li>
                    }
                    <span/>
                </React.Fragment>
                }
            </ul>
        )
    }

    renderFooterNav() {
        const {isSubNavigationPage, f_d_navi_flag, footerSetting, footerNav} = this.props
        if (f_d_navi_flag === false) {
            return (<div/>)
        }

        return (
            <React.Fragment>
                {footerNav.map((item, index) => {
                    if (isSubNavigationPage) {
                        return (
                            <li key={index}>
                                <Link to={item.url}><span dangerouslySetInnerHTML={{__html: item.page_title}}></span></Link>
                            </li>
                        )
                    }

                    return (
                        <li key={index}>
                            <Link to={item[1]}><span dangerouslySetInnerHTML={{__html: item[0]}}></span></Link>
                        </li>
                    )
                })}

                {
                    footerSetting.sitemap_flag &&
                    <li>
                        <Link to='#'><span dangerouslySetInnerHTML={{__html: footerSetting.sitemap_link.length > 0 ? footerSetting.sitemap_link : 'サイトマップ'}}></span></Link>
                    </li>
                }

                {
                    footerSetting.dictionary_flag &&
                    <li>
                        <Link to='#'><span dangerouslySetInnerHTML={{__html: footerSetting.dictionary_name.length > 0 ? footerSetting.dictionary_name : '用語一覧'}}></span></Link>
                    </li>
                }

                {
                    footerSetting.corporate_site_name &&
                    ((footerSetting.corporate_link_type == 1 && footerSetting.corporate_site_url) ||
                        (footerSetting.corporate_link_type == 0 && footerSetting.corporate_site_page_id)) &&
                    <li className='ft-menu-p2'>
                        <Link to='#'><span dangerouslySetInnerHTML={{__html: footerSetting.corporate_site_name}}></span></Link>
                    </li>
                }

                {
                    footerSetting.privacy_policy_name &&
                    ((footerSetting.privacy_policy_link_type == 1 && footerSetting.privacy_policy_url) ||
                        (footerSetting.privacy_policy_link_type == 0 && footerSetting.privacy_policy_page_id)) &&
                    <li className='ft-menu-p2'>
                        <Link to='#'><span dangerouslySetInnerHTML={{__html: footerSetting.privacy_policy_name}}></span></Link>
                    </li>
                }

            </React.Fragment>
        )
    }

    renderFooterLogo() {
        let footerSetting = this.props.footerSetting;
        let footer_image_url = IMAGES.logo_sample;
        if (footerSetting !== undefined &&
            footerSetting.footer_image !== null &&
            footerSetting.footer_image !== '') {
            footer_image_url = footerSetting.footer_image
        }
        return (
            <React.Fragment>
                <Link className={`logo-footer${footerSetting.company_footer_type}`} to="/">
                    <img src={footer_image_url} alt="【デモ】Touch&Links"/>
                </Link>

                <div className={`company-info`}>
                    <h2 className={`company-footer${footerSetting.company_footer_type}`} dangerouslySetInnerHTML={{__html: footerSetting.company_name}}/>
                    {footerSetting.common_address &&
                    <p dangerouslySetInnerHTML={{__html: footerSetting.common_address}}/>}
                    {footerSetting.recruiter_flg && footerSetting.recruiter_name &&
                    <p dangerouslySetInnerHTML={{__html: `採用担当者名: ${footerSetting.recruiter_name}`}}/>}
                    {footerSetting.corporate_site_name &&
                    ((footerSetting.corporate_link_type == 1 && footerSetting.corporate_site_url) ||
                        (footerSetting.corporate_link_type == 0 && footerSetting.corporate_site_page_id)) &&
                    <p>
                        <span dangerouslySetInnerHTML={{__html: `${footerSetting.corporate_site_name}: `}}/>
                        <a className='corp-url' href='#'>{footerSetting.corporate_url}</a>
                    </p>}
                </div>
            </React.Fragment>
        )
    }

    render() {
        let {footerSetting, generalSetting} = this.props;
        if (this.props.f_d_flag === false) {
            return (<div/>)
        }

        return (
            <div className="content_wrapper">
                <nav id="footer_menu">
                    <ul id="expand_menu">
                        {this.renderFooterNav()}
                    </ul>
                    <div className="contents_btn01">
                        {footerSetting !== undefined && footerSetting.f_button_flag && this.props.renderFooterLink}
                    </div>
                </nav>
                <div className="logo-sns">
                    <div className="logo">
                        {
                            this.props.f_d_logo_flag === true &&
                            this.renderFooterLogo()
                        }
                    </div>
                    {
                        this.renderSnsButton()
                    }
                    {generalSetting != undefined &&
                    <div id="cp-pattern2" dangerouslySetInnerHTML={{__html: `© ${new Date().getFullYear()} ${generalSetting.site_title} ALL RIGHTS RESERVED.`}}></div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (storeState, ownProps) => {
    let newState = Object.assign({}, ownProps);

    newState.footerSetting = storeState.footerSetting;
    newState.footerNav = storeState.footerNav;
    newState.f_d_flag = storeState.settings.f_d_flag;
    newState.f_d_logo_flag = storeState.settings.f_d_logo_flag;
    newState.f_d_navi_flag = storeState.settings.f_d_navi_flag;
    newState.f_d_sns_flag = storeState.settings.f_d_sns_flag;

    return newState;
}

export default connect(mapStateToProps)(FooterMenu);
