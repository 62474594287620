import React, { Component } from 'react';
import request from "../../../../untils/request";
import { APIS } from "../../../../untils/constants";

export class TemplateList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templateList: []
    };

    this.renderTemplateList = this.renderTemplateList.bind(this);
  }

  async componentDidMount () {
    const {data: response} = await request({
      method: 'get',
      url: APIS.get_templates
    })

    this.setState({
      templateList: response.templates,
    })
  }

  renderTemplateList () {
    return (
      <React.Fragment>
        {this.state.templateList.map(item => {
          return (
            <tr key={item.id}>
              <td style={{width: "80%"}}>{item.name}</td>
              <td><a onClick={() => this.props.handleUseTemplate(item.id)} style={{cursor: 'pointer', color: '#007bff'}}>テンプレートを使用</a></td>
            </tr>
          )
        })}
      </React.Fragment>
    )
  }

  render() {
    return (
      <div className="step">
        <div className="wrap">
          <h3><span>テンプレート一覧</span></h3>
          <div className="item">
            <table className="table table-striped table-bordered table-hover" id="dataTables-example" width="100%">
              <thead>
                <tr>
                  <th>テンプレート名</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.renderTemplateList()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default TemplateList
