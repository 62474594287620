import React, { Component } from 'react'
import { connect } from 'react-redux';
import {isOpenImageModal, isOpenImageSettingModal} from '../../../../../Lib/redux/actions';
import HeadlineComponent from '../HeadlineComponent';
import PropTypes from 'prop-types';
import ConfigablePart from "../ConfigablePart";
import ExplanationComponent from '../ExplanationComponent';
import { IMAGES, FRONT_END_HOST } from '../../../../../untils/constants';
import { customImageSlider, parallaxScroll } from '../../../../../functions';

class LoadableImagePart extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    isVisitOuterLink: PropTypes.bool,
    idString: PropTypes.string,
  };

  static defaultProps = {
    isEdit: true,
    isVisitOuterLink: false,
    idString: null,
  };

  constructor(props) {
    super(props);
    this.headlineNone = "1"
    this.headlineFull = "2"
    this.blockType = 'block_image'
    this.showOperatorImageModal = this.showOperatorImageModal.bind(this);
    this.showBlockImageSettingModal = this.showBlockImageSettingModal.bind(this)
    this.imageUrl = this.imageUrl.bind(this)
  }

  componentDidMount() {
    customImageSlider(this.props, null);
    parallaxScroll();
  }

  componentDidUpdate(prevProps, prevState) {
    customImageSlider(this.props, prevProps);
  }

  showOperatorImageModal() {
    this.props.handleShowImageModal(
      {isOpenImageModal: !this.props.isOpenImageModal, index: this.props.index}
    )
  };

  showBlockImageSettingModal() {
    this.props.handleShowSetting(
      { isOpenImageSettingModal: !this.props.isOpenImageSettingModal, index: this.props.index }
    )
  }

  iconsEditImage() {
    return(
      <>
        <span className="admin_edit_link 372659" title="リンク設定" onClick={this.showBlockImageSettingModal}>
          <i className="material-icons">link</i>
        </span>
        <span className="admin_edit_img 372659" title="画像を変更" onClick={this.showOperatorImageModal}>
          <i className="material-icons">collections</i>
        </span>
      </>
    )
  }

  iconSetting() {
    return(
      <span className="admin_edit_img_w" title="画像幅を変更" style={{width: '914.4px', marginLeft: '101.6px', bottom: 14}}>
        <div className="inputslider ui-slider ui-corner-all ui-slider-horizontal ui-widget ui-widget-content">
          <span tabIndex={0} className="ui-slider-handle ui-corner-all ui-state-default" style={{left: '27.7778%'}} />
        </div>
        <input type="number" min={10} max={100} step={5} />
      </span>
    )
  }

  hrefValue() {
    const content = this.props.content
    switch (parseInt(content.block_image_link)) {
      case 1:
        return '#!'
      case 2:
        if (this.props.idString === null) { return '#!' }
        const homeUrl = `${FRONT_END_HOST}/${this.props.idString}`
        if (content.block_image_inner_link === '/') { return homeUrl }
        return `${homeUrl}${content.block_image_inner_link}.html`
      case 3:
        return content.block_image_outer_link
      default:
        break;
    }
  }

  imageUrl() {
    const {item} = this.props
    return item.block_image_url.length == 0 ? IMAGES.no_image : item.block_image_url
  }

  showBlockImage() {
    if(this.props.isVisitOuterLink) {
      const {content} = this.props;
      const target = (content.block_image_target && parseInt(content.block_image_link) !== 1) ? "_blank" : "_self"

      return(
        <a href={this.hrefValue()} target={target}>
          <picture>
            <img src={this.imageUrl()} alt={this.props.content.block_image_alt} />
          </picture>
        </a>
      )
    }
    return(
      <span>
        <picture>
          <img src={this.imageUrl()} alt={this.props.content.block_image_alt} />
        </picture>
      </span>
    )
  }

  render() {
    const { content, isEdit, index, handleChangeHeadline, handleChangeAlign, item, blockId, sectionStyle} = this.props;
    return (
      <>
        <section key={item.kind_of_design} id={blockId} className={`contents_box01 align-left block_image_${item.kind_of_design} ${content.parallax != 1 ? "parallax" : ""} delay3 ${content.width_class} ${content.widthImage} blockarea ${content.top_padding_class}  ${content.bottom_padding_class} edit_outer delay4Active editing block_image delay3Active`}
          style={sectionStyle}>
          {this.props.isEdit && <ConfigablePart {...this.props} />}
          {
            content.parallax != 1 &&
            <div className="parallax-wrap">
              <div className="parallax_img" style={{backgroundImage: `url(${content.background_image_url})`}} />
            </div>
          }
          <div className="content_wrapper">
          {
            content.headlineType != this.headlineNone &&
            <HeadlineComponent content={content} index={index}
                               isEdit={isEdit}
                               handleChangeHeadline={handleChangeHeadline}
                               handleChangeAlign={handleChangeAlign} />
          }
          {
            content.headlineType == this.headlineFull &&
            <ExplanationComponent content={content} index={index}
                                  isEdit={isEdit}
                                  handleChangeHeadline={handleChangeHeadline}
                                  handleChangeAlign={handleChangeAlign} />
          }
            <div className="wrapper_item" style={{paddingBottom: 0}}>
              <div className="inner_item_img">
                {isEdit && this.iconsEditImage()}
                {this.showBlockImage()}
                {isEdit && this.iconSetting()}
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);

  newState.isOpenImageModal = storeState.isOpenImageModal
  newState.isOpenImageSettingModal = storeState.isOpenImageSettingModal

  return newState;
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleShowImageModal: (data) => {dispatch(isOpenImageModal(data))},
    handleShowSetting: (data) => {dispatch(isOpenImageSettingModal(data))}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadableImagePart)
