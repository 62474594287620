export const fetchSectionStyle = (content) => {
  let sectionStyle = {}
  if(content.parallax == 1) {
    sectionStyle = {
      backgroundImage: `url(${content.background_image_url})`,
      backgroundRepeat: content.background_repeat,
      backgroundPosition: content.background_position,
      backgroundSize: content.background_size,
    }
    if(content.r_color != undefined) {
      sectionStyle.color = `rgba(${content.r_color}, ${content.g_color}, ${content.b_color}, ${content.a_color})`
    }
    if(content.r_background != undefined) {
      sectionStyle.backgroundColor = `rgba(${content.r_background}, ${content.g_background}, ${content.b_background}, ${content.a_background})`
    }
  }

  else {
    if(content.r_color != undefined) {
      sectionStyle.color = `rgba(${content.r_color}, ${content.g_color}, ${content.b_color}, ${content.a_color})`
    }
    if(content.r_background != undefined) {
      sectionStyle.backgroundColor = `rgba(${content.r_background}, ${content.g_background}, ${content.b_background}, ${content.a_background})`
    }
    sectionStyle.backgroundSize = content.background_size
  }
  return sectionStyle
}
