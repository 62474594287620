import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ContentEditable from "react-contenteditable";

export class Tel1 extends Component {
  constructor(props) {
    super(props);
    this.contentEditable1 = React.createRef()
  }

  render() {
    return (
      <React.Fragment>
        <ContentEditable
          innerRef={this.contentEditable1}
          html={this.props.content.tel_headline} // innerHTML of the editable div
          disabled={!this.props.isEdit}       // use true to disable editing
          onKeyUp={(event) => this.props.handleChangeHeadline(this.props.index, event, "tel_headline")} // handle innerHTML change
          tagName='div' // Use a custom HTML tag (uses a div by default)
          className='inner_item_txt contenteditable'
        />
        <div className="inner_item_tel">
          <a>
            <span>
              <FontAwesomeIcon icon={["fas", "phone-alt"]} />
              {this.props.content.block_tel_other1}
            </span>
          </a>
        </div>
      </React.Fragment>
    )
  }
}

export default Tel1
