require('datatables.net-bs4');

$( document ).ready(function() {
  if($('[id^=recruits_datatable_wrapper]').length == 0 && $('#recruits_datatable').length > 0) {
    table = $('#recruits_datatable').DataTable({
      dom: "<'row'<'col-6'l><'#recruit_btn_add.col-6 text-right'>>" +
        "<'row'<'#recruit_btn_batch_delete.col-sm-12'>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'float-right mb-3'p>",
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        emptyTable: "リクルートは登録されておりません",
        zeroRecords: "リクルートは登録されておりません",
        lengthMenu: "_MENU_ を表示する",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        }
      },
      order: [],
      ordering: false,
      columnDefs: [
        {
          targets: 0,
          orderable: false
        },
        {
          targets: 2,
          orderable: false
        },
        {
          targets: 3,
          orderable: false
        },
        {
          targets: 4,
          orderable: false
        },
        {
          targets: 5,
          orderable: false
        },
        {
          targets: 6,
          visible: false
        }
      ],
      drawCallback: function(settings) {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);
      },
      stateSave: true
    });

    $('#recruit_btn_add').append(`<a id='export-btn' class='btn btn-info'>求人連携</a>`);
    $('#recruit_btn_add').append(`<a href='${$('#recruits_datatable').data("add-new-link")}' class='btn btn-info ml-2'>新規追加</a>`);

    selectCategoryElement($('.show-data').data("category-options"));
    if (!/iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent)) {
      $('#select_category').select2({
        width: 'element',
        minimumResultsForSearch: -1
      });
    }

    $('#select_category').on('change', function(){
      const val = $.fn.dataTable.util.escapeRegex(
        $(this).val()
      );
      table.column(5).search(val ? '^'+val+'$' : '', true, false).draw();
    })
  }

  $("form").on("click", ".add_field", function(event) {
    let regexp, time;
    time = new Date().getTime();
    regexp = new RegExp($(this).data("id"), "g");
    target = $(this).data('target')
    $(target).append($(this).data("fields").replace(regexp, time));
    return event.preventDefault() ;
  });

  $("form").on("click", ".close_field", function(event) {
    $(this).prev("input[type=hidden]").val("1");
    $(this).closest("tr").hide();
    return event.preventDefault() ;
  });

  $("#export-btn").on("click", function(e) {
    e.preventDefault();
    $("#export-form").submit();
  });

  $(document).on("change", ".export-checkbox", function() {
    let hiddenFieldId = "#export-recruit-indeed-ids";
    if ($(this).data('service') === 'box') hiddenFieldId = "#export-recruit-box-ids";
    if ($(this).data('service') === 'standby') hiddenFieldId = "#export-recruit-standby-ids";

    let export_ids = $(hiddenFieldId).val().split(',');
    let index = export_ids.indexOf($(this).data("recruit").toString());
    if ($(this).is(":checked")) {
      if (index === -1) export_ids.push($(this).data("recruit"));
    } else {
      if (index !== -1) export_ids.splice(index, 1);
    }
    $(hiddenFieldId).val(export_ids.join())
  });

  $(function () {
    $('#recruit-detail-sortable-table tbody').sortable({
      cursor: 'move',
      items: 'tr',
      axis: 'Y',
      cancel: '#non-sort',
      handle: '.handle',
      // ドラッグ開始時に呼ばれる
      start: function (event, ui) {},
      // 並び替え中に、DOM要素の位置が変更されたときに呼び出される
      change: function (event, ui) {},
      // 並び替えが終了し、DOM要素の位置が変更されたときに呼び出される
      update: function (event, ui) {
        recruitDetailLineSort();
      },
      // 他の Sortable 要素からアイテムを受け取ったときに呼び出される
      receive: function (event, ui) {},
      // ドラッグ終了時に呼ばれる
      stop: function (event, ui) {},
    });
  });
});

function recruitDetailLineSort() {
  $("#item-tbody tr").each(function (i) {
    $(this).find('.target-01').attr('id', `recruit_recruit_details_attributes_${i}_name`);
    $(this).find('.target-02').attr('id', `recruit_recruit_details_attributes_${i}_contents`);
    $(this).find('.target-destroy').attr('id', `recruit_recruit_details_attributes_${i}__destroy`);
    $(this).find('.target-01').attr('name', `recruit[recruit_details_attributes][${i}][name]`);
    $(this).find('.target-02').attr('name', `recruit[recruit_details_attributes][${i}][contents]`);
    $(this).find('.target-destroy').attr('name', `recruit[recruit_details_attributes][${i}][_destroy]`);
  });
}

function selectCategoryElement(menuCategories) {
  var $labelElm = $("<label>", {class: "mr-2"});
  var $selectElm = $("<select>", {class: "custom-select custom-select-sm form-control form-control-sm", id: "select_category"});
  var $option = $("<option>", {text: "カテゴリー一覧", value: ""});
  $selectElm.append($option);
  $.each(menuCategories, function( index, value ) {
    var $option = $("<option>", {text: value["name"], value: value["id"]});
    $selectElm.append($option);
  });
  // $selectElm.on('change', filterMenuByCategory);
  $labelElm.append($selectElm);
  $("#recruits_datatable_length").before($labelElm);
}
