import React from 'react';
import { connect } from 'react-redux';
import ContentEditable from 'react-contenteditable';
import PropTypes from 'prop-types';
import {FRONT_END_HOST} from '../../../../untils/constants';

export class LabelComponent extends React.Component {
  constructor(props) {
    super()
    this.contentEditable = React.createRef();
    this.showLabelContent = this.showLabelContent.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.state = {value: props.value};
    this.linkInternal = 1
  };

  static propTypes = {
    isEdit: PropTypes.bool,
    url: PropTypes.string,
    isFavorite: PropTypes.bool
  };

  static defaultProps = {
    isEdit: true,
    url: '#',
    isFavorite: false
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.contentEditable = React.createRef();
    this.setState({value: nextProps.value});
  }

  showLabelContent() {
    const { isEdit, index } = this.props;
    if(isEdit) {
      return(
        <ContentEditable
          innerRef={this.contentEditable}
          html={this.state.value}
          disabled={false}
          tagName='span'
          onChange={(event) => this.props.handleChangeLabel(event, index, "button_label")}
        />
      )
    }
    return <span>{this.state.value}</span>
  }

  handleClick(event) {
    const { isFavorite, link_type, idString } = this.props;
    let { url } = this.props;
    event.preventDefault();

    if (isFavorite && (url != '#')) {
      if (link_type == this.linkInternal) {
        const homeUrl = `${FRONT_END_HOST}/${idString}`
        if (url === '/') {
          url = homeUrl
        } else {
          url = `${homeUrl}${url}.html`
        }
      }

      if (link_type == this.linkInternal) {
        window.open(url, '_self');
      } else {
        window.open(url);
      }
    }
  }

  render(){
    return(
      <div className= "contents_btn01 block_button_1">
        <div className="content_wrapper">
          <a href="#" onClick={(event) => {this.handleClick(event)}}>
            {this.showLabelContent()}
          </a>
        </div>
      </div>
    )
  };
};

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);

  newState.idString = storeState.settings.id_string

  return newState
}

export default connect(mapStateToProps)(LabelComponent)
