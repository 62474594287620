import React, { Component } from 'react';
import ModdablePart from './ModdablePart';

export class NotImplementPart extends Component {
  render() {
    return (
      <ModdablePart title='Other'>
        This part have not implement yet.
      </ModdablePart>
    )
  }
}

export default NotImplementPart
