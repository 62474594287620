import React, { Component } from 'react';
import { display_recruit_part } from '../../../../../../functions';
export class RecruitPersonInCharge extends Component {
  constructor(props) {
    super(props);
    this.fields = [
      'name_of_person_in_charge',
      'phone_number_person_in_charge',
      'email_address_person_in_charge',
      'department',
      'position'
    ]
  }
  render() {
    const { recruit } = this.props;
    let is_display = display_recruit_part(recruit, this.fields)
    return (
      <React.Fragment>
        {is_display && (
          <div className="detail">
            <div className="heading block_header_3 align-left">
              <h4 className="h">担当者情報</h4>
            </div>
            <div className="block_table_3 width_fixed align-left">
              <table>
                <tbody>
                  {(recruit.name_of_person_in_charge !== undefined && recruit.name_of_person_in_charge !== '') && (
                  <tr>
                    <th>担当者名</th>
                    <td>{ recruit.name_of_person_in_charge }</td>
                  </tr>
                  )}
                  {(recruit.phone_number_person_in_charge !== undefined && recruit.phone_number_person_in_charge !== '') && (
                  <tr>
                    <th>電話番号（担当者）</th>
                    <td>{ recruit.phone_number_person_in_charge }</td>
                  </tr>
                  )}
                  {(recruit.email_address_person_in_charge !== undefined && recruit.email_address_person_in_charge !== '') && (
                  <tr>
                    <th>メールアドレス（担当者）</th>
                    <td>{ recruit.email_address_person_in_charge }</td>
                  </tr>
                  )}
                  {(recruit.department !== undefined && recruit.department !== '') && (
                  <tr>
                    <th>部署</th>
                    <td>{ recruit.department }</td>
                  </tr>
                  )}
                  {(recruit.position !== undefined && recruit.position !== '') && (
                  <tr>
                    <th>役職</th>
                    <td>{ recruit.position }</td>
                  </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {(recruit.can_you_prepare_past_manuscripts !== undefined && recruit.can_you_prepare_past_manuscripts !== '') && (
        <div className="detail">
          <div className="heading block_header_3 align-left">
            <h4 className="h">その他</h4>
          </div>
          <div className="block_table_3 width_fixed align-left">
            <table>
              <tbody>
                <tr>
                  <th>過去原稿の用意はできますか？</th>
                  <td>{ recruit.can_you_prepare_past_manuscripts }</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        )}
      </React.Fragment>
    )
  }
}

export default RecruitPersonInCharge
