import React, { Component } from 'react'
import OptionUrl from '../../OptionUrl'
import CategoryOptions from '../../../Modal/CategoryOptions'

export default class CouponOptions extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { contents } = this.props
    return (
      <React.Fragment>
        <h3><span>その他設定</span></h3>
        <CategoryOptions {...this.props} categories={this.props.coupons} kindOfItem="coupon" isShowDetail={true} />

        <h3><span>ボタン</span></h3>
        <div className="item">
          <p>ボタンラベル</p>
          <input name="coupon_button_label" onKeyUp={this.props.handleChangeContent} placeholder=""
                defaultValue={contents.coupon_button_label} type="text"/>
          <div className="mt10"><small>ボタンラベルが未入力の場合はボタンが表示されません。</small></div>
        </div>
        <div className="item">
          <p>リンク</p>
          <div className="link">
            <input name="coupon_link1" id="coupon_link11" defaultValue="1" type="radio"
                  onChange={this.props.handleChangeContent}
                  defaultChecked={contents.coupon_link1 === "1" ? true : false}/>
            <label htmlFor="coupon_link11">内部リンク</label>
            <input name="coupon_link1" id="coupon_link12" defaultValue="2" type="radio"
                  onChange={this.props.handleChangeContent}
                  defaultChecked={contents.coupon_link1 === "2" ? true : false}/>
            <label htmlFor="coupon_link12">外部リンク</label>

            <div className="coupon_linkinput1" style={contents.coupon_link1 === "1" ? {display: "block"} : {display: "none"}}>
              <span className="select">
                <select name="coupon_link1-select" onChange={this.props.handleChangeSelectPageLink} defaultValue={contents['coupon_link1-select']}>
                  <OptionUrl />
                </select>
              </span>
            </div>

            <div className="coupon_linkinput2" style={contents.coupon_link1 === "2" ? {display: "block"} : {display: "none"}}>
              <input name="coupon_link1-input" onKeyUp={this.props.handleChangeContent} 
                placeholder="外部リンクURL" defaultValue={contents['coupon_link1-input']} type="text" />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
