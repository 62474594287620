import React, { Component } from 'react';
import {connect} from 'react-redux';
import request from "../../untils/request";
import { APIS, IMAGES } from "../../untils/constants";

export class SectionSameCategory extends Component {
  constructor(props) {
    super(props)
    this.state = { same_category: [] }
  }

  async componentDidUpdate(prevProps, prevState) {
    let { settings } = this.props;
    if (prevProps.settings.parent_id !== settings.parent_id) {
      await request({
        method: 'get',
        url: `${APIS.get_same_category}?newpage_id=${settings.newpage_id}&parent_id=${settings.parent_id}`,
      }).then(response => {
        this.setState({same_category: response.data.same_category})
      })
    }
  }

  render() {
    return (
      <section className="contents_related pb60 pt60 width_fixed align-left delay3 delay3Active">
        <div className="content_wrapper">
          <div className="heading block_header_1">
            <h2 className="h">同じカテゴリーのページ</h2>
            <p>SAME CATEGORY</p>
          </div>

          <div className="wrapper_item">
            {
              this.state.same_category.map((page, index) => {
                let image_url = page.image_url === "" ? IMAGES.no_image : page.image_url;
                return (
                  <div className="inner_item" key={index}>
                    <a href="#" style={{textDecorationColor: "rgba(34, 34, 34, 0.3);"}}>
                      <div className="inner_item_img no_img">
                        <img src={image_url} />
                      </div>
                      <div className="inner_item_txt">
                        <div className="heading block_header_5 align-left">
                          <h3 className="h">{page.name}</h3>
                        </div>
                      </div>
                    </a>
                  </div>
                )
              })
            }

          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.settings = storeState.settings;

  return newState;
}

export default connect(mapStateToProps, null)(SectionSameCategory);
