export default (storeState, {payload}) => {
  let newState = Object.assign({}, storeState);
  let data = payload.data
  if(payload.pageStatus == "commonFooter") {
    newState.asideData = data;
  } else {  	
  	newState.pageDataUi = data;
  }
  return newState;
};
