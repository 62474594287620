import React, { Component } from 'react'
import ContentEditable from 'react-contenteditable';

export class HeadingPattern2Component extends Component {
  constructor(props) {
    super(props);
    this.contentEditable1 = React.createRef()
    this.contentEditable2 = React.createRef()
    this.renderHeadlinePattern = this.renderHeadlinePattern.bind(this)
    this.renderSubHeadlinePattern = this.renderSubHeadlinePattern.bind(this)
    this.handleDisplayAlign = this.handleDisplayAlign.bind(this)
    this.state = {
      isHeadingPattern0Edit: false,
      isSubHeadingPattern0Edit: false,
      isHeadingPattern1Edit: false,
      isSubHeadingPattern1Edit: false,
      isHeadingPattern2Edit: false,
      isSubHeadingPattern2Edit: false,
      isHeadingPattern3Edit: false,
      isSubHeadingPattern3Edit: false,
      isHeadingPattern4Edit: false,
      isSubHeadingPattern4Edit: false
    }
  }

  handleDisplayAlign(flag) {
    if (flag === `headlinePattern${this.props.positionChild}`) {
      this.setState({isHeadingPattern0Edit: false, isHeadingPattern1Edit: false, isHeadingPattern2Edit: false, isHeadingPattern3Edit: false, isHeadingPattern4Edit: false, [`isHeadingPattern${this.props.positionChild}Edit`]: !this.state[`isHeadingPattern${this.props.positionChild}Edit`] });
    } else if (flag === `subHeadlinePattern${this.props.positionChild}`) {
      this.setState({isSubHeadingPattern0Edit: false, isSubHeadingPattern1Edit: false, isSubHeadingPattern2Edit: false, isSubHeadingPattern3Edit: false, isSubHeadingPattern4Edit: false, [`isSubHeadingPattern${this.props.positionChild}Edit`]: !this.state[`isSubHeadingPattern${this.props.positionChild}Edit`] });
    }
  }

  renderContentEditableMenu(type, index) {
    let topSpecial = "-40px"
    switch(this.props.kindOfDesign){
    case 2: case 3: case 5: case 6:
      topSpecial = "30px"
      break;
    case 12:
      topSpecial = "40px"
      break;
    case 16:
      topSpecial = "50px"
      break;
    }
    return(
      <ul className="contenteditable_menu active" style={{top: topSpecial, left: "0px"}}>
        <li key={1} className="contenteditable_menu_justifyleft"
            title="左揃え" onMouseDown={(event) => this.props.handleChangeAlign(index, "align-left", type, event)} />
        <li key={2} className="contenteditable_menu_justifycenter"
            title="中央揃え" onMouseDown={(event) => this.props.handleChangeAlign(index, "align-center", type, event)} />
        <li key={3} className="contenteditable_menu_justifyright"
            title="右揃え" onMouseDown={(event) => this.props.handleChangeAlign(index, "align-right", type, event)} />
      </ul>
    )
  }

  renderHeadlinePattern(isEdit, content, index) {
    let editable = isEdit ? "h contenteditable" : "h";
    editable += this.state[`isHeadingPattern${this.props.positionChild}Edit`] ? " active" : ""

    if (isEdit) {
      return (
        <React.Fragment>
          <h3 className={`${editable}`} onFocus={() => this.handleDisplayAlign(`headlinePattern${this.props.positionChild}`)}
            onBlur={() => this.handleDisplayAlign(`headlinePattern${this.props.positionChild}`)}>
            <ContentEditable
              innerRef={this.contentEditable1}
              html={content[`headline_pattern${this.props.positionChild}`]}
              disabled={false}
              onKeyUp={(event) => this.props.handleChangeHeadline(index, event, `headline_pattern${this.props.positionChild}`)}
              tagName='span'
            />
            {this.state[`isHeadingPattern${this.props.positionChild}Edit`] && this.renderContentEditableMenu(`headingPattern${this.props.positionChild}Class`, index)}
          </h3>
        </React.Fragment>
      )
    }

    return (<h3 className={`h ${editable}`} dangerouslySetInnerHTML={{__html: content[`headline_pattern${this.props.positionChild}`]}}></h3>)
  }

  renderSubHeadlinePattern(isEdit, content, index) {
    let editable = this.props.kindOfDesign === 12 ? "h" : ""
    editable += isEdit ? " contenteditable" : "";
    editable += this.state[`isSubHeadingPattern${this.props.positionChild}Edit`] ? " active" : ""
    if (isEdit) {
      return (
        <React.Fragment>
          <p className={`${editable}`} onFocus={() => this.handleDisplayAlign(`subHeadlinePattern${this.props.positionChild}`)}
            onBlur={() => this.handleDisplayAlign(`subHeadlinePattern${this.props.positionChild}`)}>
            <ContentEditable
              innerRef={this.contentEditable2}
              html={content[`subheadline_pattern${this.props.positionChild}`]} // innerHTML of the editable div
              disabled={false}       // use true to disable editing
              onKeyUp={(event) => this.props.handleChangeHeadline(index, event, `subheadline_pattern${this.props.positionChild}`)} // handle innerHTML change
              tagName='span' // Use a custom HTML tag (uses a div by default)
            />
            {this.state[`isSubHeadingPattern${this.props.positionChild}Edit`] && this.renderContentEditableMenu(`headingPattern${this.props.positionChild}Class`, index)}
          </p>
        </React.Fragment>
      )
    }

    return (<p className={editable} dangerouslySetInnerHTML={{__html: content[`subheadline_pattern${this.props.positionChild}`]}}></p>)
  }

  render() {
    const { content, isEdit, index } = this.props;
    let headingPatternClass = content[`headingPattern${this.props.positionChild}Class`]

    return (
      <React.Fragment>
        {this.renderHeadlinePattern(isEdit, content, index)}
        {(this.props.kindOfDesign != 12) && this.renderSubHeadlinePattern(isEdit, content, index)}
      </React.Fragment>
    )
  }
}

export default HeadingPattern2Component
