import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConfigablePart from "../ConfigablePart";
import { parallaxScroll } from '../../../../../functions';

class LoadableHtmlPart extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    isEdit: true
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    parallaxScroll();
  }

  render() {
    const { content, kindOfDesign, index, isEdit, sectionStyle } = this.props;
    return (
      <section id={kindOfDesign} key={kindOfDesign} className={`block_html delay3 blockarea ${content.parallax != 1 ? "parallax" : ""} ${content.width_class} ${content.top_padding_class}  ${content.bottom_padding_class} edit_outer without delay4Active editing delay3Active`} style={sectionStyle}>
        {isEdit && <ConfigablePart {...this.props} />}
        {
          content.parallax != 1 &&
          <div className="parallax-wrap">
            <div className="parallax_img" style={{backgroundImage: `url(${content.background_image_url})`, height: '100%', transform: 'translateY(-50%)'}} />
          </div>
        }
        <div className="content_wrapper" dangerouslySetInnerHTML={{__html: content.block_html_text}} />
      </section>
    )
  }
}

export default LoadableHtmlPart
