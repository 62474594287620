$(document).ready(function() {
  $('#add-staff-line').on('click', function(e) {
    var index = $("#item-tbody").children().length
     $.ajax({
      url: `/staffs/append_detail?index=${index}`,
      type: "GET",
      dataType: 'json',
      success: function(data) {
        $('#item-tbody').append(data.html);
      }
    })
  })

  $('#item-tbody').on('click', '.delete-staff-item', function() {
    $(this).closest('tr').remove()
  })

  $('#staffs-sortable-table tbody').sortable({
    cursor: 'move',
    items: 'tr',
    axis: 'Y',
    cancel: '#non-sort',
    handle: '.handle',
    update : function (event , ui){
      $("#item-tbody tr").each(function (i) {
        $(this).find('input:hidden')[0].value = i
      });
    }
  });
})
