import ModdablePart from '../ModdablePart';
import { IMAGES } from '../../../../../untils/constants'
import React, { Component } from 'react'
import { resetCategories } from '../../../../../functions/resetCategories'
import ModalDetailData from '../../AddNew/ModalDetailData'

export default class MenuItems extends Component {
  constructor(props) {
    super(props);
    this.title = "メニューデザイン"
  }

  componentDidMount() {
    const newData = resetCategories(this.props.itemChecked, this.props.menuCategories)
    this.props.handleData(newData)
  }
  
  render() {
    return (
      <ModdablePart title={this.title}>
        { !this.props.isOpenDetailData &&
          <ul className="no_select">
            {
              this.props.data.map((item, index) => {
                let content = JSON.parse(item.contents)
                return(
                  <li key={index}>
                    <input name="block_menu_pattern" id={`block_menu_pattern${item.kind_of_design}`}  type="radio"
                      defaultChecked={this.props.itemChecked.kind_of_design == item.kind_of_design} />
                    <label htmlFor={`block_menu_pattern${item.kind_of_design}`}
                      onClick={ () => this.props.handleDoubleClickItem(resetCategories(item, this.props.menuCategories)) }>
                      <img src={IMAGES[content.src]}
                    />
                    </label>
                  </li>
                )
              })
            }
          </ul>
        }
      </ModdablePart>
    )
  }
}
