import React, { Component } from 'react';
import ModdablePart from '../ModdablePart';
import { IMAGES } from '../../../../../untils/constants';
import { resetCategories } from '../../../../../functions/resetCategories'

export class ComparisonItems extends Component {
  constructor(props) {
    super(props);
    this.title = 'ビフォーアフターデザイン'
  }

  componentDidMount() {
    const newData = resetCategories(this.props.itemChecked, this.props.comparisonCategories)
    this.props.handleData(newData)
  }

  renderComparisonList() {
    return (
      <ul className="no_select">
        {
          this.props.data.map((item, index) => {
            let content = JSON.parse(item.contents)
            return (
              <li key={index}>
                <input name="block_line_pattern" id={`block_line_pattern_${item.kind_of_design}`} value={item.kind_of_design} type="radio" />
                <label
                  htmlFor={`block_line_pattern_${item.id}`}
                  onClick={ () => this.props.handleDoubleClickItem(resetCategories(item, this.props.comparisonCategories)) }
                  style={{background: 'none 0% 0% / auto repeat scroll padding-box border-box rgb(255, 255, 255)', color: 'rgb(34, 34, 34)'}}
                  className={`block_header_label ${item.kind_of_design === this.props.itemChecked.kind_of_design ? 'item_active' : ''}`}
                >
                  <img src={IMAGES[item.src]} alt=""></img>
                </label>
              </li>
            )
          })
        }
      </ul>
    )
  }

  render() {
    return (
      <ModdablePart title={this.title}>
        { this.renderComparisonList() }
      </ModdablePart>
    )
  }
}

export default ComparisonItems
