import defaultState from '../stateDefinitions';
import IS_REQUESTING from './is_requesting';
import IS_DISPLAY_MODAL_ADD from './isDisplayModalAdd';
import IS_OPEN_IMAGE_MODAL from './isOpenImageModal';
import ADD_DATA_UI from './addDataUi';
import ADD_PAGE_DATA_UI from './addPageDataUi';
import EDIT_DATA_UI from './editDataUi';
import EDIT_PAGE_DATA_UI from './editPageDataUi';
import UPDATE_SETTING from './updateSetting';
import SAVE_DATA_UI from './saveDataUi';
import PAGE_STRUCTURE_DATA from './pageStructureData';
import PAGE_STRUCTURE_IN_SETTING from './pageStructureInSetting';
import HEADER_SETTING from './headerSetting';
import GENERAL_SETTING from './generalSetting';
import FOOTER_SETTING from './footerSetting';
import FOOTER_NAV from './footerNav';
import SAVE_PAGE_DATA_UI from './savePageDataUi';
import SAVE_IMAGE_URL from './saveImageUrl';
import SAVE_BLOCK_IMAGE from './saveBlockImage'
import SAVE_SIDE_NAVIGATION from './saveSideNavigation'
import SAVE_SIDE_NAVIGATION_PAGE from './saveSideNavigationPage'
import IS_OPEN_IMAGE_SETTING_MODAL from './isOpenImageSettingModal'
import HOME_STRUCTURE from './homeStructure';
import NEWPAGE_CATEGORIES from './newpageCategories'
import LOAD_COMPARISON_CATEGORIES from './loadComparisonCategories';
import QUESTION_CATEGORIES from './questionCategories'
import CONTACT_OPTIONS from './contactOptions'
import SAVE_SUB_NAVIGATION from './saveSubNavigation'
import SAVE_BLOCK_HISTORY from './saveBlockHistory'
import NEWS_CATEGORIES from './newsCategories';
import MENU_CATEGORIES from './menuCategories';
import LOAD_COUPONS from './loadCoupons';
import STAFF_CATEGORIES from './staffCategories';
import RECRUIT_CATEGORIES from './recruitCategories';
import GALLERY_CATEGORIES from './galleryCategories';
import SAVE_RELEASE_DATE from './saveReleaseDate';
import IS_CSS_SETTING from './isCssSetting';
import SAVE_RECRUITS from './saveRecuits';
import TAGS_LIST from './tagsList';
import SAVE_NEWPAGE_TAGS from './saveNewpageTags';
import LOAD_ALL_PAGES from './loadAllPages';
import UPDATE_ERROR_MESSAGES from './updateErrorMessages';
import LOAD_COMPARISON_DATA from './loadComparisonData';
import SAVE_PAGE_CATEGORY_IDS from './savePageCategoryIds';
import LOAD_SHOPS_DATA from './loadShopsData';
import SAVE_SETTING_ADVANCE from './saveSettingAdvance';
import SAVE_SETTING_ID_STRING from './saveSettingIdString';
import LOCAL_SIDE_NAVIGATIONS from './localSideNavigations';
import SET_TOKEN_HTTP_CLIENT from './setTokenHttpClient';
import SET_TENANTS from './setTenants';
import SET_CLIENT_ID from './setClientId';

const reducers = {
  IS_REQUESTING,
  IS_DISPLAY_MODAL_ADD,
  ADD_DATA_UI,
  UPDATE_SETTING,
  SAVE_DATA_UI,
  PAGE_STRUCTURE_DATA,
  PAGE_STRUCTURE_IN_SETTING,
  HEADER_SETTING,
  GENERAL_SETTING,
  FOOTER_SETTING,
  FOOTER_NAV,
  SAVE_PAGE_DATA_UI,
  ADD_PAGE_DATA_UI,
  EDIT_PAGE_DATA_UI,
  EDIT_DATA_UI,
  SAVE_IMAGE_URL,
  IS_OPEN_IMAGE_MODAL,
  SAVE_BLOCK_IMAGE,
  SAVE_SIDE_NAVIGATION,
  SAVE_SIDE_NAVIGATION_PAGE,
  IS_OPEN_IMAGE_SETTING_MODAL,
  HOME_STRUCTURE,
  NEWPAGE_CATEGORIES,
  LOAD_COMPARISON_CATEGORIES,
  QUESTION_CATEGORIES,
  CONTACT_OPTIONS,
  SAVE_SUB_NAVIGATION,
  SAVE_BLOCK_HISTORY,
  NEWS_CATEGORIES,
  MENU_CATEGORIES,
  LOAD_COUPONS,
  STAFF_CATEGORIES,
  RECRUIT_CATEGORIES,
  GALLERY_CATEGORIES,
  SAVE_RELEASE_DATE,
  IS_CSS_SETTING,
  SAVE_RECRUITS,
  TAGS_LIST,
  SAVE_NEWPAGE_TAGS,
  LOAD_ALL_PAGES,
  UPDATE_ERROR_MESSAGES,
  LOAD_COMPARISON_DATA,
  SAVE_PAGE_CATEGORY_IDS,
  LOAD_SHOPS_DATA,
  SAVE_SETTING_ADVANCE,
  SAVE_SETTING_ID_STRING,
  LOCAL_SIDE_NAVIGATIONS,
  SET_TOKEN_HTTP_CLIENT,
  SET_TENANTS,
  SET_CLIENT_ID
};

export default (state = defaultState, action = {}) => {
  if (reducers[action.type] === undefined) {
    return state;
  }
  return reducers[action.type](state, action);
};
