import React, { Component } from 'react'

export default class AnchorSetting extends Component {
  render() {
    return (
      <li className="admin_edit_menu_anker 460251" title="アンカー">
        <span onClick={this.props.handleOpenAnchorSetting}><i className="material-icons">assistant_photo</i></span>
      </li>
    )
  }
}
