import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HeadingComponent from './HeadingComponent';
import ConfigablePart from "../ConfigablePart";
import { parallaxScroll } from '../../../../../functions';

class LoadableHeadingPart extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    isEditContentable: PropTypes.bool,
  };

  static defaultProps = {
    isEdit: true,
    isEditContentable: false,
  };

  constructor(props) {
    super(props);
    this.state = {}
    this.HandleSwitchComponent = this.HandleSwitchComponent.bind(this);
  }

  componentDidMount() {
    parallaxScroll();
  }

  HandleSwitchComponent(key, content) {
    const { isEdit } = this.props;
    return <HeadingComponent content={content} isEdit={isEdit} index={this.props.index}
                             handleChangeHeadline={this.props.handleChangeHeadline}
                             handleChangeAlign={this.props.handleChangeAlign}
                             handleChangeText={this.handleChangeText}
                             headlineType="2" type={key} />
  }

  render() {
    const { content, kindOfDesign, sectionStyle } = this.props;
    return (
      <section key={kindOfDesign} id={kindOfDesign}
                className={`hp delay3 ${content.width_class} blockarea ${content.top_padding_class} ${content.parallax != 1 ? "parallax" : ""}  ${content.bottom_padding_class} edit_outer delay4Active editing block_header delay3Active`}
                style={sectionStyle}>
        {this.props.isEdit && <ConfigablePart {...this.props}/>}
        {
          content.parallax != 1 &&
          <div className="parallax-wrap">
            <div className="parallax_img" style={{backgroundImage: `url(${content.background_image_url})`, height: '100%', transform: 'translateY(-50%)'}} />
          </div>
        }
        <div className="content_wrapper">
          { this.HandleSwitchComponent(kindOfDesign, content) }
        </div>
      </section>
    )
  }
}

export default (LoadableHeadingPart)
