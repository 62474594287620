import React, { Component } from 'react';
import ModdablePart from '../ModdablePart';
import { IMAGES } from '../../../../../untils/constants'

export class ImageTextItems extends Component {
  constructor(props) {
    super(props);
    this.title = '画像+テキストデザイン'
  }

  render() {
    return (
      <ModdablePart title={this.title}>
        <ul className="no_select">
          {
            this.props.data.map((item, index) => {
              return (
                <li key={index}>
                  <input name="block_image_text_pattern" id={`block_image_text_pattern_${item.kind_of_design}`} value={item.kind_of_design} type="radio" />
                  <label
                    htmlFor={`block_image_text_pattern_${item.kind_of_design}`}
                    onClick={ () => this.props.handleDoubleClickItem(item) }
                    style={{background: 'none 0% 0% / auto repeat scroll padding-box border-box rgb(255, 255, 255)', color: 'rgb(34, 34, 34)'}}
                    className={`block_header_label ${item.kind_of_design === this.props.itemChecked.kind_of_design ? 'item_active' : ''}`}
                  >
                      <img src={IMAGES[item.src]} alt="" />
                  </label>
                </li>
              )
            })
          }
        </ul>
      </ModdablePart>
    )
  }
}

export default ImageTextItems
