import React, { useState, useEffect } from 'react';
import LayoutSetting from "../components/ViewEdit/LayoutSetting";
import { useDispatch } from "react-redux";
import request from "../untils/request";
import { APIS } from '../untils/constants'
import {
  headerSetting,
  pageStructureData,
  footerSetting,
  generalSetting,
  footerNav,
  pageStructureInSetting,
  homeStructure,
  savePageDataUi,
  newpageCategories,
  questionCategories,
  contactOptions,
  saveSubNavigation,
  newsCategories,
  menuCategories,
  loadCoupons,
  staffCategories,
  recruitCategories,
  galleryCategories,
  saveImageUrl,
  saveRecuits,
  tagsList,
  loadAllPages,
  loadComparisonCategories,
  loadShopsData,
  updateSetting,
  saveSettingAdvance,
  localSideNavigations,
  setClientId
} from "../Lib/redux/actions";
import { formatDateTime } from "../functions"

const AddPage = (props) => {
  const [statusPage] = useState('new');

  const dispatch = useDispatch();

  useEffect(async () => {
    const {data: {page_structure_data, page_structure_in_setting, link_for_embedded_parts}} = await request({
      method: 'post',
      url: APIS.get_home_structure
    })

    const {data: { setting_header_data }} = await request({
      method: 'post',
      url: APIS.get_setting_header,
      data: {page_status: statusPage}
    })

    const {data: { setting_footer_data }} = await request({
      method: 'post',
      url: APIS.get_setting_footer,
    })

    const {data: { general_setting_data }} = await request({
      method: 'post',
      url: APIS.get_general_seting,
    })

    const {data: { footer_routes }} = await request({
      method: 'post',
      url: APIS.get_common_footer_navigation,
    })

    const {data: {
      comparison_categories,
      newpage_categories,
      question_categories,
      news_categories,
      menu_categories,
      recruit_categories,
      gallery_categories
    }} = await request({
      method: 'post',
      url: APIS.get_newpage_categories,
      data: { client_id: props.client_id }
    })

    const {data: { contacts }} = await request({
      method: 'post',
      url: APIS.get_contacts_options,
      data: { client_id: props.client_id }
    })

    const {data: { coupons }} = await request({
      method: 'get',
      url: `${APIS.get_coupons}?client_id=${props.client_id}`
    })

    const {data: { staff_categories }} = await request({
      method: 'get',
      url: `${APIS.get_staff_categories}?client_id=${props.client_id}`,
    })

    const {data: { recruits }} = await request({
      method: 'get',
      url: `${APIS.get_recruits}?client_id=${props.client_id}`,
    })

    const tags_list = await request({
      method: 'get',
      url: `${APIS.get_tags}?client_id=${props.client_id}`
    })

    const {data: { all_pages }} = await request({
      method: 'get',
      url: `${APIS.get_all_page}?page_kind=${props.page_kind}&client_id=${props.client_id}`
    })

    const {data: { shops }} = await request({
      method: 'post',
      url: APIS.get_shops,
      data: { client_id: props.client_id }
    })

    const {data: { setting_advance }} = await request({
      method: 'get',
      url: APIS.get_setting_advanced
    })

    dispatch(loadComparisonCategories(comparison_categories))
    dispatch(loadAllPages(all_pages))
    dispatch(pageStructureData(page_structure_data))
    dispatch(pageStructureInSetting(page_structure_in_setting))
    dispatch(headerSetting(setting_header_data))
    dispatch(footerSetting(setting_footer_data))
    dispatch(generalSetting(general_setting_data))
    dispatch(footerNav(footer_routes))
    dispatch(homeStructure(link_for_embedded_parts))
    dispatch(newpageCategories(newpage_categories))
    dispatch(questionCategories(question_categories))
    dispatch(contactOptions(contacts))
    dispatch(saveSubNavigation(props.sub_navigations))
    dispatch(newsCategories(news_categories))
    dispatch(menuCategories(menu_categories))
    dispatch(loadCoupons(coupons))
    dispatch(staffCategories(staff_categories))
    dispatch(recruitCategories(recruit_categories))
    dispatch(galleryCategories(gallery_categories))
    dispatch(saveRecuits(recruits))
    dispatch(loadShopsData(shops))
    dispatch(saveSettingAdvance(setting_advance))
    dispatch(localSideNavigations(props.local_side_navigations))
    if(props.templateData !== null) {
      dispatch(savePageDataUi(props.templateData))
    }

    if(props.settings !== null) {
      let newSettings = props.settings
      newSettings['name'] = ''
      newSettings['slug'] = formatDateTime(new Date())
      newSettings['slug_plus'] = ''
      newSettings['title'] = ''

      dispatch(updateSetting(newSettings))
      dispatch(saveImageUrl(props.image_urls))
    }

    if(props.page_kind === 'blog' || props.page_kind === 'news') {
      dispatch(saveImageUrl(props.image_urls))
    }

    if(props.tags_list !== null) {
      dispatch(tagsList({tags: props.tags_list, page_kind: ''}))
    } else {
      dispatch(tagsList({tags: tags_list, page_kind: ''}))
    }
    dispatch(setClientId(props.client_id))
  }, [])

  const page_kind = props.page_kind === 'add' ? 'fixed_page' : props.page_kind

  return (
    <React.Fragment>
      <LayoutSetting pageKind={page_kind} pageStatus={statusPage} />
    </React.Fragment>
  )
}

export default AddPage;
