import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk'
import rootReducer from './reducers';

export function configureStore() {
  let composeEnhancers;
  if (
    typeof window === "object" &&
    (window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ) {
    composeEnhancers = compose(
      applyMiddleware(thunk),
      (window).__REDUX_DEVTOOLS_EXTENSION__ &&
        (window).__REDUX_DEVTOOLS_EXTENSION__()
    );
  } else {
    composeEnhancers = compose(applyMiddleware(thunk));
  }
  return createStore(
    rootReducer, composeEnhancers
  );
}

const store = configureStore();

export default store;