import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { APIS } from "../../../../../untils/constants";
import request from "../../../../../untils/request";
import CategoryOptions from '../../../Modal/CategoryOptions';
import ChooseNumberItem from '../../../Modal/ChooseNumberItem';
import OptionUrl from '../../OptionUrl'

export default class ModalSettingGalleryPart extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { itemChecked } = this.props
    const contents = JSON.parse(itemChecked.contents)
    const numberGalleryValues = [3, 4, 6, 8, 9, 10, 12, 15, 16, 18, 20, 21, 25]

    return (
      <React.Fragment>
        <h3><span>その他設定</span></h3>
        <CategoryOptions categories={this.props.galleryCategories} kindOfItem="gallery" isShowDetail={true}
          errorMessages={this.props.errorMessages} {...this.props}/>
        <div className="item">
          <p>カテゴリー名の表示</p>
          <input name="gallery_other2" id="gallery_other2_1" defaultValue={1} type="radio"
            defaultChecked={contents.gallery_other2 == 1} onChange={this.props.handleChangeContent}/>
          <label htmlFor="gallery_other2_1">表示しない</label>
          <input name="gallery_other2" id="gallery_other2_2" defaultValue={2} type="radio"
            defaultChecked={contents.gallery_other2 == 2} onChange={this.props.handleChangeContent} />
          <label htmlFor="gallery_other2_2">表示する</label>
        </div>

        <div className="item">
          <p>表示形式</p>
          <input name="gallery_other3" id="gallery_other31" defaultValue={1} type="radio" defaultChecked={contents.gallery_other3 == 1} onChange={this.props.handleChangeContent} /><label htmlFor="gallery_other31">カテゴリーで分けて表示</label>
          <input name="gallery_other3" id="gallery_other32" defaultValue={2} type="radio" defaultChecked={contents.gallery_other3 == 2} onChange={this.props.handleChangeContent} /><label htmlFor="gallery_other32">一括表示</label>
        </div>

        <ChooseNumberItem contents={contents} kindOfItem="gallery" numberValues={numberGalleryValues} handleChangeContent={this.props.handleChangeContent}/>

        <h3><span>ボタン</span></h3>
        <div className="item">
          <p>ボタンラベル</p>
          <input name="button_label" type="text" defaultValue={contents.button_label} onChange={this.props.handleChangeContent} />
          <div className="mt10"><small>ボタンラベルが未入力の場合はボタンが表示されません。</small></div>
        </div>

        <div className="item">
          <p>リンク</p>
          <div className="link">
            <input name="gallery_link_type" id="gallery_link_type_1" defaultValue={1} type="radio" defaultChecked={contents.gallery_link_type == 1} onChange={this.props.handleChangeContent}/>
            <label htmlFor="gallery_link_type_1">内部リンク</label>
            <input name="gallery_link_type" id="gallery_link_type_2" defaultValue={2} type="radio" defaultChecked={contents.gallery_link_type == 2} onChange={this.props.handleChangeContent} />
            <label htmlFor="gallery_link_type_2">外部リンク</label>
            <div className="gallery_linkinput1" style={{display: contents.gallery_link_type == 1 ? 'block' : 'none'}}>
              <span className="select">
                <select name="gallery_link_1" onChange={this.props.handleChangeSelectPageLink} defaultValue={contents.gallery_link_1}>
                  <OptionUrl />
                </select>
              </span>
            </div>
            <div className="gallery_link_2" style={{display: contents.gallery_link_type == 2 ? 'block' : 'none'}} defaultValue={contents.gallery_link_2}>
              <input name="gallery_link_2" placeholder="外部リンクURL" defaultValue={contents.gallery_link_2} type="text" onChange={this.props.handleChangeContent} /></div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
