import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IMAGES, FRONT_END_HOST } from "../../../../../untils/constants";

export class RecruitListOfItems extends Component {
  static propTypes = {
    recruit_categories: PropTypes.object,
  };

  static defaultProps = {
    recruit_categories: {},
    recruit_all: {},
    recruit_category_ids: []
  };

  handleClick(event, item) {
    event.preventDefault();
    let recruitDetailUrl = `${FRONT_END_HOST}/${item.url}`;
    if (!this.props.isEdit) window.open(recruitDetailUrl, '_self');
  }

  showListrecruit() {
    let categories = this.props.item.categories;
    const { recruit_categories, content, isFavorite, recruit_all, recruit_category_ids } = this.props;
    const recruit_number = content.recruit_number;

    if(categories.length > 0 && recruit_category_ids.length > 0 && JSON.stringify(categories.sort()) === JSON.stringify(recruit_category_ids.sort())) {
      let recruit_list = recruit_all;
      if(recruit_list.length > 0 && recruit_number > 0) {
        recruit_list = recruit_list.slice(0, recruit_number)
      }
      return (
        <React.Fragment>
          {
            recruit_list.map((item, index) => {
              let imgStyle = item.image_class == "no_img" ? {filter: "contrast(0%)", opacity: 0.3} : {}

              return(
                <div className="inner_item" key={`recruit_${index}`}>
                  <div className="inner_item_img">
                    <a href={isFavorite ? `${FRONT_END_HOST}/${item.url}`: "#!"} className={item.image_class}>
                      <img style={imgStyle} src={item.image_url != "" ? item.image_url : IMAGES.no_image} alt={item.job_title} />
                    </a>
                  </div>
                  <div className="inner_item_txt">
                    <div className="heading block_header_4 align-left">
                      <h3 className="h pre-wrap">{item.job_title}</h3>
                    </div>
                    <div className="pre-wrap">{item.job_description}</div>
                    <p className=""></p>
                    <div className="contents_btn01 block_button_1">
                      <a href="#" onClick={(event) => {this.handleClick(event, item)}}>
                        <span className="">詳しく見る</span>
                      </a>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </React.Fragment>
      )
    } else {
      return (
        Object.keys(recruit_categories).map((key, index) => {
          if(categories.length === 0) {
            categories = [null]
          }
          const category_id = recruit_categories[key].category_id;
          if(categories.indexOf(category_id) > -1) {
            let recruit_list = recruit_categories[key].recruit;
            if(recruit_list.length > 0 && recruit_number > 0) {
              recruit_list = recruit_list.slice(0, recruit_number)
            }
            return (
              <React.Fragment key={`categories_${index}`}>
                {
                  recruit_list.map((item, index) => {
                    let imgStyle = item.image_class == "no_img" ? {filter: "contrast(0%)", opacity: 0.3} : {}

                    return(
                      <div className="inner_item" key={`recruit_${index}`}>
                        <div className="inner_item_img">
                          <a href={isFavorite ? `${FRONT_END_HOST}/${item.url}`: "#!"} className={item.image_class}>
                            <img style={imgStyle} src={item.image_url != "" ? item.image_url : IMAGES.no_image} alt={item.job_title} />
                          </a>
                        </div>
                        <div className="inner_item_txt">
                          <div className="heading block_header_4 align-left">
                            <h3 className="h pre-wrap">{item.job_title}</h3>
                          </div>
                          <div className="pre-wrap">{item.job_description}</div>
                          <p className=""></p>
                          <div className="contents_btn01 block_button_1">
                            <a href="#" onClick={(event) => {this.handleClick(event, item)}}>
                              <span className="">詳しく見る</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </React.Fragment>
            )
          }
        })
      )
    }
  }

  render() {
    return (
      <div className="recruitment_list">
        <div className="wrapper_item">
          { this.showListrecruit() }
        </div>
      </div>
    )
  }
}

export default RecruitListOfItems
