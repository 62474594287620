import React, { Component } from 'react';
import { connect } from 'react-redux';
import ConfigSlider from './Slider/ConfigSlider';
import ImageSetting from './Slider/ImageSetting';
import ChooseImage from '../EditParallax/ChooseImage';
import {toast} from "react-toastify";
import { updateSetting, saveImageUrl } from '../../../../Lib/redux/actions';
import { showHideMVPointer } from '../../../../functions';

export class MvSettingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {...this.props.settings},
      images: {...this.props.slider_images},
      displayFlag: '0',
      image_name: '',
      errorMessages: {
        mv_youtubeid: {message: null, fieldLabel: 'ディスクリプション'}
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDataForm = this.handleDataForm.bind(this);
    this.handleScreen = this.handleScreen.bind(this);
    this.handleScreenImage = this.handleScreenImage.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.removeImage = this.removeImage.bind(this);
  }

  handleChange({ target }) {
    const { settings } = this.state;
    settings[target.name] = target.value

    this.setState({ settings })
  }

  validateForm() {
    const { settings, errorMessages } = this.state;
    this.handleChange({target: {name: 'mv_youtubeid', value: settings.mv_youtubeid.trim()}})

    if(settings.mv_youtubeid.length === 0) {
      errorMessages.mv_youtubeid.message = 'YouTubeIDを入力してください。'
      this.setState({errorMessages: errorMessages});
      toast.error("入力内容をご確認ください", {
        position: toast.POSITION.TOP_RIGHT
      });
      return false
    }
    return true
  }

  handleDataForm() {
    if(this.state.settings.mv_type == 2 && !this.validateForm()) return;
    showHideMVPointer(this.state.settings.mv_type);
    this.props.processSetting(this.state.settings);
    this.props.saveImageUrl(this.state.images);
    this.props.handleShowModalConfig();
  }

  handleScreen({ target }) {
    this.setState({ displayFlag: target.dataset.step })
  }

  handleScreenImage({ target }) {
    this.setState({ displayFlag: target.dataset.step, image_name: target.dataset.name })
  }

  handleImage(event) {
    const { settings, images, image_name } = this.state;

    let image_name_id = `mv_${image_name}_id`
    let image_url = `mv_${image_name}_url`
    settings[image_name_id] = event.target.dataset.id
    images[image_url] = event.target.style.backgroundImage.slice(4, -1).replace(/"/g, "");
    this.setState({
      settings: settings,
      images: images
    });
  }

  removeImage() {
    const { settings, images, image_name } = this.state;

    let image_name_id = `mv_${image_name}_id`
    let image_url = `mv_${image_name}_url`
    settings[image_name_id] = ''
    images[image_url] = ''
    this.setState({
      settings: settings,
      images: images
    });
  }

  render() {
    const { settings, image_name } = this.state;
    let loadClass = this.props.isRequesting ? 'load' : 'loaded';

    let image_name_id = `mv_${image_name}_id`
    return (
      <div id="setting_modal" data-step="#block_mv" className={`active admin_edit_menu_setting ${loadClass}`}>
        <div className="wrapper">
          <div id="contents" data-type="admin_edit_add">
            <p className="modal_header">
              <i>ページ設定</i>
              <span className="modal_header_btn cancel first_button" onClick={this.props.handleShowModalConfig}>キャンセル</span>
              <span className="modal_header_btn ok" onClick={this.handleDataForm} >決定</span>
            </p>
            <div id="content_r">
              <div className="stepset">
                <div className="step_inner">
                  {this.state.displayFlag === '0' && <ConfigSlider settings={this.state.settings} handleChange={this.handleChange} handleScreen={this.handleScreen} errorMessages={this.state.errorMessages} />}
                  {this.state.displayFlag === '1' && <ImageSetting settings={this.state.settings} images={this.state.images} handleChange={this.handleChange} handleScreenImage={this.handleScreenImage} />}
                  {this.state.displayFlag === '2' && <ChooseImage
                    isHome={true}
                    settings={this.state.settings}
                    handleImage={this.handleImage}
                    handleScreen={this.handleScreen}
                    removeImage={this.removeImage}
                    mv_image_id={settings[image_name_id]}
                  />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.settings = storeState.settings;
  newState.slider_images = storeState.slider_images;
  newState.isRequesting = storeState.isRequesting;

  return newState;
}

const mapDispatchToProps = (dispatch) => {
  return {
    processSetting: (data) => {dispatch(updateSetting(data))},
    saveImageUrl: (data) => {dispatch(saveImageUrl(data))},
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MvSettingModal)
