import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConfigablePart from "../ConfigablePart";
import { parallaxScroll } from '../../../../../functions';

class LoadableLinePart extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    isEdit: true
  };

  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    parallaxScroll();
  }

  render() {
    const { kindOfDesign, sectionStyle, content } = this.props;

    return (
      <section key={kindOfDesign} id={kindOfDesign} className={`contents_hr align-left block_line_${kindOfDesign} delay3 ${content.parallax != 1 ? "parallax" : ""} ${content.width_class} blockarea ${content.top_padding_class}  ${content.bottom_padding_class} edit_outer delay4Active editing block_line delay3Active`}
        style={sectionStyle}>
        { this.props.isEdit && <ConfigablePart {...this.props} /> }
        {
          content.parallax != 1 &&
          <div className="parallax-wrap">
            <div className="parallax_img" style={{backgroundImage: `url(${content.background_image_url})`, height: '100%', transform: 'translateY(-50%)'}} />
          </div>
        }
        <hr/>
      </section>
    )
  }
}

export default (LoadableLinePart)
