import React, { Component } from 'react'

export default class ChooseNumberItem extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const {kindOfItem, numberValues, contents} = this.props
    const note = kindOfItem === "question" || kindOfItem === "gallery" ? " （カテゴリ毎）" : ""

    return (
      <div className="item">
        <p>{"表示件数" + note}</p>
        {
          numberValues.map((value, index) => {
            let labelText = `${value}件`
            if(value == -1) {
              labelText = "すべて"
            }
            return(
              <React.Fragment key={index}>
                <input name={`${kindOfItem}_number`} id={`${kindOfItem}_number_${value}`} defaultValue={value} type="radio" onChange={this.props.handleChangeContent}
                  defaultChecked={value == contents[`${kindOfItem}_number`]} />
                <label htmlFor={`${kindOfItem}_number_${value}`}>{labelText}</label>
              </React.Fragment>
            )
          })
        }
      </div>
    )
  }
}
