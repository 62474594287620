import React, { Component } from 'react'
import HeadingPattern2Component from '../BlockImageText2/HeadingPattern2Component';
import BlockType1 from '../BlockImageText2/BlockType1';
import BlockType2 from '../BlockImageText2/BlockType2';
import BlockType3 from '../BlockImageText2/BlockType3';
import BlockType31 from '../BlockImageText2/BlockType31';
import BlockType4 from '../BlockImageText2/BlockType4';
import BlockType5 from '../BlockImageText2/BlockType5';
import ContentComponent from '../ContentComponent';
import {isOpenImageModal, isOpenImageSettingModal} from '../../../../../Lib/redux/actions';
import { connect } from 'react-redux';
import ContentEditable from 'react-contenteditable';
import { FRONT_END_HOST } from '../../../../../untils/constants';

export class ImageBlock extends Component {
  constructor(props) {
    super(props);
    this.showBlockImgSettingModal = this.showBlockImgSettingModal.bind(this)
    this.showOperatorImageModal = this.showOperatorImageModal.bind(this);
    this.imageUrl = this.imageUrl.bind(this)
    this.showBlockImage = this.showBlockImage.bind(this)
    this.hrefValue = this.hrefValue.bind(this)
    this.targetValue = this.targetValue.bind(this)
    this.state = {
      isHeadingPattern0Edit: false,
      isSubHeadingPattern0Edit: false,
      isHeadingPattern1Edit: false,
      isSubHeadingPattern1Edit: false,
      isHeadingPattern2Edit: false,
      isSubHeadingPattern2Edit: false,
      isHeadingPattern3Edit: false,
      isSubHeadingPattern3Edit: false,
      isHeadingPattern4Edit: false,
      isSubHeadingPattern4Edit: false
    }
  }

  showBlockImgSettingModal = () => {
    this.props.handleShowSetting(
      { isOpenImageSettingModal: !this.props.isOpenImageSettingModal, index: this.props.index, positionChild: this.props.positionChild }
    )
  }

  showOperatorImageModal = () => {
    this.props.handleShowImageModal(
      {isOpenImageModal: !this.props.isOpenImageModal, index: this.props.index, positionChild: this.props.positionChild}
    )
  };

  imageUrl() {
    return this.props.block_image_url.length == 0 ? IMAGES.no_image : this.props.block_image_url
  }

  showBlockImage() {
    return(
      <picture>
        <img src={this.imageUrl()} alt={this.props.block_image_alt}/>
      </picture>
    )
  }

  targetValue(positionChild) {
    const { content } = this.props;
    const target = (
      content[`block_image${positionChild}_target`] !== undefined &&
      content[`block_image${positionChild}_target`] &&
      parseInt(content[`block_image${positionChild}_link`]) !== 1
    ) ? "_blank" : "_self"

    return target;
  }

  hrefValue() {
    const { content, idString, positionChild } = this.props;
    switch (content[`block_image${positionChild}_link`]) {
      case "1":
        return false
      case "2":
        if (idString === null) { return '#!' }
        const homeUrl = `${FRONT_END_HOST}/${idString}`
        if (content[`block_image${positionChild}_inner_link`] === '/') { return homeUrl }
        return `${homeUrl}${content[`block_image${positionChild}_inner_link`]}.html`
      case "3":
        return content[`block_image${positionChild}_outer_link`]
      default:
        return false
    }
  }

  render() {
    let headingPatternClass = this.props.content[`headingPattern${this.props.positionChild}Class`]
    return (
      <div className="inner_item" key={this.props.positionChild}>
        { ([1, 2].indexOf(this.props.kindOfDesign) > -1) &&
          <BlockType1 {...this.props} targetValue={this.targetValue} showBlockImage={this.showBlockImage} showBlockImgSettingModal={this.showBlockImgSettingModal} showOperatorImageModal={this.showOperatorImageModal} hrefValue={this.hrefValue} headingPatternClass={headingPatternClass} />
        }
        { (this.props.kindOfDesign === 3) &&
          <BlockType2 {...this.props} targetValue={this.targetValue} showBlockImage={this.showBlockImage} showBlockImgSettingModal={this.showBlockImgSettingModal} showOperatorImageModal={this.showOperatorImageModal} hrefValue={this.hrefValue} headingPatternClass={headingPatternClass} />
        }
        { ([4, 5, 7, 8, 9, 10, 14, 15, 16, 17].indexOf(this.props.kindOfDesign) > -1) &&
          <BlockType3 {...this.props} targetValue={this.targetValue} showBlockImage={this.showBlockImage} showBlockImgSettingModal={this.showBlockImgSettingModal} showOperatorImageModal={this.showOperatorImageModal} hrefValue={this.hrefValue} headingPatternClass={headingPatternClass} />
        }
        { (this.props.kindOfDesign === 11) &&
          <BlockType31 {...this.props} targetValue={this.targetValue} showBlockImage={this.showBlockImage} showBlockImgSettingModal={this.showBlockImgSettingModal} imageUrl={this.imageUrl} hrefValue={this.hrefValue} showOperatorImageModal={this.showOperatorImageModal} headingPatternClass={headingPatternClass} />
        }
        { (this.props.kindOfDesign === 13) &&
          <BlockType3 {...this.props} targetValue={this.targetValue} showBlockImage={this.showBlockImage} showBlockImgSettingModal={this.showBlockImgSettingModal} imageUrl={this.imageUrl} hrefValue={this.hrefValue} showOperatorImageModal={this.showOperatorImageModal} headingPatternClass={headingPatternClass} />
        }
        { (this.props.kindOfDesign === 12) &&
          <BlockType4 {...this.props} targetValue={this.targetValue} showBlockImage={this.showBlockImage} showBlockImgSettingModal={this.showBlockImgSettingModal} imageUrl={this.imageUrl} hrefValue={this.hrefValue} showOperatorImageModal={this.showOperatorImageModal} headingPatternClass={headingPatternClass} />
        }
        { (this.props.kindOfDesign === 6) &&
          <BlockType5 {...this.props} targetValue={this.targetValue} showBlockImage={this.showBlockImage} showBlockImgSettingModal={this.showBlockImgSettingModal} imageUrl={this.imageUrl} hrefValue={this.hrefValue} showOperatorImageModal={this.showOperatorImageModal} headingPatternClass={headingPatternClass} />
        }
      </div>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.isOpenImageModal = storeState.isOpenImageModal
  newState.isOpenImageSettingModal = storeState.isOpenImageSettingModal
  return newState;
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleShowImageModal: (data) => {dispatch(isOpenImageModal(data))},
    handleShowSetting: (data) => {dispatch(isOpenImageSettingModal(data))}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageBlock)
