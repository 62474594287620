import React, { Component, cloneElement } from 'react'
import { renderToString } from 'react-dom/server';
import parse, { domToReact } from 'html-react-parser';
import { CKEditor } from "ckeditor4-react";
import HeadlineComponent from '../HeadlineComponent';
import ExplanationComponent from '../ExplanationComponent';
import { customSlider, resizeTable } from '../../../../../functions';
import "jquery-ui";

export class TableComponent extends Component {
  constructor(props) {
    super(props);
    this.renderTable = this.renderTable.bind(this);
    this.addColumnTable = this.addColumnTable.bind(this);
    this.removeColumnTable = this.removeColumnTable.bind(this);
    this.addRowTable = this.addRowTable.bind(this);
    this.removeRowTable = this.removeRowTable.bind(this);
    this.pretreatmentHandleTable = this.pretreatmentHandleTable.bind(this);
    this.renderEndUserTable = this.renderEndUserTable.bind(this);
    this.renderEditableComponent = this.renderEditableComponent.bind(this);
  }

  componentDidMount() {
    customSlider(this.props, null);
    resizeTable();
  }

  componentDidUpdate(prevProps, prevState) {
    customSlider(this.props, prevProps);
    resizeTable();
  }

  pretreatmentHandleTable(event) {
    const content = $(event.editor.element.$).parents('table').html();
    if (content !== this.props.content.table)
      this.props.handleChangeTable(content, this.props.index);
  }

  addColumnTable(event) {
    // var $tgt = $(event.target).parents('.content_wrapper').find('table');
    // if($tgt.find('.block_table_2_item').length > 0){//デザイン2でSPの時
    //   $tgt.find('tbody:not(.block_table_2_item) tr:last-child').clone().insertAfter($tgt.find('tbody:not(.block_table_2_item) tr:last-child')).find('.admin_edit_cell_w').remove();
    //   $tgt.find('tbody.block_table_2_item tr').each(function(){
    //     $(this).find('td:last-child').clone().appendTo($(this)).find('.admin_edit_cell_w').remove();
    //   });
    // }else{
    //   $tgt.find('tr').each(function(){
    //     $(this).find('th:last-child').clone().appendTo($(this)).find('.admin_edit_cell_w').remove();
    //     $(this).find('td:last-child').clone().appendTo($(this)).find('.admin_edit_cell_w').remove();
    //   });
    // }
    let content = parse(this.props.content.table, {
      replace: (domNode) => {
        if (domNode.name == 'tr') {
          let newRow = domNode.children[domNode.children.length - 1].cloneNode({recursive: true})
          domNode.children.push(newRow);
        }
      }
    });
    this.props.handleChangeTable(renderToString(content), this.props.index);
  }

  removeColumnTable(event) {
    // const propsComponent = this.props;
    // var $tgt = $(event.target).parents('.content_wrapper').find('table');
    // if($tgt.find('.block_table_2_item').length > 0){//デザイン2でSPの時
    //   if($tgt.find('tbody.block_table_2_item tr:last-child td').length <= 1){
    //     alert('削除する列がありません。')
    //   }else{
    //     if(confirm('本当に削除してよろしいですか？')){
    //       $tgt.find('tbody:not(.block_table_2_item) tr:last-child').addClass('delete').delay(500).queue(function(){
    //         $tgt.find('tbody:not(.block_table_2_item) tr:last-child').remove();
    //       });
    //       $tgt.find('tbody.block_table_2_item tr').each(function(){
    //         $(this).find('td:last-child').addClass('delete').delay(500).queue(function(){
    //           $(this).remove();
    //         });
    //       });
    //     }
    //   }
    // }else{
    //   if($tgt.find('tbody tr:last-child td').length <= 1){
    //     alert('削除する列がありません。')
    //   }else{
    //     if(confirm('本当に削除してよろしいですか？')){
    //       $tgt.find('tr').each(function(){
    //         $(this).find('th:last-child, td:last-child').addClass('delete').delay(500).queue(function(){
    //           $(this).remove();
    //         });
    //       });
    //     };
    //   }
    // }
    // setTimeout(function(){
    //   let content = $tgt.html()
    //   propsComponent.handleChangeTable(content, propsComponent.index);
    // }, 1000);

    if(confirm('本当に削除してよろしいですか？')){
      let content = parse(this.props.content.table, {
        replace: (domNode) => {
          if (domNode.name == 'tr' && domNode.children.length >= 1) {
            domNode.children.pop();
          }
        }
      });
      this.props.handleChangeTable(renderToString(content), this.props.index);
    }
  }

  addRowTable(event) {
    // var $tgt = $(event.target).parents('.content_wrapper').find('table');
    // if($tgt.find('.block_table_2_item').length > 0){//デザイン2でSPの時
    //   $tgt.find('thead tr, tbody:not(.block_table_2_item) tr').each(function(){
    //     $(this).find('th:last-child').clone().appendTo($(this)).find('.admin_edit_cell_w').remove();
    //     $(this).find('td:last-child').clone().appendTo($(this)).find('.admin_edit_cell_w').remove();
    //   });
    //   $tgt.find('tbody.block_table_2_item tr:last-child').clone().insertAfter($tgt.find('tbody.block_table_2_item tr:last-child')).find('.admin_edit_cell_w').remove();
    // }else{
    //   $tgt.find('tbody tr:last-child').clone().insertAfter($tgt.find('tbody tr:last-child')).find('.admin_edit_cell_w').remove();
    // }
    let content = parse(this.props.content.table, {
      replace: (domNode) => {
        if (domNode.name == 'tbody') {
          let newRow = domNode.children[domNode.children.length - 1].cloneNode({recursive: true})
          domNode.children.push(newRow);
        }
      }
    });
    this.props.handleChangeTable(renderToString(content), this.props.index);
  }

  removeRowTable(event) {
    // const propsComponent = this.props;
    // var $tgt = $(event.target).parents('.content_wrapper').find('table');
    // if($tgt.find('.block_table_2_item').length > 0){//デザイン2でSPの時
    //   if($tgt.find('tbody.block_table_2_item tr').length <= 1){
    //     alert('削除する行がありません。')
    //   }else{
    //     if(confirm('本当に削除してよろしいですか？')){
    //       $tgt.find('tbody.block_table_2_item tr:last-child').addClass('delete').delay(500).queue(function(){
    //         $tgt.find('tbody.block_table_2_item tr:last-child').remove();
    //         $(this).remove();
    //       });
    //       $tgt.find('thead tr, tbody:not(.block_table_2_item) tr').each(function(){
    //         $(this).find('th:last-child, td:last-child').addClass('delete').delay(500).queue(function(){
    //           $(this).remove();
    //         });
    //       });
    //     };
    //   }
    // }else{
    //   if($tgt.find('tbody tr').length <= 1){
    //     alert('削除する行がありません。')
    //   }else{
    //     if(confirm('本当に削除してよろしいですか？')){
    //       $tgt.find('tbody tr:last-child').addClass('delete').delay(500).queue(function(){
    //         $tgt.find('tbody tr:last-child').remove();
    //       });
    //     };
    //   }
    // }
    // setTimeout(function(){
    //   let content = $tgt.html()
    //   propsComponent.handleChangeTable(content, propsComponent.index);
    // }, 1000);

    if(confirm('本当に削除してよろしいですか？')){
      let content = parse(this.props.content.table, {
        replace: (domNode) => {
          if (domNode.name == 'tbody' && domNode.children.length >= 1) {
            domNode.children.pop();
          }
        }
      });
      this.props.handleChangeTable(renderToString(content), this.props.index);
    }
  }

  renderTable() {
    return(
      <div className="outer">
        <span className="edit_cel edit_cel_column">
          <i className="add_column material-icons" title="列を追加" onClick={(event) => this.addColumnTable(event)}>add</i>
          <i className="remove_column material-icons" title="列を削除" onClick={(event) => this.removeColumnTable(event)}>remove</i>
        </span>
        <span className="edit_cel edit_cel_row">
          <i className="add_row material-icons" title="行を追加" onClick={(event) => this.addRowTable(event)}>add</i>
          <i className="remove_row material-icons" title="行を削除" onClick={(event) => this.removeRowTable(event)}>remove</i>
        </span>
        <table>
          { parse(this.props.content.table, {
            replace: (domNode) => this.renderEditableComponent(domNode) 
          }) }
        </table>
      </div>
    )
  }

  renderEditableComponent(domNode) {
    if (domNode.attribs && domNode.attribs.contenteditable === "true") {
      let data = "";
      if (domNode.children[0]) {
        data = renderToString(domToReact(domNode.children)).replaceAll(' data-reactroot=""', "")
      }

      return (
        <CKEditor
          initData={data}
          type="inline"
          className='content-editable'
          onBlur={(event) => this.pretreatmentHandleTable(event)}
          config={{
            width: '100%',
            height: '300',
            language: 'ja',
            allowedContent: true,
            extraPlugins: "justify,font,colorbutton,autogrow,sourcedialog,sourcearea,smiley,dialogadvtab,colordialog",
            autoGrow_onStartup: true,
            line: 1,
            font_names:'游ゴシック/Yu Gothic Medium;游明朝/Yu Mincho;ヒラギノ角ゴ Pro W3;メイリオ/メイリオ, Microsoft Sans Serif, Osaka, Arial, sans-serif;ＭＳ ゴシック/ＭＳ ゴシック;ＭＳ 明朝/ＭＳ 明朝;ＭＳ Ｐゴシック/ＭＳ Ｐゴシック;ＭＳ Ｐ明朝/ＭＳ Ｐ明朝;Verdana/Verdana;Arial/Arial;Courier New/Courier New;Times New Roman/Times New Roman',
            toolbar: [
              { name: 'clipboard', items: [ '-', 'Undo', 'Redo' ] },
              { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike'/*, '-', 'RemoveFormat' */] },
              { name: 'paragraph', items: [ /*'NumberedList', 'BulletedList', '-', 'Blockquote', '-', */'JustifyLeft', 'JustifyCenter', 'JustifyRight'/*, 'JustifyBlock'*/ ] },
              { name: 'links', items: [ 'Link', 'Unlink' ] },
              { name: 'insert', items: [ 'Table', 'Smiley' ] },
              { name: 'styles', items: [ 'Font', 'FontSize' ] },
              { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
              { name: 'tools', items: [ 'Sourcedialog' ] },
            ],
            removeButtons: 'Underline,Subscript,Superscript',
            format_tags: 'p;h1;h2;h3;pre',
            removeDialogTabs: 'image:advanced;link:advanced',
            title: false,
            versionCheck: false
          }}
        />
      )
    }
  }

  renderEndUserTable(content) {
    let fix_content = content.table || ""
    fix_content = fix_content.replace(/class='contenteditable'/gi, "class=''")
    fix_content = fix_content.replace(/contenteditable='true'/gi, "contenteditable='false' style='min-height: 27px'");
    fix_content = fix_content.replace(/<span class='admin_edit_cell_w'/gi, "<span class='admin_edit_cell_w none'")
    fix_content = fix_content.replace(/<div class='inputslider ui-slider ui-corner-all ui-slider-horizontal ui-widget ui-widget-content' contenteditable='false'><span tabindex='0' class='ui-slider-handle ui-corner-all ui-state-default' style='left: 0%'><\/span><\/div>/gi, "")

    fix_content = fix_content.replace(/class=\"contenteditable\"/gi, "class=\"\"")
    fix_content = fix_content.replace(/contenteditable=\"true\"/gi, "contenteditable=\"false\" style=\"min-height: 27px\"");
    fix_content = fix_content.replace(/<span class=\"admin_edit_cell_w\"/gi, "<span class=\"admin_edit_cell_w none\"")
    fix_content = fix_content.replace(/<div class=\"inputslider ui-slider ui-corner-all ui-slider-horizontal ui-widget ui-widget-content\" contenteditable=\"false\"><span tabindex=\"0\" class=\"ui-slider-handle ui-corner-all ui-state-default\" style=\"left: 0%\"><\/span><\/div>/gi, "")

    return(<table dangerouslySetInnerHTML={{__html: fix_content}} />)
  }

  render() {
    const { isEdit, content } = this.props
    return (
      <React.Fragment>
        <HeadlineComponent content={content} index={this.props.index}
                           isEdit={isEdit} blockType={this.props.blockType}
                           handleChangeHeadline={this.props.handleChangeHeadline}
                           handleChangeText={this.props.handleChangeText}
                           handleChangeAlign={this.props.handleChangeAlign} />
        {
          this.props.content.headlineType == "2" &&
          <ExplanationComponent content={this.props.content} index={this.props.index}
                                isEdit={this.props.isEdit}
                                handleChangeHeadline={this.props.handleChangeHeadline}
                                handleChangeAlign={this.props.handleChangeAlign} />
        }
        <div className="wrapper_item">
          <div className="inner_item_txt">
            {isEdit ? this.renderTable() : this.renderEndUserTable(content)}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default TableComponent
