import React, { Component } from 'react'
import ContentEditable from 'react-contenteditable';

export class ExplanationComponent extends Component {
  constructor(props) {
    super(props);
    this.contentEditable = React.createRef()
    this.renderExplanation = this.renderExplanation.bind(this)
    this.handleDisplayAlign = this.handleDisplayAlign.bind(this)
    this.state = {
      isExplanationEdit: false
    }
  }

  handleDisplayAlign(flag) {
    if (flag === "explanation") {
      this.setState({ isExplanationEdit: !this.state.isExplanationEdit })
    }
  }

  renderContentEditableMenu(type) {
    return(
      <ul className="contenteditable_menu active" style={{top: "-40px", left: "0"}}>
        <li key={1} className="contenteditable_menu_justifyleft" style={{padding: "0px" }}
            title="左揃え" onMouseDown={(event) => this.props.handleChangeAlign(this.props.index, "align-left", type, event)} />
        <li key={2} className="contenteditable_menu_justifycenter" style={{padding: "0px" }}
            title="中央揃え" onMouseDown={(event) => this.props.handleChangeAlign(this.props.index, "align-center", type, event)} />
        <li key={3} className="contenteditable_menu_justifyright" style={{padding: "0px" }}
            title="右揃え" onMouseDown={(event) => this.props.handleChangeAlign(this.props.index, "align-right", type, event)} />
      </ul>
    )
  }

  renderExplanation() {
    const { content, isEdit, index } = this.props;
    let editable = isEdit ? "contenteditable" : "";
    editable += this.state.isExplanationEdit ? " active" : ""

    if (isEdit) {
      return (
        <p className={`add_text h ${editable} ${content.explanationClass}` } onFocus={() => this.handleDisplayAlign("explanation")}
            onBlur={() => this.handleDisplayAlign("explanation")}>
          <ContentEditable
            innerRef={this.contentEditable}
            html={content.explanation}
            disabled={false}
            onKeyUp={(event) => this.props.handleChangeHeadline(index, event, "explanation")}
            tagName='span'
          />
          {this.state.isExplanationEdit && this.renderContentEditableMenu("explanationClass")}
        </p>
      )
    }

    return (<p className={`add_text h ${editable} ${content.explanationClass}`} dangerouslySetInnerHTML={{__html: content.explanation}} />)
  }

  render() {
    return (
      <React.Fragment>
        { this.renderExplanation() }
      </React.Fragment>
    )
  }
}

export default ExplanationComponent
