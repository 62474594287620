import React, { Component } from 'react'
import ContentEditable from 'react-contenteditable';
import { CKEditor } from "ckeditor4-react";

export class ContentComponent extends Component {
  constructor(props) {
    super(props);
    this.renderContent = this.renderContent.bind(this)
    this.state = {
      isContentEdit: false
    }
  }

  renderContent() {
    const { text, isEdit, index } = this.props;
    let editable = isEdit ? "contenteditable" : "";
    editable += this.state.isContentEdit ? " active" : ""

    if (isEdit) {
      return (
        <p data-target="1" className="editor contenteditable overflow-hidden">
          <CKEditor
            initData={text}
            type="inline"
            className='content-editable'
            onChange={(event) => this.props.handleChangeText(index, event, this.props.positionChild)}
            config={{
              width: '100%',
              height: '300',
              language: 'ja',
              allowedContent: true,
              extraPlugins: "justify,font,colorbutton,autogrow,sourcedialog,sourcearea,smiley,dialogadvtab,colordialog",
              autoGrow_onStartup: true,
              line: 1,
              font_names:'游ゴシック/Yu Gothic Medium;游明朝/Yu Mincho;ヒラギノ角ゴ Pro W3;メイリオ/メイリオ, Microsoft Sans Serif, Osaka, Arial, sans-serif;ＭＳ ゴシック/ＭＳ ゴシック;ＭＳ 明朝/ＭＳ 明朝;ＭＳ Ｐゴシック/ＭＳ Ｐゴシック;ＭＳ Ｐ明朝/ＭＳ Ｐ明朝;Verdana/Verdana;Arial/Arial;Courier New/Courier New;Times New Roman/Times New Roman',
              toolbar: [
                { name: 'clipboard', items: [ '-', 'Undo', 'Redo' ] },
                { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike'/*, '-', 'RemoveFormat' */] },
                { name: 'paragraph', items: [ /*'NumberedList', 'BulletedList', '-', 'Blockquote', '-', */'JustifyLeft', 'JustifyCenter', 'JustifyRight'/*, 'JustifyBlock'*/ ] },
                { name: 'links', items: [ 'Link', 'Unlink' ] },
                { name: 'insert', items: [ 'Table', 'Smiley' ] },
                { name: 'styles', items: [ 'Font', 'FontSize' ] },
                { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
                { name: 'tools', items: [ 'Sourcedialog' ] },
              ],
              removeButtons: 'Underline,Subscript,Superscript',
              format_tags: 'p;h1;h2;h3;pre',
              removeDialogTabs: 'image:advanced;link:advanced',
              title: false,
              versionCheck: false
            }}
          />
        </p>
      )
    }

    return (<div dangerouslySetInnerHTML={{__html: text}} />)
  }

  render() {
    return (
      <React.Fragment>
        {this.renderContent()}
      </React.Fragment>
    )
  }
}

export default ContentComponent
