import React, { Component } from 'react';
import { connect } from 'react-redux'
import MainNoData from './MainNoData';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {isDisplayModalAdd, saveDataUi} from '../../Lib/redux/actions';
import LoadableHeadingPart from '../Parts/Modal/EmbeddedParts/BlockHeading/LoadableHeadingPart';
import LoadableMoviePart from '../Parts/Modal/EmbeddedParts/BlockMovie/LoadableMoviePart';
import LoadableMapPart from '../Parts/Modal/EmbeddedParts/BlockMap/LoadableMapPart';
import LoadableTextPart from '../Parts/Modal/EmbeddedParts/BlockText/LoadableTextPart';
import LoadableLinePart from '../Parts/Modal/EmbeddedParts/BlockLine/LoadableLinePart';
import LoadableImageTextPart from '../Parts/Modal/EmbeddedParts/BlockImageText/LoadableImageTextPart';
import LoadableImageText2Part from '../Parts/Modal/EmbeddedParts/BlockImageText2/LoadableImageText2Part';
import Cssable from '../Parts/Css/Cssable';
import LoadableTelPart from "../Parts/Modal/EmbeddedParts/BlockTel/LoadableTelPart";
import LoadableImagePart from '../Parts/Modal/EmbeddedParts/BlockImage/LoadableImagePart';
import LoadableLinkPart from '../Parts/Modal/EmbeddedParts/BlockLink/LoadableLinkPart';
import LoadableTablePart from '../Parts/Modal/EmbeddedParts/BlockTable/LoadableTablePart';
import LoadableBlogPart from '../Parts/Modal/EmbeddedParts/BlockBlog/LoadableBlogPart'
import { changeText } from '../../functions';
import LoadableComparisonPart from '../Parts/Modal/EmbeddedParts/BlockComparison/LoadableComparisonPart';
import LoadableQuestionPart from '../Parts/Modal/EmbeddedParts/BlockQuestion/LoadableQuestionPart'
import LoadableMenuPart from '../Parts/Modal/EmbeddedParts/BlockMenu/LoadableMenuPart'
import LoadableGalleryPart from '../Parts/Modal/EmbeddedParts/BlockGallery/LoadableGalleryPart'
import LoadableButtonPart from '../Parts/Modal/EmbeddedParts/BlockButton/LoadableButtonPart';
import LoadableFormPart from '../Parts/Modal/EmbeddedParts/BlockForm/LoadableFormPart';
import LoadableChangeLogPart from '../Parts/Modal/EmbeddedParts/BlockChangeLog/LoadableChangeLogPart';
import LoadableShopPart from "../Parts/Modal/EmbeddedParts/BlockShop/LoadableShopPart";
import LoadableConversationPart from "../Parts/Modal/EmbeddedParts/BlockConversation/LoadableConversationPart";
import LoadableNewsPart from '../Parts/Modal/EmbeddedParts/BlockNews/LoadableNewsPart';
import LoadableCouponPart from '../Parts/Modal/EmbeddedParts/BlockCoupon/LoadableCouponPart';
import LoadableStaffPart from "../Parts/Modal/EmbeddedParts/BlockStaff/LoadableStaffPart";
import LoadableRecruitPart from "../Parts/Modal/EmbeddedParts/BlockRecruit/LoadableRecruitPart";
import LoadableHtmlPart from "../Parts/Modal/EmbeddedParts/BlockHtml/LoadableHtmlPart";
import FavoriteModal from "../Parts/Modal/FavoriteModal";
import { addDataUi, addPageDataUi } from '../../Lib/redux/actions';
import {fetchSectionStyle} from '../../functions/fetchSectionStyle';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';

const SortableItem = SortableElement(({value, content, displayModalAdd, items, handleSwitchComponents, indexItem}) => {
  return(
    <li tabIndex={0}>
      <Cssable css={value.css}>
        <div className="admin_edit" title="パーツを追加" id={`insertion_position_${indexItem + 1}`}
            data-type={`insertion_position_${indexItem + 1}`}
            onClick={() => displayModalAdd(true, indexItem)}>
          <i className="material-icons">
            <FontAwesomeIcon icon={faPlus}/>
          </i>
        </div>

        {handleSwitchComponents(content, value, indexItem, items)}
      </Cssable>
    </li>
  )
});

const SortableList = SortableContainer(({items, displayModalAdd, handleSwitchComponents}) => {
  return (
    <ul>
      {
        items.map((item, index) => {
          let content = JSON.parse(item.contents);
          return (
            <SortableItem key={item.sort} value={item} content={content}
                          displayModalAdd={displayModalAdd} items={items}
                          handleSwitchComponents={handleSwitchComponents}
                          indexItem={index} index={index}/>
          )
        })
      }
    </ul>
  );
});


class AsideEditComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data
    };
    this.handleSwitchComponents = this.handleSwitchComponents.bind(this);
    this.handleChangeHeadline = this.handleChangeHeadline.bind(this);
    this.handleChangeText = this.handleChangeText.bind(this);
    this.handleChangeAlign = this.handleChangeAlign.bind(this);
    this.handleDeleteComponent = this.handleDeleteComponent.bind(this);
    this.handleChangeTable = this.handleChangeTable.bind(this);
    this.handleChangeWidth = this.handleChangeWidth.bind(this);
    this.handleChangeImageWidth = this.handleChangeImageWidth.bind(this)
    this.handleChangeLabel = this.handleChangeLabel.bind(this);
    this.handeChangeCssSetting = this.handeChangeCssSetting.bind(this)
    this.handleChangeChangeColor = this.handleChangeChangeColor.bind(this)
    this.handleChangeDelay = this.handleChangeDelay.bind(this);
    this.handleCopyComponent = this.handleCopyComponent.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({data: nextProps.data});
  }

  componentDidMount() {
    document.addEventListener('paste', function(e){
      if((e.target.parentElement !== null && e.target.parentElement.className.indexOf('contenteditable') != -1) || e.target.className.indexOf('contenteditable') != -1){
        e.preventDefault();
        var text;
        if(window.clipboardData && window.clipboardData.getData){//IE
          //text = window.clipboardData.getData('Text');
        }else if(e.clipboardData && e.clipboardData.getData){
          text = (e.originalEvent || e).clipboardData.getData('text/plain');
          document.execCommand('insertHTML', false, text);
        }
      }
    });
  }

  handleChangeHeadline(index, event, field) {
    const {data} = this.state;
    if(data[index] === undefined) {return false}
    let contents = JSON.parse(data[index].contents);
    contents[field] = event.currentTarget.textContent;

    if (event.key === 'Enter' && event.target.textContent.charCodeAt(event.target.textContent.length - 1) === 10) {
      contents[field] += "\n";
    } else if (event.key === 'Enter' && event.target.textContent.charCodeAt(event.target.textContent.length - 1) !== 10) {
      contents[field] += "\n\n";
    }

    data[index].contents = JSON.stringify(contents);
    this.setState({
      data: data
    }, () => this.props.saveDataUi(this.state.data))
  }

  handleCopyComponent(indexBlockData, data) {
    data.indexBlockData = indexBlockData
    this.props.appendDataUi(data);
  }

  handleChangeText(index, event, positionChild) {
    const {data} = this.state;
    if(data[index] === undefined) {return false}
    let contents = JSON.parse(data[index].contents);
    let text = `text`
    if(positionChild !== "" && positionChild !== undefined) { text = `text${positionChild}` }
    data[index].contents = JSON.stringify(changeText(contents, text, event));
    this.setState({
      data: data
    }, () => this.props.saveDataUi(this.state.data))
  }

  handleChangeAlign(index, align, field) {
    const {data} = this.state;
    if(data[index] === undefined) {return false}
    let contents = JSON.parse(data[index].contents);
    contents[field] = align;

    data[index].contents = JSON.stringify(contents);
    this.setState({
      data: data
    }, () => this.props.saveDataUi(this.state.data))
  }

  handleDeleteComponent(index) {
    if (window.confirm('本当に削除してよろしいですか？')) {
      const {data} = this.state;
      if(data[index] === undefined) {return false}
      if(index > -1 && index < data.length) {
        data.splice(index, 1);
      }
      this.setState({
        data: data
      }, () => this.props.saveDataUi(this.state.data))
    }
  }

  handleChangeTable(content, index) {
    const {data} = this.state;
    if(data[index] === undefined) {return false}
    let contents = JSON.parse(data[index].contents);
    contents.table = content
    data[index].contents = JSON.stringify(contents);
    this.setState({
      data: data
    }, () => this.props.saveDataUi(this.state.data))
  }

  handleChangeWidth(value, index) {
    const {data} = this.state;
    if(data[index] === undefined) {return false}
    let contents = JSON.parse(data[index].contents);
    contents.widthTable = value
    data[index].contents = JSON.stringify(contents);
    this.setState({
      data: data
    }, () => this.props.saveDataUi(this.state.data))
  }

  handleChangeImageWidth(value, index) {
    const {data} = this.state;
    if(data[index] === undefined) {return false}
    let contents = JSON.parse(data[index].contents);
    contents.widthImage = value
    data[index].contents = JSON.stringify(contents);
    this.setState({
      data: data
    }, () => this.props.saveDataUi(this.state.data))
  }

  handleChangeLabel(event, index, field) {
    const {data} = this.state;
    if(data[index] === undefined) {return false}
    let contents = JSON.parse(data[index].contents);
    contents[field] = event.target.value;
    data[index].contents = JSON.stringify(contents);
    this.setState({
      data: data
    }, () => this.props.saveDataUi(this.state.data))
  }

  handeChangeCssSetting(index, field, event = {}) {
    const {data} = this.state;
    if(data[index] === undefined) {return false}
    let contents = JSON.parse(data[index].contents);
    const fieldEvents = ["top_padding_class", "bottom_padding_class", "anchor_tag"]
    if(fieldEvents.indexOf(field) > -1) {
      contents[field] = event.target.value
    }
    switch (field) {
      case "width_class":
        if(contents.width_class === "width_full")
          contents.width_class = "width_fixed"
        else
          contents.width_class = "width_full"

        setTimeout(function() {
          let array = document.getElementsByClassName('admin_edit_img_w');
          for (let i = 0; i < array.length; i++) {
            let section = array[i].closest('section').classList;
            let wrapper_width = array[i].parentElement.parentElement.offsetWidth;

            if (section.contains('block_image_1') || section.contains('block_image_2') || section.contains('block_image_text_1') ||
              section.contains('block_image_text_2') || section.contains('block_image_text_5') || section.contains('block_image_text_6')) {

              array[i].style.width = (wrapper_width * 0.45) + 'px'
              array[i].style.marginLeft = (wrapper_width * 0.05) + 'px';

            } else if (section.contains('block_image_text_3') || section.contains('block_image_text_7') || section.contains('block_image_text_9')) {

              array[i].style.width = (wrapper_width * 0.9) + 'px';
              array[i].style.left = (wrapper_width * 0.1) + 'px';
              array[i].style.right = 'auto';

            } else if (section.contains('block_image_text_4') || section.contains('block_image_text_8') || section.contains('block_image_text_10')) {

              array[i].style.width = (wrapper_width * 0.9) + 'px';
              array[i].style.left = 'auto';
              array[i].style.right = (wrapper_width * 0.1) + 'px';
            }
            array[i].style.bottom = (array[i].parentElement.offsetHeight / 2) + 'px';
          }
        }, 10);
      default:
        break;
    }

    data[index].contents = JSON.stringify(contents);
    this.setState({
      data: data
    }, () => this.props.saveDataUi(this.state.data))
  }

  handleChangeDelay(event, index) {
    const {data} = this.state;
    if(data[index] === undefined) {return false}
    const value = event.target.value;

    let contents = JSON.parse(data[index].contents);
    contents.delay = value;
    data[index].contents = JSON.stringify(contents);
    this.setState({
      data: data
    }, () => this.props.saveDataUi(this.state.data))

    let block = event.target.closest("section");
    block.classList.remove("delay1", "delay2", "delay3", "delay4", "delay1Active", "delay2Active", "delay3Active", "delay4Active");
    if (value !== '') {
      block.classList.add(value);
      setTimeout(() => {
        block.classList.add(`${value}Active`);
      }, 800);
    }
  }

  onSortEnd({oldIndex, newIndex}) {
    const newDatas = arrayMove(this.state.data, oldIndex, newIndex);
    this.setState({
      data: newDatas
    }, () => this.props.saveDataUi(this.state.data))
  }

  handleChangeChangeColor(index, color, colorType) {
    const {data} = this.state;
    if(data[index] === undefined) {return false}
    let contents = JSON.parse(data[index].contents);
    contents[`r_${colorType}`] = color.rgb.r
    contents[`g_${colorType}`] = color.rgb.g
    contents[`b_${colorType}`] = color.rgb.b
    contents[`a_${colorType}`] = color.rgb.a
    data[index].contents = JSON.stringify(contents);
    this.setState({
      data: data
    }, () => this.props.saveDataUi(this.state.data))
  }

  handleSwitchComponents(content, item, index, data) {
    const sectionStyle = fetchSectionStyle(content)
    switch (item.block_type) {
      case 'block_header':
        return <LoadableHeadingPart content={content} kindOfDesign={item.kind_of_design} index={index}
                                    handleChangeHeadline={this.handleChangeHeadline}
                                    handleCopyComponent={this.handleCopyComponent}
                                    handleDeleteComponent={this.handleDeleteComponent}
                                    handleChangeAlign={this.handleChangeAlign}
                                    handleChangeText={this.handleChangeText}
                                    handleChangeDelay={this.handleChangeDelay}
                                    blockType={item.block_type}
                                    data={data} {...this.props}
                                    handeChangeCssSetting={this.handeChangeCssSetting}
                                    sectionStyle={sectionStyle}
                                    handleChangeChangeColor={this.handleChangeChangeColor}/>
        break;
      case 'block_movie':
        return <LoadableMoviePart content={content} kindOfDesign={item.kind_of_design} index={index}
                                  handleChangeHeadline={this.handleChangeHeadline}
                                  handleCopyComponent={this.handleCopyComponent}
                                  handleDeleteComponent={this.handleDeleteComponent}
                                  handleChangeAlign={this.handleChangeAlign}
                                  handleChangeText={this.handleChangeText}
                                  handleChangeDelay={this.handleChangeDelay}
                                  blockType={item.block_type}
                                  data={data} {...this.props}
                                  handeChangeCssSetting={this.handeChangeCssSetting}
                                  sectionStyle={sectionStyle}
                                  handleChangeChangeColor={this.handleChangeChangeColor}/>
        break;
      case 'block_map':
        return <LoadableMapPart content={content} kindOfDesign={item.kind_of_design} index={index}
                                handleChangeHeadline={this.handleChangeHeadline}
                                handleCopyComponent={this.handleCopyComponent}
                                handleDeleteComponent={this.handleDeleteComponent}
                                handleChangeAlign={this.handleChangeAlign}
                                handleChangeText={this.handleChangeText}
                                handleChangeDelay={this.handleChangeDelay}
                                blockType={item.block_type}
                                data={data} {...this.props}
                                handeChangeCssSetting={this.handeChangeCssSetting}
                                sectionStyle={sectionStyle}
                                handleChangeChangeColor={this.handleChangeChangeColor}/>
        break;
      case 'block_tel':
        return <LoadableTelPart content={content} kindOfDesign={item.kind_of_design} index={index} item={item}
                                handleChangeHeadline={this.handleChangeHeadline}
                                handleCopyComponent={this.handleCopyComponent}
                                handleDeleteComponent={this.handleDeleteComponent}
                                handleChangeAlign={this.handleChangeAlign}
                                handleChangeText={this.handleChangeText}
                                handleChangeDelay={this.handleChangeDelay}
                                blockType={item.block_type}
                                data={data} {...this.props}
                                handeChangeCssSetting={this.handeChangeCssSetting}
                                sectionStyle={sectionStyle}
                                handleChangeChangeColor={this.handleChangeChangeColor}/>
        break;
      case 'block_shop':
        return <LoadableShopPart content={content} kindOfDesign={item.kind_of_design} index={index} item={item}
                                handleChangeHeadline={this.handleChangeHeadline}
                                handleCopyComponent={this.handleCopyComponent}
                                handleDeleteComponent={this.handleDeleteComponent}
                                handleChangeAlign={this.handleChangeAlign}
                                handleChangeText={this.handleChangeText}
                                handleChangeDelay={this.handleChangeDelay}
                                blockType={item.block_type}
                                data={data} {...this.props}
                                handeChangeCssSetting={this.handeChangeCssSetting}
                                sectionStyle={sectionStyle}
                                handleChangeChangeColor={this.handleChangeChangeColor}/>
        break;
      case 'block_conversation':
        return <LoadableConversationPart content={content} kindOfDesign={item.kind_of_design} index={index} item={item}
                                 handleChangeHeadline={this.handleChangeHeadline}
                                 handleCopyComponent={this.handleCopyComponent}
                                 handleDeleteComponent={this.handleDeleteComponent}
                                 handleChangeAlign={this.handleChangeAlign}
                                 handleChangeText={this.handleChangeText}
                                 handleChangeDelay={this.handleChangeDelay}
                                 blockType={item.block_type}
                                 data={data} {...this.props}
                                 sectionStyle={sectionStyle}
                                 handeChangeCssSetting={this.handeChangeCssSetting}
                                 handleChangeChangeColor={this.handleChangeChangeColor}/>
        break;
      case 'block_text':
        return <LoadableTextPart content={content} kindOfDesign={item.kind_of_design} index={index}
                                 handleChangeHeadline={this.handleChangeHeadline}
                                 handleCopyComponent={this.handleCopyComponent}
                                 handleDeleteComponent={this.handleDeleteComponent}
                                 handleChangeText={this.handleChangeText}
                                 handleChangeAlign={this.handleChangeAlign}
                                 handleChangeDelay={this.handleChangeDelay}
                                 blockType={item.block_type}
                                 data={data} {...this.props}
                                 handeChangeCssSetting={this.handeChangeCssSetting}
                                 sectionStyle={sectionStyle}
                                 handleChangeChangeColor={this.handleChangeChangeColor}/>
        break;
      case 'block_line':
        return <LoadableLinePart index={index} handleDeleteComponent={this.handleDeleteComponent}
                                 kindOfDesign={item.kind_of_design} data={data} {...this.props}
                                 handeChangeCssSetting={this.handeChangeCssSetting}
                                 handleChangeDelay={this.handleChangeDelay}
                                 handleCopyComponent={this.handleCopyComponent}
                                 kindOfDesign={item.kind_of_design} data={data} {...this.props}
                                 handleChangeChangeColor={this.handleChangeChangeColor}
                                 sectionStyle={sectionStyle}
                                 content={content}/>

        break;
      case 'block_image_text':
        return <LoadableImageTextPart content={content} index={index} kindOfDesign={item.kind_of_design} item={item}
                                      data={data} {...this.props}
                                      handleChangeHeadline={this.handleChangeHeadline}
                                      handleCopyComponent={this.handleCopyComponent}
                                      handleDeleteComponent={this.handleDeleteComponent}
                                      handleChangeText={this.handleChangeText}
                                      handleChangeAlign={this.handleChangeAlign}
                                      handleChangeDelay={this.handleChangeDelay}
                                      blockId={`40${index}0${item.kind_of_design}`}
                                      handleChangeImageWidth={this.handleChangeImageWidth}
                                      isVisitOuterLink={false}
                                      handeChangeCssSetting={this.handeChangeCssSetting}
                                      sectionStyle={sectionStyle}
                                      handleChangeChangeColor={this.handleChangeChangeColor}/>
      break;
      case 'block_image_text2':
        return <LoadableImageText2Part content={content} index={index} kindOfDesign={item.kind_of_design} item={item}
                                      data={data} {...this.props}
                                      handleChangeHeadline={this.handleChangeHeadline}
                                      handleCopyComponent={this.handleCopyComponent}
                                      handleDeleteComponent={this.handleDeleteComponent}
                                      handleChangeText={this.handleChangeText}
                                      handleChangeAlign={this.handleChangeAlign}
                                      isVisitOuterLink={false}
                                      handeChangeCssSetting={this.handeChangeCssSetting}
                                      sectionStyle={sectionStyle}
                                      handleChangeDelay={this.handleChangeDelay}
                                      isVisitOuterLink={false}
                                      handleChangeChangeColor={this.handleChangeChangeColor}/>
      break;
      case 'block_image':
        return <LoadableImagePart content={content}
                                  item={item}
                                  index={index}
                                  data={data}
                                  blockId={`30${index}0${item.kind_of_design}`}
                                  {...this.props}
                                  handleDeleteComponent={this.handleDeleteComponent}
                                  handleChangeHeadline={this.handleChangeHeadline}
                                  handleCopyComponent={this.handleCopyComponent}
                                  handleChangeSubHeadline={this.handleChangeSubHeadline}
                                  handleChangeHeadlinePattern={this.handleChangeHeadlinePattern}
                                  handleChangeText={this.handleChangeText}
                                  handleChangeAlign={this.handleChangeAlign}
                                  handleChangeDelay={this.handleChangeDelay}
                                  isVisitOuterLink={false}
                                  handleChangeImageWidth={this.handleChangeImageWidth}
                                  handeChangeCssSetting={this.handeChangeCssSetting}
                                  sectionStyle={sectionStyle}
                                  handleChangeChangeColor={this.handleChangeChangeColor}
                                  />
        break;
        case 'block_table':
        return <LoadableTablePart content={content} index={index} kindOfDesign={item.kind_of_design}
                                  data={data} {...this.props}
                                  handleChangeHeadline={this.handleChangeHeadline}
                                  handleCopyComponent={this.handleCopyComponent}
                                  handleChangeSubHeadline={this.handleChangeSubHeadline}
                                  handleChangeExplanation={this.handleChangeExplanation}
                                  handleDeleteComponent={this.handleDeleteComponent}
                                  handleChangeAlign={this.handleChangeAlign}
                                  handleChangeTable={this.handleChangeTable}
                                  handeChangeCssSetting={this.handeChangeCssSetting}
                                  sectionStyle={sectionStyle}
                                  handleChangeWidth={this.handleChangeWidth}
                                  handleChangeDelay={this.handleChangeDelay}
                                  handleChangeChangeColor={this.handleChangeChangeColor}/>
        break;
        case 'block_link':
          return <LoadableLinkPart content={content}
                                   item={item}
                                   index={index}
                                   data={data} {...this.props}
                                   indexBlockData={this.props.indexBlockData}
                                   handleCopyComponent={this.handleCopyComponent}
                                   handleDeleteComponent={this.handleDeleteComponent}
                                   handleChangeHeadline={this.handleChangeHeadline}
                                   handleChangeSubHeadline={this.handleChangeSubHeadline}
                                   handleChangeHeadlinePattern={this.handleChangeHeadlinePattern}
                                   handleChangeText={this.handleChangeText}
                                   handleChangeAlign={this.handleChangeAlign}
                                   handeChangeCssSetting={this.handeChangeCssSetting}
                                   sectionStyle={sectionStyle}
                                   handleChangeAlign={this.handleChangeAlign}
                                   handleChangeDelay={this.handleChangeDelay}
                                   handleChangeChangeColor={this.handleChangeChangeColor}/>
          break;
        case 'block_blog':
          return <LoadableBlogPart content={content}
                                    item={item}
                                    index={index}
                                    data={data}
                                    {...this.props}
                                    handleDeleteComponent={this.handleDeleteComponent}
                                    handleCopyComponent={this.handleCopyComponent}
                                    handleChangeHeadline={this.handleChangeHeadline}
                                    handleChangeSubHeadline={this.handleChangeSubHeadline}
                                    handleChangeHeadlinePattern={this.handleChangeHeadlinePattern}
                                    handleChangeText={this.handleChangeText}
                                    handleChangeAlign={this.handleChangeAlign}
                                    handleChangeLabel={this.handleChangeLabel}
                                    handeChangeCssSetting={this.handeChangeCssSetting}
                                    sectionStyle={sectionStyle}
                                    handleChangeDelay={this.handleChangeDelay}
                                    handleChangeChangeColor={this.handleChangeChangeColor}
                                    />
          break;
        case 'block_comparison':
          return <LoadableComparisonPart index={index} handleDeleteComponent={this.handleDeleteComponent}
                                          kindOfDesign={item.kind_of_design} data={data} {...this.props}
                                          content={content}
                                          item={item}
                                          handleCopyComponent={this.handleCopyComponent}
                                          handleChangeHeadline={this.handleChangeHeadline}
                                          handleChangeSubHeadline={this.handleChangeSubHeadline}
                                          handleChangeAlign={this.handleChangeAlign}
                                          handleChangeExplanation={this.handleChangeExplanation}
                                          handeChangeCssSetting={this.handeChangeCssSetting}
                                          sectionStyle={sectionStyle}
                                          handleChangeDelay={this.handleChangeDelay}
                                          {...this.props}
                                          handleChangeChangeColor={this.handleChangeChangeColor}/>
          break;
        case 'block_question':
          return <LoadableQuestionPart content={content}
                                    item={item}
                                    index={index}
                                    data={data}
                                    {...this.props}
                                    handleDeleteComponent={this.handleDeleteComponent}
                                    handleChangeHeadline={this.handleChangeHeadline}
                                    handleCopyComponent={this.handleCopyComponent}
                                    handleChangeSubHeadline={this.handleChangeSubHeadline}
                                    handleChangeHeadlinePattern={this.handleChangeHeadlinePattern}
                                    handleChangeText={this.handleChangeText}
                                    handleChangeAlign={this.handleChangeAlign}
                                    handleChangeLabel={this.handleChangeLabel}
                                    handeChangeCssSetting={this.handeChangeCssSetting}
                                    sectionStyle={sectionStyle}
                                    handleChangeDelay={this.handleChangeDelay}
                                    handleChangeChangeColor={this.handleChangeChangeColor}
                                    />
          break;
        case 'block_menu':
          return <LoadableMenuPart content={content}
                                    item={item}
                                    index={index}
                                    data={data}
                                    {...this.props}
                                    handleDeleteComponent={this.handleDeleteComponent}
                                    handleChangeHeadline={this.handleChangeHeadline}
                                    handleCopyComponent={this.handleCopyComponent}
                                    handleChangeSubHeadline={this.handleChangeSubHeadline}
                                    handleChangeHeadlinePattern={this.handleChangeHeadlinePattern}
                                    handleChangeText={this.handleChangeText}
                                    handleChangeAlign={this.handleChangeAlign}
                                    handleChangeLabel={this.handleChangeLabel}
                                    handeChangeCssSetting={this.handeChangeCssSetting}
                                    sectionStyle={sectionStyle}
                                    handleChangeDelay={this.handleChangeDelay}
                                    handleChangeChangeColor={this.handleChangeChangeColor}
                                    />
          break;
        case 'block_gallery':
          return <LoadableGalleryPart content={content}
                                    item={item}
                                    index={index}
                                    data={data}
                                    {...this.props}
                                    handleDeleteComponent={this.handleDeleteComponent}
                                    handleCopyComponent={this.handleCopyComponent}
                                    handleChangeHeadline={this.handleChangeHeadline}
                                    handleChangeSubHeadline={this.handleChangeSubHeadline}
                                    handleChangeHeadlinePattern={this.handleChangeHeadlinePattern}
                                    handleChangeText={this.handleChangeText}
                                    handleChangeAlign={this.handleChangeAlign}
                                    handleChangeLabel={this.handleChangeLabel}
                                    handeChangeCssSetting={this.handeChangeCssSetting}
                                    sectionStyle={sectionStyle}
                                    handleChangeDelay={this.handleChangeDelay}
                                    handleChangeChangeColor={this.handleChangeChangeColor}
                                    />
          break;
        case 'block_button':
          return <LoadableButtonPart index={index} handleDeleteComponent={this.handleDeleteComponent}
                                          kindOfDesign={item.kind_of_design} data={data} {...this.props}
                                          content={content}
                                          item={item}
                                          handleChangeHeadline={this.handleChangeHeadline}
                                          handleCopyComponent={this.handleCopyComponent}
                                          handleChangeSubHeadline={this.handleChangeSubHeadline}
                                          handleChangeAlign={this.handleChangeAlign}
                                          handleChangeExplanation={this.handleChangeExplanation}
                                          {...this.props}
                                          handeChangeCssSetting={this.handeChangeCssSetting}
                                          sectionStyle={sectionStyle}
                                          handleChangeDelay={this.handleChangeDelay}
                                          {...this.props}
                                          handleChangeChangeColor={this.handleChangeChangeColor}/>
          break;
          case 'block_form':
            return <LoadableFormPart index={index} handleDeleteComponent={this.handleDeleteComponent}
                                    kindOfDesign={item.kind_of_design} data={data} {...this.props}
                                    content={content}
                                    item={item}
                                    handleChangeHeadline={this.handleChangeHeadline}
                                    handleCopyComponent={this.handleCopyComponent}
                                    handleChangeSubHeadline={this.handleChangeSubHeadline}
                                    handleChangeAlign={this.handleChangeAlign}
                                    handleChangeExplanation={this.handleChangeExplanation}
                                    handeChangeCssSetting={this.handeChangeCssSetting}
                                    sectionStyle={sectionStyle}
                                    handleChangeExplanation={this.handleChangeExplanation}
                                    handleChangeDelay={this.handleChangeDelay}
                                    handleChangeChangeColor={this.handleChangeChangeColor}/>
            break;
        case 'block_news':
          return <LoadableNewsPart index={index} handleDeleteComponent={this.handleDeleteComponent}
                                          kindOfDesign={item.kind_of_design} data={data} {...this.props}
                                          content={content}
                                          item={item}
                                          handleChangeHeadline={this.handleChangeHeadline}
                                          handleCopyComponent={this.handleCopyComponent}
                                          handleChangeSubHeadline={this.handleChangeSubHeadline}
                                          handleChangeAlign={this.handleChangeAlign}
                                          handleChangeExplanation={this.handleChangeExplanation}
                                          {...this.props}
                                          handeChangeCssSetting={this.handeChangeCssSetting}
                                          sectionStyle={sectionStyle}
                                          handleChangeDelay={this.handleChangeDelay}
                                          {...this.props}
                                          handleChangeChangeColor={this.handleChangeChangeColor}/>
          break;
        case 'block_change_log':
          return <LoadableChangeLogPart index={index} handleDeleteComponent={this.handleDeleteComponent}
                                          kindOfDesign={item.kind_of_design} data={data} {...this.props}
                                          content={content}
                                          item={item}
                                          handleChangeHeadline={this.handleChangeHeadline}
                                          handleCopyComponent={this.handleCopyComponent}
                                          handleChangeSubHeadline={this.handleChangeSubHeadline}
                                          handleChangeAlign={this.handleChangeAlign}
                                          handleChangeExplanation={this.handleChangeExplanation}
                                          handeChangeCssSetting={this.handeChangeCssSetting}
                                          sectionStyle={sectionStyle}
                                          handleChangeExplanation={this.handleChangeExplanation}
                                          handleChangeDelay={this.handleChangeDelay}
                                          handleChangeChangeColor={this.handleChangeChangeColor}/>
          break;
        case 'block_coupon':
          return <LoadableCouponPart index={index} handleDeleteComponent={this.handleDeleteComponent}
                                          kindOfDesign={item.kind_of_design} data={data} {...this.props}
                                          content={content}
                                          item={item}
                                          handleChangeHeadline={this.handleChangeHeadline}
                                          handleCopyComponent={this.handleCopyComponent}
                                          handleChangeSubHeadline={this.handleChangeSubHeadline}
                                          handleChangeAlign={this.handleChangeAlign}
                                          handleChangeExplanation={this.handleChangeExplanation}
                                          handeChangeCssSetting={this.handeChangeCssSetting}
                                          sectionStyle={sectionStyle}
                                          handleChangeExplanation={this.handleChangeExplanation}
                                          handleChangeDelay={this.handleChangeDelay}
                                          handleChangeChangeColor={this.handleChangeChangeColor}/>
          break;
        case 'block_staff':
          return <LoadableStaffPart index={index} handleDeleteComponent={this.handleDeleteComponent}
                                          data={data} {...this.props}
                                          content={content}
                                          item={item}
                                          handleChangeHeadline={this.handleChangeHeadline}
                                          handleCopyComponent={this.handleCopyComponent}
                                          handleChangeSubHeadline={this.handleChangeSubHeadline}
                                          handleChangeAlign={this.handleChangeAlign}
                                          handleChangeExplanation={this.handleChangeExplanation}
                                          {...this.props}
                                          handeChangeCssSetting={this.handeChangeCssSetting}
                                          sectionStyle={sectionStyle}
                                          handleChangeDelay={this.handleChangeDelay}
                                          {...this.props}
                                          handleChangeChangeColor={this.handleChangeChangeColor}/>
          break;
        case 'block_recruit':
          return <LoadableRecruitPart index={index} handleDeleteComponent={this.handleDeleteComponent}
                                          data={data} {...this.props}
                                          content={content}
                                          handleCopyComponent={this.handleCopyComponent}
                                          item={item}
                                          handleChangeHeadline={this.handleChangeHeadline}
                                          handleChangeSubHeadline={this.handleChangeSubHeadline}
                                          handleChangeAlign={this.handleChangeAlign}
                                          handleChangeExplanation={this.handleChangeExplanation}
                                          handleChangeDelay={this.handleChangeDelay}
                                          handeChangeCssSetting={this.handeChangeCssSetting}
                                          sectionStyle={sectionStyle}
                                          handleChangeChangeColor={this.handleChangeChangeColor}
                                          />
          break;
        case 'block_html':
          return <LoadableHtmlPart index={index} handleDeleteComponent={this.handleDeleteComponent}
                                          kindOfDesign={item.kind_of_design} data={data} {...this.props}
                                          content={content}
                                          item={item}
                                          handleChangeHeadline={this.handleChangeHeadline}
                                          handleCopyComponent={this.handleCopyComponent}
                                          handleChangeSubHeadline={this.handleChangeSubHeadline}
                                          handleChangeAlign={this.handleChangeAlign}
                                          handleChangeExplanation={this.handleChangeExplanation}
                                          handeChangeCssSetting={this.handeChangeCssSetting}
                                          sectionStyle={sectionStyle}
                                          handleChangeChangeColor={this.handleChangeChangeColor}
                                          handleChangeExplanation={this.handleChangeExplanation}
                                          handleChangeDelay={this.handleChangeDelay}
                                          handleChangeChangeColor={this.handleChangeChangeColor}/>
          break;
      default:
        break;
    }
  }

  render() {
    let { data } = this.props;

    if (data.length <= 0) {
      return (<MainNoData />);
    }

    return (
      <aside id="common_footer">
        <SortableList items={data} displayModalAdd={this.props.displayModalAdd} handleSwitchComponents={this.handleSwitchComponents}
                      distance={1} onSortEnd={this.onSortEnd} useWindowAsScrollContainer={true} helperClass='sortable-helper'
                      lockAxis="y" useDragHandle />
        {
          this.props.isOpenFavoriteModal && this.props.indexBlockData !== null &&
          <FavoriteModal handleShowModalFavorite={this.props.handleShowModalFavorite} content={data[this.props.indexBlockData]} />
        }
        <div  className="admin_edit" title="パーツを追加" id={`insertion_position_${data.length + 1}`} data-type={`insertion_position_${data.length + 1}`} onClick={() => this.props.displayModalAdd(true, data.length)}>
          <i className="material-icons">
            <FontAwesomeIcon icon={faPlus} />
          </i>
        </div>
      </aside>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);

  newState.data = storeState.asideData;
  newState.dataBlock = storeState.dataBlock;
  newState.isDisplaySetting = storeState.isDisplaySetting;

  return newState;
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveDataUi: (data) => dispatch(saveDataUi(data)),
    displayModalAdd: (isDisplay, index, isDisplaySetting, dataBlock) => dispatch(isDisplayModalAdd({isDisplay, index, isDisplaySetting, dataBlock})),
    appendDataUi: (data) => dispatch(addDataUi(data)),
    appendPageDataUi: (data) => dispatch(addPageDataUi(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AsideEditComponent)
