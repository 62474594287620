import React from "react";
import { connect } from 'react-redux';
import Footer from './Footer';
import HeaderContent from './HeaderContent';
import HeaderMenu from './HeaderMenu';
import Authority from '../Parts/Auth/Authority';
import LoggingIn from './LoggingIn';
import EditParallaxImage from "../ViewEdit/EditParallaxImage";
import HomeSlider from '../Parts/MvSlider/HomeSlider';
import FooterComponent from './LayoutContent/FooterComponent'
import SectionContent from './LayoutContent/SectionContents';
import SideNavigation from './LayoutContent/SideNivationPart/SideNavigation';

class Layout extends React.Component {
  constructor(props) {
    super(props);
  }

  handleShowWithSideNaivation() {
    return (
      <div className="column2">
        <div id="primary">
          <SectionContent />
        </div>
        <SideNavigation />
      </div>
    )
  }

  handleShowWithoutSideNaivation() {
    return <SectionContent />
  }

  render () {
    const { isHome, common_footer_flag, common_sidenavi_flag } = this.props;

    return (
      <Authority>
        <LoggingIn />
        <header id="pattern1" className="header_class ">
          <HeaderContent />
          <HeaderMenu />
        </header>
        {
          isHome ? (<HomeSlider isEdit={false} />) : <EditParallaxImage isEdit={false} />
        }
        <main id="main">
          { common_sidenavi_flag ? this.handleShowWithSideNaivation() : this.handleShowWithoutSideNaivation()}
          { common_footer_flag && <FooterComponent /> }
        </main>
        
        <Footer />
      </Authority>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);

  newState.common_footer_flag = storeState.settings.common_footer_flag;
  newState.common_sidenavi_flag = storeState.settings.common_sidenavi_flag;

  return newState;
}

export default connect(mapStateToProps)(Layout);
