import React, { Component } from 'react'
import ContentEditable from 'react-contenteditable';

export class HeadingPatternComponent extends Component {
  constructor(props) {
    super(props);
    this.contentEditable1 = React.createRef()
    this.contentEditable2 = React.createRef()
    this.renderHeadlinePattern = this.renderHeadlinePattern.bind(this)
    this.renderSubHeadlinePattern = this.renderSubHeadlinePattern.bind(this)
    this.handleDisplayAlign = this.handleDisplayAlign.bind(this)
    this.state = {
      isHeadingPatternEdit: false,
      isSubHeadingPatternEdit: false
    }
  }

  handleDisplayAlign(flag) {
    if (flag === "headlinePattern") {
      this.setState({ isHeadingPatternEdit: !this.state.isHeadingPatternEdit })
    } else if (flag === "subHeadlinePattern") {
      this.setState({ isSubHeadingPatternEdit: !this.state.isSubHeadingPatternEdit })
    }
  }

  renderContentEditableMenu(type, index) {
    return(
      <ul className="contenteditable_menu active" style={{top: "-40px", left: "0"}}>
        <li key={1} className="contenteditable_menu_justifyleft"
            title="左揃え" onMouseDown={(event) => this.props.handleChangeAlign(index, "align-left", type, event)} />
        <li key={2} className="contenteditable_menu_justifycenter"
            title="中央揃え" onMouseDown={(event) => this.props.handleChangeAlign(index, "align-center", type, event)} />
        <li key={3} className="contenteditable_menu_justifyright"
            title="右揃え" onMouseDown={(event) => this.props.handleChangeAlign(index, "align-right", type, event)} />
      </ul>
    )
  }

  renderHeadlinePattern(isEdit, content, index) {
    let editable = isEdit ? "contenteditable" : "";
    editable += this.state.isHeadingPatternEdit ? " active" : ""

    if (isEdit) {
      return (
        <h3 className={`h ${editable}`} onFocus={() => this.handleDisplayAlign("headlinePattern")}
            onBlur={() => this.handleDisplayAlign("headlinePattern")}>
          <ContentEditable
            innerRef={this.contentEditable1}
            html={content.headline_pattern}
            disabled={false}
            onKeyUp={(event) => this.props.handleChangeHeadline(index, event, "headline_pattern")}
            tagName='span'
          />
          {this.state.isHeadingPatternEdit && this.renderContentEditableMenu("headingPatternClass", index)}
        </h3>
      )
    }

    return (<h3 className={`h ${editable}`} dangerouslySetInnerHTML={{__html: content.headline_pattern}}></h3>)
  }

  renderSubHeadlinePattern(isEdit, content, index) {
    let editable = isEdit ? "contenteditable" : "";
    editable += this.state.isSubHeadingPatternEdit ? " active" : ""

    if (isEdit) {
      return (
        <p className={editable} onFocus={() => this.handleDisplayAlign("subHeadlinePattern")}
           onBlur={() => this.handleDisplayAlign("subHeadlinePattern")}>
          <ContentEditable
            innerRef={this.contentEditable2}
            html={content.subheadline_pattern} // innerHTML of the editable div
            disabled={false}       // use true to disable editing
            onKeyUp={(event) => this.props.handleChangeHeadline(index, event, "subheadline_pattern")} // handle innerHTML change
            tagName='span' // Use a custom HTML tag (uses a div by default)
          />
          {this.state.isSubHeadingPatternEdit && this.renderContentEditableMenu("headingPatternClass", index)}
        </p>
      )
    }

    return (<p className={editable} dangerouslySetInnerHTML={{__html: content.subheadline_pattern}}></p>)
  }

  render() {
    const { content, isEdit, index } = this.props;
    let headingPatternClass = content.headingPatternClass

    return (
      <div className={`heading block_header_${this.props.type} ${headingPatternClass}`}>
        {this.renderHeadlinePattern(isEdit, content, index)}
        {this.renderSubHeadlinePattern(isEdit, content, index)}
      </div>
    )
  }
}

export default HeadingPatternComponent
