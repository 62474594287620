import React, { Component } from 'react'
import {movFunc, runYoutube} from '../../../functions';

export class MvMovieComponent extends Component {
  componentDidMount(prevProps, prevState) {
    runYoutube();
    movFunc();
  }

  render() {
    const { youtubeId } = this.props
    return (
      <div className="mv_mov" data-youtubeid={this.props.youtubeId}>
        <iframe id="player" allowFullScreen="1"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                title="YouTube video player"
                src={`https://www.youtube.com/embed/${youtubeId}?controls=0&amp;disablekb=0&amp;fs=0&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;rel=0&amp;showinfo=0&amp;enablejsapi=1&amp;widgetid=1&amp;autoplay=1&amp;mute=1&amp;loop=1&amp;playlist=${youtubeId}`}
                style={{ width: '100vh', height: '120vh', marginTop: '-22px', marginLeft: '0px' }}
                frameBorder="0"> </iframe>
      </div>
    )
  }
}

export default MvMovieComponent
