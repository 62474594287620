import React, { Component } from 'react'

export default class UndefinedField extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { item } = this.props
    return(
      <dl>
        <dt>{item.input_name}</dt>
        <dd>
          <p>{"{content}"}</p>
        </dd>
      </dl>
    )
  }
}
