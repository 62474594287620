require('datatables.net-bs4');

$( document ).ready(function() {
  if($('[id^=menus_datatable_wrapper]').length == 0 && $('#menus_datatable').length > 0) {
    table = $('#menus_datatable').DataTable({
      dom: "<'row'<'col-6'l><'#menu_btn_add.col-6 text-right'>>" +
        "<'row'<'#menu_btn_batch_delete.col-sm-12'>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'float-right mb-3'p>",
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        emptyTable: "メニューは登録されておりません",
        zeroRecords: "メニューは登録されておりません",
        lengthMenu: "_MENU_ を表示する",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        }
      },
      order: [],
      ordering: false,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
          width: "10%"
        },
        {
          targets: 1,
          width: "30%"
        },
        {
          targets: 2,
          width: "20%"
        },
        {
          targets: 3,
          width: "20%"
        },
        {
          targets: 4,
          orderable: false,
          width: "20%"
        },
        {
          targets: 5,
          visible: false,
        },
      ],
      drawCallback: function(settings) {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);

        reload_checkbox_all_state();
      },
      stateSave: true
    });

    $('#menus_datatable_length').before('<label class="mr-2">' +
      '<select id="menu_select_display_flag" class="custom-select custom-select-sm form-control form-control-sm">' +
        '<option value="">-- 表示・非表示 --</option>' +
        '<option value="1">表示</option>' +
        '<option value="0">非表示</option>' +
      '</select>' +
    '</label>');
    selectMenuCategoryElement($('#menus_datatable').data("menu-categories"));
    if (!/iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent)) {
      $('#select_category').select2({
        width: 'element',
        minimumResultsForSearch: -1
      });
    }
    $('#menu_btn_add').append(`<a href='${$('#menus_datatable').data("add-new-link")}' class='btn btn-info'>新規追加</a>`);

    setTimeout(function() {
      search = table.state().columns[5].search.search
      $('#menu_select_display_flag').val("");
      switch(search) {
        case "true": $('#menu_select_display_flag').val("1"); break;
        case "false": $('#menu_select_display_flag').val("0"); break;
      }
    }, 450);
    $('#menu_select_display_flag').on( 'change', function () {
      if (this.value == "") {
        valueSearch = "";
      } else if (this.value == 1) {
        valueSearch = "true";
      } else {
        valueSearch = "false";
      }

      table.columns(5).search(valueSearch).draw();
    } );

    $('#select_category').on('change', function () {
      const val = $.fn.dataTable.util.escapeRegex(
        $(this).val()
      );
      table.column(2).search(val ? '^'+val+'$' : '', true, false).draw();
    });

    $('#btn-batch-delete-menus').on('click', function(event) {
      if ($('.checkbox_menu:checked').length == 0) {
        event.preventDefault();
        alert('チェックボックスが選択されていません');
        return false;
      }
    });

    $('#menus_check_all').on('click', function() {
      if (this.checked) {
        $('.checkbox_menu').not(':disabled').prop('checked', true);
      } else {
        $('.checkbox_menu').not(':disabled').prop('checked', false);
      }
    });

    $('.checkbox_menu').on('click', function() {
      number_of_checkbox_unchecked = $('.checkbox_menu:not(:checked)').length;
      if (number_of_checkbox_unchecked > 0) {
        $('#menus_check_all').prop('checked', false);
      } else {
        $('#menus_check_all').prop('checked', true);
      }
    });
  }
});

function selectMenuCategoryElement(menuCategories) {
  var $labelElm = $("<label>", {class: "mr-2"});
  var $selectElm = $("<select>", {class: "custom-select custom-select-sm form-control form-control-sm", id: "select_category"});
  var $option = $("<option>", {text: "カテゴリー一覧", value: ""});
  $selectElm.append($option);
  $.each(menuCategories, function( index, value ) {
    var $option = $("<option>", {text: value["name"], value: value["id"]});
    $selectElm.append($option);
  });

  $labelElm.append($selectElm);
  $("#menu_select_display_flag").parent().before($labelElm);
}

function reload_checkbox_all_state() {
  number_of_checkbox_enable = $('.checkbox_menu').not(':disabled').length;
  number_of_checkbox_unchecked = $('.checkbox_menu:not(:checked)').not(':disabled').length;

  if (number_of_checkbox_enable == 0 || number_of_checkbox_unchecked > 0) {
    $('#menus_check_all').prop('checked', false);
  } else {
    $('#menus_check_all').prop('checked', true);
  }
}
