import React, {Component} from 'react'
import ConfigablePart from "../ConfigablePart";
import PropTypes from 'prop-types';
import {IMAGES, FRONT_END_HOST} from "../../../../../untils/constants";
import {isOpenImageModal, isOpenImageSettingModal} from "../../../../../Lib/redux/actions";
import {connect} from "react-redux";
import ContentEditable from "react-contenteditable";
import ContentComponent from "../ContentComponent";
import { parallaxScroll } from '../../../../../functions';

class LoadableConversationPart extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    idString: PropTypes.string,
  };

  static defaultProps = {
    isEdit: true,
    idString: null,
  };

  constructor(props) {
    super(props)
    this.showOperatorImageModal = this.showOperatorImageModal.bind(this);
    this.showBlockImageSettingModal = this.showBlockImageSettingModal.bind(this)
    this.contentEditable1 = React.createRef()
    this.contentEditable2 = React.createRef()
    this.renderHeadlinePattern = this.renderHeadlinePattern.bind(this)
    this.renderSubHeadlinePattern = this.renderSubHeadlinePattern.bind(this)
    this.handleDisplayAlign = this.handleDisplayAlign.bind(this)
    this.state = {
      isHeadingPatternEdit: false,
      isSubHeadingPatternEdit: false
    }
  }

  componentDidMount() {
    parallaxScroll();
  }

  componentDidUpdate() {
    parallaxScroll();
  }

  showOperatorImageModal() {
    this.props.handleShowImageModal(
      {isOpenImageModal: !this.props.isOpenImageModal, index: this.props.index}
    )
  };

  showBlockImageSettingModal() {
    this.props.handleShowSetting(
      { isOpenImageSettingModal: !this.props.isOpenImageSettingModal, index: this.props.index }
    )
  }

  iconsEditImage() {
    return(
      <>
        <span className="admin_edit_img 372659" title="画像を変更" onClick={this.showOperatorImageModal}>
          <i className="material-icons">collections</i>
        </span>
      </>
    )
  }

  iconsEditImage() {
    return(
      <>
        <span className="admin_edit_link 372659" title="リンク設定" onClick={this.showBlockImageSettingModal}>
          <i className="material-icons">link</i>
        </span>
        <span className="admin_edit_img 372659" title="画像を変更" onClick={this.showOperatorImageModal}>
          <i className="material-icons">collections</i>
        </span>
      </>
    )
  }

  imageUrl() {
    const {item} = this.props
    return item.block_image_url.length == 0 ? IMAGES.no_image : item.block_image_url
  }

  handleDisplayAlign(flag) {
    if (flag === "conversation_txt_1") {
      this.setState({ isHeadingPatternEdit: !this.state.isHeadingPatternEdit })
    } else if (flag === "conversation_txt_2") {
      this.setState({ isSubHeadingPatternEdit: !this.state.isSubHeadingPatternEdit })
    }
  }

  renderContentEditableMenu(type, index) {
    return(
        <ul className="contenteditable_menu active" style={{top: "-40px", left: "0"}}>
          <li key={1} className="contenteditable_menu_justifyleft"
              title="左揃え" onMouseDown={(event) => this.props.handleChangeAlign(index, "align-left", type, event)} />
          <li key={2} className="contenteditable_menu_justifycenter"
              title="中央揃え" onMouseDown={(event) => this.props.handleChangeAlign(index, "align-center", type, event)} />
          <li key={3} className="contenteditable_menu_justifyright"
              title="右揃え" onMouseDown={(event) => this.props.handleChangeAlign(index, "align-right", type, event)} />
        </ul>
    )
  }

  renderHeadlinePattern(isEdit, content, index) {
    let editable = isEdit ? "contenteditable" : "";
    editable += this.state.isHeadingPatternEdit ? " active" : ""

    if (isEdit) {
      return (
          <h3 className={`h ${editable}`} onFocus={() => this.handleDisplayAlign("conversation_txt_1")}
              onBlur={() => this.handleDisplayAlign("conversation_txt_1")}>
            <ContentEditable
                innerRef={this.contentEditable1}
                html={content.conversation_txt_1}
                disabled={false}
                onKeyUp={(event) => this.props.handleChangeHeadline(index, event, "conversation_txt_1")}
                tagName='span'
            />
            {this.state.isHeadingPatternEdit && this.renderContentEditableMenu("headingPatternClass", index)}
          </h3>
      )
    }

    return (<h3 className={`h ${editable}`} dangerouslySetInnerHTML={{__html: content.conversation_txt_1}}></h3>)
  }

  renderSubHeadlinePattern(isEdit, content, index) {
    let editable = isEdit ? "contenteditable" : "";
    editable += this.state.isSubHeadingPatternEdit ? " active" : ""

    if (isEdit) {
      return (
          <p className={editable} onFocus={() => this.handleDisplayAlign("conversation_txt_2")}
             onBlur={() => this.handleDisplayAlign("conversation_txt_2")}>
            <ContentEditable
                innerRef={this.contentEditable2}
                html={content.conversation_txt_2} // innerHTML of the editable div
                disabled={false}       // use true to disable editing
                onKeyUp={(event) => this.props.handleChangeHeadline(index, event, "conversation_txt_2")} // handle innerHTML change
                tagName='span' // Use a custom HTML tag (uses a div by default)
            />
            {this.state.isSubHeadingPatternEdit && this.renderContentEditableMenu("headingPatternClass", index)}
          </p>
      )
    }

    return (<p className={editable} dangerouslySetInnerHTML={{__html: content.conversation_txt_2}}></p>)
  }

  getUrl() {
    const content = this.props.content
    switch (parseInt(content.block_image_link)) {
      case 1:
        return '#!'
      case 2:
        if (this.props.idString === null) { return '#!' }
        const homeUrl = `${FRONT_END_HOST}/${this.props.idString}`
        if (content.block_image_inner_link === '/') { return homeUrl }
        return `${homeUrl}${content.block_image_inner_link}.html`
      case 3:
        return content.block_image_outer_link
      default:
        break;
    }
  }

  render() {
    const {sectionStyle, content, isEdit, index} = this.props;

    return (
      <section key={this.props.kindOfDesign} id={this.props.kindOfDesign}
               className={`contents_box01 align-left block_fukidashi_${this.props.kindOfDesign}  delay3  blockarea ${content.width_class} blockarea ${content.top_padding_class} ${content.bottom_padding_class} ${content.parallax != 1 ? "parallax" : ""} edit_outer delay4Active editing block_fukidashi delay3Active`}
               style={sectionStyle}>
        {this.props.isEdit && <ConfigablePart {...this.props} />}
        {
          content.parallax != 1 &&
          <div className="parallax-wrap">
            <div className="parallax_img" style={{backgroundImage: `url(${content.background_image_url})`, height: '100%', transform: 'translateY(-50%)'}} />
          </div>
        }
        <div className="content_wrapper">
          <div className="wrapper_item">
            <div className="inner_item_img">
              {this.props.isEdit && this.iconsEditImage()}
              {
                isEdit &&
                <img src={this.imageUrl()} />
              }
              {
                !isEdit &&
                <a href={this.getUrl()} target={(content.block_image_target && parseInt(content.block_image_link) !== 1) ? "_blank" : "_self"}>
                  <picture>
                    <img src={this.imageUrl()} alt="" />
                  </picture>
                </a>
              }
            </div>

            <div className="inner_item_txt">
              <div className={`heading block_header_2 ${content.headingPatternClass}`}>
                {this.renderHeadlinePattern(isEdit, content, index)}
                {this.renderSubHeadlinePattern(isEdit, content, index)}
              </div>

              <ContentComponent text={this.props.content.text} contentClass={this.props.content.contentClass} index={this.props.index}
                                isEdit={this.props.isEdit}
                                handleChangeText={this.props.handleChangeText}
                                handleChangeAlign={this.props.handleChangeAlign} />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);

  newState.isOpenImageModal = storeState.isOpenImageModal
  newState.isOpenImageSettingModal = storeState.isOpenImageSettingModal

  return newState;
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleShowImageModal: (data) => {dispatch(isOpenImageModal(data))},
    handleShowSetting: (data) => {dispatch(isOpenImageSettingModal(data))}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadableConversationPart)
