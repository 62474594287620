import * as React from "react";
import PropTypes from 'prop-types';
import FooterContent from './FooterContent';
import FooterMenu from './FooterMenu';
import {connect} from "react-redux";
import {Link} from "react-router-dom";

class FooterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.renderFooterLink = this.renderFooterLink.bind(this)
  }

  static propTypes = {
    footerSetting: PropTypes.object,
    isDisplay: PropTypes.bool
  };

  static defaultProps = {
    footerSetting: {},
    isDisplay: false
  };

  renderFooterLink() {
    const {footerSetting} = this.props
    let linkTarget1 = footerSetting.footer_link_target === 0 ? "" : '_blank';
    let linkTarget2 = footerSetting.footer_link_target2 === 0 ? "" : '_blank';

    return (
        <React.Fragment>
          {
            (footerSetting.footer_link_type === 0 && footerSetting.footer_text !== '') &&
            <Link to='#'>
              <span dangerouslySetInnerHTML={{__html: footerSetting.footer_text}}></span>
            </Link>
          }
          {
            (footerSetting.footer_link_type === 1 && footerSetting.footer_text !== '') &&
            <Link to='#'>
              <span dangerouslySetInnerHTML={{__html: footerSetting.footer_text}}></span>
            </Link>
          }
          {
            (footerSetting.footer_link_type2 === 0 && footerSetting.footer_text2 !== '') &&
            <Link to='#'>
              <span dangerouslySetInnerHTML={{__html: footerSetting.footer_text2}}></span>
            </Link>
          }
          {
            (footerSetting.footer_link_type2 === 1 && footerSetting.footer_text2 !== '') &&
            <Link to='#'>
              <span dangerouslySetInnerHTML={{__html: footerSetting.footer_text2}}></span>
            </Link>
          }
        </React.Fragment>
    )
  }

  render () {
    const { generalSetting, settingsAdvance, isDisplay, footerSetting } = this.props;
    return (
      isDisplay
        ? (<div dangerouslySetInnerHTML={{__html: settingsAdvance.footer_content}}></div>)
        : (
        <React.Fragment>
          <footer id={`f-pattern${footerSetting.css_footer}`} className="width_fixed footer_responsive">
            <FooterContent {...this.props} renderFooterLink={this.renderFooterLink()} />
            <FooterMenu {...this.props} renderFooterLink={this.renderFooterLink()}/>
            {generalSetting != undefined &&
              <div id="cp" dangerouslySetInnerHTML={{__html: `© ${new Date().getFullYear()} ${generalSetting.site_title} ALL RIGHTS RESERVED.`}}></div>
            }
          </footer>
          <div dangerouslySetInnerHTML={{__html: settingsAdvance.footer_html}}></div>
        </React.Fragment>)
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.generalSetting = storeState.generalSetting;
  newState.settingsAdvance = storeState.settingsAdvance;
  newState.footerSetting = storeState.footerSetting;
  if (storeState.settingsAdvance.footer_content != undefined) {
    newState.isDisplay = storeState.settingsAdvance.footer_content.length > 0;
  }
  return newState;
}

export default connect(mapStateToProps) (FooterComponent)
