import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhoneAlt, faGlobe, faCircle} from "@fortawesome/free-solid-svg-icons";
import {faFacebook, faTwitter, faInstagram, faBloggerB} from "@fortawesome/free-brands-svg-icons";
import {IMAGES} from '../../untils/constants'
import {library} from "@fortawesome/fontawesome-svg-core";
import {resizeHeader} from '../../functions';

library.add(faCircle, faPhoneAlt, faGlobe);


class HeaderContent extends Component {
    static propTypes = {
        headerSetting: PropTypes.object,
    };

    static defaultProps = {
        headerSetting: {},
    };

    constructor(props) {
        super(props);
        this.renderHeaderPhoneNumber = this.renderHeaderPhoneNumber.bind(this);
        this.renderHeaderLink = this.renderHeaderLink.bind(this);
        this.renderSnsButton = this.renderSnsButton.bind(this);
    }

    componentDidMount() {
        resizeHeader()
    }

    renderHeaderPhoneNumber() {
        const {headerSetting} = this.props;
        return (
            <React.Fragment>
                {
                    (headerSetting.h_tel_flag && headerSetting.common_tel !== '') &&
                    <div className="tel" x-ms-format-detection="none">
                        <Link to='#'>
                            <svg id="icon-tel" viewBox="0 0 32 32">
                                <title>tel</title>
                                <path
                                    d="M31.985 25.248c0.068 0.52-0.090 0.971-0.474 1.355l-4.506 4.471c-0.203 0.226-0.469 0.418-0.796 0.576s-0.649 0.26-0.965 0.305c-0.023 0-0.090 0.006-0.203 0.017s-0.26 0.017-0.44 0.017c-0.429 0-1.124-0.073-2.084-0.22s-2.134-0.508-3.523-1.084c-1.389-0.576-2.964-1.44-4.726-2.592s-3.636-2.733-5.623-4.743c-1.581-1.558-2.891-3.049-3.93-4.472s-1.874-2.738-2.507-3.947c-0.632-1.208-1.107-2.304-1.423-3.286s-0.531-1.829-0.644-2.541-0.158-1.27-0.136-1.677c0.023-0.407 0.034-0.632 0.034-0.678 0.045-0.316 0.147-0.638 0.305-0.965s0.35-0.593 0.576-0.796l4.506-4.506c0.316-0.316 0.678-0.474 1.084-0.474 0.294 0 0.553 0.085 0.779 0.254s0.418 0.378 0.576 0.627l3.625 6.877c0.203 0.361 0.26 0.757 0.169 1.186s-0.282 0.79-0.576 1.084l-1.66 1.66c-0.045 0.045-0.085 0.119-0.119 0.22s-0.051 0.186-0.051 0.254c0.090 0.474 0.294 1.016 0.61 1.626 0.271 0.542 0.689 1.203 1.253 1.982s1.366 1.677 2.405 2.693c1.016 1.039 1.92 1.846 2.71 2.422s1.451 0.999 1.982 1.27 0.937 0.435 1.22 0.491l0.423 0.085c0.045 0 0.119-0.017 0.22-0.051s0.175-0.073 0.22-0.119l1.931-1.965c0.407-0.361 0.881-0.542 1.423-0.542 0.384 0 0.689 0.068 0.915 0.203h0.034l6.538 3.862c0.474 0.294 0.757 0.666 0.847 1.118z"></path>
                            </svg>
                            {headerSetting.common_tel}
                            <i className="teli" dangerouslySetInnerHTML={{__html: headerSetting.common_teli}}></i>
                        </Link>
                    </div>
                }
                {
                    (headerSetting.h_tel2_flag && headerSetting.common_tel2 !== '') &&
                    <div className="tel" x-ms-format-detection="none">
                        <Link to='#'>
                            <svg id="icon-tel" viewBox="0 0 32 32">
                                <title>tel</title>
                                <path
                                    d="M31.985 25.248c0.068 0.52-0.090 0.971-0.474 1.355l-4.506 4.471c-0.203 0.226-0.469 0.418-0.796 0.576s-0.649 0.26-0.965 0.305c-0.023 0-0.090 0.006-0.203 0.017s-0.26 0.017-0.44 0.017c-0.429 0-1.124-0.073-2.084-0.22s-2.134-0.508-3.523-1.084c-1.389-0.576-2.964-1.44-4.726-2.592s-3.636-2.733-5.623-4.743c-1.581-1.558-2.891-3.049-3.93-4.472s-1.874-2.738-2.507-3.947c-0.632-1.208-1.107-2.304-1.423-3.286s-0.531-1.829-0.644-2.541-0.158-1.27-0.136-1.677c0.023-0.407 0.034-0.632 0.034-0.678 0.045-0.316 0.147-0.638 0.305-0.965s0.35-0.593 0.576-0.796l4.506-4.506c0.316-0.316 0.678-0.474 1.084-0.474 0.294 0 0.553 0.085 0.779 0.254s0.418 0.378 0.576 0.627l3.625 6.877c0.203 0.361 0.26 0.757 0.169 1.186s-0.282 0.79-0.576 1.084l-1.66 1.66c-0.045 0.045-0.085 0.119-0.119 0.22s-0.051 0.186-0.051 0.254c0.090 0.474 0.294 1.016 0.61 1.626 0.271 0.542 0.689 1.203 1.253 1.982s1.366 1.677 2.405 2.693c1.016 1.039 1.92 1.846 2.71 2.422s1.451 0.999 1.982 1.27 0.937 0.435 1.22 0.491l0.423 0.085c0.045 0 0.119-0.017 0.22-0.051s0.175-0.073 0.22-0.119l1.931-1.965c0.407-0.361 0.881-0.542 1.423-0.542 0.384 0 0.689 0.068 0.915 0.203h0.034l6.538 3.862c0.474 0.294 0.757 0.666 0.847 1.118z"></path>
                            </svg>
                            {headerSetting.common_tel2}
                            <i className="teli" dangerouslySetInnerHTML={{__html: headerSetting.common_tel2i}}></i>
                        </Link>
                    </div>
                }
            </React.Fragment>
        )
    }

    renderHeaderLink() {
        const {headerSetting} = this.props;
        let linkTarget1 = headerSetting.header_link_target === 0 ? "" : '_blank';
        let linkTarget2 = headerSetting.header_link_target2 === 0 ? "" : '_blank';
        return (
            <React.Fragment>
                {
                    (headerSetting.header_link_type === 0 && headerSetting.header_text !== '') &&
                    <Link to='#' dangerouslySetInnerHTML={{__html: headerSetting.header_text}}/>
                }
                {
                    (headerSetting.header_link_type === 1 && headerSetting.header_text !== '') &&
                    <Link to='#' dangerouslySetInnerHTML={{__html: headerSetting.header_text}}/>
                }
                {
                    (headerSetting.header_link_type2 === 0 && headerSetting.header_text2 !== '') &&
                    <Link to='#' dangerouslySetInnerHTML={{__html: headerSetting.header_text2}}/>
                }
                {
                    (headerSetting.header_link_type2 === 1 && headerSetting.header_text2 !== '') &&
                    <Link to='#' dangerouslySetInnerHTML={{__html: headerSetting.header_text2}}/>
                }
            </React.Fragment>
        )
    }

    renderSnsButton() {
        const {headerSetting} = this.props
        return (
            <React.Fragment>
                {
                    headerSetting.ameblo_id !== '' &&
                    <li><a href={headerSetting.ameblo_id} title="Blog" target="_blank"><FontAwesomeIcon icon={faBloggerB}/></a>
                    </li>
                }
                {
                    headerSetting.facebook_account !== '' &&
                    <li><a href={headerSetting.facebook_account} title="Facebook" target="_blank"><FontAwesomeIcon
                        icon={faFacebook}/></a></li>
                }
                {
                    headerSetting.twitter_account !== '' &&
                    <li><a href={headerSetting.twitter_account} title="Twitter" target="_blank"><FontAwesomeIcon
                        icon={faTwitter}/></a></li>
                }
                {
                    headerSetting.instagram_account !== '' &&
                    <li><a href={headerSetting.instagram_account} title="Instagram" target="_blank"><FontAwesomeIcon
                        icon={faInstagram}/></a></li>
                }
            </React.Fragment>
        )
    }

    renderLogo() {
        if (this.props.h_d_logo_flag === false) {
            return (<div/>)
        }

        let logo_url = IMAGES.logo_sample
        if (
            this.props.headerSetting !== undefined &&
            this.props.headerSetting.logo_image !== null &&
            this.props.headerSetting.logo_image !== ''
        ) {
            logo_url = this.props.headerSetting.logo_image
        }

        return (
            <a href="/">
                <img src={logo_url} alt="【デモ】Touch&Links" className="header-logo" onLoad={() => resizeHeader()}/>
            </a>
        )
    }

    render() {
        const {headerSetting, settings} = this.props

        if (this.props.h_d_flag === false) {
            return (<div/>)
        }

        let snsClassName = 'header_sns'
        if (headerSetting !== undefined && headerSetting.h_pc_sns_flag) {
            snsClassName = 'header_sns on'
        }

        return (
            <div className="wraper">
                <div className="inner">
                    {headerSetting.css1 != 4 && <h1 dangerouslySetInnerHTML={{__html: settings.h1_text}}/>}
                    <div className="logo">
                        {this.renderLogo()}
                    </div>
                    <div className="burger">
                        <ul>
                            {
                                headerSetting !== undefined && headerSetting.translate_flag &&
                                <li className="sp_translate">
                                    <a href="#"><FontAwesomeIcon icon={["fas", "globe"]}/></a>
                                </li>
                            }
                            <li className="sp_menu"><a href="#" className="no">
                                <div><span>Menu</span></div>
                            </a></li>
                        </ul>
                    </div>
                    <div className="header_contents">
                        <div className="inner">
                            <div className={snsClassName}>
                                <ul>
                                    {
                                        headerSetting !== undefined && headerSetting.h_pc_sns_flag &&
                                        this.renderSnsButton()
                                    }
                                </ul>
                            </div>
                            <div className="header_col1">
                                <div className="block-tel">{headerSetting !== undefined && this.renderHeaderPhoneNumber()}</div>
                                <div className="block-url">
                                    <div id="link_setting" className="link_setting">
                                        <a href='#'><span dangerouslySetInnerHTML={{__html: headerSetting.sitemap_link.trim() === "" ? 'サイトマップ' : headerSetting.sitemap_link}}/></a>
                                        {headerSetting.privacy_policy_name &&
                                        ((headerSetting.privacy_policy_link_type == 1 && headerSetting.privacy_policy_url) ||
                                            (headerSetting.privacy_policy_link_type == 0 && headerSetting.privacy_policy_page_id)) &&
                                        <a href='#'><span dangerouslySetInnerHTML={{__html: headerSetting.privacy_policy_name}}/></a>
                                        }
                                        {headerSetting.corporate_site_name &&
                                        ((headerSetting.corporate_link_type == 1 && headerSetting.corporate_site_url) ||
                                            (headerSetting.corporate_link_type == 0 && headerSetting.corporate_site_page_id)) &&
                                        <a href='#'><span dangerouslySetInnerHTML={{__html: headerSetting.corporate_site_name}}/></a>
                                        }
                                    </div>
                                    <div className="btn">
                                        {headerSetting !== undefined && headerSetting.h_button_flag && this.renderHeaderLink()}
                                        {
                                            headerSetting !== undefined && headerSetting.translate_flag &&
                                            <div className="translate">
                                                <a href="#"> <i className="fas fa-globe"/></a>
                                                <script dangerouslySetInnerHTML={{
                                                    __html: `
                        function googleTranslateElementInit() {new google.translate.TranslateElement({pageLanguage: 'ja', layout: google.translate.TranslateElement.FloatPosition.TOP_LEFT}, 'google_translate_element');}
                      `
                                                }}/>
                                                <script type="text/javascript"
                                                        src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script>
                                                <div id="google_translate_element"></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="header_col2">
                                <div className="add">
                                    {
                                        headerSetting !== undefined && headerSetting.h_address_flag &&
                                        <dt className={'header-address'} dangerouslySetInnerHTML={{__html: headerSetting.common_address}}></dt>
                                    }
                                    {
                                        headerSetting !== undefined && headerSetting.h_hour_flag &&
                                        <dt className={'hour-pattern'} dangerouslySetInnerHTML={{__html: headerSetting.common_hour}}></dt>
                                    }
                                    {
                                        headerSetting !== undefined && headerSetting.h_hour_flag &&
                                        <dt className={'hour-pattern5'} dangerouslySetInnerHTML={{__html: `（受付／${headerSetting.common_hour}）`}}></dt>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (storeState, ownProps) => {
    let newState = Object.assign({}, ownProps);

    newState.headerSetting = storeState.headerSetting;
    newState.h_d_flag = storeState.settings.h_d_flag;
    newState.h_d_logo_flag = storeState.settings.h_d_logo_flag;
    newState.settings = storeState.settings

    return newState;
}

export default connect(mapStateToProps)(HeaderContent);
