import React, { Component } from 'react'
import HeadlineComponent from '../HeadlineComponent';
import ContentComponent from '../ContentComponent';

export class TextComponent extends Component {
  constructor(props) {
    super(props);
    this.contentEditable = React.createRef()
    this.renderText = this.renderText.bind(this);
  }

  renderText(isEdit, content) {
    return(
      <ContentComponent content={content} index={this.props.index}
                        text={content.text}
                        isEdit={isEdit}
                        handleChangeText={this.props.handleChangeText}
                        handleChangeAlign={this.props.handleChangeAlign} />
    )
  }

  render() {
    const { isEdit, content } = this.props
    return (
      <React.Fragment>
        <HeadlineComponent text={content.text} contentClass={content.contentClass} index={this.props.index}
                          isEdit={isEdit} blockType={this.props.blockType}
                          handleChangeHeadline={this.props.handleChangeHeadline}
                          handleChangeAlign={this.props.handleChangeAlign}
                          content={content}/>
        <div className="wrapper_item">
          <div className="inner_item_txt">
            { this.renderText(isEdit, content) }
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default TextComponent
