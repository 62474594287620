import React, { Component } from 'react';
import { connect } from 'react-redux';
import SideNavigationTop from './SideNavigationTop';
import SideNavigationBottom from './SideNavigationBottom';
import SideNavigationNav from './SideNavigationNav';

export class SideNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { isDisplayContent } = this.props;
    return (
      <div id="sidebar">
        <SideNavigationTop />
        { isDisplayContent && <SideNavigationNav /> }
        <SideNavigationBottom />
      </div>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.isDisplayContent = storeState.sideNavigationPages.length > 0;

  return newState;
}

export default connect(mapStateToProps)(SideNavigation)
