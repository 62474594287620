require('datatables.net-bs4');

$(document).ready(function() {
  if($('[id^=favorite_categories_datatable_wrapper]').length == 0 && $('#favorite_categories_datatable').length > 0) {
    table = $('#favorite_categories_datatable').DataTable({
      dom: "<'row'<'col-6'l><'#favorite_category_btn_add.col-6 text-right'>>" +
        "<'row'<'#favorite_category_btn_batch_delete.col-sm-12.position-relative'>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'float-right mb-3'p>",
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        lengthMenu: "_MENU_ を表示する",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ'
        },
        emptyTable: "お気に入りカテゴリーは登録されておりません",
        zeroRecords: "お気に入りカテゴリーは登録されておりません"
      },
      order: [],
      columnDefs: [
        {
          targets: 0,
          orderable: false
        },
        {
          targets: 2,
          orderable: false
        }
      ],
      drawCallback: function(settings) {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);

        String(localStorage.getItem('checked_favorite_categories')).split(',').forEach(function(value) {
          $('.checkbox_favorite_category[value=' + value + ']').prop('checked', true);
        });

        if($('.checkbox_favorite_category:checkbox:not(:checked)').length == 0) {
          $('#favorite_category_checkbox_check_all').prop('checked', false);
        }
        else{
          var number_of_checkbox_unchecked = $('.checkbox_favorite_category:checkbox:not(:checked)').not(':disabled').length;
          var number_of_valid_checkbox = $('.checkbox_favorite_category:checkbox').not(':disabled').length;
          if (number_of_checkbox_unchecked > 0 || number_of_valid_checkbox == 0) {
            $('#favorite_category_checkbox_check_all').prop('checked', false);
          } else {
            $('#favorite_category_checkbox_check_all').prop('checked', true);
          }
        }
      },
      stateSave: true
    });
    $('#favorite_category_btn_add').append(`<a href='${$('#favorite_categories_datatable').data("add-new-link")}' class='btn btn-info'>新規追加</a>`);
    $('#favorite_category_btn_batch_delete').append("<input type='submit' id='favorite_category_batch_delete_favorite_category_btn' value='一括削除' class='btn btn-info my-3' data-confirm='削除後のデータは復旧できません。よろしいですか？' data-disable-with='一括削除' />");
    $('#favorite_category_btn_batch_delete').append("<p class='text-danger text-right position-absolute d-inline col-7 bottom-0px right-0px'>※データが存在するカテゴリーは削除できません</p>");

    $('#favorite_category_checkbox_check_all').on('click', function() {
      if (this.checked) {
        $('.checkbox_favorite_category').not(':disabled').prop('checked', true);
      } else {
        $('.checkbox_favorite_category').not(':disabled').prop('checked', false);
      }
      $('.checkbox_favorite_category').first().trigger('change');
    });

    $('#select_category').on('click', function(){
      table.columns(5).search(this.value).draw();
    })

    $('#favorite_categories_datatable').on('click', '.checkbox_favorite_category', function() {
      number_of_checkbox_unchecked = $('.checkbox_favorite_category:checkbox:not(:checked)').not(':disabled').length;
      if (number_of_checkbox_unchecked > 0) {
        $('#favorite_category_checkbox_check_all').prop('checked', false);
      } else {
        $('#favorite_category_checkbox_check_all').prop('checked', true);
      }
    });

    $('#favorite_category_batch_delete_favorite_category_btn').on('click', function(event) {
      if ($('.checkbox_favorite_category:checked').not(':disabled').length == 0) {
        event.preventDefault();
        alert('チェックボックスが選択されていません');
        return false;
      } else {
        let deleteItem = []
        $('.checkbox_favorite_category:checked').each(function() {
          deleteItem.push($(this).val());
        });
        $('#favorite_categories_delete').val(deleteItem);
      }
    });

    $('.checkbox_favorite_category').on('change', function() {
      var checked_favorite_categories = String(localStorage.getItem('checked_favorite_categories')).split(',');

      $('.checkbox_favorite_category').each(function() {
        var index = checked_favorite_categories.indexOf($(this).val());
        if (index !== -1) checked_favorite_categories.splice(index, 1);
      });

      $('.checkbox_favorite_category:checked').each(function() {
        checked_favorite_categories.push($(this).val());
      });

      localStorage.setItem('checked_favorite_categories', checked_favorite_categories.join(','));
    })
  } else {
    localStorage.removeItem('checked_favorite_categories')
  }
});
