require('datatables.net-bs4');

$( document ).ready(function() {
  if($('[id^=blogs_datatable_wrapper]').length == 0 && $('#blogs_datatable').length > 0) {
    table = $('#blogs_datatable').DataTable({
      dom: "<'row'<'col-6'l><'#blog_btn_add.col-6 text-right'>>" +
        "<'row'<'#blog_btn_batch_delete.col-sm-12'>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'float-right mb-3'p>",
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        emptyTable: "ブログは登録されておりません",
        zeroRecords: "ブログは登録されておりません",
        lengthMenu: "_MENU_ を表示する  ",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        }
      },
      order: [],
      columnDefs: [
        {
          targets: 0,
          orderable: false,
          width: "10%"
        },
        {
          targets: 1,
          width: "30%"
        },
        {
          targets: 2,
          width: "30%"
        },
        {
          targets: 3,
          width: "10%"
        },
        {
          targets: 4,
          width: "10%",
        },
        {
          targets: 5,
          width: "10%",
          orderable: false
        },
        {
          targets: 6,
          visible: false
        }
      ],
      drawCallback: function() {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);

        String(localStorage.getItem('checked_blogs')).split(',').forEach(function(value) {
          $('.checkbox_blog[value=' + value + ']').prop('checked', true);
        });

        if($('.checkbox_blog:checkbox:not(:checked)').length == 0) {
          $('#blog_checkbox_check_all').prop('checked', false);
        }
        else{
          var number_of_checkbox_unchecked = $('.checkbox_blog:checkbox:not(:checked)').not(':disabled').length;
          if (number_of_checkbox_unchecked > 0) {
            $('#blog_checkbox_check_all').prop('checked', false);
          } else {
            $('#blog_checkbox_check_all').prop('checked', true);
          }
        }

        if($('.release_time').length > 0) {
          $('.release_time').each(function(index) {
            let dateString = $(this).html().replaceAll('-', '/');
            let date = new Date(dateString);
            date = date.toLocaleString('ja-JP', { year: 'numeric', month: "numeric",
                                                  day: "numeric", hour: "numeric",
                                                  minute: "numeric" })
            $(this).html(date)
          })
        }
      },
      stateSave: true
    });

    $("#blogs_datatable_length").after(`<label id="filter_keyword_label"><input id="filter_keyword" style="height: calc(1.5em + 0.5rem - 1px);" type="text" name="blog_keyword" class="form-control" placeholder="キーワード" value=""></label>`);

    selectBlogCategoryElement($('#blogs_datatable').data("blog-categories"));

    if (!/iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent)) {
      $('#select_category').select2({
        width: 'element',
        minimumResultsForSearch: -1
      });
    }

    $('#blog_btn_add').append(`
      <span class="text-right" style="display:inline-flex;padding-right:10px;"><i class="fas fa-wrench" style="padding:4px"></i>
      <a class="text-left" style="word-break:keep-all;" href="${$('#blogs_datatable').data("admin-words-path")}">おすすめキーワード</a></span>
      <span class="text-right" style="display:inline-flex;padding-right:10px;"><i class="fas fa-calendar-alt" style="padding:4px"></i>
      <a href="${$('#blogs_datatable').data("admin-what-day-index-path")}">今日は何の日？</a></span>
      <span class="text-right" style="display:inline-flex;padding-right:10px;"><i class="fas fa-list-alt" style="padding:4px"></i>
      <a href="${$('#blogs_datatable').data("blog-admin-newtemplates-path")}">テンプレート</a></span>
    `);
    $('#blog_btn_add').append(`<a href='${$('#blogs_datatable').data("add-new-link")}' class='btn btn-info ml-2'>新規追加</a>`);

    $('#blog_btn_batch_delete').append("<input type='submit' id='blog_batch_delete_blog_btn' value='一括削除' class='btn btn-info my-3' data-confirm='削除後のデータは復旧できません。よろしいですか？' data-disable-with='一括削除' />");

    $('#blog_checkbox_check_all').on('click', function() {
      if (this.checked) {
        $('.checkbox_blog').not(':disabled').prop('checked', true);
      } else {
        $('.checkbox_blog').not(':disabled').prop('checked', false);
      }
      $('.checkbox_blog').first().trigger('change');
    });

    $('#select_category').on('change', function () {
      if ($(this).val() !== '') {
        table.column(6).search("\\b" + parseInt($(this).val()) + "\\b", true, false).draw();
      } else {
        table.column(6).search('').draw();
      }
    });

    $('#filter_keyword').keydown(function (e) {
      if (e.keyCode == 13) {
        return false;
      }
    });

    $('#filter_keyword').on('keyup', function () {
      const val = $.fn.dataTable.util.escapeRegex(
        $(this).val()
      );
      table.column(1).search(val, true, false).draw();
    });

    $('#blogs_datatable').on('click', '.checkbox_blog', function() {
      number_of_checkbox_unchecked = $('.checkbox_blog:checkbox:not(:checked)').not(':disabled').length;
      if (number_of_checkbox_unchecked > 0) {
        $('#blog_checkbox_check_all').prop('checked', false);
      } else {
        $('#blog_checkbox_check_all').prop('checked', true);
      }
    });

    $('#blog_batch_delete_blog_btn').on('click', function(event) {
      if ($('.checkbox_blog:checked').not(':disabled').length == 0) {
        event.preventDefault();
        alert('チェックボックスが選択されていません');
        return false;
      } else {
        let deleteItem = []
        $('.checkbox_blog:checked').each(function() {
          deleteItem.push($(this).val());
        });
        $('#blogs_delete').val(deleteItem);
      }
    });

    $('.checkbox_blog').on('change', function() {
      var checked_blogs = String(localStorage.getItem('checked_blogs')).split(',');

      $('.checkbox_blog').each(function() {
        var index = checked_blogs.indexOf($(this).val());
        if (index !== -1) checked_blogs.splice(index, 1);
      });

      $('.checkbox_blog:checked').each(function() {
        checked_blogs.push($(this).val());
      });

      localStorage.setItem('checked_blogs', checked_blogs.join(','));
    })
  } else {
    localStorage.removeItem('checked_blogs')
  }
});

function selectBlogCategoryElement(blogCategories) {
  var $labelElm = $("<label>", {class: "mr-2"});
  var $selectElm = $("<select>", {class: "custom-select custom-select-sm form-control form-control-sm", id: "select_category"});
  var $option = $("<option>", {text: "カテゴリー一覧", value: ""});
  $selectElm.append($option);
  $.each(blogCategories, function( index, value ) {
    var $option = $("<option>", {text: value["name"], value: value["id"]});
    $selectElm.append($option);
  });
  $labelElm.append($selectElm);
  $("#blogs_datatable_length").before($labelElm);
}
