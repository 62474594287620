import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from "../../components/Layouts/Header";
import HeaderMenu from "../../components/Layouts/HeaderMenu";
import EditParallaxImage from "../../components/ViewEdit/EditParallaxImage";
import SectionNewPage from "../../components/ViewEdit/SectionNewPage";
import Footer from "../Layouts/Footer";
import OperableModal from "../../components/Parts/Modal/OperableModal";
import HomeSlider from "../../components/Parts/MvSlider/HomeSlider";
import { ToastContainer } from 'react-toastify';
import { addDataUi, addPageDataUi } from '../../Lib/redux/actions';
import ShareSnsModal from "../Parts/Modal/ShareSnsModal";
import { FRONT_END_HOST } from "../../untils/constants";

export class LayoutSetting extends Component {
  static propTypes = {
    isSubNavigationPage: PropTypes.bool
  };

  static defaultProps = {
    isSubNavigationPage: false
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpenEditParallax: false,
      isOpen: false,
      isOpenFavoriteModal: false,
      indexBlockData: null,
      templateId: null,
      isOpenShareSnsModal: false,
      endUserUrl: ''
    }
    this.handleShowModalEditParallax = this.handleShowModalEditParallax.bind(this)
    this.showSettings = this.showSettings.bind(this)
    this.handleShowModalConfig = this.handleShowModalConfig.bind(this)
    this.handleShowModalFavorite = this.handleShowModalFavorite.bind(this)
    this.handleCopyComponent = this.handleCopyComponent.bind(this)
    this.handleUseTemplate = this.handleUseTemplate.bind(this)
    this.handleShowModalShareSns = this.handleShowModalShareSns.bind(this);
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
  }

  componentDidMount() {
    if(this.props.facebookFlag || this.props.twitterFlag) {
      this.setState({
        isOpenShareSnsModal: true
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.settings.id_string !== this.props.settings.id_string || prevProps.settings.slug_plus !== this.props.settings.slug_plus) {
      this.setState({
        endUserUrl: this.props.LpUrl
      })
    }
  }

  handleShowModalConfig() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  showSettings() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleShowModalEditParallax() {
    this.setState({ isOpenEditParallax: !this.state.isOpenEditParallax });
  }

  handleShowModalFavorite(indexBlockData) {
    this.setState({
      isOpenFavoriteModal: !this.state.isOpenFavoriteModal,
      indexBlockData: indexBlockData
    });
  }

  handleCopyComponent(indexBlockData, data) {
    data.indexBlockData = indexBlockData
    this.props.appendPageDataUi(data);
  }

  handleUseTemplate(template_id) {
    this.setState({templateId: template_id})
  }

  handleShowModalShareSns() {
    const url = `${this.props.LpUrl}?time=${new Date().valueOf()}`
    this.setState({
      isOpenShareSnsModal: !this.state.isOpenShareSnsModal,
      endUserUrl: url
    });
  }

  handleOpenDialog(url) {
    window.open(url, 'facebook share', 'width=600, height=800');
  }

  render() {
    const { mv_position, mv_original } = this.props
    let fixed = (mv_position == 2 && mv_original == 1)  ? 'fixedmv' : ''
    return (
      <React.Fragment>
        <main className={`layout-setting ${fixed}`}>
          <div id="primary" style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Header />
            {
              this.props.pageKind == 'home' ?
                (<HomeSlider isOpen={this.state.isOpen} handleShowModalConfig={this.handleShowModalConfig}
                             showSettings={this.showSettings} />) :
                (<EditParallaxImage isOpenEditParallax={this.state.isOpenEditParallax}
                                    handleShowModalEditParallax={this.handleShowModalEditParallax}
                                    pageKind={this.props.pageKind} />)
            }
            <SectionNewPage isOpenFavoriteModal={this.state.isOpenFavoriteModal}
                            handleShowModalFavorite={this.handleShowModalFavorite}
                            handleCopyComponent={this.handleCopyComponent}
                            indexBlockData={this.state.indexBlockData}
                            pageStatus={this.props.pageStatus}
                            templateId={this.state.templateId}
                            pageKind={this.props.pageKind}
                            settings={this.props.settings} />
            <Footer isSubNavigationPage={this.props.isSubNavigationPage} />
            <OperableModal isNewpageAdd={true} pageKind={this.props.pageKind} pageStatus={this.props.pageStatus}
                           isOpenEditParallax={this.state.isOpenEditParallax} isOpen = {this.state.isOpen}
                           isOpenFavoriteModal={this.state.isOpenFavoriteModal}
                           handleUseTemplate={this.handleUseTemplate}
                           templateId={this.state.templateId}
                           handleShowModalShareSns={this.handleShowModalShareSns}/>
            {
              this.state.isOpenShareSnsModal &&
              <ShareSnsModal handleShowModalShareSns={this.handleShowModalShareSns} handleOpenDialog={this.handleOpenDialog}
                             endUserUrl={this.state.endUserUrl} settings={this.props.settings} />
            }
          </div>
        </main>
        <ToastContainer />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.mv_position = storeState.settings.mv_position;
  newState.mv_original = storeState.settings.mv_original;
  newState.settings = storeState.settings;

  return newState;
}

const mapDispatchToProps = (dispatch) => {
  return {
    appendDataUi: (data) => dispatch(addDataUi(data)),
    appendPageDataUi: (data) => dispatch(addPageDataUi(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutSetting)
