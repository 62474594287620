import React, { Component } from 'react'
import ModdablePart from '../ModdablePart';
import { IMAGES } from '../../../../../untils/constants'

class LinkItems extends Component {
  constructor(props) {
    super(props);
    this.title = "外部参照デザイン"
  }
  render() {
    return (
      <ModdablePart title={this.title}>
        <ul className="no_select">
          {
            this.props.data.map((item, index) => {
              let content = JSON.parse(item.contents)
              return(
                <li key={index}>
                  <input name="block_link_pattern" id={`block_link_pattern_${item.kind_of_design}`} value={item.kind_of_design} type="radio" />
                  <label
                    htmlFor={`block_link_pattern_${item.kind_of_design}`}
                    onClick={ () => this.props.handleDoubleClickItem(item) }
                    style={{background: 'none 0% 0% / auto repeat scroll padding-box border-box rgb(255, 255, 255)', color: 'rgb(34, 34, 34)'}}
                    className={`block_link_label ${item.kind_of_design === this.props.itemChecked.kind_of_design ? 'item_active' : ''}`}
                  >
                  <img src={IMAGES[content.src]}></img>
                  </label>
                </li>
              )
            })
          }
        </ul>
      </ModdablePart>
    )
  }
}

export default LinkItems
