require('datatables.net-bs4');

$(document).ready(function() {
  if($('[id^=tags_datatable_wrapper]').length == 0 && $('#tags_datatable').length > 0) {
    table = $('#tags_datatable').DataTable({
      dom: "<'row'<'col-6'l><'#tag_btn_add_page.col-6 text-right'>>" +
      "<'row'<'col-sm-12'tr>>" +
      "<'float-right mb-3'p>",
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        lengthMenu: "_MENU_ を表示する",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        },
        emptyTable: "タグは登録されておりません",
        zeroRecords: "タグは登録されておりません"
      },
      order: [],
      ordering: false,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
          width: "10%",
        },
        {
          targets: 2,
          orderable: false,
          width: "10%",
        },
        {
          targets: 3,
          orderable: false,
          width: "10%",
        }
      ],
      drawCallback: function() {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);
      },
      stateSave: true
    });
    $('#tag_btn_add_page').append('<a href="/tags/add" class="btn btn-info mb-3">新規追加</a>');
  }
});
