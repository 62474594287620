require('datatables.net-bs4');

$( document ).ready(function() {
  if($('[id^=coupons_datatable_wrapper]').length == 0 && $('#coupons_datatable').length > 0) {
    table = $('#coupons_datatable').DataTable({
      dom: "<'row'<'col-6'l><'#coupon_btn_add.col-6 text-right'>>" +
        "<'row'<'col-sm-12 mt-3'tr>>" +
        "<'float-right mb-3'p>",
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        emptyTable: "クーポンは登録されておりません",
        zeroRecords: "クーポンは登録されておりません",
        lengthMenu: "_MENU_ を表示する",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        }
      },
      order: [],
      ordering: false,
      autoWidth: false,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
        },
        {
          targets: 1,
        },
        {
          targets: 2,
          orderable: false,
        },
        {
          targets: 3,
          orderable: false,
          visible: false,
        },
      ],
      drawCallback: function(settings) {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);

        reload_checkbox_all_state();
      },
      stateSave: true,
    });

    $('#coupons_datatable_length').before('<label class="mr-2">' +
      '<select id="select_display_flag" class="custom-select custom-select-sm form-control form-control-sm">' +
        '<option value="">-- 表示・非表示 --</option>' +
        '<option value="1">表示</option>' +
        '<option value="0">非表示</option>' +
      '</select>' +
    '</label>');
    $('#coupon_btn_add').append(`<a href='${$('#coupons_datatable').data("add-new-link")}' class='btn btn-info'>新規追加</a>`);

    setTimeout(function() {
      search = table.state().columns[3].search.search
      $('#select_display_flag').val("");
      switch(search) {
        case "show": $('#select_display_flag').val("1"); break;
        case "hidden": $('#select_display_flag').val("0"); break;
      }
    }, 450);
    $('#select_display_flag').on( 'change', function () {
      if (this.value == "") {
        valueSearch = "";
      } else if (this.value == 1) {
        valueSearch = "show";
      } else {
        valueSearch = "hidden";
      }

      table.columns(3).search(valueSearch).draw();
    } );
  }

  $('#coupons_check_all').on('click', function() {
    if (this.checked) {
      $('.checkbox_coupon').not(':disabled').prop('checked', true);
    } else {
      $('.checkbox_coupon').not(':disabled').prop('checked', false);
    }
  });

  $('.checkbox_coupon').on('click', function() {
    number_of_checkbox_unchecked = $('.checkbox_coupon:not(:checked)').length;
    if (number_of_checkbox_unchecked > 0) {
      $('#coupons_check_all').prop('checked', false);
    } else {
      $('#coupons_check_all').prop('checked', true);
    }
  });

});

function reload_checkbox_all_state() {
  number_of_checkbox_enable = $('.checkbox_coupon').not(':disabled').length;
  number_of_checkbox_unchecked = $('.checkbox_coupon:not(:checked)').not(':disabled').length;

  if (number_of_checkbox_enable == 0 || number_of_checkbox_unchecked > 0) {
    $('#coupons_check_all').prop('checked', false);
  } else {
    $('#coupons_check_all').prop('checked', true);
  }
}
