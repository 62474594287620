import React, { Component } from 'react'
import ReactTooltip from "react-tooltip";

export default class Tooltip extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const {title, content, tooltipId} = this.props
    return (
      <>
        <p data-tip data-for={tooltipId}>
          {title}
        </p>
        <ReactTooltip id={tooltipId} place="right" effect="solid" className="tooltip">
          {content}
        </ReactTooltip>
      </>
    )
  }
}
