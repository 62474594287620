import React, { Component } from 'react';
import ModdablePart from '../ModdablePart';
import { IMAGES } from '../../../../../untils/constants';

export class ButtonItems extends Component {
  constructor(props) {
    super(props);
    this.title = "ボタンデザイン"
  }

  HandleSwitchComponent(item) {
    switch (item.kind_of_design) {
      case 1:
        return (
          <div className="contents_btn01 width_full align-center"
               style={{width: "100%", minHeight: "161px", display: "flex", alignItems: "center"}}>
            <div className="content_wrapper" style={{padding: 0}}>
              <a style={{color: "white"}}><span>View More</span></a>
            </div>
          </div>
        )
        break;
      case 2:
        return <div style={{width: "100%", minHeight: "161px", display: "flex", alignItems: "center", padding: "5px"}}><img src={IMAGES[item.src]} alt=""></img></div>
        break;

      default:
        break;
    }
  }

  render() {
    return (
      <ModdablePart title={this.title}>
        <ul className="no_select">
          {
            this.props.data.map((item, index) => {
              let content = JSON.parse(item.contents)
              return (
                <li key={index}>
                  <input name="block_line_pattern" id={`block_line_pattern_${item.kind_of_design}`} value={item.kind_of_design} type="radio" />
                  <label
                    htmlFor={`block_line_pattern_${item.id}`}
                    onClick={ () => this.props.handleDoubleClickItem(item) }
                    className={`block_header_label ${item.kind_of_design === this.props.itemChecked.kind_of_design ? 'item_active' : ''}`}
                  >
                    { this.HandleSwitchComponent(item) }
                  </label>
                </li>
              )
            })
          }
        </ul>
      </ModdablePart>
    )
  }
}

export default ButtonItems
