import React, { Component } from 'react';
import { connect } from 'react-redux';

export class SideNavigationNav extends Component {
  render() {
    const { sideNavigationPages } = this.props;
    return (
      <section className="siidenav">
        <div className="heading block_header_5">
          <h4 className="h">Contents</h4>
          <p>コンテンツ</p>
        </div>

        <ul className="sidebar_list">
          {sideNavigationPages.map(item => {
            return (
              <li><a href={item.slug_plus}>{item.name}</a></li>
            )
          })}
        </ul>
      </section>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.sideNavigationPages = storeState.sideNavigationPages;

  return newState;
}

export default connect(mapStateToProps)(SideNavigationNav)
