import React, { Component } from 'react'
import HeadingPattern2Component from '../BlockImageText2/HeadingPattern2Component';
import ContentXnComponent from '../ContentXnComponent';
import {isOpenImageModal, isOpenImageSettingModal} from '../../../../../Lib/redux/actions';
import { connect } from 'react-redux';
import ContentEditable from 'react-contenteditable';

export class BlockType1 extends Component {
  constructor(props) {
    super(props);
    this.renderContentBlock = this.renderContentBlock.bind(this)
    this.state = {
      isHeadingPattern0Edit: false,
      isSubHeadingPattern0Edit: false,
      isHeadingPattern1Edit: false,
      isSubHeadingPattern1Edit: false,
      isHeadingPattern2Edit: false,
      isSubHeadingPattern2Edit: false,
      isHeadingPattern3Edit: false,
      isSubHeadingPattern3Edit: false,
      isHeadingPattern4Edit: false,
      isSubHeadingPattern4Edit: false
    }
  }

  renderContentBlock(){
    return (
      <React.Fragment>
        <div className="inner_item_img">
          { this.props.isEdit &&
            (
              <React.Fragment>
                <span className="admin_edit_link 378534" title="リンク設定" onClick={this.props.showBlockImgSettingModal}>
                  <i className="material-icons">link</i>
                </span>
                <span className="admin_edit_img 378534" title="画像を変更" onClick={this.props.showOperatorImageModal}>
                  <i className="material-icons">collections</i>
                </span>
              </React.Fragment>
            )
          }
          {this.props.showBlockImage()}
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { isVisitOuterLink, positionChild } = this.props;
    const hrefValue = this.props.hrefValue();
    return (
      <React.Fragment>
        { hrefValue != false && isVisitOuterLink &&
          <a href={hrefValue} target={this.props.targetValue(positionChild)}>
            {this.renderContentBlock()}
          </a>
        }
        {
          hrefValue != false && !isVisitOuterLink &&
          <a href="#!">
            {this.renderContentBlock()}
          </a>
        }
        { hrefValue == false &&
          <React.Fragment>
            {this.renderContentBlock()}
          </React.Fragment>
        }
        <div className="inner_item_txt">
          <div className={`heading block_header_4 ${this.props.headingPatternClass}`} data-target="1">
            <HeadingPattern2Component content={this.props.content} index={this.props.index} kindOfDesign={this.props.kindOfDesign}
                         positionChild={this.props.positionChild}
                         isEdit={this.props.isEdit}
                         handleChangeHeadline={this.props.handleChangeHeadline}
                         handleChangeAlign={this.props.handleChangeAlign} />
          </div>
        
          <ContentXnComponent text={this.props.content[`text${this.props.positionChild}`]} positionChild={this.props.positionChild} contentClass={this.props.content[`contentClass${this.props.positionChild}`]} index={this.props.index} i={this.props.positionChild}
                           isEdit={this.props.isEdit}
                           handleChangeText={this.props.handleChangeText}
                           handleChangeAlign={this.props.handleChangeAlign} />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.isOpenImageModal = storeState.isOpenImageModal
  newState.isOpenImageSettingModal = storeState.isOpenImageSettingModal
  return newState;
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleShowImageModal: (data) => {dispatch(isOpenImageModal(data))},
    handleShowSetting: (data) => {dispatch(isOpenImageSettingModal(data))}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockType1)
