import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddModalItem from './AddModalItem';
import AddModalCss from './AddModalCss';
import ModalDetailData from './ModalDetailData'

export class AddModalContent extends Component {
  constructor(props) {
    super(props);
    this.displayFooter = this.displayFooter.bind(this)
    this.displayModal = this.displayModal.bind(this)
  }

  displayFooter() {
    const {isOpenCss, isOpenDetailData} = this.props
    if(isOpenCss) {
      return "« デザイン設定へ"
    }
    if(isOpenDetailData) {
      return "« その他設定へ"
    }
    return "その他設定へ »"
  }

  displayModal() {
    const {isOpenCss, isOpenDetailData, itemChecked} = this.props
    if(isOpenCss) {
      return<AddModalCss {...this.props} comparisonCategories={this.props.loadComparisonCategories} />
    }
    if(isOpenDetailData) {
      let src
      switch (itemChecked.block_type) {
        case "block_question":
          src = "/questions?iframe=true"
          break;
        case "block_menu":
          src = "/menus?iframe=true"
          break;
        case "block_gallery":
          src = "/galleries?iframe=true"
          break;
        case "block_comparison":
          src = "/comparisons?iframe=true"
          break;
        case "block_shop":
          src = "/shops?iframe=true"
          break;
        case "block_form":
          src = "/contacts?iframe=true"
          break;
        case "block_coupon":
          src = "/coupons?iframe=true"
          break;
        case "block_staff":
          src = "/staffs?iframe=true"
          break;
        case "block_recruit":
          src = "/recruits?iframe=true"
          break;
        default:
          break;
      }
      return <ModalDetailData src={src}/>
    }
    return <AddModalItem {...this.props} />
  }
  render() {
    return (
      <div className="stepset">
        <div className="step_inner">
          <div className={`step${this.props.isOpenDetailData ? ' partsModal' : ''}`}>
            {this.displayModal()}
            <div className="modal_footer">
              <a href="#!" onClick={this.props.displaySetting}>
                {this.displayFooter()}
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.loadComparisonCategories = storeState.loadComparisonCategories
  newState.contactOptions = storeState.contactOptions
  return newState
}

export default connect(mapStateToProps, null)(AddModalContent)
