import React, { Component } from 'react';
import ModdablePart from '../ModdablePart';
import { IMAGES } from '../../../../../untils/constants';

export class LineItems extends Component {
  constructor(props) {
    super(props);
    this.title = '区切り線デザイン'
  }

  render() {
    return (
      <ModdablePart title={this.title}>
        <ul className="no_select">
          {
            this.props.data.map((item, index) => {
              let image_url = `line_${item.kind_of_design}`;
              return (
                <li key={index}>
                  <input name="block_line_pattern" id={`block_line_pattern_${item.kind_of_design}`} value={item.kind_of_design} type="radio" />
                  <label
                    htmlFor={`block_line_pattern_${item.id}`}
                    onClick={ () => this.props.handleDoubleClickItem(item) }
                    style={{background: 'none 0% 0% / auto repeat scroll padding-box border-box rgb(255, 255, 255)', color: 'rgb(34, 34, 34)'}}
                    className={`block_header_label ${item.kind_of_design === this.props.itemChecked.kind_of_design ? 'item_active' : ''}`}
                  >
                    <img src={IMAGES[image_url]} alt="" />
                  </label>
                </li>
              )
            })
          }
        </ul>
      </ModdablePart>
    )
  }
}

export default LineItems
