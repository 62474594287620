import React, { Component } from 'react';
import { connect } from "react-redux";
import ImageSetup from './ImageSetup'
import ChooseImage from './ChooseImage'
import { updateSetting, saveImageUrl } from "../../../../Lib/redux/actions";

export class EditParallaxImageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayFlag: '0',
      headerSetting: {...this.props.headerSetting},
      settings: {...this.props.settings},
      mv_image_id: '',
      images: {
        mv_image_url: props.mv_image_url
      }
    };
    this.handleTabSetting = this.handleTabSetting.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.handleSaveMvImage = this.handleSaveMvImage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.removeImage = this.removeImage.bind(this);
  }

  handleTabSetting({target}) {
    this.setState({ displayFlag: target.dataset.step })
  }

  handleImage(event) {
    const { settings, images } = this.state;

    settings['mv_image_id'] = event.target.dataset.id;
    images['mv_image_url'] = event.target.style.backgroundImage.slice(4, -1).replace(/"/g, "");
    this.setState({
      settings: settings,
      images: images
    });
  }

  removeImage() {
    const { settings, images } = this.state;

    settings['mv_image_id'] = '';
    images['mv_image_url'] = '';
    this.setState({
      settings: settings,
      images: images
    });
  }

  handleSaveMvImage() {
    this.props.processSettings(this.state.settings);
    this.props.saveImageUrl(this.state.images);

    this.props.handleShowModalEditParallax();
  }

  handleChange({ target }) {
    const { settings } = this.state;

    settings[target.name] = target.value

    this.setState({ settings })
  }

  render() {
    const { settings } = this.state;
    return (
      <div id="setting_modal" data-step="#block_lv" className="active admin_edit_menu_add admin_edit_extended_settings loaded">
        <div className="wrapper">
          <div id="contents">
            <div className="modal_header">
              <i>パーツ設定</i>
              <span className="modal_header_btn cancel first_button" onClick={this.props.handleShowModalEditParallax}>キャンセル</span>
              <span className="modal_header_btn ok" onClick={this.handleSaveMvImage}>決定</span>
            </div>
            <div id="content_r" style={{opacity: 1}}>
              <div className="extended_settings_wrap">
                <div className="stepset">
                  <div className="step_inner" style={{width: "100%"}}>
                    {
                      this.state.displayFlag === '0' &&
                      <ImageSetup handleTabSetting={this.handleTabSetting}
                                  handleChange={this.handleChange}
                                  mv_image_url={this.state.images.mv_image_url}
                                  settings={settings} />
                    }
                    {
                      this.state.displayFlag === '1' &&
                      <ChooseImage handleImage={this.handleImage}
                                   mv_image_id={this.state.settings.mv_image_id}
                                   mv_image01_id={this.state.settings.mv_image01_id}
                                   mv_image02_id={this.state.settings.mv_image02_id}
                                   mv_image03_id={this.state.settings.mv_image03_id}
                                   mv_image04_id={this.state.settings.mv_image04_id}
                                   mv_image05_id={this.state.settings.mv_image05_id}
                                   removeImage={this.removeImage}
                                   isHome={false} />
                    }
                  </div>
                  {this.state.displayFlag === '1' &&
                    <div className="modal_footer">
                      <a href="#" data-step="0" onClick={this.handleTabSetting}>« 画像設定へ</a>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);

  newState.settings = storeState.settings;
  newState.mv_image_url = storeState.slider_images.mv_image_url;

  return newState;
}

const mapDispatchToProps = (dispatch) => {
  return {
    processSettings: (data) => {dispatch(updateSetting(data))},
    saveImageUrl: (data) => {dispatch(saveImageUrl(data))},
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditParallaxImageModal);
