import React, { Component } from 'react'
import ImageModalFooter from '../Modal/ImageModalFooter'

export default class ImageBackgroundSetting extends Component {
  render() {
    const {content} = this.props
    return (
      <>
        <div className="step">
          <div className="wrap">
            <div className="item">
              <p>パララックス</p>
              <span className="select">
                <select name="parallax" defaultValue={content.parallax} onChange={this.props.handleChangeContent}>
                  <option value={1}>しない</option>
                  <option value={2}>する</option>
                </select>
              </span>
            </div>
            <div className="item">
              <p>繰り返し</p>
              <span className="select">
                <select name="background_repeat" defaultValue={content.background_repeat} onChange={this.props.handleChangeContent}>
                  <option value="no-repeat">しない</option>
                  <option value="repeat">する</option>
                  <option value="repeat-y">縦方向</option>
                  <option value="repeat-x">横方向</option>
                </select>
              </span>
            </div>
            <div className="item">
              <p>位置</p>
              <span className="select">
                <select name="background_position" defaultValue={content.background_position} onChange={this.props.handleChangeContent}>
                  <option value="left top">左上</option>
                  <option value="left center">左中央</option>
                  <option value="left bottom">左下</option>
                  <option value="center top">中央上</option>
                  <option value="center center">中央</option>
                  <option value="center bottom">中央下</option>
                  <option value="right bottom">右上</option>
                  <option value="right center">右中央</option>
                  <option value="right bottom">右下</option>
                </select>
              </span>
            </div>
            <div className="item">
              <p>サイズ</p>
              <span className="select">
                <select name="background_size" defaultValue={content.background_size} onChange={this.props.handleChangeContent}>
                  <option value="auto">そのまま</option>
                  <option value="cover">覆う</option>
                  <option value="contain">フィット</option>
                </select>
              </span>
            </div>
          </div>
          <ImageModalFooter isOpenImageAdditional={this.props.isOpenImageAdditional} handleDisplaySetting={this.props.handleDisplaySetting} />
        </div>
      </>
    )
  }
}
