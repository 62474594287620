require('datatables.net-bs4');

$(document).ready(function() {
  if($('[id^=favorites_datatable_wrapper]').length == 0 && $('#favorites_datatable').length > 0) {
    table = $('#favorites_datatable').DataTable({
      dom: "<'row'<'col-sm-12 col-md-12'l>>" +
        "<'row'<'#favorite_btn_batch_delete.col-sm-12'>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'float-right mb-3'p>",
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        lengthMenu: "_MENU_ を表示する",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ'
        },
        emptyTable: "お気に入りは登録されておりません",
        zeroRecords: "お気に入りは登録されておりません"
      },
      order: [[ 1, 'asc' ]],
      columnDefs: [
        {
          targets: 0,
          orderable: false
        },
        {
          targets: 1,
          orderable: true,
          width: '40%'
        },
        {
          targets: 2,
          orderable: false,
          width: '20%'
        },
        {
          targets: 3,
          orderable: false
        },
        {
          targets: 4,
          orderable: false
        }
      ],
      drawCallback: function(settings) {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);

        String(localStorage.getItem('checked_favorites')).split(',').forEach(function(value) {
          $('.checkbox_favorite[value=' + value + ']').prop('checked', true);
        });

        number_of_checkbox_enable = $('.checkbox_favorite').not(':disabled').length;
        number_of_checkbox_unchecked = $('.checkbox_favorite:not(:checked)').not(':disabled').length;
        if (number_of_checkbox_enable == 0 || number_of_checkbox_unchecked > 0) {
          $('#favorite_checkbox_check_all').prop('checked', false);
        } else {
          $('#favorite_checkbox_check_all').prop('checked', true);
        }
      },
      stateSave: false
    });

    selectFavoriteCategoryElement($('#favorites_datatable').data("favorite-categories"));
    if (!/iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent)) {
      $('#select_category').select2({
        width: 'element',
        minimumResultsForSearch: -1
      });
    }
    $('#favorite_btn_batch_delete').append("<input type='submit' id='favorite_batch_delete_favorite_btn' value='一括削除' class='btn btn-info my-3' data-confirm='削除後のデータは復旧できません。よろしいですか？' data-disable-with='一括削除' />");

    $('#favorite_checkbox_check_all').on('click', function() {
      if (this.checked) {
        $('.checkbox_favorite').not(':disabled').prop('checked', true);
      } else {
        $('.checkbox_favorite').not(':disabled').prop('checked', false);
      }
      $('.checkbox_favorite').first().trigger('change');
    });

    $('#select_category').on('change', function(){
      const val = $.fn.dataTable.util.escapeRegex(
        $(this).val()
      );
      table.column(2).search(val ? '^'+val+'$' : '', true, false).draw();
    })

    $('#favorites_datatable').on('click', '.checkbox_favorite', function() {
      number_of_checkbox_unchecked = $('.checkbox_favorite:checkbox:not(:checked)').not(':disabled').length;
      if (number_of_checkbox_unchecked > 0) {
        $('#favorite_checkbox_check_all').prop('checked', false);
      } else {
        $('#favorite_checkbox_check_all').prop('checked', true);
      }
    });

    $('#favorite_batch_delete_favorite_btn').on('click', function(event) {
      if ($('.checkbox_favorite:checked').not(':disabled').length == 0) {
        event.preventDefault();
        alert('チェックボックスが選択されていません');
        return false;
      } else {
        let deleteItem = []
        $('.checkbox_favorite:checked').each(function() {
          deleteItem.push($(this).val());
        });
        $('#favorites_delete').val(deleteItem);
      }
    });

    $('.checkbox_favorite').on('change', function() {
      var checked_favorites = String(localStorage.getItem('checked_favorites')).split(',');

      $('.checkbox_favorite').each(function() {
        var index = checked_favorites.indexOf($(this).val());
        if (index !== -1) checked_favorites.splice(index, 1);
      });

      $('.checkbox_favorite:checked').each(function() {
        checked_favorites.push($(this).val());
      });

      localStorage.setItem('checked_favorites', checked_favorites.join(','));
    })
  } else {
    localStorage.removeItem('checked_favorites')
  }
});

function selectFavoriteCategoryElement(cats) {
  var $labelElm = $("<label>", {class: "mr-2"});
  var $selectElm = $("<select>", {class: "custom-select custom-select-sm form-control form-control-sm", id: "select_category"});
  var $option = $("<option>", {text: "カテゴリー一覧", value: ""});
  $selectElm.append($option);
  $.each(cats, function( index, value ) {
    var $option = $("<option>", {text: value["name"], value: value["id"]});
    $selectElm.append($option);
  });
  // $selectElm.on('change', filterGalleryByCategory);
  $labelElm.append($selectElm);
  $("#favorites_datatable_length").before($labelElm);
}
