import React, { Component } from 'react'

class SliderComponent extends Component {
  render() {
    let url_image = 'https://cmstest-touch-links.onerank-cms.com/common/upload_data/cmstest-touch-links/image/20210322131420.png'
    return (
      <div id="lv_outer" style={{ borderTop: '142px solid transparent' }}>
        <div id="lv" className=" parallax">
            <div className="parallax_img" style={{backgroundImage: `url(${url_image})`,transform: 'translateY(-254.022px)', height:"1000px" }}></div>
            <p><span>404 error - File Not Found</span></p>
        </div>
      </div>
    )
  }
}

export default SliderComponent
