require('datatables.net-bs4');

$( document ).ready(function() {
  if($('[id^=comparisons_datatable_wrapper]').length == 0 && $('#comparisons_datatable').length > 0) {
    table = $('#comparisons_datatable').DataTable({
      dom: "<'row'<'col-6'l><'#comparison_btn_add.col-6 text-right'>>" +
        "<'row'<'#comparison_btn_batch_delete.col-sm-12 mt-3'>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'float-right mb-3'p>",
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        emptyTable: "ビフォーアフターは登録されておりません",
        zeroRecords: "ビフォーアフターは登録されておりません",
        lengthMenu: "_MENU_ を表示する",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        }
      },
      order: [],
      ordering: false,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
          width: "10%"
        },
        {
          targets: 1,
          width: "40%"
        },
        {
          targets: 2,
          width: "30%"
        },
        {
          targets: 3,
          orderable: false,
          width: "10%"
        },
        {
          targets: 4,
          visible: false
        },
        {
          targets: 5,
          visible: false
        },
      ],
      drawCallback: function(settings) {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);
      },
      stateSave: true
    });

    $('#comparisons_datatable_length').before('<label class="mr-2">' +
      '<select id="comparison_select_display_flag" class="custom-select custom-select-sm form-control form-control-sm">' +
        '<option value="">-- 表示・非表示 --</option>' +
        '<option value="1">表示</option>' +
        '<option value="0">非表示</option>' +
      '</select>' +
    '</label>');

    selectComparisonCategoryElement($('#comparisons_datatable').data("comparison-categories"));

    if (!/iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent)) {
      $('#select_category').select2({
        width: 'element',
        minimumResultsForSearch: -1
      });
    }

    $('#comparison_btn_add').append(`<a href='${$('#comparisons_datatable').data("add-new-link")}' class='btn btn-info'>新規追加</a>`);

    $('#comparison_select_display_flag').on( 'change', function () {
      if (this.value == "") {
        valueSearch = "";
      } else if (this.value == 1) {
        valueSearch = "true";
      } else {
        valueSearch = "false";
      }

      table.columns(4).search(valueSearch).draw();
    } );

    setTimeout(function() {
      search = table.state().columns[5].search.search
      let selectCategoryId = search.includes("$") && search.includes("^") ? search.replace("$", '').replace("^", '') : search;
      $('#select_category').val(selectCategoryId).trigger('change');;
    }, 450);

    $('#select_category').on('change', function () {
      if (this.value == "") {
        valueSearch = "";
      } else {
        valueSearch = $(this.options[this.selectedIndex]).val();
      }

      table.columns(5).search(valueSearch).draw();
    });

    $('#comparison_select_display_flag').val("");
    switch(table.state().columns[4].search.search) {
      case "true": $('#comparison_select_display_flag').val("1"); break;
      case "false": $('#comparison_select_display_flag').val("0"); break;
    }
  }
});

function selectComparisonCategoryElement(comparisonCategories) {
  var $labelElm = $("<label>", {class: "mr-2"});
  var $selectElm = $("<select>", {class: "custom-select custom-select-sm form-control form-control-sm", id: "select_category"});
  var $option = $("<option>", {text: "カテゴリー一覧", value: ""});
  $selectElm.append($option);
  $.each(comparisonCategories, function( index, value ) {
    var $option = $("<option>", {text: value["name"], value: value["id"]});
    $selectElm.append($option);
  });

  $labelElm.append($selectElm);
  $("#comparison_select_display_flag").parent().before($labelElm);
}
