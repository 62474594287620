$( document ).ready(function() {
  if($(".dataTables_wrapper").length == 0 && $('#files_datatable').length > 0) {
    table = $('#files_datatable').DataTable({
      dom: "<'row'<'col-6'l><'#file_btn_add.col-6 text-right'>>" +
        "<'row'<'#file_btn_batch_delete.col-sm-12'>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'float-right mb-3'p>",
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        lengthMenu: "_MENU_ を表示する",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        },
        emptyTable: "文書ファイルは登録されておりません",
        zeroRecords: "文書ファイルは登録されておりません"
      },
      order: [],
      columnDefs: [
        {
          targets: 0,
          orderable: false,
          width: '10%'
        },
        {
          targets: 2,
          orderable: false,
          width: '10%'
        },
      ],
      drawCallback: function(settings) {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);

        reload_checkbox_all_state();
      },
      stateSave: true
    });

    $('#file_btn_add').append(`<a href='/images'>画像ファイル</a><br/><a href='${$('#files_datatable').data("add-new-link")}' class='btn btn-info'>新規追加</a>`);
    $('#file_btn_batch_delete').append( "<input type='submit' id='btn-batch-delete-files' value='一括削除' class='btn btn-info my-3' data-confirm='削除後のデータは復旧できません。よろしいですか？' data-disable-with='一括削除' />" );

    $('#btn-batch-delete-files').on('click', function(event) {
      if ($('.checkbox_file:checked').length == 0) {
        event.preventDefault();
        alert('チェックボックスが選択されていません');
        return false;
      } else {
        let deleteItem = []
        $('.checkbox_file:checked').each(function() {
          deleteItem.push($(this).val());
        });
        $('#files_delete').val(deleteItem);
      }
    });

    $('#files_check_all').on('click', function() {
      if (this.checked) {
        $('.checkbox_file').not(':disabled').prop('checked', true);
      } else {
        $('.checkbox_file').not(':disabled').prop('checked', false);
      }
      $('.checkbox_file').first().trigger('change');
    });

    $('.checkbox_file').on('click', function() {
      number_of_checkbox_unchecked = $('.checkbox_file:not(:checked)').length;
      if (number_of_checkbox_unchecked > 0) {
        $('#files_check_all').prop('checked', false);
      } else {
        $('#files_check_all').prop('checked', true);
      }
    });

    $('.checkbox_file').on('change', function() {
      var checked_files = String(localStorage.getItem('checked_files')).split(',');

      $('.checkbox_file').each(function() {
        var index = checked_files.indexOf($(this).val());
        if (index !== -1) checked_files.splice(index, 1);
      });

      $('.checkbox_file:checked').each(function() {
        checked_files.push($(this).val());
      });

      localStorage.setItem('checked_files', checked_files.join(','));
    })
  } else {
    localStorage.removeItem('checked_files')
  }
});

function reload_checkbox_all_state() {
  String(localStorage.getItem('checked_files')).split(',').forEach(function(value) {
    $('.checkbox_file[value=' + value + ']').prop('checked', true);
  });

  number_of_checkbox_enable = $('.checkbox_file').not(':disabled').length;
  number_of_checkbox_unchecked = $('.checkbox_file:not(:checked)').not(':disabled').length;

  if (number_of_checkbox_enable == 0 || number_of_checkbox_unchecked > 0) {
    $('#files_check_all').prop('checked', false);
  } else {
    $('#files_check_all').prop('checked', true);
  }
}
