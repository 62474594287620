import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ConfigablePart from "../ConfigablePart";
import Tel1 from "../BlockTel/Tel1";
import Tel2 from "../BlockTel/Tel2";
import HeadlineComponent from "../HeadlineComponent";
import ExplanationComponent from "../ExplanationComponent";
import { parallaxScroll } from '../../../../../functions';

class LoadableTelPart extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    isEdit: true
  };

  constructor(props) {
    super(props);
    this.handleSwitchComponent = this.handleSwitchComponent.bind(this);
  }

  componentDidMount() {
    parallaxScroll();
  }

  handleSwitchComponent(key) {
    switch (key) {
      case 1:
        return <Tel1 {...this.props} />
        break;
      case 2:
        return <Tel2 {...this.props} />
        break;

      default:
        break;
    }
  }

  render() {
    const { kindOfDesign, content, sectionStyle } = this.props;
    return (
      <section key={kindOfDesign} id={kindOfDesign}
               className={`widget_tel align-left block_map_1 delay3 ${content.width_class} blockarea ${content.parallax != 1 ? "parallax" : ""} ${content.top_padding_class}  ${content.bottom_padding_class} edit_outer delay4Active editing block_map without delay3Active`}
               style={sectionStyle}>
        {this.props.isEdit && <ConfigablePart {...this.props} />}
        {
          content.parallax != 1 &&
          <div className="parallax-wrap">
            <div className="parallax_img" style={{backgroundImage: `url(${content.background_image_url})`, height: '100%', transform: 'translateY(-50%)'}} />
          </div>
        }
        <div className="content_wrapper">
          <HeadlineComponent content={this.props.content} index={this.props.index}
                             isEdit={this.props.isEdit}
                             handleChangeHeadline={this.props.handleChangeHeadline}
                             handleChangeAlign={this.props.handleChangeAlign}
                             handleChangeText={this.props.handleChangeText}
                             blockType={this.props.blockType} />
          {
            this.props.content.headlineType == "2" &&
            <ExplanationComponent content={this.props.content} index={this.props.index}
                                  isEdit={this.props.isEdit}
                                  handleChangeHeadline={this.props.handleChangeHeadline}
                                  handleChangeAlign={this.props.handleChangeAlign} />
          }
          {this.handleSwitchComponent(kindOfDesign)}
        </div>
      </section>
    )
  }
}

export default LoadableTelPart
