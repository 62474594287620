import React, { useState, useEffect } from 'react';
import LayoutSetting from "../components/ViewEdit/LayoutSetting";
import { useDispatch } from "react-redux";
import request from "../untils/request";
import { APIS } from '../untils/constants'

import {
  headerSetting,
  pageStructureData,
  footerSetting,
  generalSetting,
  footerNav,
  pageStructureInSetting,
  homeStructure,
  savePageDataUi,
  saveImageUrl,
  updateSetting,
  newpageCategories,
  questionCategories,
  saveSubNavigation,
  contactOptions,
  newsCategories,
  menuCategories,
  loadCoupons,
  staffCategories,
  galleryCategories,
  saveReleaseDate,
  saveRecuits,
  recruitCategories,
  tagsList,
  loadAllPages,
  loadComparisonCategories,
  loadComparisonData,
  savePageCategoryIds,
  loadShopsData,
  saveSettingAdvance,
  localSideNavigations,
  setTenants,
  setClientId
} from "../Lib/redux/actions";

const EditPage = (props) => {
  const [statusPage] = useState('edit');

  const dispatch = useDispatch();

  useEffect(async () => {
    let settings = props.settings;
    if(props.facebook_flag) settings.facebook_flag = true
    if(props.twitter_flag) settings.twitter_flag = true
    dispatch(updateSetting(settings))
    dispatch(setClientId(props.client_id))
    const {data: {page_structure_data, page_structure_in_setting, link_for_embedded_parts}} = await request({
      method: 'post',
      url: APIS.get_home_structure,
      data: {newpage_id: props.settings.newpage_id, client_id: props.client_id}
    })

    const {data: { setting_header_data }} = await request({
      method: 'post',
      url: APIS.get_setting_header,
      data: {page_status: statusPage, client_id: props.client_id}
    })

    const {data: { setting_footer_data }} = await request({
      method: 'post',
      url: APIS.get_setting_footer,
      data: {client_id: props.client_id}
    })

    const {data: { general_setting_data }} = await request({
      method: 'post',
      url: APIS.get_general_seting,
      data: {client_id: props.client_id}
    })

    const {data: { footer_routes }} = await request({
      method: 'post',
      url: APIS.get_common_footer_navigation,
      data: { client_id: props.client_id}
    })

    const {data: {
      comparison_categories,
      newpage_categories,
      question_categories,
      news_categories,
      menu_categories,
      gallery_categories,
      recruit_categories
    }} = await request({
      method: 'post',
      url: APIS.get_newpage_categories,
      data: { client_id: props.client_id }
    })

    const {data: { contacts }} = await request({
      method: 'post',
      url: APIS.get_contacts_options,
      data: { client_id: props.client_id }
    })

    const {data: { coupons }} = await request({
      method: 'get',
      url: `${APIS.get_coupons}?client_id=${props.client_id}`
    })

    const {data: { staff_categories }} = await request({
      method: 'get',
      url: `${APIS.get_staff_categories}?client_id=${props.client_id}`
    })

    const {data: { recruits }} = await request({
      method: 'get',
      url: `${APIS.get_recruits}?client_id=${props.client_id}`,
    })

    const tags_list = await request({
      method: 'get',
      url: `${APIS.get_tags}?newpage_id=${props.settings.newpage_id}&client_id=${props.client_id}`
    })

    let {data: { all_pages }} = await request({
      method: 'get',
      url: `${APIS.get_all_page}?page_kind=${props.page_kind}&client_id=${props.client_id}`
    })

    const {data: { shops }} = await request({
      method: 'post',
      url: APIS.get_shops,
      data: { client_id: props.client_id }
    })

    const newpage_id = props.match.params.newpage_id
    all_pages = all_pages.filter(page => parseInt(page.id) !== parseInt(newpage_id));

    const {data: {categories, comparisons}} = await request({
      method: 'get',
      url: `${APIS.get_comparison_categories}?client_id=${props.client_id}`
    })

    const {data: { setting_advance }} = await request({
      method: 'get',
      url: `${APIS.get_setting_advanced}?client_id=${props.client_id}`
    })

    const comparisonData = {
      categories: categories,
      comparisons: comparisons
    }

    if (props.page_kind == 'copy_template') {
      const {data: { tenants }} = await request({
        method: 'get',
        url: APIS.get_tenants
      })

      dispatch(setTenants(tenants))
    }

    dispatch(loadComparisonCategories(comparison_categories))
    dispatch(loadAllPages(all_pages))
    dispatch(pageStructureData(page_structure_data))
    dispatch(pageStructureInSetting(page_structure_in_setting))
    dispatch(headerSetting(setting_header_data))
    dispatch(footerSetting(setting_footer_data))
    dispatch(generalSetting(general_setting_data))
    dispatch(footerNav(footer_routes))
    dispatch(homeStructure(link_for_embedded_parts))
    dispatch(savePageDataUi(props.templateData))
    dispatch(saveImageUrl(props.image_urls))
    dispatch(newpageCategories(newpage_categories))
    dispatch(questionCategories(question_categories))
    dispatch(saveSubNavigation(props.sub_navigations))
    dispatch(contactOptions(contacts))
    dispatch(newsCategories(news_categories))
    dispatch(menuCategories(menu_categories))
    dispatch(loadCoupons(coupons))
    dispatch(staffCategories(staff_categories))
    dispatch(galleryCategories(gallery_categories))
    dispatch(saveReleaseDate(props.release_date))
    dispatch(saveRecuits(recruits))
    dispatch(recruitCategories(recruit_categories))
    dispatch(tagsList({tags: tags_list, page_kind: props.page_kind}))
    dispatch(loadComparisonData(comparisonData))
    dispatch(savePageCategoryIds(props.page_category_ids))
    dispatch(loadShopsData(shops))
    dispatch(saveSettingAdvance(setting_advance))
    dispatch(localSideNavigations(props.local_side_navigations))
  }, [])

  return (
    <React.Fragment>
      <LayoutSetting pageKind={props.page_kind} pageStatus={statusPage}
                     facebookFlag={props.facebook_flag} twitterFlag={props.twitter_flag} LpUrl={props.lp_url} />
    </React.Fragment>
  )
}

export default EditPage;
