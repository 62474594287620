import React, { Component } from 'react';
import { display_recruit_part } from '../../../../../../functions'

export class RecruitRequirements extends Component {
  constructor(props) {
    super(props);
    this.fields = [
      'mandatory_requirements',
      'welcome_yes'
    ]
  }
  render() {
    const { recruit } = this.props;
    let is_display = display_recruit_part(recruit, this.fields)
    return (
      <React.Fragment>
        {is_display && (
        <div className="detail">
          <div className="heading block_header_3 align-left">
            <h4 className="h">必須・歓迎要件</h4>
          </div>
          <div className="block_table_3 width_fixed align-left">
            <table>
              <tbody>
                {(recruit.mandatory_requirements !== undefined && recruit.mandatory_requirements !== '') && (
                <tr>
                  <th>応募資格</th>
                  <td className="pre-wrap"> { recruit.mandatory_requirements } </td>
                </tr>
                )}
                {(recruit.welcome_yes !== undefined && recruit.welcome_yes !== '') && (
                <tr>
                  <th>歓迎 / 尚可</th>
                  <td className="pre-wrap"> { recruit.welcome_yes } </td>
                </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        )}

        {(recruit.required_requirements_for_entry !== undefined && recruit.required_requirements_for_entry !== '') && (
        <div className="detail">
          <div className="heading block_header_3 align-left">
            <h4 className="h">推薦時における記入必須要件</h4>
          </div>
          <div className="block_table_3 width_fixed align-left">
            <table>
              <tbody>
                <tr>
                  <th>★推薦時における記入必須要件</th>
                  <td className="pre-wrap"> { recruit.required_requirements_for_entry } </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        )}
      </React.Fragment>
    )
  }
}

export default RecruitRequirements
