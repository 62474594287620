require('datatables.net-bs4');

$( document ).ready(function() {
  if($('[id^=shops_datatable_wrapper]').length == 0 && $('#shops_datatable').length > 0) {
    table = $('#shops_datatable').DataTable({
      dom: "<'row'<'col-6'l><'#shop_btn_add.col-6 text-right'>>" +
        "<'row'<'#shop_btn_batch_delete.col-sm-12'>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'float-right mb-3'p>",
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        emptyTable: "企業・店舗は登録されておりません",
        zeroRecords: "企業・店舗は登録されておりません",
        lengthMenu: "_MENU_ を表示する",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        }
      },
      order: [[ 1, 'asc' ]],
      columnDefs: [
        {
          targets: 0,
          orderable: false,
          width: '10%'
        },
        {
          targets: 2,
          orderable: false,
          width: '20%'
        },
      ],
      drawCallback: function(settings) {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);

        reload_checkbox_all_state();
      },
      stateSave: true
    });

    $('#shop_btn_add').append(`<a href='${$('#shops_datatable').data("add-new-link")}' class='btn btn-info'>新規追加</a>`);
    $('#shop_btn_batch_delete').append( "<input type='submit' id='btn-batch-delete-shops' value='一括削除' class='btn btn-info my-3' data-confirm='削除後のデータは復旧できません。よろしいですか？' data-disable-with='一括削除' />" );
  } else {
    localStorage.removeItem('checked_shops')
  }

  $('#btn-batch-delete-shops').on('click', function(event) {
    if ($('.checkbox_shop:checked').length == 0) {
      event.preventDefault();
      alert('チェックボックスが選択されていません');
      return false;
    } else {
      let deleteItem = []
      $('.checkbox_shop:checked').each(function() {
        deleteItem.push($(this).val());
      });
      $('#shops_delete').val(deleteItem);
    }
  });

  $('#shops_check_all').on('click', function() {
    if (this.checked) {
      $('.checkbox_shop').not(':disabled').prop('checked', true);
    } else {
      $('.checkbox_shop').not(':disabled').prop('checked', false);
    }
    $('.checkbox_shop').first().trigger('change');
  });

  $('#shops_datatable').on('click', '.checkbox_shop', function() {
    number_of_checkbox_unchecked = $('.checkbox_shop:not(:checked)').length;
    if (number_of_checkbox_unchecked > 0) {
      $('#shops_check_all').prop('checked', false);
    } else {
      $('#shops_check_all').prop('checked', true);
    }
  });

  $('.checkbox_shop').on('change', function() {
    var checked_shops = String(localStorage.getItem('checked_shops')).split(',');

    $('.checkbox_shop').each(function() {
      var index = checked_shops.indexOf($(this).val());
      if (index !== -1) checked_shops.splice(index, 1);
    });

    $('.checkbox_shop:checked').each(function() {
      checked_shops.push($(this).val());
    });

    localStorage.setItem('checked_shops', checked_shops.join(','));
  })
});

function reload_checkbox_all_state() {
  String(localStorage.getItem('checked_shops')).split(',').forEach(function(value) {
    $('.checkbox_shop[value=' + value + ']').prop('checked', true);
  });

  number_of_checkbox_enable = $('.checkbox_shop').not(':disabled').length;
  number_of_checkbox_unchecked = $('.checkbox_shop:not(:checked)').not(':disabled').length;

  if (number_of_checkbox_enable == 0 || number_of_checkbox_unchecked > 0) {
    $('#shops_check_all').prop('checked', false);
  } else {
    $('#shops_check_all').prop('checked', true);
  }
}
