import React, {Component} from 'react'
import PropTypes from 'prop-types';
import request from "../../../untils/request";
import {APIS} from "../../../untils/constants";

export default class NameSelections extends Component {
  constructor(props) {
    super(props)
    this.state = { items: [] }
    this.handleChangeCheck = this.handleChangeCheck.bind(this);
  }

  static propTypes = {
    isShowDetail: PropTypes.bool,
    errorMessages: PropTypes.object
  };

  static defaultProps = {
    isShowDetail: true,
    errorMessages: {}
  };

  async componentDidMount () {
    switch (this.props.kindOfItem) {
      case 'shop':
        await request({
          method: 'post',
          url: APIS.get_shops,
          headers: { "Content-Type": "multipart/form-data" },
        }).then(response => {
          this.setState({items: response.data.shops})
          if (response.data.shops.length > 0 && !JSON.parse(this.props.itemChecked.contents)['shop_id']) {
            this.updateShopItem(response.data.shops[0]);
          }
        })
        break;
      default:
        break;
    }
  }

  handleChangeCheck({target}) {
    switch (this.props.kindOfItem) {
      case 'shop':
        const item = this.state.items.find(x => x.id.toString() === target.value);
        this.updateShopItem(item);
        break;
      default:
        break;
    }
  }

  updateShopItem(item) {
    this.props.handleChangeContent({target: {name: 'shop_id', value: item.id}})
    this.props.handleChangeContent({target: {name: 'name', value: item.name}})
    this.props.handleChangeContent({target: {name: 'address1', value: item.address1}})
    this.props.handleChangeContent({target: {name: 'address2', value: item.address2}})
    this.props.handleChangeContent({target: {name: 'fax', value: item.fax}})
    this.props.handleChangeContent({target: {name: 'tel', value: item.tel}})
    this.props.handleChangeContent({target: {name: 'zip3', value: item.zip3}})
    this.props.handleChangeContent({target: {name: 'zip4', value: item.zip4}})
    this.props.handleChangeContent({target: {name: 'image_url', value: item.image_url}})
    this.props.handleChangeContent({target: {name: 'map_flag', value: item.map_flag}})
    this.props.handleChangeContent({target: {name: 'shop_details', value: item.shop_details}})
  }

  render() {
    const {kindOfItem, itemChecked} = this.props
    return (
      <div className={`item ${kindOfItem}-cat`}>
        <p>店舗名<small className="required"> *</small></p>
        {
          this.state.items.map((item, index) => {
            return (
              <div className={`${kindOfItem}-checkbox`} key={`${kindOfItem}-${item.id}`}>
                <input name={`${kindOfItem}-name`} id={`${kindOfItem}-${item.id}`} defaultValue={item.id} type="radio"
                       onChange={this.handleChangeCheck}
                       defaultChecked={JSON.parse(itemChecked.contents)[`${kindOfItem}_id`] == item.id
                       || (!JSON.parse(itemChecked.contents)[`${kindOfItem}_id`] && index == 0)}/>
                <label htmlFor={`${kindOfItem}-${item.id}`}>{item.name}</label>
              </div>
            )
          })
        }

        {this.props.errorMessages[kindOfItem] && <div><small className="required">{this.props.errorMessages[kindOfItem]}</small></div>}

        {this.props.isShowDetail &&
        <button type="button" className="button-iframe" iframevalue={kindOfItem} data-remodal-target="partsModal"
                onClick={this.props.displayDetailData}>情報の追加・編集</button>
        }
      </div>
    )
  }
}
