import ModdablePart from '../ModdablePart';
import { IMAGES } from '../../../../../untils/constants'
import React, { Component } from 'react'

export default class ShopItems extends Component {
  constructor(props) {
    super(props);
    this.title = "企業・店舗情報デザイン"
  }

  componentDidMount() {
    this.props.handleData(
      this.handleItemData(this.props.itemChecked, this.props.shops)
    )
  }

  HandleSwitchComponent(key) {
    switch (key) {
      case 1:
        return <img src={ IMAGES.company1 } />
        break;
      case 2:
        return <img src={ IMAGES.company2 } />
        break;

      default:
        break;
    }
  }

  handleItemData(item, shopDatas) {
    if (shopDatas.length <= 0) return item
    let contents = JSON.parse(item.contents);
    if (contents.hasOwnProperty('shop_id')) return item

    const shopData = shopDatas[0]

    contents['shop_id'] = shopData.id
    contents['name'] =  shopData.name
    contents['address1'] =  shopData.address1
    contents['address2'] =  shopData.address2
    contents['fax'] =  shopData.fax
    contents['tel'] =  shopData.tel
    contents['zip3'] =  shopData.zip3
    contents['zip4'] =  shopData.zip4
    contents['image_url'] =  shopData.image_url
    contents['map_flag'] =  shopData.map_flag
    contents['shop_details'] =  shopData.shop_details
    
    item.contents = JSON.stringify(contents)
    return item
  }

  render() {
    return (
      <ModdablePart title={this.title}>
        <ul className="map_design no_select">
          {
            this.props.data.map((item, index) => {
              return (
                <li key={index}>
                  <input name="block_header_pattern" id={`block_header_pattern_${item.kind_of_design}`} value={item.kind_of_design} type="radio" />
                  <label
                    htmlFor={`block_header_pattern_${item.id}`}
                    onClick={ () => this.props.handleDoubleClickItem(this.handleItemData(item, this.props.shops)) }
                    style={{background: 'none 0% 0% / auto repeat scroll padding-box border-box rgb(255, 255, 255)', color: 'rgb(34, 34, 34)'}}
                    className={`block_header_label ${item.kind_of_design === this.props.itemChecked.kind_of_design ? 'item_active' : ''}`}
                  >
                    { this.HandleSwitchComponent(item.kind_of_design) }
                  </label>
                </li>
              )
            })
          }
        </ul>
      </ModdablePart>
    )
  }
}
