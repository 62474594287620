import React, { Component } from 'react'

export default class FileField extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const {item, remarks} = this.props
    return (
      <dl>
        <dt>{item.input_name}</dt>
        <dd>
          <input type="file" />
          <p className="cap">{remarks}</p>
        </dd>
      </dl>
    )
  }
}
