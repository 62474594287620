import React, { Component } from 'react'

export class Cssable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderStyle = this.renderStyle.bind(this);
  }

  renderStyle() {
    return {__html: this.props.css}
  }

  render() {
    return (
      <React.Fragment>
        { this.props.children }
        { this.props.css && <style type="text/css" dangerouslySetInnerHTML={this.renderStyle()}/> }
      </React.Fragment>
    )
  }
}

export default Cssable
