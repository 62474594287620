import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { APIS } from "../../../../../untils/constants";
import request from "../../../../../untils/request";

export class RecruitSelection extends Component {
  static propTypes = {
    recruits: PropTypes.array,
  };

  static defaultProps = {
    recruits: [],
  };

  constructor(props) {
    super(props);
    this.maxLength = 70
    this.state = {
      recruits: props.recruits
    };
  }

  formatContent(value) {
    if (value.length > this.maxLength) {
      value =  `${value.slice(0, this.maxLength)}...`
    }
    return value;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({recruits: nextProps.recruits})
  }

  async componentDidMount() {
    const { itemChecked, contents } = this.props;
    
    const { recruits } = this.state;
    if (contents.recruit_id === '' && recruits.length > 0) {
      contents.recruit_id = recruits[0].id

      itemChecked.contents = JSON.stringify(contents)

      this.props.handleData(itemChecked)
    }
  }

  render() {
    const { contents } = this.props;
    return (
      <div id="recruit_detail_area">
        <div className="item">
          <div className="linkinput">
            <span className="select" style={{width: '100%'}}>
              <select
                name="recruit_id"
                id="recruit_id"
                defaultValue={contents.recruit_id}
                style={{width: '100%'}}
                onChange={this.props.handleChangeContent}
              >
                {this.state.recruits.map((item, index) => {
                  return <option value={item.id} key={index}>{this.formatContent(item.job_title)}</option>
                })}
              </select>
            </span>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.recruits = storeState.recruits

  return newState;
}

export default connect(mapStateToProps)(RecruitSelection)
