import React, { Component } from 'react'

export class ModdablePart extends Component {

  render() {
    return (
      <div className="wrap">
        <h3><span>{this.props.title}</span></h3>
        <div className="sample item">
          { this.props.children }
        </div>
      </div>
    )
  }
}

export default ModdablePart
