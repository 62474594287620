import React, { Component } from 'react';
import { display_recruit_part } from '../../../../../../functions'

export class RecruitJobDescription extends Component {
  constructor(props) {
    super(props);
    this.fields = [
      'occupation_category',
      'job_description'
    ]
  }
  render() {
    const { recruit } = this.props;
    let is_display = display_recruit_part(recruit, this.fields)
    return (
      is_display && (
      <div className="detail">
        <div className="heading block_header_3 align-left">
          <h4 className="h">仕事内容</h4>
        </div>
        <div className="block_table_3 width_fixed align-left">
          <table>
            <tbody>
              { (recruit.occupation_category !== undefined && recruit.occupation_category !== '') && (
              <tr>
                <th>職種カテゴリ※</th>
                <td>{ recruit.occupation_category }</td>
              </tr>
              )}
              { (recruit.job_description !== undefined && recruit.job_description !== '') && (
              <tr>
                <th>仕事内容※</th>
                <td className="pre-wrap"> { recruit.job_description } </td>
              </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      )
    )
  }
}

export default RecruitJobDescription
