import React, {Component} from 'react';

export class Youtube extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="wrapper_item">
          <div className="youtube_block">
            <iframe className="lozad" src={`https://www.youtube.com/embed/${this.props.content.block_movie_text}`}/>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Youtube
