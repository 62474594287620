import React, { Component } from 'react';
import ModdablePart from '../ModdablePart';

export class HtmlItems extends Component {
  constructor(props) {
    super(props);
    this.title = 'HTML'
  }

  render() {
    const textareaPlaceHolder = '<div class="your_parts_class">\n    <p>テキストテキストテキストテキストテキスト</p>\n</div>'
    let {contents} = this.props.itemChecked
    contents = JSON.parse(contents)
    return (
      <ModdablePart title={this.title}>
        <textarea name="block_html_text"
          onKeyUp={this.props.handleChangeContent}
          placeholder={textareaPlaceHolder}
          className="form-control rightclickarea"
          rows="14"
          defaultValue={contents.block_html_text}
        />
      </ModdablePart>
    )
  }
}

export default HtmlItems
