import React, { useState, useEffect } from 'react';
import LayoutSetting from "../components/ViewEdit/LayoutSetting";
import { useDispatch } from "react-redux";
import request from "../untils/request";
import { APIS } from '../untils/constants'
import {
  headerSetting,
  pageStructureData,
  footerSetting,
  generalSetting,
  footerNav,
  pageStructureInSetting,
  homeStructure,
  savePageDataUi,
  saveImageUrl,
  saveReleaseDate,
  updateSetting,
  newpageCategories,
  questionCategories,
  menuCategories,
  contactOptions,
  saveSubNavigation,
  loadCoupons,
  staffCategories,
  galleryCategories,
  saveRecuits,
  recruitCategories,
  tagsList,
  loadAllPages,
  loadComparisonCategories,
  loadShopsData,
  saveSettingAdvance,
  loadComparisonData,
  localSideNavigations,
  setTenants,
  setClientId,
  savePageCategoryIds,
  newsCategories
} from "../Lib/redux/actions";

const SubNavigationPage = (props) => {
  const [statusPage] = useState('edit');
  const [isSubNavigation, setIsSubNavigation] = useState(true);

  const dispatch = useDispatch();

  useEffect(async () => {
    let settings = props.settings;
    if(props.facebook_flag) settings.facebook_flag = true
    if(props.twitter_flag) settings.twitter_flag = true
    dispatch(updateSetting(props.settings))
    dispatch(setClientId(props.client_id))
    const {data: { page_structure_data, page_structure_in_setting, link_for_embedded_parts }} = await request({
      method: 'post',
      url: APIS.get_home_structure,
      data: {newpage_id: props.settings.newpage_id}
    })

    const {data: { navigation_menu_header, navigation_menu_footer, start_page_id }} = await request({
      method: 'post',
      url: APIS.navigation_menu,
      data: { navigation_id: props.settings.navigation_id }
    })

    const {data: { header_setting_data }} = await request({
      method: 'post',
      url: APIS.navigation_header_setting,
      data: { navigation_id: props.settings.navigation_id }
    })

    const {data: { general_setting_data }} = await request({
      method: 'post',
      url: APIS.get_general_seting,
    })

    const {data: { footer_setting_data }} = await request({
      method: 'post',
      url: APIS.navigation_footer_setting,
      data: { navigation_id: props.settings.navigation_id }
    })
    const {data: {
      comparison_categories,
      newpage_categories,
      question_categories,
      menu_categories,
      gallery_categories,
      recruit_categories,
      news_categories
    }} = await request({
      method: 'post',
      data: {
        page_kind: 'blog',
        client_id: props.client_id
      },
      url: APIS.get_newpage_categories
    })

    const {data: { footer_routes }} = await request({
      method: 'post',
      url: APIS.get_common_footer_navigation,
      data: { client_id: props.client_id}
    })

    const {data: { contacts }} = await request({
      method: 'post',
      url: APIS.get_contacts_options,
      data: { client_id: props.client_id }
    })

    const {data: { coupons }} = await request({
      method: 'get',
      url: `${APIS.get_coupons}?client_id=${props.client_id}`
    })

    const {data: { staff_categories }} = await request({
      method: 'get',
      url: APIS.get_staff_categories,
    })

    const {data: { recruits }} = await request({
      method: 'get',
      url: `${APIS.get_recruits}?client_id=${props.client_id}`,
    })

    const tags_list = await request({
      method: 'get',
      url: `${APIS.get_tags}?newpage_id=${props.settings.newpage_id}&client_id=${props.client_id}`
    })

    const {data: { shops }} = await request({
      method: 'post',
      url: APIS.get_shops
    })

    const {data: { setting_advance }} = await request({
      method: 'get',
      url: `${APIS.get_setting_advanced}?client_id=${props.client_id}`
    })

    let headerMenu, footerMenu;

    if (start_page_id) {
      headerMenu = navigation_menu_header;
      footerMenu = navigation_menu_footer;
    } else {
      headerMenu = page_structure_data;
      footerMenu = footer_routes;
      setIsSubNavigation(false)
    }

    let {data: { all_pages }} = await request({
      method: 'get',
      url: APIS.get_all_page
    })

    const newpage_id = props.match.params.newpage_id
    all_pages = all_pages.filter(page => parseInt(page.id) !== parseInt(newpage_id));

    const {data: {categories, comparisons}} = await request({
      method: 'get',
      url: `${APIS.get_comparison_categories}?client_id=${props.client_id}`
    })

    const comparisonData = {
      categories: categories,
      comparisons: comparisons
    }


    if (props.page_kind == 'copy_template') {
      const {data: { tenants }} = await request({
        method: 'get',
        url: APIS.get_tenants
      })

      dispatch(setTenants(tenants))
    }

    dispatch(loadComparisonCategories(comparison_categories))
    dispatch(loadAllPages(all_pages))
    dispatch(pageStructureData(headerMenu))
    dispatch(pageStructureInSetting(page_structure_in_setting))
    dispatch(headerSetting(header_setting_data))
    dispatch(footerSetting(footer_setting_data))
    dispatch(generalSetting(general_setting_data))
    dispatch(footerNav(footerMenu))
    dispatch(homeStructure(link_for_embedded_parts))
    dispatch(savePageDataUi(props.templateData))
    dispatch(saveImageUrl(props.image_urls))
    dispatch(saveReleaseDate(props.release_date))
    dispatch(newpageCategories(newpage_categories))
    dispatch(questionCategories(question_categories))
    dispatch(menuCategories(menu_categories))
    dispatch(saveSubNavigation(props.sub_navigations))
    dispatch(contactOptions(contacts))
    dispatch(loadCoupons(coupons))
    dispatch(staffCategories(staff_categories))
    dispatch(galleryCategories(gallery_categories))
    dispatch(saveRecuits(recruits))
    dispatch(recruitCategories(recruit_categories))
    dispatch(tagsList({tags: tags_list, page_kind: props.page_kind}))
    dispatch(loadShopsData(shops))
    dispatch(saveSettingAdvance(setting_advance))
    dispatch(loadComparisonData(comparisonData))
    dispatch(localSideNavigations(props.local_side_navigations))
    dispatch(savePageCategoryIds(props.page_category_ids))
    dispatch(newsCategories(news_categories))
  }, [])

  return (
    <React.Fragment>
      <LayoutSetting pageKind={props.page_kind} isSubNavigationPage={isSubNavigation} pageStatus={statusPage}
                     pageCategoryIds={props.page_category_ids} facebookFlag={props.facebook_flag}
                     twitterFlag={props.twitter_flag} LpUrl={props.lp_url}/>
    </React.Fragment>
  )
}

export default SubNavigationPage;
