import React, { Component } from 'react'
import { connect } from 'react-redux';
import {isOpenImageSettingModal, saveBlockImage} from '../../../Lib/redux/actions';
import OptionUrl from '../Modal/OptionUrl'
export class OperatorImageSettingModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      settingData: [...props.data],
      index: props.index,
      homeStructure: props.homeStructure,
      positionChild: props.positionChild
    };
    this.showSettingModal = this.showSettingModal.bind(this);
    this.handleBlockSetting = this.handleBlockSetting.bind(this);
    this.handleChangeItem = this.handleChangeItem.bind(this);
    this.handleChangelinkType = this.handleChangelinkType.bind(this)
  }

  showSettingModal() {
    this.props.handleShowSettingModal(
      {isOpenImageSettingModal: !this.props.isOpenImageSettingModal, index: ''}
    )
  }

  handleChangeItem(event) {
    const { settingData, index } = this.state;
    let data = {...settingData[index]};
    let value = ""

    const contents = JSON.parse(data.contents)

    if(event.target.type == "checkbox") {
      value = event.target.checked ? true : false
    }
    else {
      value = event.target.value

      if (event.target.options !== undefined) {
        const page_id = event.target.options[event.target.selectedIndex].getAttribute('data_page_id');
        contents[event.target.getAttribute('data_button_page_id_key')] = page_id
      }
    }

    contents[event.target.name] = value
    data.contents = JSON.stringify(contents);
    settingData[index] = data;
    this.setState({settingData: settingData})
  }

  handleChangelinkType(event) {
    const { settingData, index } = this.state;
    let data = {...settingData[index]};
    let contents = JSON.parse(data.contents)
    let block_image_link_field = `block_image_link`
    let block_image_target = `block_image_target`;

    if (this.props.positionChild !== "") {
      block_image_target = `block_image${this.props.positionChild}_target`;
      block_image_link_field = `block_image${this.props.positionChild}_link`
    }
    contents[block_image_link_field] = event.target.value

    if (parseInt(event.target.value) === 3) {
      contents[block_image_target] = true
    }

    data.contents = JSON.stringify(contents);
    settingData[index] = data;
    this.setState({settingData: settingData})
  }

  handleBlockSetting() {
    this.props.handleShowSettingModal(
      {isOpenImageSettingModal: !this.props.isOpenImageSettingModal, index: this.props.index}
    )
    this.props.handleSaveSetting({data: this.state.settingData, pageStatus: this.props.pageStatus})
  }

  render() {
    const { settingData, index, positionChild } = this.state;
    const isNotBlockButton = (settingData[index].block_type !== "block_button")
    const contents = JSON.parse(settingData[index].contents)
    let block_image_link = `block_image_link`;
    let block_image_inner_link = `block_image_inner_link`;
    let block_image_outer_link = `block_image_outer_link`;
    let block_image_target = `block_image_target`;
    let button_page_id = `button_page_id`
    if (positionChild !== "") {
      block_image_link = `block_image${positionChild}_link`;
      block_image_inner_link = `block_image${positionChild}_inner_link`;
      block_image_outer_link = `block_image${positionChild}_outer_link`;
      block_image_target = `block_image${positionChild}_target`;
      button_page_id = `button${positionChild}_page_id`
    }
    let loadClass = this.props.isRequesting ? 'load' : 'loaded';

    return (
      <div id="setting_modal" data-step className={`active admin_edit_link ${loadClass}`}>
        <div className="load">
          <div />
          <div className="loader" />
        </div>
        <div className="wrapper">
          <div id="contents" data-type="admin_edit_link">
            <p className="modal_header"><i>リンク設定</i>
              <span className="modal_header_btn cancel first_button" onClick={this.showSettingModal}>キャンセル</span>
              <span className="modal_header_btn ok" onClick={this.handleBlockSetting}>決定</span>
            </p>
            <div id="content_r">
              <div className="wrap">
                <h3><span>リンク</span></h3>
                <div className="item">
                  <input name={block_image_link} id="link1" type="radio" defaultChecked={contents[`${block_image_link}`] == 1 } onChange={this.handleChangelinkType} value={1}/>
                  {
                    isNotBlockButton &&
                    <React.Fragment>
                      <label htmlFor="link1">無し</label>
                      <br />
                    </React.Fragment>
                  }
                  <input name="block_image_" id="link4" type="radio" />

                  <input name={block_image_link} id="link2" type="radio" defaultChecked={contents[`${block_image_link}`] == 2 } onChange={this.handleChangelinkType} value={2}/>
                  <label htmlFor="link2">ページを選択</label><br />
                  <span className="select">
                    <select disabled={contents[block_image_link] != 2} data_button_page_id_key={button_page_id} name={block_image_inner_link} defaultValue={contents[block_image_inner_link]} onChange={this.handleChangeItem}>
                      <OptionUrl />
                    </select>
                  </span>
                  <br />
                  <input name={block_image_link} id="link3" type="radio" defaultChecked={contents[`${block_image_link}`] == 3} onChange={this.handleChangelinkType} value={3}/>
                  <label htmlFor="link3">任意で設定</label>
                  <input disabled = {contents[block_image_link] != 3} name={block_image_outer_link} placeholder="URL" defaultValue={contents[`${block_image_outer_link}`]} type="text" onChange={this.handleChangeItem}/>
                  {
                    contents[`${block_image_link}`] == 3 &&
                    <p>常に別ウインドウで開きます。</p>
                  }
                  <input type="hidden" name="target" defaultValue={0} />
                  <input name={block_image_target} id="target" type="checkbox"
                         onChange={this.handleChangeItem}
                         checked={contents[block_image_target]}
                         disabled={contents[block_image_link] == 3}
                  />
                  <label htmlFor="target">別ウインドウ(別タブ)で開く</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);

  newState.isOpenImageSettingModal = storeState.isOpenImageSettingModal
  newState.index = storeState.index;
  newState.positionChild = storeState.positionChild;
  newState.isRequesting = storeState.isRequesting;
  newState.data = ownProps.pageStatus == "commonFooter" ?  storeState.asideData : storeState.pageDataUi;
  newState.homeStructure = storeState.homeStructure

  return newState;
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleShowSettingModal: (payload) => {dispatch(isOpenImageSettingModal(payload))},
    handleSaveSetting: (payload) => {dispatch(saveBlockImage(payload))}
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(OperatorImageSettingModal)
