import React, { Component } from 'react'

export default class WidthClassConfigable extends Component {
  constructor(props) {
    super(props)
  }

  resizeComponent(index) {
    this.props.handeChangeCssSetting(index, "width_class")
    this.props.handleDisableAllComponent()
  };

  render() {
    const {content, index} = this.props
    if(content.width_class == "width_full") {
      return(
        <li className="admin_edit_menu_w 457634" title="コンテンツの幅" onClick={() => this.resizeComponent(index)}>
        <i className="material-icons full" style={{display: 'none'}}><font style={{verticalAlign: 'inherit'}}>
          <font style={{verticalAlign: 'inherit'}}>fullscreen</font></font></i><i className="material-icons fix" style={{display: 'flex'}}>
            <font style={{verticalAlign: 'inherit'}}>
              <font style={{verticalAlign: 'inherit'}}>fullscreen_exit</font>
            </font>
        </i>
      </li>
      )
    }
    return(
      <li className="admin_edit_menu_w 457935" title="コンテンツの幅" onClick={() => this.resizeComponent(index)}>
        <i className="material-icons full" style={{display: 'flex'}}>fullscreen</i>
        <i className="material-icons fix" style={{display: 'none'}}>fullscreen_exit</i>
      </li>
    )
  }
}
