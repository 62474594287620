import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCog} from "@fortawesome/free-solid-svg-icons";
import EditParallaxImageModal from "../Parts/Modal/EditParallax/EditParallaxImageModal";
import {connect} from "react-redux";
import ContentEditable from 'react-contenteditable';
import {updateSetting} from "../../Lib/redux/actions";
import { IS_PARALLAX, IMAGES } from '../../untils/constants';

class EditParallaxImage extends Component {
  static propTypes = {
    isOpenEditParallax: PropTypes.bool,
    isEdit: PropTypes.bool
  };

  static defaultProps = {
    isOpenEditParallax: false,
    isEdit: true
  };

  constructor(props) {
    super(props);
    this.state = {
      settings: {...this.props.settings},
      isEntryTitleEdit: false
    }
    this.contentEditable = React.createRef()
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeAlign = this.handleChangeAlign.bind(this)
    this.handleDisplayAlign = this.handleDisplayAlign.bind(this)
    this.handleOnBlurDisplayAlign = this.handleOnBlurDisplayAlign.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      settings: nextProps.settings
    })
  }

  handleChange(event) {
    const { settings } = this.state;
    settings['entry_title'] = event.target.value;
    this.setState({ settings }, () => { this.props.processSetting(this.state.settings) });
  }


  handleChangeAlign(field, value) {
    const { settings } = this.state;

    settings[field] = value;
    this.setState({ settings }, () => { this.props.processSetting(this.state.settings) } );
  }

  handleDisplayAlign() {
    this.setState({ isEntryTitleEdit: !this.setState.isEntryTitleEdit })
  }

  handleOnBlurDisplayAlign(field) {
    this.setState({ isEntryTitleEdit: false })
  }

  render() {
    const { settings } = this.state;
    const { isEdit } = this.props;
    if (this.props.h_d_mv_flag === false) {
      return (<div className='responsive-menu'/>);
    }

    let parallax_class = ''

    if (settings.parallax == IS_PARALLAX) {
      parallax_class = 'parallax'
    }

    let mv_image_url = IMAGES.no_image
    if (this.props.mv_image_url !== '') {
      mv_image_url = this.props.mv_image_url
    } else if (this.props.generalSetting != undefined && this.props.generalSetting.entry_image !== '') {
      mv_image_url = this.props.generalSetting.entry_image
    }
    let editable = "contenteditable"
    editable += this.state.isEntryTitleEdit ? " active" : ""

    return (
      <div id="lv_outer" className="edit_outer">
        <React.Fragment>
          {isEdit && <ul className="admin_edit_menu">
            <li className="admin_edit_menu_setting" title="パーツ設定">
              <i className="" onClick={() => this.props.handleShowModalEditParallax()}>
                <FontAwesomeIcon icon={faCog} />
              </i>
            </li>
            <li className="bm"><i className="material-icons">dehaze</i></li>
          </ul>}
          <div id="lv" className={`${this.props.settings.blur} ${parallax_class}`}>
            <div className="parallax_img"
                 style={{backgroundImage: `url(${mv_image_url})`}} />
            <p
              className={ `${editable} ${settings.entry_title_class} ` }
              onFocus={() => this.handleDisplayAlign()}
              onBlur={() => this.handleOnBlurDisplayAlign()}
            >
              <ContentEditable
                innerRef={this.contentEditable}
                html={this.props.settings.entry_title} // innerHTML of the editable div
                disabled={false}       // use true to disable editing
                onChange={this.handleChange} // handle innerHTML change
                tagName='span' // Use a custom HTML tag (uses a div by default)
              />
            </p>
          </div>
        </React.Fragment>
        {
          this.props.isOpenEditParallax &&
          <EditParallaxImageModal handleShowModalEditParallax={this.props.handleShowModalEditParallax} />
        }
      </div>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);

  newState.headerSetting = storeState.headerSetting;
  newState.generalSetting = storeState.generalSetting;
  newState.settings = storeState.settings;
  newState.h_d_mv_flag = storeState.settings.h_d_mv_flag;
  newState.mv_image_url = storeState.slider_images.mv_image_url;

  return newState;
}

const mapDispatchToProps = (dispatch) => {
  return {
    processSetting: (data) => {dispatch(updateSetting(data))},
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditParallaxImage);
