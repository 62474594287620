import React, { Component } from 'react'
import ConfigablePart from "../ConfigablePart";
import PropTypes from 'prop-types';
import { APIS, S3_URL, HTML_REGEX } from "../../../../../untils/constants";
import request from "../../../../../untils/request";
import HeadlineComponent from '../HeadlineComponent';
import ExplanationComponent from '../ExplanationComponent';
import FavoriteModal from "../../FavoriteModal";
import { comparisonPopup } from '../../../../../functions';
import LabelComponent from "../../EmbeddedParts/LabelComponent"
import { parallaxScroll } from '../../../../../functions';
import { connect } from 'react-redux';

class LoadableMenuPart extends Component {
  constructor(props) {
    super(props)
    this.showListMenu = this.showListMenu.bind(this)
    this.headlineNone = "1"
    this.headlineFull = "2"
    this.state = { menus: {} }
  }

  static propTypes = {
    isEdit: PropTypes.bool,
    isOpenFavoriteModal: PropTypes.bool,
    isFavorite: PropTypes.bool
  };

  static defaultProps = {
    isEdit: true,
    isOpenFavoriteModal: false,
    isFavorite: false
  };

  async componentDidMount () {
    var bodyFormData = new FormData()
    bodyFormData.append('menu_categories', JSON.stringify(this.props.item.categories))
    bodyFormData.append('limit', this.props.content.menu_number)
    await request({
      method: 'get',
      url: `${APIS.get_menus}?client_id=${this.props.clientId}`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then(response => {
      this.setState({menus: response.data.menus})
    })
    parallaxScroll();
    comparisonPopup(this.props.pageStatus, S3_URL)
  }

  componentDidUpdate() {
    comparisonPopup(this.props.pageStatus, S3_URL)
  }

  showListMenu() {
    const categories = this.props.item.categories
    const {menus} = this.state
    const {content, generalSetting} = this.props
    const popup_flag = this.props.item.popup_flag
    return(
      Object.keys(menus).map((key, index) => {
        const category_id = menus[key].category_id
        if(categories.indexOf(category_id) > -1) {
          const menu_number = this.props.content.menu_number
          let menu_contents = menus[key].menu
          if(menu_contents.length > 0 && menu_number > 0) {
            menu_contents = menu_contents.slice(0, menu_number)
          }
          let image_1_url = "#!"
          let image_2_url = "#!"
          let image_3_url = "#!"
          if(generalSetting != undefined){
            image_1_url = generalSetting.popup_flag === 0 ? menus[key].image_1_url : "#!"
            image_2_url = generalSetting.popup_flag === 0 ? menus[key].image_2_url : "#!"
            image_3_url = generalSetting.popup_flag === 0 ? menus[key].image_3_url : "#!"
          } else {
            image_1_url = popup_flag === 0 ? menus[key].image_1_url : "#!"
            image_2_url = popup_flag === 0 ? menus[key].image_2_url : "#!"
            image_3_url = popup_flag === 0 ? menus[key].image_3_url : "#!"
          }
          return (
            <div key={index} className="inner_item">
              { content.display_category == 2 &&
                <React.Fragment>
                  <div className="heading block_header_2">
                    <h3 className="h">{menus[key].category_name}</h3>
                    <p>{menus[key].category_alias}</p>
                  </div>
                  <div className="category_img pop">
                    { menus[key].image_1_url !== null &&
                      <a href={image_1_url} style={{textDecorationColor: 'rgba(34, 34, 34, 0.3)'}}><img src={menus[key].image_1_url} /></a>
                    }
                    { menus[key].image_2_url !== null &&
                      <a href={image_2_url} style={{textDecorationColor: 'rgba(34, 34, 34, 0.3)'}}><img src={menus[key].image_2_url} /></a>
                    }
                    { menus[key].image_3_url !== null &&
                      <a href={image_3_url} style={{textDecorationColor: 'rgba(34, 34, 34, 0.3)'}}><img src={menus[key].image_3_url} /></a>
                    }
                  </div>
                  <div className="mbx pre-wrap">
                    {menus[key].category_content}
                  </div>
                </React.Fragment>
              }
              <ul className="pop">
                {
                  menu_contents.map((item, i) => {
                    let menu_image_url = "#!"
                    if(generalSetting != undefined){
                      menu_image_url = generalSetting.popup_flag === 0 ? item.menu_image_url : "#!"
                    } else {
                      menu_image_url = popup_flag === 0 ? item.menu_image_url : "#!"
                    }
                    return(
                      <li key={i}>
                        { item.menu_image_url !== null &&
                          <div className="inner_item_img">
                            <a href={menu_image_url} style={{textDecorationColor: 'rgba(34, 34, 34, 0.3)'}}>
                              <img src={item.menu_image_url} />
                            </a>
                          </div>
                        }
                        <div className="inner_item_txt">
                          <dl>
                            <dt>{item.name}</dt>
                            <dd className='pre-wrap'>
                              {item.content}
                            </dd>
                          </dl>
                          <p className="price">{item.price_with_tax}</p>
                        </div>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          )
        }
      })
    )
  }
  render() {
    const {content, isEdit, handleChangeHeadline, handleChangeAlign, item, indexBlockData, index, data, sectionStyle} = this.props;
    let style_short_content = ""
    switch (content.menu_other3) {
      case "1":
        style_short_content = "cotents_hide"
        break;
      case "2":
        style_short_content = "cotents_hide_sp"
        break;
      default:
        break;
    }
    return (
      <section key={item.kind_of_design} id={item.kind_of_design} className={`widget_menu01 align-left block_menu_1 add_design${item.kind_of_design} ${content.parallax != 1 ? "parallax" : ""} delay3 ${style_short_content} ${content.width_class} blockarea ${content.top_padding_class}  ${content.bottom_padding_class} edit_outer without delay4Active editing block_menu delay3Active`}
        style={sectionStyle}>
        {this.props.isEdit && <ConfigablePart {...this.props} />}
        {
          content.parallax != 1 &&
          <div className="parallax-wrap">
            <div className="parallax_img" style={{backgroundImage: `url(${content.background_image_url})`, height: '100%', transform: 'translateY(-50%)'}} />
          </div>
        }
        <div className="content_wrapper">
          {
            content.headlineType != this.headlineNone &&
            <HeadlineComponent content={content} index={index}
                              isEdit={isEdit}
                              handleChangeHeadline={handleChangeHeadline}
                              handleChangeAlign={handleChangeAlign} />
          }
          {
            content.headlineType == this.headlineFull &&
            <ExplanationComponent content={content} index={index}
                                  isEdit={isEdit}
                                  handleChangeHeadline={handleChangeHeadline}
                                  handleChangeAlign={handleChangeAlign} />
          }
          <div className="items">
            {
              this.showListMenu()
            }
          </div>
          {
            content.button_label.trim() != "" &&
            <LabelComponent value={content.button_label}
              index={index} {...this.props} isEdit={false} url={content.menu_link_type == 1 ? content.menu_link_1 : content.menu_link_2}
              link_type={content.menu_link_type}/>
          }
        </div>
        {
          this.props.isOpenFavoriteModal && indexBlockData !== null &&
          <FavoriteModal {...this.props} content={data[indexBlockData]} />
        }
      </section>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.generalSetting = storeState.generalSetting
  newState.clientId = storeState.clientId;
  return newState
}

export default connect(mapStateToProps)(LoadableMenuPart)
