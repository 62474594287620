import React, { Component } from 'react'
import { display_recruit_part } from '../../../../../../functions'

export class RecruitDepartment extends Component {
  constructor(props) {
    super(props);
    this.departmant_fields = [
      'assigned_department',
      'assigned_department_details'
    ];

    this.employee_fields = [
      'employment_status',
      'proposal_for_dispatch_to_be_introduced',
      'trial_period'
    ]
  }
  render() {
    const { recruit } = this.props;
    let is_display_department = display_recruit_part(recruit, this.departmant_fields)
    let is_display_employee = display_recruit_part(recruit, this.employee_fields)
    return (
      <React.Fragment>
        {is_display_department && (
        <div className="detail">
          <div className="heading block_header_3 align-left">
            <h4 className="h">配属部署</h4>
          </div>
          <div className="block_table_3 width_fixed align-left">
            <table>
              <tbody>
                { (recruit.assigned_department !== undefined && recruit.assigned_department !== '') && (
                <tr>
                  <th>配属部署</th>
                  <td>{ recruit.assigned_department }</td>
                </tr>
                )}
                { (recruit.assigned_department_details !== undefined && recruit.assigned_department_details !== '') && (
                <tr>
                  <th>配属部署詳細</th>
                  <td className="pre-wrap">{ recruit.assigned_department_details }</td>
                </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        )}

        {is_display_employee && (
        <div className="detail">
          <div className="heading block_header_3 align-left">
            <h4 className="h">雇用形態</h4>
          </div>
          <div className="block_table_3 width_fixed align-left">
            <table>
              <tbody>
                { (recruit.employment_status !== undefined && recruit.employment_status !== '') && (
                <tr>
                  <th>雇用形態</th>
                  <td>{ recruit.employment_status }</td>
                </tr>
                )}
                { (recruit.proposal_for_dispatch_to_be_introduced !== undefined && recruit.proposal_for_dispatch_to_be_introduced !== '') && (
                <tr>
                  <th>紹介予定派遣の提案</th>
                  <td>{ recruit.proposal_for_dispatch_to_be_introduced }</td>
                </tr>
                )}
                { (recruit.trial_period !== undefined && recruit.trial_period !== '') && (
                <tr>
                  <th>試用期間</th>
                  <td>{ recruit.trial_period }</td>
                </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        )}
      </React.Fragment>
    )
  }
}

export default RecruitDepartment
