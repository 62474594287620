import React, { Component } from 'react';
import CustomSlider from './CustomSlider';
import MvTypeSlider from './MvTypeSlider';
import MvTypeMovie from './MvTypeMovie';

export class ConfigSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  renderSettingSlider() {
    const { settings } = this.props
    return (
      <div className="mvoriginal mvoriginal_1">
        <div className="item">
          <p>MV位置固定</p>
          <input type="radio"
            name="mv_position"
            value="1"
            id="pages-mv-pos_1"
            defaultChecked={ settings.mv_position == 1 ? 'checked' : '' }
            onChange={this.props.handleChange}
          />
          <label htmlFor="pages-mv-pos_1" className="radio-inline">固定しない</label>
          <input type="radio" name="mv_position" value="2" id="pages-mv-pos_2" defaultChecked={ settings.mv_position == 2 ? 'checked' : '' } onChange={this.props.handleChange} />
          <label htmlFor="pages-mv-pos_2" className="radio-inline">固定する</label>
          <p><small>デザインによっては独自cssの調整が必要な場合があります。</small></p>
        </div>
        <div className="item">
          <p>MVタイプ</p>
          <input type="radio" name="mv_type" value="1" id="pages-mv-type_1" defaultChecked={ settings.mv_type == 1 ? 'checked' : '' } onChange={this.props.handleChange} />
          <label htmlFor="pages-mv-type_1" className="radio-inline">スライド</label>
          <input type="radio" name="mv_type" value="2" id="pages-mv-type_2" defaultChecked={ settings.mv_type == 2 ? 'checked' : '' } onChange={this.props.handleChange} />
          <label htmlFor="pages-mv-type_2" className="radio-inline">Youtube</label>
        </div>
        
        {settings.mv_type == 1 ? <MvTypeSlider {...this.props} /> : <MvTypeMovie {...this.props} />}
        
        <div className="item">
          <p>スマートフォンでの見出しテキスト位置</p>
          <input type="radio" name="entry_text_sp" value="1" id="pages-entry-text-sp_0" defaultChecked={ settings.entry_text_sp == 1 ? 'checked' : '' } onChange={this.props.handleChange} />
          <label htmlFor="pages-entry-text-sp_0" className="radio-inline">MV下に移動</label>
          <input type="radio" name="entry_text_sp" value="2" id="pages-entry-text-sp_1" defaultChecked={ settings.entry_text_sp == 2 ? 'checked' : '' } onChange={this.props.handleChange} />
          <label htmlFor="pages-entry-text-sp_1" className="radio-inline">MV上に表示</label>
          <input type="radio" name="entry_text_sp" value="0" id="pages-entry-text-sp_2" defaultChecked={ settings.entry_text_sp == 0 ? 'checked' : '' } onChange={this.props.handleChange} />
          <label htmlFor="pages-entry-text-sp_2" className="radio-inline">非表示</label>
        </div>
        <div className="item">
          <p>文字組み</p>
          <input type="radio" name="character_set" value="0" id="pages-character-set_0" defaultChecked={ settings.character_set == 0 ? 'checked' : '' } onChange={this.props.handleChange} />
          <label htmlFor="pages-character-set_0" className="radio-inline">横書き</label>
          <input type="radio" name="character_set" value="1" id="pages-character-set_1" defaultChecked={ settings.character_set == 1 ? 'checked' : '' } onChange={this.props.handleChange} />
          <label htmlFor="pages-character-set_1" className="radio-inline">縦書き</label>
        </div>
      </div>
    )
  }

  render() {
    const { settings } = this.props
    return (
      <div className="step" data-step="#block_mv" style={{display: 'block', width: '33.3333%'}}>
        <div className="wrap">
          <h3><span>MV設定</span></h3>
          <div className="item">
            <p>MV仕様</p>
            <input type="radio"
              name="mv_original"
              value="1"
              id="pages-mv-original_1"
              defaultChecked={ settings.mv_original == 1 ? 'checked' : '' }
              onChange={this.props.handleChange}
            />
            <label htmlFor="pages-mv-original_1" className="radio-inline">機能を使う</label>
            <input type="radio"
              name="mv_original"
              value="2"
              id="pages-mv-original_2"
              defaultChecked={settings.mv_original == 2 ? 'checked' : ''}
              onChange={this.props.handleChange}
            />
            <label htmlFor="pages-mv-original_2" className="radio-inline">オリジナルで作成する</label>
          </div>
          { settings.mv_original == 1 ? this.renderSettingSlider() : <CustomSlider {...this.props} /> }
        </div>
      
        { settings.mv_original == 1 && settings.mv_type == 1 && <div className="modal_footer" style={{display: 'flex'}}><a href="#" data-step="1" onClick={this.props.handleScreen}>画像設定へ »</a></div> }
      </div>
    )
  }
}

export default ConfigSlider
