import React, { Component } from 'react';
import { IMAGES } from '../../../untils/constants'


export class ShareSnsModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id="setting_modal" data-step="#block_header" className="active admin_edit_menu_add loaded">
        <div className="modal-wrapper" onClick={() => this.props.handleShowModalShareSns()}></div>
        <div id="contents" style={{zIndex: '9999', width: 'auto'}}>
          <div className="modal_header">
            <h4 className="modal-title text-center">SNS投稿</h4>
            <span className="modal_header_btn cancel first_button" onClick={() => this.props.handleShowModalShareSns()}>キャンセル</span>
          </div>
          <div className="modal_content">
            {
              this.props.settings.facebook_flag &&
              <span className="fb-share"
                      onClick={() => this.props.handleOpenDialog(`https://www.facebook.com/sharer/sharer.php?u=${this.props.endUserUrl}`)}>
                <img className="img"
                     style={{verticalAlign: 'middle'}}
                     src="https://static.xx.fbcdn.net/rsrc.php/v3/y4/r/ps3LEjFUMch.png"
                     alt="" width="16" height="16" />  シェア
              </span>
            }

            {
              this.props.settings.twitter_flag &&
              <a className="twitter-share" target="_blank"
                href={`https://twitter.com/intent/tweet?text=${this.props.settings.title} ${this.props.endUserUrl}`}
                data-size="large">
                <img src={ IMAGES.twitter_icon } />  ツイート
              </a>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default ShareSnsModal
