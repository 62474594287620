require('datatables.net-bs4');

$( document ).ready(function() {
  if($('[id^=navigations_datatable_wrapper]').length == 0 && $('#navigations_datatable').length > 0) {
    table = $('#navigations_datatable').DataTable({
      dom: "<'row'<'col-6'l><'#navigation_btn_add.col-6 text-right'>>" +
        "<'row'<'#navigation_btn_batch_delete.col-sm-12'>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'float-right mb-3'p>",
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        emptyTable: "サブナビゲーションは登録されておりません",
        zeroRecords: "サブナビゲーションは登録されておりません",
        lengthMenu: "_MENU_ を表示する",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        }
      },
      order: [],
      columnDefs: [
        {
          targets: 0,
          orderable: false,
          width: "10%"
        },
        {
          targets: 1,
          width: "80%"
        },
        {
          targets: 2,
          orderable: false,
          width: "10%"
        }
      ],
      drawCallback: function(settings) {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);

        reload_checkbox_all_state();
      },
      stateSave: true
    });

    $('#navigation_btn_add').append(`<a href='${$('#navigations_datatable').data("add-new-link")}' class='btn btn-info'>新規追加</a>`);
    $('#navigation_btn_batch_delete').append( "<input type='submit' id='btn-batch-delete-navigations' value='一括削除' class='btn btn-info my-3' data-confirm='削除後のデータは復旧できません。よろしいですか？' data-disable-with='一括削除' />" );
  } else {
    localStorage.removeItem('checked_navigations')
  }

  $('#btn-batch-delete-navigations').on('click', function(event) {
    if ($('.checkbox_navigation:checked').length == 0) {
      event.preventDefault();
      alert('チェックボックスが選択されていません');
      return false;
    } else {
      let deleteItem = []
      $('.checkbox_navigation:checked').each(function() {
        deleteItem.push($(this).val());
      });
      $('#navigations_delete').val(deleteItem);
    }
  });

  $('#navigations_check_all').on('click', function() {
    if (this.checked) {
      $('.checkbox_navigation').not(':disabled').prop('checked', true);
    } else {
      $('.checkbox_navigation').not(':disabled').prop('checked', false);
    }
    $('.checkbox_navigation').first().trigger('change');
  });

  $('#navigations_datatable').on('click', '.checkbox_navigation', function() {
    number_of_checkbox_unchecked = $('.checkbox_navigation:not(:checked)').length;
    if (number_of_checkbox_unchecked > 0) {
      $('#navigations_check_all').prop('checked', false);
    } else {
      $('#navigations_check_all').prop('checked', true);
    }
  });

  $('.checkbox_navigation').on('change', function() {
    var checked_navigations = String(localStorage.getItem('checked_navigations')).split(',');

    $('.checkbox_navigation').each(function() {
      var index = checked_navigations.indexOf($(this).val());
      if (index !== -1) checked_navigations.splice(index, 1);
    });

    $('.checkbox_navigation:checked').each(function() {
      checked_navigations.push($(this).val());
    });

    localStorage.setItem('checked_navigations', checked_navigations.join(','));
  })

  const maxLength = 50;
  $('select#navigation_newpage_id > option').text(function(i, text) {
    if (text.length > maxLength) {
      return text.substr(0, maxLength) + '...';
    }
  });
});

function reload_checkbox_all_state() {
  String(localStorage.getItem('checked_navigations')).split(',').forEach(function(value) {
    $('.checkbox_navigation[value=' + value + ']').prop('checked', true);
  });

  number_of_checkbox_enable = $('.checkbox_navigation').not(':disabled').length;
  number_of_checkbox_unchecked = $('.checkbox_navigation:not(:checked)').not(':disabled').length;

  if (number_of_checkbox_enable == 0 || number_of_checkbox_unchecked > 0) {
    $('#navigations_check_all').prop('checked', false);
  } else {
    $('#navigations_check_all').prop('checked', true);
  }
}
