import React, { Component } from 'react';
import ModdablePart from '../ModdablePart';
import HeadingComponent from './HeadingComponent';

export class HeadingItems extends Component {
  constructor(props) {
    super(props);
    this.title = '見出しデザイン'
  }

  HandleSwitchComponent(key, content) {
    return <HeadingComponent content={content} isEdit={false} headlineType="2" type={key} isDynamicClass={false} />
  }

  render() {
    return (
      <ModdablePart title={this.title}>
        <ul className="heading_design no_select">
          {
            this.props.data.map((item, index) => {
              let content = JSON.parse(item.contents)
              content.headline = content.headline1
              content.subheadline = content.subheadline1
              return (
                <li key={index}>
                  <input name="block_header_pattern" id={`block_header_pattern_${item.kind_of_design}`} value={item.kind_of_design} type="radio" />
                  <label
                    htmlFor={`block_header_pattern_${item.id}`}
                    onClick={ () => this.props.handleDoubleClickItem(item) }
                    style={{background: 'none 0% 0% / auto repeat scroll padding-box border-box rgb(255, 255, 255)', color: 'rgb(34, 34, 34)'}}
                    className={`block_header_label ${item.kind_of_design === this.props.itemChecked.kind_of_design ? 'item_active' : ''}`}
                  >
                      { this.HandleSwitchComponent(item.kind_of_design, content) }
                  </label>
                </li>
              )
            })
          }
        </ul>
      </ModdablePart>
    )
  }
}

export default HeadingItems
