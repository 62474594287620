import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mvFunc } from '../../../functions';

export class MvImageComponent extends Component {
  componentDidUpdate(prevProps, prevState) {
    mvFunc(this.props.slide_speed)
  }

  render() {
    const { settings, slider_images } = this.props;
    return (
      <ul className="mv_img">
        {[1, 2, 3, 4, 5, 6, 7].map((item, index) => {
          let namePosition = `image0${item}_position`
          let nameUrl = `mv_image0${item}_url`
          if (slider_images[nameUrl] === '') {
            return false
          }
          return (<li className={`show ${settings[namePosition]}`} key={index}>
            <img src={slider_images[nameUrl]} alt=""/>
          </li>)
        })}
        
      </ul>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  
  newState.settings = storeState.settings;
  newState.slider_images = storeState.slider_images;

  return newState;
}

export default connect(mapStateToProps)(MvImageComponent)
