export function validateTel(value) {
  if (value.length == 0) {
    return "電話番号を入力してください。";
  } else if (value.length > 16) {
    return "電話番号に16文字以下で入力してください。";
  } else if (!/^[0-9\-!"\#$%&'()*+,\-./:;<=>?@\[\]\^_`{|}~]*$/.test(value)) {
    return "電話番号は半角数字で入力してください。";
  } else {
    return null;
  }
}

export function validateMap(value) {
  if (value.length == 0) {
    return "住所を入力してください。";
  } else {
    return null;
  }
}

export function validateRequired(value, field) {
  if (value === undefined || value.length == 0) {
    return `${field}を選択してください`;
  } else {
    return null;
  }
}

export function validateUniqueValue(value, listValue, field) {
  if (listValue.includes(value.toLowerCase())) {
    return `ご入力いただいた${field}は既に使用されています。他の${field}を使用してください。`;
  } else {
    return null;
  }
}

export function validateTextFieldRequired(value, field) {
  if (value.length == 0) {
    return `${field}を入力してください。`;
  } else {
    return null;
  }
}

export function validateMovie(value) {
  if (value.length == 0) {
    return "YouTubeIDを入力してください。";
  } else {
    return null;
  }
}
