import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConfigablePart from "../ConfigablePart";
import { connect } from 'react-redux';
import HeadlineComponent from '../HeadlineComponent';
import {isOpenImageModal, isOpenImageSettingModal} from '../../../../../Lib/redux/actions';
import HeadingPattern2Component from '../BlockImageText2/HeadingPattern2Component';
import ImageBlock from '../BlockImageText2/ImageBlock';
import ContentComponent from '../ContentComponent';
import ExplanationComponent from '../ExplanationComponent';
import { IMAGES } from '../../../../../untils/constants';
import { parallaxScroll } from '../../../../../functions';
import { ResizeCompositex } from '../../../../../functions';

class LoadableImageText2Part extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    isVisitOuterLink: PropTypes.bool,
    idString: PropTypes.string,
  };

  static defaultProps = {
    isEdit: true,
    isVisitOuterLink: false,
    idString: null,
  };

  constructor(props) {
    super(props);
    this.state = {block_image_text2_other: props.item.block_image_text2_other}
    this.blockType = 'block_image_text2'
    this.headlineNone = "1"
    this.headlineFull = "2"
    this.noneImage = IMAGES.no_image
    this.showOperatorImageModal = this.showOperatorImageModal.bind(this);
    this.showBlockImgSettingModal = this.showBlockImgSettingModal.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      block_image_text2_other: nextProps.item.block_image_text2_other
    })
  }

  componentDidMount() {
    parallaxScroll();
    if (this.props.isEdit) { ResizeCompositex(); }
  }

  showOperatorImageModal = () => {
    this.props.handleShowImageModal(
      {isOpenImageModal: !this.props.isOpenImageModal, index: this.props.index}
    )
  };

  showBlockImgSettingModal = () => {
    this.props.handleShowSetting(
      { isOpenBlockImgSettingModal: !this.props.isOpenBlockImgSettingModal, index: this.props.index }
    )
  }

  render() {
    const { content, kindOfDesign, isEdit, item, data, index, handleChangeHeadline, handleChangeAlign, sectionStyle } = this.props;
    const block_image1_url = item.block_image1_url.length == 0 ? this.noneImage : item.block_image1_url
    const block_image2_url = item.block_image2_url.length == 0 ? this.noneImage : item.block_image2_url
    const block_image3_url = item.block_image3_url.length == 0 ? this.noneImage : item.block_image3_url
    const block_image4_url = item.block_image4_url.length == 0 ? this.noneImage : item.block_image4_url
    const block_image0_url = item.block_image0_url.length == 0 ? this.noneImage : item.block_image0_url
    return (
      <section key={kindOfDesign} id={kindOfDesign}
               className={`composite_box01 align-left block_images_${kindOfDesign} delay2 ${content.width_class} ${content.parallax != 1 ? "parallax" : ""} blockarea ${content.top_padding_class}  ${content.bottom_padding_class} edit_outer delay4Active editing block_image_text2 delay3Active ${isEdit ? '' : 'on_favorite_page'}`}
               style={sectionStyle}>
        {isEdit && <ConfigablePart {...this.props} />}
        {
          content.parallax != 1 &&
          <div className="parallax-wrap">
            <div className="parallax_img" style={{backgroundImage: `url(${content.background_image_url})`, height: '100%', transform: 'translateY(-50%)'}} />
          </div>
        }
        <div className="content_wrapper">
          {
            content.headlineType != this.headlineNone &&
            <HeadlineComponent content={content} index={index}
                               isEdit={isEdit}
                               handleChangeHeadline={handleChangeHeadline}
                               handleChangeAlign={handleChangeAlign} />
          }
          {
            content.headlineType == this.headlineFull &&
            <ExplanationComponent content={content} index={index}
                                  isEdit={isEdit}
                                  handleChangeHeadline={handleChangeHeadline}
                                  handleChangeAlign={handleChangeAlign} />
          }
          <div className="wrapper_item">
            {
              [...Array(parseInt(this.state.block_image_text2_other))].map((el, i) => {
                return (
                  <ImageBlock key={i} index={index} block_image_url={eval(`block_image${i}_url`)} block_image_alt={content[`block_image${i}_alt`]} content={content} positionChild={i}
                                     isEdit={isEdit}
                                     blockImageText2Other={this.state.block_image_text2_other}
                                     kindOfDesign={kindOfDesign}
                                     showBlockImgSettingModal={this.showBlockImgSettingModal}
                                     showOperatorImageModal={this.showOperatorImageModal}
                                     handleChangeText={this.props.handleChangeText}
                                     handleChangeHeadline={handleChangeHeadline}
                                     handleChangeAlign={handleChangeAlign} isVisitOuterLink={this.props.isVisitOuterLink}
                                     idString={this.props.idString} />
                )
              })
            }
          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.isOpenImageModal = storeState.isOpenImageModal
  newState.isOpenBlockImgSettingModal = storeState.isOpenBlockImgSettingModal

  return newState;
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleShowImageModal: (data) => {dispatch(isOpenImageModal(data))},
    handleShowSetting: (data) => {dispatch(isOpenBlockImgSettingModal(data))}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadableImageText2Part)
