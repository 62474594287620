import React, { Component } from 'react'

export class AdvancedSettings extends Component {
  render() {
    return (
      <div className="step">
        <div className="wrap">
          <h3><span>上級者設定</span></h3>
          <div className="item">
            <p>独自CSS</p>
            <p className="caution">※設定内容によってはサイト表示が大幅に崩れる事があります、ご注意ください。</p>
            <textarea name="css" onChange={this.props.handleChange} value={this.props.settings.css} className="form-control" placeholder="" rows="20"/>
          </div>
          <div className="item">
            <p>独自JS</p>
            <p className="caution">※設定内容によってはサイト表示が大幅に崩れる事があります、ご注意ください。</p>
            <textarea name="js" onChange={this.props.handleChange} value={this.props.settings.js} className="form-control" placeholder="" rows="20"/>
          </div>
          <div className="item">
            <p>独自 &lt;head&gt; タグ内容</p>
            <p className="caution">※設定内容によってはサイト表示が大幅に崩れる事があります、ご注意ください。</p>
            <textarea name="head_content" onChange={this.props.handleChange} value={this.props.settings.head_content} className="form-control" placeholder="" rows="20"/>
          </div>
        </div>
      </div>
    )
  }
}

export default AdvancedSettings
