import React from "react";
import PropTypes from "prop-types";
import { Provider } from 'react-redux';
import Store from './Lib/redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AddPage from './Pages/AddPage';
import EditPage from './Pages/EditPage';
import SubNavigationPage from './Pages/SubNavigationPage';
import CommonFooterPage from './Pages/CommonFooterPage';
import FavoritePage from './Pages/favorite/FavoritePage';
import AuthorityContainer from './components/Parts/Auth/Authority';
import "@fortawesome/fontawesome-svg-core/styles.css";
import 'react-toastify/dist/ReactToastify.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { interceptor } from './untils/request';
import Loading from "./components/Layouts/Loading"

interceptor(Store)

class App extends React.Component {
  render () {
    const { settings, page_kind } = this.props;

    return (
      <Provider store={Store}>
        <AuthorityContainer page_kind={page_kind} settings={settings}>
          <BrowserRouter>
            <Switch>
              <Route path='/frame/add' name='home' render={props => ( <AddPage {...props} {...this.props} /> )}/>
              <Route
                path='/frame/edit/:newpage_id'
                name='Edit'
                render={props => ( settings.navigation_id === '' || settings.navigation_id === null ? <EditPage {...props} {...this.props} /> : <SubNavigationPage {...props} {...this.props} /> )}/>
              <Route path='/frame/common/footer' name='Common Footer' render={props => ( <CommonFooterPage {...props} {...this.props} /> )}/>
              <Route path='/frame/favorite/:id' name='Favorite' render={props => ( <FavoritePage {...props} {...this.props} /> )}/>
              <Route
                path='/frame/copy/template/:template_id'
                name='Copy Template'
                render={props => ( settings.navigation_id === '' || settings.navigation_id === null ? <EditPage {...props} {...this.props} /> : <SubNavigationPage {...props} {...this.props} /> )}/>
            </Switch>
            <Loading />
          </BrowserRouter>
        </AuthorityContainer>
      </Provider>
    );
  }
}

App.propTypes = {
  greeting: PropTypes.string
};

export default App
