import React, { Component } from 'react';
import { connect } from 'react-redux';
import request from '../../../../untils/request';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faSave } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
import { APIS } from '../../../../untils/constants';
import { updateErrorMessages } from '../../../../Lib/redux/actions';

export class Configable extends Component {
  constructor (props) {
    super(props);
    this.handleForm = this.handleForm.bind(this)
    this.handleFormCopyTemplate = this.handleFormCopyTemplate.bind(this)
    this.handleDataOfPage = this.handleDataOfPage.bind(this)
    this.renderButtonSave = this.renderButtonSave.bind(this)
  }

  async handleForm() {
    let formData = this.props.settings;
    let dataUi = this.props.dataUi;

    await request.post(APIS.footer_save, {
      data: {form_data: formData, data_ui: dataUi},
      headers: {'Content-Type': 'application/json'}
    }).then(res => {
      toast.info("共通フッターを編集しました。", {
        position: toast.POSITION.TOP_RIGHT
      });
    }).catch(e => {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })
  }

  async handleFormCopyTemplate() {
    let formData = this.props.settings;
    let dataUi = this.props.pageDataUi;
    formData.facebook_flag = false
    formData.ameblo_flag = false
    formData.twitter_flag = false
    formData.display_flag = false
    formData.slug_plus = `/${formData.slug}`
    formData.parent_id = null

    await request.post(APIS.copy_template, {
      data: {
        form_data: formData,
        data_ui: dataUi,
        page_kind: 'template',
        display_flag: true,
        tag_names: [],
      },
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      toast.info("テンプレートを複製しました。", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        onClose: () => {
          window.parent.postMessage({
            'func': 'parentFunc',
            'message': "/tenants/templates"
          }, "*");
        }
      });
    }).catch(e => {
      if(e.response.status === 422) {
        let errorMessage = this.props.settings.errorMessages
        let toastMessage = '入力内容をご確認ください'
        for (const key in errorMessage) {
          if(e.response.data.message.hasOwnProperty('no_content')){
            toastMessage = e.response.data.message['no_content']
          }

          if(e.response.data.message.hasOwnProperty(key)) {
            errorMessage[key] = e.response.data.message[key]
          } else {
            errorMessage[key] = null
          }
        }

        this.props.updateErrorMessages(errorMessage)
        toast.error(toastMessage, {
          position: toast.POSITION.TOP_RIGHT
        });
      } else {
        toast.error(e.response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    })
  }

  handleDataOfPage() {
    const { pageKind } = this.props;
    if (pageKind === 'copy_template') {
      this.handleFormCopyTemplate()
    } else {
      this.handleForm()
    }
  }

  renderButtonSave() {
    if (this.props.isNewpageAdd && this.props.pageKind !== 'copy_template') {
      return (
        <i className=" save" onClick={() => this.props.handleShowModalConfirm()}>
          <FontAwesomeIcon icon={faSave} />
        </i>
      )
    } else {
      return (
        <i className=" save" onClick={this.handleDataOfPage}>
          <FontAwesomeIcon icon={faSave} />
        </i>
      )
    }
  }

  render() {
    const { isNewpageAdd } = this.props;
    return (
      <div className="">
        {
          isNewpageAdd &&
          <span className="extended_settings" title="Page setup" onClick={() => this.props.handleShowModal()}>
            <i className="">
              <FontAwesomeIcon icon={faCog} />
            </i>
          </span>
        }

        {
          !isNewpageAdd &&
          <span className="extended_settings" title="Page setup">
            <i className="">
              <FontAwesomeIcon icon={faCog} className='awesome-icon-disabled' />
            </i>
          </span>
        }

        <div className="admin_edit_window">
          {this.renderButtonSave()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.settings = storeState.settings
  newState.dataUi = storeState.asideData
  newState.pageDataUi = storeState.pageDataUi;
  newState.newpageTags = storeState.newpageTags;
  return newState
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateErrorMessages: (data) => {dispatch(updateErrorMessages(data))}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Configable)
