import React, { Component } from 'react'
import { connect } from 'react-redux';
import {isOpenImageModal, saveBlockImage, isCssSetting} from '../../../Lib/redux/actions';
import ChooseImage from './EditParallax/ChooseImage'
import ImageAdditional from '../../../components/Parts/Modal/ImageAdditional'
import ImageBackgroundSetting from '../../Parts/Modal/ImageBackgroundSetting'

export class OperatorImageModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [ ...this.props.data ],
      index: this.props.index,
      isOpenImageAdditional: false,
      positionChild: this.props.positionChild
    };
    this.handleImage = this.handleImage.bind(this)
    this.removeImage = this.removeImage.bind(this)
    this.showOperatorImageModal = this.showOperatorImageModal.bind(this)
    this.saveBlockImage = this.saveBlockImage.bind(this)
    this.showImageSetting = this.showImageSetting.bind(this)
    this.changeAlt = this.changeAlt.bind(this)
    this.handleDisplaySetting = this.handleDisplaySetting.bind(this)
    this.showAdditionalSetting = this.showAdditionalSetting.bind(this)
    this.handleChangeContent = this.handleChangeContent.bind(this)
  }

  handleImage(event) {
    const { data, index, positionChild } = this.state;
    const newData = {...data[index]}
    const contents = JSON.parse(data[this.state.index].contents)
    if(this.props.isCssSetting) {
      contents.background_image_id = event.target.dataset.id
      contents.background_image_url = event.target.style.backgroundImage.slice(4, -1).replace(/"/g, "");
    }
    else {
      let block_image_id_field = `block_image_id`
      let block_image_url_field = `block_image_url`
      if (positionChild !== "") {
        block_image_id_field = `block_image${positionChild}_id`
        block_image_url_field = `block_image${positionChild}_url`
      }
      newData[block_image_id_field] = event.target.dataset.id
      newData[block_image_url_field] = event.target.style.backgroundImage.slice(4, -1).replace(/"/g, "");
    }
    newData.contents = JSON.stringify(contents)
    data[index] = newData
    this.setState({ data });
  }

  saveBlockImage() {
    this.props.handleShowImageModal({isOpenImageModal: !this.props.isOpenImageModal, index: this.props.index, positionChild: this.props.positionChild })
    this.props.handleSaveBlockImage({data: this.state.data, pageStatus: this.props.pageStatus})
    this.props.handleCssSetting(false)
  }

  removeImage() {
    const { data, index, positionChild } = this.state;
    const newData = {...data[index]}
    if(this.props.isCssSetting) {
      const contents = JSON.parse(data[this.state.index].contents)
      contents.background_image_id = ""
      contents.background_image_url = ""
      newData.contents = JSON.stringify(contents)
    }
    else {
      let block_image_id_field = `block_image_id`
      let block_image_url_field = `block_image_url`
      if (positionChild !== "") {
        block_image_id_field = `block_image${positionChild}_id`
        block_image_url_field = `block_image${positionChild}_url`
      }
      newData[block_image_id_field] = ''
      newData[block_image_url_field] = ''
    }
    data[index] = newData
    this.setState({ data });
  }

  changeAlt(event) {
    const {data, index, positionChild} = this.state
    let newData = {...data[index]}
    const contents = JSON.parse(data[this.state.index].contents)

    let block_image_alt_field = `block_image_alt`
    if (positionChild !== "") {
      block_image_alt_field = `block_image${positionChild}_alt`
    }
    contents[block_image_alt_field] = event.target.value
    newData.contents = JSON.stringify(contents)
    data[index] = newData
    this.setState({data: data})
  }

  handleChangeContent(event) {
    const {data, index} = this.state
    let newData = {...data[index]}
    const contents = JSON.parse(data[this.state.index].contents)
    contents[event.target.name] = event.target.value
    newData.contents = JSON.stringify(contents)
    data[index] = newData
    this.setState({data: data})
  }

  showOperatorImageModal = () => {
    this.props.handleShowImageModal(
      {isOpenImageModal: !this.props.isOpenImageModal, index: '', positionChild: ''}
    )
    this.props.handleCssSetting(false)
  };

  handleDisplaySetting () {
    this.setState({
      isOpenImageAdditional: !this.state.isOpenImageAdditional
    })
  }

  showImageSetting() {
    const { data, index, positionChild } = this.state;
    const {isCssSetting} = this.props
    if (this.state.isOpenImageAdditional) {
      return this.showAdditionalSetting()
    }
    const contents = JSON.parse(data[index].contents)
    const mv_image_id = isCssSetting ? contents.background_image_id : data[index].block_image_id
    return(
      <ChooseImage handleImage={this.handleImage}
        removeImage={this.removeImage}
        isHome={false}
        isBlockImage={true}
        mv_image_id={mv_image_id}
        mv_image01_id={data[index].block_image0_id}
        mv_image02_id={data[index].block_image1_id}
        mv_image03_id={data[index].block_image2_id}
        mv_image04_id={data[index].block_image3_id}
        mv_image05_id={data[index].block_image4_id}
        positionChild={this.props.positionChild}
        handleDisplaySetting={this.handleDisplaySetting}
        isOpenImageAdditional={this.state.isOpenImageAdditional} />
    )
  }

  showAdditionalSetting() {
    const { data, index, positionChild } = this.state;
    let block_image_alt_field = `block_image_alt`
    if (positionChild !== "") {
      block_image_alt_field = `block_image${positionChild}_alt`
    }
    const contents = JSON.parse(data[index].contents)
    if(this.props.isCssSetting) {
      return(
        <ImageBackgroundSetting content={contents} isOpenImageAdditional={this.state.isOpenImageAdditional}
        handleDisplaySetting={this.handleDisplaySetting}
        handleChangeContent={this.handleChangeContent}/>
      )
    }
    return(
      <ImageAdditional changeAlt={this.changeAlt}
        value={contents[block_image_alt_field]}
        name={"alt"}
        handleDisplaySetting={this.handleDisplaySetting}
        isOpenImageAdditional={this.state.isOpenImageAdditional} />
    )
  }

  render() {
    let loadClass = this.props.isRequesting ? 'load' : 'loaded';
    return (
      <div id="setting_modal" data-step className={`active admin_edit_menu_img ${loadClass}`}>
        <div className="load">
          <div />
          <div className="loader" />
        </div>
        <div className="wrapper">
          <div id="contents" data-type="admin_edit_menu_img">
            <p className="modal_header"><i>画像設定</i>
            <span className="modal_header_btn cancel first_button" onClick={this.showOperatorImageModal}>キャンセル</span>
            <span className="modal_header_btn ok" onClick={this.saveBlockImage}>決定</span></p>
            <div className="stepset">
              <div className="step_inner" style={{width: '200%', marginLeft: 0}}>
                {this.showImageSetting()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.isOpenImageModal = storeState.isOpenImageModal
  newState.index = storeState.index;
  newState.positionChild = storeState.positionChild;
  newState.isCssSetting = storeState.isCssSetting;
  newState.isRequesting = storeState.isRequesting;
  newState.data = ownProps.pageStatus == "commonFooter" ?  storeState.asideData : storeState.pageDataUi;
  return newState;
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleShowImageModal: (data) => {dispatch(isOpenImageModal(data))},
    handleSaveBlockImage: (data) => {dispatch(saveBlockImage(data))},
    handleCssSetting: (data) => {dispatch(isCssSetting(data))}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OperatorImageModal)
