import React, { Component } from 'react';
import ModdablePart from '../ModdablePart';
import { IMAGES } from '../../../../../untils/constants';
import { resetCategories } from '../../../../../functions/resetCategories'

export class NewsItems extends Component {
  constructor(props) {
    super(props);
    this.title = '新着情報デザイン'
  }

  componentDidMount() {
    const newData = resetCategories(this.props.itemChecked, this.props.newsCategories)
    this.props.handleData(newData)
  }

  render() {
    return (
      <ModdablePart title={this.title}>
        <ul className="no_select">
          {
            this.props.data.map((item, index) => {
              let content = JSON.parse(item.contents)
              return (
                <li key={index}>
                  <input name="block_news_pattern" id={`block_news_pattern_${item.kind_of_design}`} value={item.kind_of_design} type="radio"
                    defaultChecked={this.props.itemChecked.kind_of_design == item.kind_of_design} />
                  <label htmlFor={`block_news_pattern_${item.kind_of_design}`} 
                         onClick={ () => this.props.handleDoubleClickItem(resetCategories(item, this.props.newsCategories)) }>
                    <img src={IMAGES[item.src]} alt="" />
                  </label>
                </li>
              )
            })
          }
        </ul>
      </ModdablePart>
    )
  }
}

export default NewsItems
