import React, { Component } from 'react'
import PropTypes from 'prop-types';

export default class CategoryOptions extends Component {
  constructor(props) {
    super(props)
  }

  static propTypes = {
    isShowDetail: PropTypes.bool,
    errorMessages: PropTypes.object,
    title: PropTypes.string,
    isRequired: PropTypes.bool
  };

  static defaultProps = {
    isShowDetail: false,
    errorMessages: {},
    title: "カテゴリー",
    isRequired: true
  };

  render() {
    const {categories, kindOfItem, itemChecked, isRequired} = this.props;
    return (
      <div className={`item ${kindOfItem}-cat`}>
        <p>
          { kindOfItem === 'coupon' ? 'クーポン' : this.props.title }
          { isRequired &&
            <small className="required"> *</small>
          }
        </p>
        {
          categories.map((item) => {
            const checked = itemChecked.categories.indexOf(item.id) > -1
            return(
              <div className={`${kindOfItem}-checkbox`} key={`${kindOfItem}-${item.id}`}>
                <input name={`${kindOfItem}-name`} id={`${kindOfItem}-${item.id}`} defaultValue={item.id} type="checkbox"
                  checked={checked} onChange={this.props.handleChangeCategory}/>
                <label htmlFor={`${kindOfItem}-${item.id}`}>{item.name}</label>
              </div>
            )
          })
        }
        {this.props.errorMessages[kindOfItem] && <div><small className="required">{this.props.errorMessages[kindOfItem]}</small></div>}
        <button type="button" className={`${kindOfItem}_button-allcheck`} cat={`${kindOfItem}`}
          onClick={() => this.props.onClickCheckAll(`${kindOfItem}Categories`)}>
          すべてのカテゴリーを選択する
        </button>
        { this.props.isShowDetail && 
          <button type="button" className="button-iframe" iframevalue={`${kindOfItem}`} data-remodal-target="partsModal"
            onClick={this.props.displayDetailData}>情報の追加・編集</button>
        }
      </div>
    )
  }
}
