import React, { Component } from 'react';
import { IMAGES } from '../../../../untils/constants'

export class ImageSetup extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { settings } = this.props;
    let mv_image_url = IMAGES.no_image
    if ( this.props.mv_image_url !== '' ) {
      mv_image_url = this.props.mv_image_url
    }

    return (
      <div className="step" style={{width: '100%'}}>
        <div className="wrap" style={{overflow: 'inherit'}}>
          <h3><span>画像設定</span></h3>
          <div className="item mvimg lv">
            <div className="img" onClick={this.props.handleTabSetting}>
              <img id="lv_image01" data-step="1" src={mv_image_url} alt="" />
              <span className="admin_edit_img" onClick={this.props.handleTabSetting} data-step="1">
                <i className="material-icons" data-step="1">collections</i>
              </span>
            </div>
          </div>
          <div className="item">
            <p>パララックス</p>
            <span className="select">
              <select name="parallax" defaultValue={settings.parallax} onChange={this.props.handleChange}>
                <option value="1">しない</option>
                <option value="2">する</option>
              </select>
            </span>
          </div>
          <div className="item">
            <p>ぼかし効果（ぼかし半径）</p>
            <span className="select">
              <select name="blur" defaultValue={settings.blur} onChange={this.props.handleChange}>
                <option value="blur0">なし</option>
                <option value="blur5">5px</option>
                <option value="blur10">10px</option>
                <option value="blur20">20px</option>
                <option value="blur30">30px</option>
              </select>
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default ImageSetup
