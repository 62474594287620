require('datatables.net-bs4');

$( document ).ready(function() {
  if($('[id^=seo_content_datatable_wrapper]').length == 0 && $('#seo_content_datatable').length > 0) {
    table = $('#seo_content_datatable').DataTable({
      dom: "<'row'<'col-6'l>>" +
           "<'row'<'col-sm-12'tr>>" +
           "<'float-right mb-3'p>",
      "ordering": false,
      "autoWidth": true,
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        emptyTable: "コンテンツは登録されておりません",
        zeroRecords: "コンテンツは登録されておりません",
        lengthMenu: "_MENU_ を表示する  ",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        }
      },
      drawCallback: function() {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);

        if($('.release_time').length > 0) {
          $('.release_time').each(function(index) {
            let date = new Date($(this).html());
            date = date.toLocaleString('ja-JP', { year: 'numeric', month: "numeric",
                                                  day: "numeric", hour: "numeric",
                                                  minute: "numeric" })
            $(this).html(date)
          })
        }
      },
      stateSave: true
    });
  }

  if($('[id^=seo_keyword_datatable_wrapper]').length == 0 && $('#seo_keyword_datatable').length > 0) {
    table = $('#seo_keyword_datatable').DataTable({
      dom: "<'row'<'col-6'l>>" +
           "<'row'<'col-sm-12'tr>>" +
           "<'float-right mb-3'p>",
      "ordering": false,
      "autoWidth": true,
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        emptyTable: "キーワードは登録されておりません",
        zeroRecords: "キーワードは登録されておりません",
        lengthMenu: "_MENU_ を表示する  ",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        }
      },
      drawCallback: function() {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);

        if($('.release_time').length > 0) {
          $('.release_time').each(function(index) {
            let date = new Date($(this).html());
            date = date.toLocaleString('ja-JP', { year: 'numeric', month: "numeric",
                                                  day: "numeric", hour: "numeric",
                                                  minute: "numeric" })
            $(this).html(date)
          })
        }
      },
      stateSave: true
    });
  }
});
