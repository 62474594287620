import React, {Component} from 'react';
import { connect } from 'react-redux';
import request from '../../untils/request';
import { APIS } from '../../untils/constants';
import { staffCategories, saveSettingIdString, saveRecuits, setClientId } from '../../Lib/redux/actions';
import Cssable from '../../components/Parts/Css/Cssable';

import LoadableHeadingPart from '../../components/Parts/Modal/EmbeddedParts/BlockHeading/LoadableHeadingPart';
import LoadableLinePart from '../../components/Parts/Modal/EmbeddedParts/BlockLine/LoadableLinePart';
import styles from './Favorite.module.css';
import LoadableMapPart from '../../components/Parts/Modal/EmbeddedParts/BlockMap/LoadableMapPart';
import LoadableMoviePart from '../../components/Parts/Modal/EmbeddedParts/BlockMovie/LoadableMoviePart';
import LoadableTelPart from '../../components/Parts/Modal/EmbeddedParts/BlockTel/LoadableTelPart';
import LoadableTextPart from '../../components/Parts/Modal/EmbeddedParts/BlockText/LoadableTextPart';
import LoadableImageTextPart from '../../components/Parts/Modal/EmbeddedParts/BlockImageText/LoadableImageTextPart';
import LoadableImageText2Part from '../../components/Parts/Modal/EmbeddedParts/BlockImageText2/LoadableImageText2Part';
import LoadableImagePart from '../../components/Parts/Modal/EmbeddedParts/BlockImage/LoadableImagePart';
import LoadableTablePart from '../../components/Parts/Modal/EmbeddedParts/BlockTable/LoadableTablePart';
import LoadableBlogPart from '../../components/Parts/Modal/EmbeddedParts/BlockBlog/LoadableBlogPart';
import LoadableLinkPart from '../../components/Parts/Modal/EmbeddedParts/BlockLink/LoadableLinkPart'
import LoadableQuestionPart from '../../components/Parts/Modal/EmbeddedParts/BlockQuestion/LoadableQuestionPart'
import LoadableNewsPart from '../../components/Parts/Modal/EmbeddedParts/BlockNews/LoadableNewsPart'
import LoadableMenuPart from '../../components/Parts/Modal/EmbeddedParts/BlockMenu/LoadableMenuPart'
import LoadableGalleryPart from '../../components/Parts/Modal/EmbeddedParts/BlockGallery/LoadableGalleryPart'
import LoadableHtmlPart from '../../components/Parts/Modal/EmbeddedParts/BlockHtml/LoadableHtmlPart'
import LoadableCouponPart from '../../components/Parts/Modal/EmbeddedParts/BlockCoupon/LoadableCouponPart'
import LoadableButtonPart from '../../components/Parts/Modal/EmbeddedParts/BlockButton/LoadableButtonPart'
import LoadableComparisonPart from '../../components/Parts/Modal/EmbeddedParts/BlockComparison/LoadableComparisonPart'
import LoadableFormPart from '../../components/Parts/Modal/EmbeddedParts/BlockForm/LoadableFormPart'
import LoadableShopPart from "../../components/Parts/Modal/EmbeddedParts/BlockShop/LoadableShopPart";
import LoadableStaffPart from "../../components/Parts/Modal/EmbeddedParts/BlockStaff/LoadableStaffPart";
import LoadableConversationPart from "../../components/Parts/Modal/EmbeddedParts/BlockConversation/LoadableConversationPart";
import LoadableRecruitPart from "../../components/Parts/Modal/EmbeddedParts/BlockRecruit/LoadableRecruitPart";
import LoadableChangeLogPart from '../../components/Parts/Modal/EmbeddedParts/BlockChangeLog/LoadableChangeLogPart';
import {fetchSectionStyle} from '../../functions/fetchSectionStyle'

export class FavoriteDetailComponent extends Component {
  constructor(props) {
    super(props);
    this.showFavorite = this.showFavorite.bind(this)
  }

  componentWillMount() {
    this.props.setClientId(this.props.clientId)
  }

  async componentDidMount () {
    await request({
      method: 'get',
      url: APIS.get_staff_categories
    }).then(response => {
      this.props.handleStaffCategories(response.data.staff_categories)
    })

    await request({
      method: 'get',
      url: APIS.get_recruits
    }).then(response => {
      this.props.saveRecuits(response.data.recruits)
    })

    this.props.handleIstring(this.props.idString)
  }

  handleSwitchComponents(content, item) {
    const sectionStyle = fetchSectionStyle(content)
    const { idString } = this.props

    switch (item.block_type) {
      case 'block_header':
        return <LoadableHeadingPart content={content} kindOfDesign={item.kind_of_design} isEdit={false} sectionStyle={sectionStyle}/>
        break;
      case 'block_movie':
        return <LoadableMoviePart content={content} kindOfDesign={item.kind_of_design} isEdit={false} sectionStyle={sectionStyle}/>
        break;
      case 'block_map':
        return <LoadableMapPart content={content} kindOfDesign={item.kind_of_design} isEdit={false} sectionStyle={sectionStyle}/>
        break;
      case 'block_text':
        return <LoadableTextPart content={content} kindOfDesign={item.kind_of_design} isEdit={false} sectionStyle={sectionStyle}/>
        break;
      case 'block_line':
        return <LoadableLinePart content={content} kindOfDesign={item.kind_of_design} isEdit={false} sectionStyle={sectionStyle}/>
        break;
      case 'block_image_text':
        return <LoadableImageTextPart idString={idString} isVisitOuterLink={true} content={content} kindOfDesign={item.kind_of_design} isEdit={false} item={item} sectionStyle={sectionStyle} />
        break;
      case 'block_image_text2':
        return <LoadableImageText2Part idString={idString} isVisitOuterLink={true} content={content} kindOfDesign={item.kind_of_design} item={item} sectionStyle={sectionStyle} isEdit={false} />
        break;
      case 'block_tel':
        return <LoadableTelPart content={content} kindOfDesign={item.kind_of_design} isEdit={false} item={item} sectionStyle={sectionStyle} />
        break;
      case 'block_shop':
        return <LoadableShopPart content={content} kindOfDesign={item.kind_of_design} isEdit={false} item={item} sectionStyle={sectionStyle} />
        break;
      case 'block_conversation':
        return <LoadableConversationPart idString={idString} content={content} kindOfDesign={item.kind_of_design} isEdit={false} item={item} sectionStyle={sectionStyle} />
        break;
      case 'block_image':
        return <LoadableImagePart idString={idString} isVisitOuterLink={true} content={content} isEdit={false} item={item} sectionStyle={sectionStyle} />
        break;
      case 'block_table':
        return <LoadableTablePart content={content} kindOfDesign={item.kind_of_design} isEdit={false} sectionStyle={sectionStyle} />
        break;
      case 'block_blog':
        return <LoadableBlogPart content={content} isEdit={false} item={item} sectionStyle={sectionStyle} isFavorite={true} />
        break;
      case 'block_link':
        return <LoadableLinkPart content={content} isEdit={false} item={item} sectionStyle={sectionStyle} isFavorite={true} />
        break;
      case 'block_question':
        return <LoadableQuestionPart content={content} isEdit={false} item={this.props.favorite} isFavorite={true} sectionStyle={sectionStyle} />
        break;
      case 'block_menu':
        return <LoadableMenuPart content={content} isEdit={false} item={this.props.favorite} isFavorite={true} sectionStyle={sectionStyle} />
        break;
      case 'block_gallery':
        return <LoadableGalleryPart content={content} isEdit={false} item={this.props.favorite} sectionStyle={sectionStyle} isFavorite={true} />
        break;
      case 'block_button':
        return <LoadableButtonPart idString={idString} content={content} kindOfDesign={item.kind_of_design} isEdit={false} item={item} sectionStyle={sectionStyle} />
        break;
      case 'block_comparison':
        return <LoadableComparisonPart idString={idString} content={content} kindOfDesign={item.kind_of_design} isEdit={false} item={item} sectionStyle={sectionStyle} />
        break;
      case 'block_form':
        return <LoadableFormPart content={content} kindOfDesign={item.kind_of_design} isEdit={false} item={item} sectionStyle={sectionStyle} isFavorite={true} />
        break;
      case 'block_news':
        return <LoadableNewsPart idString={idString} content={content} kindOfDesign={item.kind_of_design} isEdit={false} item={item} sectionStyle={sectionStyle} isFavorite={true} />
        break;
      case 'block_coupon':
        return <LoadableCouponPart idString={idString} content={content} kindOfDesign={item.kind_of_design} isEdit={false} item={item} sectionStyle={sectionStyle} />
        break;
      case 'block_staff':
        return <LoadableStaffPart content={content} isEdit={false} item={item} sectionStyle={sectionStyle} isFavorite={true} />
        break;
      case 'block_recruit':
        return <LoadableRecruitPart content={content} isEdit={false} item={item} sectionStyle={sectionStyle} isFavorite={true} />
        break;
      case 'block_html':
        return <LoadableHtmlPart idString={idString} content={content} isEdit={false} item={item} sectionStyle={sectionStyle} />
        break;
      case 'block_change_log':
        return <LoadableChangeLogPart content={content} isEdit={false} item={item} sectionStyle={sectionStyle} />;
        break;
      default:
        break;
    }
  }

  showFavorite() {
    const {favorite} = this.props;
    const content = favorite == null ? {} : JSON.parse(favorite.contents)
    if(Object.keys(content).length === 0) {
      return(
        <>
          このページが見つかりません
        </>
      )
    }

    return (
      <Cssable css={favorite.css}>
        {this.handleSwitchComponents(content, favorite)}
      </Cssable>
    )
  }

  render() {
    return (
      <div className="row">
        <main className={styles.main}>
          <br/>
          <div id="main" className={`${styles.w100} favoriteDetail`}>
            {this.showFavorite()}
          </div>
        </main>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleStaffCategories: (data) => {dispatch(staffCategories(data))},
    handleIstring: (id_string) => {dispatch(saveSettingIdString(id_string))},
    saveRecuits: (recruits) => {dispatch(saveRecuits(recruits))},
    setClientId: (client_id) => {dispatch(setClientId(client_id))}
  }
}

export default connect(null, mapDispatchToProps)(FavoriteDetailComponent)
