// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Favorite-module__main___30COh {\n  min-width: 320px;\n\tz-index: 1;\n\tposition: relative;\n\tbackground: #fff;\n\tjustify-content: start;\n}\n\n.Favorite-module__w100___31QIj {\n\twidth: 100%;\n}\n", "",{"version":3,"sources":["Favorite.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;CACjB,UAAU;CACV,kBAAkB;CAClB,gBAAgB;CAChB,sBAAsB;AACvB;;AAEA;CACC,WAAW;AACZ","file":"Favorite.module.css","sourcesContent":[".main {\n  min-width: 320px;\n\tz-index: 1;\n\tposition: relative;\n\tbackground: #fff;\n\tjustify-content: start;\n}\n\n.w100 {\n\twidth: 100%;\n}\n"]}]);
// Exports
exports.locals = {
	"main": "Favorite-module__main___30COh",
	"w100": "Favorite-module__w100___31QIj"
};
module.exports = exports;
