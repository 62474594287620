import React, { Component } from 'react';
import ModdablePart from '../ModdablePart';
import { IMAGES } from '../../../../../untils/constants';

export class TableItems extends Component {
  constructor(props) {
    super(props);
    this.title = 'テーブルデザイン'
  }

  HandleSwitchComponent(key) {
    switch (key) {
      case 1:
        return <img src={IMAGES.block_table_1} />
        break;
      case 2:
        return <img src={IMAGES.block_table_2} />
        break;
      case 3:
        return <img src={IMAGES.block_table_3} />
        break;
      case 5:
        return <img src={IMAGES.block_table_5} />
        break;
      case 6:
        return <img src={IMAGES.block_table_6} />
        break;
      case 7:
        return <img src={IMAGES.block_table_7} />
        break;
      case 8:
        return <img src={IMAGES.block_table_8} />
        break;

      default:
        break;
    }
  }

  render() {
    return (
      <ModdablePart title={this.title}>
        <ul className="map_design no_select">
          {
            this.props.data.map((item, index) => {
              return (
                <li key={index}>
                  <input name="block_header_pattern" id={`block_header_pattern_${item.kind_of_design}`} value={item.kind_of_design} type="radio" />
                  <label
                    htmlFor={`block_header_pattern_${item.id}`}
                    onClick={ () => this.props.handleDoubleClickItem(item) }
                    style={{background: 'none 0% 0% / auto repeat scroll padding-box border-box rgb(255, 255, 255)', color: 'rgb(34, 34, 34)'}}
                    className={`block_header_label ${item.kind_of_design === this.props.itemChecked.kind_of_design ? 'item_active' : ''}`}
                  >
                    { this.HandleSwitchComponent(item.kind_of_design) }
                  </label>
                </li>
              )
            })
          }
        </ul>
      </ModdablePart>
    )
  }
}

export default TableItems
