import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCog} from "@fortawesome/free-solid-svg-icons";
import MvSettingModal from '../Modal/Settings/MvSettingModal';
import MvSliderImages from './MvSliderImages';
import MvCustomSlider from './MvCustomSlider';

export class HomeSliderComponent extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    isEdit: PropTypes.bool
  };

  static defaultProps = {
    isOpen: false,
    isEdit: true
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { isEdit } = this.props;

    if (!this.props.h_d_mv_flag) {
      return (<div className='responsive-menu'/>);
    }
    
    return (
      <React.Fragment>
        <div id="mv_outer" className="editing edit_outer">
          {isEdit && <ul className="admin_edit_menu">
            <li className="admin_edit_menu_setting" title="パーツ設定" onClick={this.props.showSettings}>
              <i className="">
                <FontAwesomeIcon icon={faCog} />
              </i>
            </li>
            <li className="bm"><i className="material-icons">dehaze</i></li>
          </ul>}

          {this.props.mv_original == 1 ? <MvSliderImages /> : <MvCustomSlider />}
        </div>
        {this.props.isOpen && (<MvSettingModal showSettings={this.props.showSettings} handleShowModalConfig={this.props.handleShowModalConfig} />)}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.slide_pattern = storeState.settings.slide_pattern
  newState.slide_speed = storeState.settings.slide_speed
  newState.mv_type = storeState.settings.mv_type
  newState.mv_original = storeState.settings.mv_original
  newState.mv_youtubeid = storeState.settings.mv_youtubeid
  newState.character_set = storeState.settings.character_set
  newState.h_d_mv_flag = storeState.settings.h_d_mv_flag

  return newState;
}

export default connect(mapStateToProps)(HomeSliderComponent)
