import ModdablePart from '../ModdablePart';
import { IMAGES } from '../../../../../untils/constants'
import React, { Component } from 'react'
import { resetCategories } from '../../../../../functions/resetCategories'

export default class QuestionItems extends Component {
  constructor(props) {
    super(props);
    this.title = "よくあるご質問デザイン"
  }

  componentDidMount() {
    const newData = resetCategories(this.props.itemChecked, this.props.questionCategories)
    this.props.handleData(newData)   
  }

  render() {
    return (
      <ModdablePart title={this.title}>
      { !this.props.isOpenDetailData &&
        <ul className="no_select">
          {
            this.props.data.map((item, index) => {
              let content = JSON.parse(item.contents)
              return(
                <li key={index}>
                  <input name="block_question_pattern" id={`block_question_pattern${item.kind_of_design}`}  type="radio"
                    defaultChecked={this.props.itemChecked.kind_of_design == item.kind_of_design} />
                  <label htmlFor={`block_question_pattern${item.kind_of_design}`}
                    onClick={ () => this.props.handleDoubleClickItem() }
                    style={{background: 'none 0% 0% / auto repeat scroll padding-box border-box rgb(255, 255, 255)', color: 'rgb(34, 34, 34)'}}>
                    <img src={IMAGES[content.src]}
                  />
                  </label>
                </li>
              )
            })
          }
        </ul>
      }
    </ModdablePart>
    )
  }
}
