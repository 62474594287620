require('datatables.net-bs4');

$(document).ready(function() {
  if($('[id^=dictionaries_datatable_wrapper]').length == 0 && $('#dictionaries_datatable').length > 0) {
    table = $('#dictionaries_datatable').DataTable({
      dom: "<'row'<'col-6'l><'#dictinary_btn_add_page.col-6 text-right'>>" +
        "<'row'<'#dictinary_btn_batch_delete.col-sm-12'>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'float-right mb-3'p>",
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        lengthMenu: "_MENU_ を表示する",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        },
        emptyTable: "用語は登録されておりません"
      },
      order: [[ 1, 'asc' ]],
      columnDefs: [
        {
          targets: 0,
          orderable: false,
          width: "10%",
        },
        {
          targets: 2,
          width: "10%",
        },
        {
          targets: 3,
          orderable: false,
          width: "10%",
        }
      ],
      drawCallback: function(settings) {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);

        String(localStorage.getItem('checked_dictionaries')).split(',').forEach(function(value) {
          $('.checkbox_dictionary[value=' + value + ']').prop('checked', true);
        });

        number_of_checkbox_enable = $('.checkbox_dictionary').not(':disabled').length;
        number_of_checkbox_unchecked = $('.checkbox_dictionary:not(:checked)').not(':disabled').length;
        if (number_of_checkbox_enable == 0 || number_of_checkbox_unchecked > 0) {
          $('#dictionary_checkbox_check_all').prop('checked', false);
        } else {
          $('#dictionary_checkbox_check_all').prop('checked', true);
        }
      },
      stateSave: true
    });

    $('#dictinary_btn_add_page').append(`<a href='${$('#dictionaries_datatable').data("add-new-link")}' class='btn btn-info'>新規追加</a>`);
    $('#dictinary_btn_batch_delete').append("<input type='submit' id='dictinary_batch_delete_dictinary_btn' value='一括削除' class='btn btn-info my-3' data-confirm='削除後のデータは復旧できません。よろしいですか？' data-disable-with='一括削除' />");
  } else {
    localStorage.removeItem('checked_dictionaries')
  }

  $('#dictionary_checkbox_check_all').on('click', function() {
    if (this.checked) {
      $('.checkbox_dictionary').not(':disabled').prop('checked', true);
    } else {
      $('.checkbox_dictionary').not(':disabled').prop('checked', false);
    }
    $('.checkbox_dictionary').first().trigger('change');
  });

  $('#dictionaries_datatable').on('click','.checkbox_dictionary', function() {
    number_of_checkbox_unchecked = $('.checkbox_dictionary:checkbox:not(:checked)').not(':disabled').length;
    if (number_of_checkbox_unchecked > 0) {
      $('#dictionary_checkbox_check_all').prop('checked', false);
    } else {
      $('#dictionary_checkbox_check_all').prop('checked', true);
    }
  });

  $('#dictinary_batch_delete_dictinary_btn').on('click', function(event) {
    if ($('.checkbox_dictionary:checked').not(':disabled').length == 0) {
      event.preventDefault();
      alert('チェックボックスが選択されていません');
      return false;
    } else {
      let deleteItem = []
      $('.checkbox_dictionary:checked').each(function() {
        deleteItem.push($(this).val());
      });
      $('#dictionaries_delete').val(deleteItem);
    }
  });

  $('.checkbox_dictionary').on('change', function() {
    var checked_dictionaries = String(localStorage.getItem('checked_dictionaries')).split(',');

    $('.checkbox_dictionary').each(function() {
      var index = checked_dictionaries.indexOf($(this).val());
      if (index !== -1) checked_dictionaries.splice(index, 1);
    });

    $('.checkbox_dictionary:checked').each(function() {
      checked_dictionaries.push($(this).val());
    });

    localStorage.setItem('checked_dictionaries', checked_dictionaries.join(','));
  })
});
