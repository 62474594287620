import React, { Component } from 'react'
import HeadingPattern2Component from '../BlockImageText2/HeadingPattern2Component';
import ContentXnComponent from '../ContentXnComponent';
import {isOpenImageModal, isOpenImageSettingModal} from '../../../../../Lib/redux/actions';
import { connect } from 'react-redux';
import ContentEditable from 'react-contenteditable';

export class BlockType4 extends Component {
  constructor(props) {
    super(props);
    this.renderContentBlock = this.renderContentBlock.bind(this)
    this.handleDisplayAlign = this.handleDisplayAlign.bind(this)
    this.renderContentEditableMenu = this.renderContentEditableMenu.bind(this)
    this.state = {
      isHeadingPattern0Edit: false,
      isSubHeadingPattern0Edit: false,
      isHeadingPattern1Edit: false,
      isSubHeadingPattern1Edit: false,
      isHeadingPattern2Edit: false,
      isSubHeadingPattern2Edit: false,
      isHeadingPattern3Edit: false,
      isSubHeadingPattern3Edit: false,
      isHeadingPattern4Edit: false,
      isSubHeadingPattern4Edit: false
    }
  }

  handleDisplayAlign(flag) {
    if (flag === `headlinePattern${this.props.positionChild}`) {
      this.setState({isHeadingPattern0Edit: false, isHeadingPattern1Edit: false, isHeadingPattern2Edit: false, isHeadingPattern3Edit: false, isHeadingPattern4Edit: false, [`isHeadingPattern${this.props.positionChild}Edit`]: !this.state[`isHeadingPattern${this.props.positionChild}Edit`] });
    } else if (flag === `subHeadlinePattern${this.props.positionChild}`) {
      this.setState({isSubHeadingPattern0Edit: false, isSubHeadingPattern1Edit: false, isSubHeadingPattern2Edit: false, isSubHeadingPattern3Edit: false, isSubHeadingPattern4Edit: false, [`isSubHeadingPattern${this.props.positionChild}Edit`]: !this.state[`isSubHeadingPattern${this.props.positionChild}Edit`] });
    }
  }

  renderContentEditableMenu(type, index) {
    return(
      <ul className="contenteditable_menu active" style={{top: "-40px", left: "0px"}}>
        <li key={1} className="contenteditable_menu_justifyleft"
            title="左揃え" onMouseDown={(event) => this.props.handleChangeAlign(index, "align-left", type, event)} />
        <li key={2} className="contenteditable_menu_justifycenter"
            title="中央揃え" onMouseDown={(event) => this.props.handleChangeAlign(index, "align-center", type, event)} />
        <li key={3} className="contenteditable_menu_justifyright"
            title="右揃え" onMouseDown={(event) => this.props.handleChangeAlign(index, "align-right", type, event)} />
      </ul>
    )
  }

  renderSubHeadlinePattern(isEdit, content, index) {
    let editable = this.props.kindOfDesign === 12 ? "h" : ""
    editable += isEdit ? " contenteditable" : "";
    editable += this.state[`isSubHeadingPattern${this.props.positionChild}Edit`] ? " active" : ""
    if (isEdit) {
      return (
        <React.Fragment>
          <p className={`h ${editable}`} onFocus={() => this.handleDisplayAlign(`subHeadlinePattern${this.props.positionChild}`)}
            onBlur={() => this.handleDisplayAlign(`subHeadlinePattern${this.props.positionChild}`)}>
            <ContentEditable
              innerRef={this.contentEditable2}
              html={content[`subheadline_pattern${this.props.positionChild}`]} // innerHTML of the editable div
              disabled={false}       // use true to disable editing
              onKeyUp={(event) => this.props.handleChangeHeadline(index, event, `subheadline_pattern${this.props.positionChild}`)} // handle innerHTML change
              tagName='span' // Use a custom HTML tag (uses a div by default)
            />
            {this.state[`isSubHeadingPattern${this.props.positionChild}Edit`] && this.renderContentEditableMenu(`headingPattern${this.props.positionChild}Class`, index)}
          </p>
        </React.Fragment>
      )
    }

    return (<p className={editable} dangerouslySetInnerHTML={{__html: content[`subheadline_pattern${this.props.positionChild}`]}}></p>)
  }

  renderContentBlock(){
    return (
      <React.Fragment>
        <div className="inner_item_img">
          { this.props.isEdit &&
            (
              <React.Fragment>
                <span className="admin_edit_link 378534" title="リンク設定" onClick={this.props.showBlockImgSettingModal}>
                  <i className="material-icons">link</i>
                </span>
                <span className="admin_edit_img 378534" title="画像を変更" onClick={this.props.showOperatorImageModal}>
                  <i className="material-icons">collections</i>
                </span>
              </React.Fragment>
            )
          }
          {this.props.showBlockImage()}
        </div>
        <div style={{ pointerEvents: "auto" }} className="inner_item_txt">
          <div className={`heading block_header_4 ${this.props.headingPatternClass}`} data-target="1">
            <HeadingPattern2Component content={this.props.content} index={this.props.index} kindOfDesign={this.props.kindOfDesign}
                         positionChild={this.props.positionChild}
                         isEdit={this.props.isEdit}
                         handleChangeHeadline={this.props.handleChangeHeadline}
                         handleChangeAlign={this.props.handleChangeAlign} />
          </div>
          <div className="pwrap fix_toolbar">
            {this.renderSubHeadlinePattern(this.props.isEdit, this.props.content, this.props.index)}
            <div className="iwrap fix_toolbar"><img src={this.props.imageUrl()} /></div>
            <ContentXnComponent text={this.props.content[`text${this.props.positionChild}`]} positionChild={this.props.positionChild} contentClass={this.props.content[`contentClass${this.props.positionChild}`]} index={this.props.index} i={this.props.positionChild}
                           isEdit={this.props.isEdit}
                           handleChangeText={this.props.handleChangeText}
                           handleChangeAlign={this.props.handleChangeAlign} />
          </div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { isVisitOuterLink, positionChild } = this.props;
    const hrefValue = this.props.hrefValue();
    return (
      <React.Fragment>
        { hrefValue != false && isVisitOuterLink &&
          <a href={hrefValue} target={this.props.targetValue(positionChild)}>
            {this.renderContentBlock()}
          </a>
        }
        {
          hrefValue != false && !isVisitOuterLink &&
          <a href="#!">
            {this.renderContentBlock()}
          </a>
        }
        { hrefValue == false &&
          <React.Fragment>
            {this.renderContentBlock()}
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.isOpenImageModal = storeState.isOpenImageModal
  newState.isOpenImageSettingModal = storeState.isOpenImageSettingModal
  return newState;
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleShowImageModal: (data) => {dispatch(isOpenImageModal(data))},
    handleShowSetting: (data) => {dispatch(isOpenImageSettingModal(data))}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockType4)
