import React, {Component} from 'react';

export class GoogleMap extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <iframe className="lozad"
                src={`https://maps.google.co.jp/maps?q=${this.props.content.block_map_text || "東京都千代田区丸の内１丁目"}&output=embed&t=m`}></iframe>
      </React.Fragment>
    )
  }
}

export default GoogleMap
