import React, { Component } from 'react';
import { connect } from 'react-redux';
import BasicInformation from './BasicInformation';
import AdvancedSettings from './AdvancedSettings';
import ExtendedSettings from './ExtendedSettings';
import TemplateList from './TemplateList';
import ChooseImage from '../EditParallax/ChooseImage'
import {toast} from "react-toastify";
import {validateRequired, validateTextFieldRequired, validateUniqueValue} from "../../../../functions/validateAddModalCss";
import { updateSetting, saveImageUrl, saveNewpageTags } from '../../../../Lib/redux/actions';
import PropTypes from 'prop-types';

export class SettingModal extends Component {
  static propTypes = {
    allPages: PropTypes.array
  };

  static defaultProps = {
    allPages: []
  };

  constructor(props) {
    super(props);
    let errorMessagesData = this.props.settings.errorMessages
    this.state = {
      displayFlag: '0',
      settings: {...this.props.settings},
      isOpenImage: false,
      templateId: null,
      images: {
        eyecatch_image_url: props.eyecatch_image_url
      },
      newpageTags: props.newpageTags,
      showSuggests: false,
      errorMessages: {
        tag: {message: errorMessagesData.tag},
        input_tag: {message: null},
        description: {message: errorMessagesData.description, maxLength: 250, fieldLabel: 'ディスクリプション'},
        h1_text: {message: errorMessagesData.h1_text, maxLength: 9999, fieldLabel: 'h1テキスト'},
        title: {message: errorMessagesData.title, maxLength: 9999, fieldLabel: 'タイトル'},
        name: {message: errorMessagesData.name, maxLength: 1024, fieldLabel: 'ページ名', currentValue: this.props.settings.name},
        slug: {message: errorMessagesData.slug, maxLength: 50, fieldLabel: 'ディレクトリ名', currentValue: this.props.settings.slug},
        basic_account: {message: errorMessagesData.basic_account, maxLength: 128, fieldLabel: 'アカウント'},
        basic_password: {message: errorMessagesData.basic_password, maxLength: 64, fieldLabel: 'パスワード'},
        tenant_id: {message: errorMessagesData.tenant_id, fieldLabel: 'クライアント名'}
      },
      invalidFlag: false
    };
    this.handleTabSetting = this.handleTabSetting.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDataForm = this.handleDataForm.bind(this);
    this.handleSwitchImageTab = this.handleSwitchImageTab.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.handleShowListTemplates = this.handleShowListTemplates.bind(this);
    this.addTag = this.addTag.bind(this)
    this.removeTag = this.removeTag.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.settings !== this.props.settings) {
      this.setState({
        settings: this.props.settings
      })
    }
    if(prevProps.eyecatch_image_url !== this.props.eyecatch_image_url) {
      this.setState({
        images: {
          eyecatch_image_url: this.props.eyecatch_image_url
        }
      })
    }
    if(prevProps.newpageTags !== this.props.newpageTags) {
      this.setState({
        newpageTags: this.props.newpageTags
      })
    }
    if(prevProps.templateId !== this.props.templateId) {
      this.setState({
        displayFlag: '0', isOpenImage: false
      })
    }
  }

  validateRequiredField(fieldName, isInvalid) {
    const { settings, errorMessages } = this.state;
    let message = null;

    this.handleChange({target: {name: fieldName, value: settings[fieldName].trim()}})
    message = validateTextFieldRequired(settings[fieldName], errorMessages[fieldName].fieldLabel);

    if(message !== null) {
      isInvalid = true
      errorMessages[fieldName].message = message
    } else {
      errorMessages[fieldName].message = null
    }
    this.setState({errorMessages: errorMessages});

    return isInvalid
  }

  validateUnique(listValue, fieldName, isInvalid) {
    const { settings, errorMessages } = this.state;
    let message = null;
    this.handleChange({target: {name: fieldName, value: settings[fieldName].trim()}})
    message = validateUniqueValue(settings[fieldName], listValue, errorMessages[fieldName].fieldLabel);

    if(message !== null) {
      isInvalid = true
      errorMessages[fieldName].message = message
    } else {
      errorMessages[fieldName].message = null
    }
    this.setState({errorMessages: errorMessages});
    return isInvalid
  }

  validateLength(fieldNames, isInvalid) {
    const { settings, errorMessages } = this.state;

    fieldNames.forEach(fieldName => {
      const maxLength = errorMessages[fieldName].maxLength
      this.handleChange({target: {name: fieldName, value: settings[fieldName].trim()}})

      if(settings[fieldName].length > maxLength) {
        isInvalid = true
        errorMessages[fieldName].message = `${errorMessages[fieldName].fieldLabel}に${maxLength}文字以下で入力してください。`
      }
    });

    this.setState({errorMessages: errorMessages});
    return isInvalid
  }

  validateAccountPasswordFields() {
    let isValid = true
    const { settings, errorMessages } = this.state;
    const isAccountPresent = settings.basic_account.length !== 0
    const isPasswordPresent = settings.basic_password.length !== 0
    const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9]+$/

    if(isAccountPresent && !isPasswordPresent) {
      errorMessages.basic_account.message = null
      errorMessages.basic_password.message = 'パスワードを入力してください。'
      isValid = false
    }

    if(!isAccountPresent && isPasswordPresent) {
      errorMessages.basic_account.message = 'アカウントを入力してください。'
      errorMessages.basic_password.message = null
      isValid = false
    }

    if (isAccountPresent && !ALPHANUMERIC_REGEX.test(settings.basic_account)) {
      errorMessages.basic_account.message = 'アクセス制限のアカウントは半角英数字で入力してください。'
      isValid = false
    }

    if (isPasswordPresent && !ALPHANUMERIC_REGEX.test(settings.basic_password)) {
      errorMessages.basic_password.message = 'アクセス制限のパスワードは半角英数字で入力してください。'
      isValid = false
    }

    if(isValid) {
      errorMessages.basic_account.message = null
      errorMessages.basic_password.message = null
    }

    this.setState({errorMessages: errorMessages});
    return isValid
  }

  validateSettingsTab() {
    const { allPages, pageKind } = this.props
    const { settings, errorMessages } = this.state
    var isInvalid = false

    isInvalid = !this.validateAccountPasswordFields()

    isInvalid = this.validateRequiredField('name', isInvalid)
    if (pageKind === 'copy_template') {
      isInvalid = this.validateRequiredField('tenant_id', isInvalid)
    }
    if ( pageKind !== 'home') {
      isInvalid = this.validateRequiredField('slug', isInvalid)
    }

    const listPageName = allPages.map(function(page) {return page['name'].toLowerCase();})
    let listPageSlug = allPages.map(function(page) {return page['slug'].toLowerCase();})

    const specialSlug = [
      'page', 'dictionary', 'comparison_setting', 'expand_menu',
      'header_menu', 'footer_menu', 'header_content', 'footer_content',
      'sidebar', 'logo', 'footer_image', 'footer_sns', 'footer_component',
      'tags', 'sitemap'
    ]

    listPageSlug = listPageSlug.concat(specialSlug, settings.id_string)

    if(errorMessages.name.message === null && pageKind !== 'template') {
      if (pageKind !== 'copy_template') {
        isInvalid = this.validateUnique(listPageName, 'name', isInvalid)
      }
    }

    if(errorMessages.slug.message === null && pageKind !== 'template') {
      if (pageKind !== 'copy_template') {
        isInvalid = this.validateUnique(listPageSlug, 'slug', isInvalid)
      }
    }

    isInvalid = this.validateLength(
      ['name', 'slug', 'title', 'h1_text', 'description', 'basic_account', 'basic_password'], isInvalid
    )

    if(errorMessages.slug.message === null && settings.slug.match(/[\/?%#;]/)) {
      errorMessages.slug.message = 'ディレクトリ名に「/?%#;」を記入しないでください。'
      isInvalid = true
    }

    if(!isInvalid) {
      for (let key in errorMessages) {
        errorMessages[key].message = null
      }
    }

    return isInvalid
  }

  handleTabSetting({ target }) {
    this.setState({ displayFlag: target.dataset.step, isOpenImage: false })
  }

  handleChange({ target }) {
    const { settings } = this.state;
    if (target.name === 'common_footer_flag' && target.value === 'other_flag') {
      settings['other_flag'] = true;
      settings[target.name] = false;
    } else if (target.name === 'common_footer_flag') {
      settings['other_flag'] = false;
      let value_flag = false;
      if (target.value === 'true' || target.value === true) {
        value_flag = true;
      }
      settings[target.name] = target.type === "checkbox" ? target.checked : value_flag;
    } else {
      settings[target.name] = target.type === "checkbox" ? target.checked : target.value;
    }

    this.setState({
      settings: settings
    })
  }

  showErrorToast() {
    toast.error("入力内容をご確認ください", {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  handleDataForm() {
    const { settings } = this.state;

    if(this.props.pageKind !== 'common_footer' && this.validateSettingsTab()) {
      this.showErrorToast();
      return;
    }

    this.props.processSetting(settings);
    this.props.saveImageUrl(this.state.images);
    this.props.saveNewpageTags(this.state.newpageTags);
    this.props.handleShowModalConfig();
  }

  handleImage(event) {
    const { settings, images } = this.state;

    settings['eyecatch_image_id'] = event.target.dataset.id;
    images['eyecatch_image_url'] = event.target.style.backgroundImage.slice(4, -1).replace(/"/g, "");
    this.setState({
      settings: settings,
      images: images
    });
  }

  removeImage() {
    const { settings, images } = this.state;

    settings['eyecatch_image_id'] = '';
    images['eyecatch_image_url'] = '';
    this.setState({
      settings: settings,
      images: images
    });
  }

  handleSwitchImageTab() {
    this.setState({ isOpenImage: !this.state.isOpenImage, displayFlag: '' })
  }

  handleShowListTemplates() {
    this.setState({ displayFlag: '3', isOpenImage: false })
  }

  addTag(tag, clearInput) {
    const trim_tag = tag.trim();
    if (trim_tag == '') return;

    const { settings, errorMessages } = this.state
    if (trim_tag.length > 50) {
      errorMessages.input_tag.message = 'キーワード（タグ）に50文字以下で入力してください。'
      this.setState({errorMessages: errorMessages});
      this.showErrorToast();
      return;
    } else {
      errorMessages.input_tag.message = null
    }

    settings['input_tag'] = clearInput ? '' : settings['input_tag']
    let newTags = this.state.newpageTags.map((t) => t);
    const index = newTags.indexOf(trim_tag);
    if (index < 0) newTags.push(trim_tag);

    this.setState({
      settings: settings,
      newpageTags: newTags,
      showSuggests: true
    });
  }

  removeTag(tag) {
    let newTags = this.state.newpageTags;
    newTags = newTags.filter(t => t !== tag);

    let validFlag = true
    newTags.forEach((item, index) => {
      if (item.length > 128) { validFlag = false }
    })

    if (validFlag) {
      const { errorMessages } = this.state
      errorMessages.tag.message = null
      this.setState({errorMessages: errorMessages});
    }

    this.setState({
      newpageTags: newTags,
      showSuggests: newTags.length > 0
    });
  }

  render() {
    const { settings, images, newpageTags } = this.state;
    let loadClass = this.props.isRequesting ? 'load' : 'loaded';

    const cancelClass = (this.props.pageStatus === 'new' || this.props.pageStatus === 'edit') ? 'modal_header_btn cancel' : 'modal_header_btn cancel first_button'

    return (
      <div id="setting_modal" data-step="#block_header" className={`active admin_edit_menu_add admin_edit_extended_settings ${loadClass}`}>
        <div className="wrapper">
          <div id="contents" data-type="admin_edit_add">
            <div className="modal_header modal_header_setting">
              <i>ページ設定</i>
              {
                (this.props.pageStatus === 'new' || this.props.pageStatus === 'edit') &&
                <p className="modal_header_btn use_template first_button" onClick={this.handleShowListTemplates}>テンプレート</p>
              }
              <span className={cancelClass} onClick={() => this.props.handleShowModalConfig()}>キャンセル</span>
              <span className="modal_header_btn ok" onClick={this.handleDataForm} >決定</span>
            </div>
            <div id="content_r" style={{opacity: 1}}>
              <div className="extended_settings_wrap">
                <div className="stepset">
                  <div className="step_inner">
                    {this.state.displayFlag === '0' && <BasicInformation handleChange={this.handleChange} settings={settings}
                                                                         tagsList={this.props.tagsList}
                                                                         newpageTags={newpageTags}
                                                                         tenants={this.props.tenants}
                                                                         pageKind={this.props.pageKind}
                                                                         addTag={this.addTag}
                                                                         removeTag={this.removeTag}
                                                                         showSuggests={this.state.showSuggests}
                                                                         errorMessages={this.state.errorMessages} />}
                    {this.state.displayFlag === '1' && <ExtendedSettings handleChange={this.handleChange} settings={settings}
                                                                        isNewpageAdd={this.props.isNewpageAdd}
                                                                        pageKind={this.props.pageKind}
                                                                        eyecatch_image_url={images.eyecatch_image_url}
                                                                        handleSwitchImageTab={this.handleSwitchImageTab}
                                                                        subNavigations={this.props.subNavigations}
                                                                        localSideNavigations={this.props.localSideNavigations}
                                                                        parent_id={settings.parent_id}
                                                                        errorMessages={this.state.errorMessages}
                                                                        pageStatus={this.props.pageStatus} />}
                    {this.state.displayFlag === '2' && <AdvancedSettings handleChange={this.handleChange} settings={settings} />}
                    {this.state.displayFlag === '3' && <TemplateList handleUseTemplate={this.props.handleUseTemplate} />}
                    {this.state.isOpenImage && <ChooseImage handleImage={this.handleImage}
                                                            mv_image_id={settings.eyecatch_image_id}
                                                            removeImage={this.removeImage}
                                                            isConfig={true} />}
                  </div>
                  {!this.state.isOpenImage && <div className="modal_footer">
                    <a href="#!" data-step="0" onClick={this.handleTabSetting}>基本設定</a>
                    <a href="#!" data-step="1" onClick={this.handleTabSetting}>拡張設定</a>
                    <a href="#!" data-step="2" onClick={this.handleTabSetting}>上級者設定</a>
                  </div>}

                  {this.state.isOpenImage && <div className="modal_footer">
                    <a href="#!" data-step="1" onClick={this.handleTabSetting}>« 画像設定へ</a>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.settings = storeState.settings;
  newState.eyecatch_image_url = storeState.slider_images.eyecatch_image_url;
  newState.subNavigations = storeState.subNavigations;
  newState.tagsList = storeState.tagsList;
  newState.newpageTags = storeState.newpageTags || [];
  newState.allPages = storeState.allPages;
  newState.isRequesting = storeState.isRequesting;
  newState.localSideNavigations = storeState.localSideNavigations;
  newState.tenants = storeState.tenants;
  return newState
}

const mapDispatchToProps = (dispatch) => {
  return {
    processSetting: (data) => {dispatch(updateSetting(data))},
    saveImageUrl: (data) => {dispatch(saveImageUrl(data))},
    saveNewpageTags: (data) => {dispatch(saveNewpageTags(data))}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingModal)
