export default (storeState, {payload}) => {
  let newState = Object.assign({}, storeState);
  let contents = JSON.parse(payload.contents)
  let currentContents = JSON.parse(newState.asideData[newState.index].contents)
  let newContents = {...currentContents, ...contents}
  payload.contents = JSON.stringify(newContents)

  payload.sort = Math.max.apply(Math, newState.asideData.map(function(o) { return o.sort; })) + 1
  newState.asideData[newState.index] = payload;

  newState.asideData = [...newState.asideData]

  return newState;
};
