import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";

class FooterContentComponent extends Component {
    constructor(props) {
        super(props);
        this.renderFooterPhoneNumber = this.renderFooterPhoneNumber.bind(this)
    }

    renderFooterPhoneNumber() {
        const {footerSetting} = this.props;
        return (
            <React.Fragment>
                {
                    (footerSetting.f_tel_flag && footerSetting.common_tel !== '') &&
                    <Link to='#'>
                        <span>
                          <FontAwesomeIcon icon={["fas", "phone-alt"]}/>
                          <span dangerouslySetInnerHTML={{__html: footerSetting.common_tel}}></span>
                          <i className="teli" dangerouslySetInnerHTML={{__html: footerSetting.common_teli}}></i>
                        </span>
                    </Link>
                }
                {
                    (footerSetting.f_tel2_flag && footerSetting.common_tel2 !== '') &&
                    <Link to='#'>
                        <span>
                          <FontAwesomeIcon icon={["fas", "phone-alt"]}/>
                          <span dangerouslySetInnerHTML={{__html: footerSetting.common_tel2}}></span>
                          <i className="teli" dangerouslySetInnerHTML={{__html: footerSetting.common_tel2i}}></i>
                        </span>
                    </Link>
                }
            </React.Fragment>
        )
    }

    render() {
        const {footerSetting} = this.props
        if (footerSetting !== undefined && footerSetting.footer_flag) {
            return (
                <div id="fixbtn" style={{position: 'relative', minHeight: 83}}>
                    <div className="fixbtnwrap" style={{position: 'absolute', bottom: 'auto', top: '0px'}}>
                        <div className="inner">
                            <div className="fixbtntel">
                                {this.renderFooterPhoneNumber()}
                                {
                                    footerSetting !== undefined && footerSetting.f_hour_flag &&
                                    <p dangerouslySetInnerHTML={{__html: footerSetting.common_hour}}></p>
                                }
                            </div>
                            <div className="contents_btn01">
                                {footerSetting !== undefined && footerSetting.f_button_flag && this.props.renderFooterLink}
                            </div>
                            <div id="scrolltop"><a href="#top"></a></div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (<div/>)
        }
    }
}

const mapStateToProps = (storeState, ownProps) => {
    let newState = Object.assign({}, ownProps);

    newState.footerSetting = storeState.footerSetting;

    return newState;
}

export default connect(mapStateToProps)(FooterContentComponent);
