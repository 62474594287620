import React, {Component} from 'react';
import {connect} from 'react-redux';
import LoadableHeadingPart from '../../Parts/Modal/EmbeddedParts/BlockHeading/LoadableHeadingPart';
import LoadableMoviePart from '../../Parts/Modal/EmbeddedParts/BlockMovie/LoadableMoviePart';
import LoadableMapPart from '../../Parts/Modal/EmbeddedParts/BlockMap/LoadableMapPart';
import LoadableTextPart from '../../Parts/Modal/EmbeddedParts/BlockText/LoadableTextPart';
import LoadableImageTextPart from '../../Parts/Modal/EmbeddedParts/BlockImageText/LoadableImageTextPart';
import LoadableLinePart from '../../Parts/Modal/EmbeddedParts/BlockLine/LoadableLinePart';
import LoadableTelPart from '../../Parts/Modal/EmbeddedParts/BlockTel/LoadableTelPart';
import Cssable from '../../Parts/Css/Cssable';
import LoadableImagePart from '../../Parts/Modal/EmbeddedParts/BlockImage/LoadableImagePart';
import LoadableTablePart from '../../Parts/Modal/EmbeddedParts/BlockTable/LoadableTablePart';
import LoadableShopPart from "../../Parts/Modal/EmbeddedParts/BlockShop/LoadableShopPart";
import LoadableConversationPart from "../../Parts/Modal/EmbeddedParts/BlockConversation/LoadableConversationPart";

class FooterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data
    };
    this.handleSwitchComponents = this.handleSwitchComponents.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({data: nextProps.data});
  }

  handleSwitchComponents(content, item) {
    switch (item.block_type) {
      case 'block_header':
        return <LoadableHeadingPart content={content} kindOfDesign={item.kind_of_design} isEdit={false}/>
        break;
      case 'block_movie':
        return <LoadableMoviePart content={content} kindOfDesign={item.kind_of_design} isEdit={false}/>
        break;
      case 'block_map':
        return <LoadableMapPart content={content} kindOfDesign={item.kind_of_design} isEdit={false}/>
        break;
      case 'block_text':
        return <LoadableTextPart content={content} kindOfDesign={item.kind_of_design} isEdit={false}/>
        break;
      case 'block_line':
        return <LoadableLinePart content={content} kindOfDesign={item.kind_of_design} isEdit={false}/>
        break;
      case 'block_image_text':
        return <LoadableImageTextPart content={content} kindOfDesign={item.kind_of_design} isEdit={false} item={item} isVisitOuterLink={true}/>
        break;
      case 'block_image_text2':
        return <LoadableImageText2Part content={content} kindOfDesign={item.kind_of_design} isEdit={false} item={item} isVisitOuterLink={true}/>
        break;
      case 'block_tel':
        return <LoadableTelPart content={content} kindOfDesign={item.kind_of_design} isEdit={false} item={item} />
        break;
      case 'block_shop':
        return <LoadableShopPart content={content} kindOfDesign={item.kind_of_design} isEdit={false} item={item} />
        break;
      case 'block_conversation':
        return <LoadableConversationPart content={content} kindOfDesign={item.kind_of_design} isEdit={false} item={item} />
        break;
      case 'block_image':
        return <LoadableImagePart content={content} isEdit={false}
                                  item={item} isVisitOuterLink={true}/>
        break;
      case 'block_table':
        return <LoadableTablePart content={content} kindOfDesign={item.kind_of_design} isEdit={false}/>
        break;
      default:
        break;
    }
  }

  render() {
    let {data} = this.props;
    return (
      <aside id="common_footer">
        {
          data.map((item, index) => {
            let content = JSON.parse(item.contents)
            return (
              <React.Fragment key={index}>
                <Cssable css={item.css}>
                  {this.handleSwitchComponents(content, item)}
                </Cssable>
              </React.Fragment>
            )
          })
        }
      </aside>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);

  newState.data = storeState.asideData;

  return newState;
}

export default connect(mapStateToProps)(FooterComponent)
