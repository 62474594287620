import React, { Component } from 'react'

export default class TextAreaField extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { item, remarkFlag } = this.props
    if(remarkFlag === 1) {
      return(
        <dl>
          <dt>{item.input_name}</dt>
          <dd>
            <textarea name="" cols="50" rows="5"></textarea>
            <p className="cap">{item.input_remaks}</p>
          </dd>
        </dl>
      )
    }
    return(
      <dl>
        <dt>{item.input_name}</dt>
        <dd>
          <textarea name="" cols="50" rows="5" placeholder={item.input_remaks}></textarea>
        </dd>
      </dl>
    )
  }
}
