import React, { Component } from 'react'
import { connect } from 'react-redux';

export class OptionUrl extends Component {
  constructor(props) {
    super(props)
    this.state = {
      homeStructure: props.homeStructure,
    };
    this.maxLength = 30
  }

  formatContent(item) {
    let title = `${item.page_title}: ${item.url}`
    if(title.length > this.maxLength) {
      title = title.slice(0, this.maxLength) + "..."
    }
    if(item.url === "home") {
      title = "HOME: /"
    }
    return title
  }

  render() {
    return (
      <React.Fragment>
        {
          this.state.homeStructure.map((item, index) => {
            const parentMenuTitle = this.formatContent(item)
            const pageUrl = (item.url === "home") ? "/" : `/${item.url}`
            return (
              <option data_page_id={item.newpage_id} value={pageUrl} key={index}>{parentMenuTitle}</option>
            )
          })
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);

  newState.homeStructure = storeState.homeStructure

  return newState;
}
export default connect(mapStateToProps)(OptionUrl)
