import React, { Component } from 'react'

export default class ModalDetailData extends Component {
  render() {
    return (
      <div className="wrap">
        <h3><span>情報の追加・編集</span></h3>
        <div className="item">
          <iframe id="partsIframe" src={this.props.src} width="100%" height="100%" frameBorder={0} />
        </div>
      </div>
    )
  }
}
