import React, { Component } from 'react'
import CategoryOptions from '../../../../Parts/Modal/CategoryOptions'
import ChooseNumberItem from '../../../../Parts/Modal/ChooseNumberItem'
import OptionUrl from '../../../../Parts/Modal/OptionUrl'

export default class BlogOptions extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const numberBlogValues = [3, 4, 5, 6, 8, 9, 10, 12]
    const { contents, newpageCategories } = this.props
    return (
      <React.Fragment>
        <h3><span>その他設定</span></h3>
        <CategoryOptions {...this.props} kindOfItem="blog" categories={newpageCategories} isRequired={false}/>
        <ChooseNumberItem contents={contents} kindOfItem="blog" numberValues={numberBlogValues} handleChangeContent={this.props.handleChangeContent}/>
        <h3><span>ボタン</span></h3>
        <div className="item">
          <p>ボタンラベル</p>
          <input name="button_label" type="text" defaultValue={contents.button_label} onChange={this.props.handleChangeContent} />
          <div className="mt10"><small>ボタンラベルが未入力の場合はボタンが表示されません。</small></div>
        </div>
        <div className="item">
          <p>リンク</p>
          <div className="link">
            <input name="blog_link_type" id="blog_link_type_1" defaultValue={1} type="radio" defaultChecked={contents.blog_link_type == 1} onChange={this.props.handleChangeContent}/>
            <label htmlFor="blog_link_type_1">内部リンク</label>
            <input name="blog_link_type" id="blog_link_type_2" defaultValue={2} type="radio" defaultChecked={contents.blog_link_type == 2} onChange={this.props.handleChangeContent} />
            <label htmlFor="blog_link_type_2">外部リンク</label>
            <div className="blog_linkinput1" style={{display: contents.blog_link_type == 1 ? 'block' : 'none'}}>
              <span className="select">
                <select name="blog_link_1" onChange={this.props.handleChangeSelectPageLink} defaultValue={contents.blog_link_1}>
                  <OptionUrl />
                </select>
              </span>
            </div>
            <div className="blog_link2" style={{display: contents.blog_link_type == 2 ? 'block' : 'none'}}>
              <input name="blog_link_2" placeholder="外部リンクURL" type="text" onChange={this.props.handleChangeContent} defaultValue={contents.blog_link_2}/>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
