import React, { Component } from 'react'
import ModdablePart from '../ModdablePart';
import { IMAGES } from '../../../../../untils/constants'
import { connect } from 'react-redux';
import { resetCategories } from '../../../../../functions/resetCategories'
export class BlogItems extends Component {
  constructor(props) {
    super(props);
    this.title = "ブログデザイン"
  }

  componentDidMount() {
    const newData = resetCategories(this.props.itemChecked, this.props.newpageCategories)
    this.props.handleData(newData)
  }
  
  render() {
    return (
      <ModdablePart title={this.title}>
        <ul className="no_select">
          {
            this.props.data.map((item, index) => {
              let content = JSON.parse(item.contents)
              return(
                <li key={index}>
                  <input name="block_blog_pattern" id={`block_blog_pattern_${item.kind_of_design}`}  type="radio"
                    defaultChecked={this.props.itemChecked.kind_of_design == item.kind_of_design} />
                  <label htmlFor={`block_blog_pattern_${item.kind_of_design}`} style={{background: 'none 0% 0% / auto repeat scroll padding-box border-box rgb(255, 255, 255)', color: 'rgb(34, 34, 34)'}}
                    onClick={ () => this.props.handleDoubleClickItem(resetCategories(item, this.props.newpageCategories)) }>
                    <img src={IMAGES[content.src]}
                    />
                  </label>
                </li>           
              )
            })
          }
        </ul>
      </ModdablePart>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.newpageCategories = storeState.newpageCategories
  return newState
}

export default connect(mapStateToProps)(BlogItems)
