import React, { Component } from 'react'
import ConfigablePart from "../ConfigablePart";
import PropTypes from 'prop-types';
import { APIS, FRONT_END_HOST } from "../../../../../untils/constants";
import request from "../../../../../untils/request";
import HeadlineComponent from '../HeadlineComponent';
import ExplanationComponent from '../ExplanationComponent';
import LabelComponent from "../../EmbeddedParts/LabelComponent"
import { IMAGES } from '../../../../../untils/constants';
import { parallaxScroll, convertDateTimeLocalContent } from '../../../../../functions';
import {connect} from 'react-redux';

class LoadableBlogPart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      blogs: [],
    };
    this.headlineNone = "1"
    this.headlineFull = "2"
    this.KindOfDesign1 = 1
    this.KindOfDesign2 = 2
    this.KindOfDesign3 = 3
    this.KindOfDesign4 = 4
    this.KindOfDesignDefault = 10
    this.KindOfDesign5 = 5
    this.KindOfDesign6 = 6
    this.KindOfDesign7 = 7
    this.KindOfDesign8 = 8
    this.KindOfDesign9 = 9
    this.hidenKinds = [5, 7]
    this.addDesignClass = this.addDesignClass.bind(this)
    this.showListBlogs = this.showListBlogs.bind(this)
  }

  static propTypes = {
    isEdit: PropTypes.bool,
    isFavorite: PropTypes.bool
  };

  static defaultProps = {
    isEdit: true,
    isFavorite: false
  };

  async componentDidMount () {
    const newpage_id = (this.props.isFavorite || this.props.pageStatus === "commonFooter") ? '' : this.props.settings.newpage_id
    const { clientId } = this.props
    await request({
      method: 'post',
      url: APIS.get_blogs,
      data: { newpage_id: newpage_id, client_id: clientId }
    }).then(response => {
      this.setState({
        blogs: response.data.blogs
      })
    })
    parallaxScroll();
    convertDateTimeLocalContent();
  }

  addDesignClass(item) {
    const kind_of_design = item.kind_of_design
    if(kind_of_design === this.KindOfDesign1|| kind_of_design === this.KindOfDesign2) {
      return ""
    }
    if(kind_of_design >= this.KindOfDesignDefault) {
      return `add_design${kind_of_design}`
    }

    if(kind_of_design == this.KindOfDesign3) {
      return "add_design2"
    }

    if(kind_of_design == this.KindOfDesign4 || kind_of_design == this.KindOfDesign5) {
      return "add_design3"
    }

    if(kind_of_design == this.KindOfDesign6 || kind_of_design == this.KindOfDesign7) {
      return "add_design4"
    }

    return `add_design${kind_of_design - 3}`
  }

  blogCommonInfo(item, blog) {
    if(item.kind_of_design == this.KindOfDesignDefault) {
      return(
        <div className="pwrap">
          <span><img src={blog.image_url != "" ? blog.image_url : IMAGES.no_image} alt={blog.name} /></span>
          <p className="release_time">{blog.date}</p>
          <p dangerouslySetInnerHTML={{__html: blog.name}}></p>
        </div>
      )
    }
    return(
      <>
        <p className="release_time">{blog.date}</p>
        <p dangerouslySetInnerHTML={{__html: blog.name}}></p>
      </>
    )
  }

  showListBlogs(item) {
    const { blogs } = this.state
    const categories = this.props.item.categories
    let limitedBlogs = blogs
    if(categories.length === 0) {
      limitedBlogs = blogs.filter(blogItem => { return blogItem.category_ids.length == 0 })
    }
    else {
      limitedBlogs = blogs.filter(blogItem => {
        return blogItem.category_ids.some(category_id => categories.includes(category_id));
      });
    }

    if(limitedBlogs.length > this.props.content.blog_number) {
      limitedBlogs = limitedBlogs.slice(0, this.props.content.blog_number)
    }
    const imageKinds = [4, 5, 6, 7, 8, 9, 10, 13]
    return(
      limitedBlogs.map((blog, index) => {
        let imgStyle = blog.image_class == "no_img" ? {filter: "contrast(0%)", opacity: 0.3} : {}
        return(
          <li key={index}>
            <a href={this.props.isFavorite ? `${FRONT_END_HOST}/${blog.url}`: "#!"} className={blog.image_class == "no_img" ? "noimg" : ""}>
              {
                imageKinds.indexOf(item.kind_of_design) >-1 &&
                  <span className={blog.image_class}>
                    <img style={imgStyle} src={blog.image_url != "" ? blog.image_url : IMAGES.no_image} alt={blog.name} />
                  </span>
              }
              { this.hidenKinds.indexOf(item.kind_of_design) < 0 &&
                this.blogCommonInfo(item, blog)
              }
              {
                (item.kind_of_design == this.KindOfDesign8 || item.kind_of_design == this.KindOfDesign9) &&
                <div className="date_blog" dangerouslySetInnerHTML={{__html: `<p></p> ${blog.header_text}` }} />
              }
            </a>
          </li>
        )
      })
    )
  }

  render() {
    const {isEdit, handleChangeHeadline, handleChangeAlign, item, index, sectionStyle, content} = this.props;
    const kind_of_block = item.kind_of_design === 2 ? "2" : "1"
    return (
      <section key={item.kind_of_design} style={sectionStyle}
        id={item.kind_of_design}
        className={`block_news_${kind_of_block} ${this.addDesignClass(item)} ${content.parallax != 1 ? "parallax" : ""} delay3 ${content.width_class} blockarea ${content.top_padding_class} ${content.bottom_padding_class} edit_outer without delay4Active editing block_blog delay3Active`}
        style={sectionStyle}>
        {this.props.isEdit && <ConfigablePart {...this.props} />}
        {
          content.parallax != 1 &&
          <div className="parallax-wrap">
            <div className="parallax_img" style={{backgroundImage: `url(${content.background_image_url})`}} />
          </div>
        }
        <div className="content_wrapper">
          {
            content.headlineType != this.headlineNone &&
            <HeadlineComponent content={content} index={index}
                              isEdit={isEdit}
                              handleChangeHeadline={handleChangeHeadline}
                              handleChangeAlign={handleChangeAlign} />
          }
          {
            content.headlineType == this.headlineFull &&
            <ExplanationComponent content={content} index={index}
                                  isEdit={isEdit}
                                  handleChangeHeadline={handleChangeHeadline}
                                  handleChangeAlign={handleChangeAlign} />
          }
          <ul>
            {this.showListBlogs(item)}
          </ul>
          {
            content.button_label.trim() != "" &&
              <LabelComponent value={content.button_label}
              handleChangeLabel={this.props.handleChangeLabel}
              url={content.blog_link_type == 1 ? content.blog_link_1 : content.blog_link_2}
              isFavorite={this.props.isFavorite}
              index={index}
              link_type={content.blog_link_type}
              isEdit={false}/>
          }
        </div>
      </section>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.clientId = storeState.clientId;
  return newState;
}

export default connect(mapStateToProps, null)(LoadableBlogPart)
