import React, { Component } from 'react'

export default class ColorTextConfigagle extends Component {
  render() {
    if(this.props.colorType === "background") {
      return(
        <li className="admin_edit_menu_bgcolor 464043" title="背景色" onClick={this.props.handleOpenBackroundPicker}>
          <i className="material-icons">color_lens</i><input type="text" className="picker" style={{display: 'none'}} />
          <div className="sp-replacer sp-light sp-active">
            <div className="sp-preview">
              <div className="sp-preview-inner" style={{backgroundColor: 'rgb(211, 119, 119)'}} />
            </div>
            <div className="sp-dd">▼</div>
          </div>
        </li>
      )
    }
    return (
      <React.Fragment>
        <li className="admin_edit_menu_color 457935" title="文字色" onClick={this.props.handleOpenColorPicker}>
          <i className="material-icons">text_format</i>
          <input type="text" className="picker" style={{display: 'none'}} />
          <div className="sp-replacer sp-light">
            <div className="sp-preview">
              <div className="sp-preview-inner sp-clear-display" style={{backgroundColor: 'transparent'}} />
            </div>
            <div className="sp-dd">▼</div>
          </div>
        </li>
      </React.Fragment>
    )
  }
}
