import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {isDisplayModalAdd} from '../../Lib/redux/actions';

export class MainNoData extends Component {
  static propTypes = {
    renderSameCategory: PropTypes.func
  };

  static defaultProps = {
    renderSameCategory: () => {}
  };

  render() {
    return (
      <main id="main" style={{minHeight: '474px'}}>
        <div className="admin_edit" title="パーツを追加" id="insertion_position_1" data-type="insertion_position_1" onClick={() => this.props.displayModalAdd(true, 0)}>
          <i className="material-icons">
            <FontAwesomeIcon icon={faPlus} />
          </i>
        </div>
        {this.props.renderSameCategory()}

        <p id="firstguide">『＋』アイコンを押すと表示される各ツールを使用して、ページを作成しましょう。<br />
        お気に入りからブロック・パーツを使用する場合は重複コンテンツに注意してください。</p>
      </main>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    displayModalAdd: (isDisplay, index) => dispatch(isDisplayModalAdd({isDisplay, index}))
  }
}

export default connect(null, mapDispatchToProps)(MainNoData)
