import React, { Component } from 'react';
import { display_recruit_part } from '../../../../../../functions'

export class RecruitScreening extends Component {
  constructor(props) {
    super(props);
    this.fields = [
      'gender_of_recruitment_target',
      'supplementary_information_about_gender',
      'age_of_recruitment_target',
      'supplementary_information_about_age',
      'final_academic_background_that_can_be_applied',
      'educational_background',
      'number_of_job_changes',
      'nationality',
      'supplementary_information_about_nationality',
      'hope_for_language_skills'
    ]
  }
  render() {
    const { recruit } = this.props;
    let is_display = display_recruit_part(recruit, this.fields)
    return (
      <React.Fragment>
        {is_display && (
        <div className="detail">
          <div className="heading block_header_3 align-left">
            <h4 className="h">スクリーニング</h4>
          </div>
          <div className="block_table_3 width_fixed align-left">
            <table>
              <tbody>
                {(recruit.gender_of_recruitment_target !== undefined && recruit.gender_of_recruitment_target !== '') && (
                <tr>
                  <th>★採用ターゲットの性別</th>
                  <td>{ recruit.gender_of_recruitment_target }</td>
                </tr>
                )}
                {(recruit.supplementary_information_about_gender !== undefined && recruit.supplementary_information_about_gender !== '') && (
                <tr>
                  <th>★性別についての補足事項</th>
                  <td className="pre-wrap"> { recruit.supplementary_information_about_gender } </td>
                </tr>
                )}
                {(recruit.age_of_recruitment_target !== undefined && recruit.age_of_recruitment_target !== '' && recruit.age_of_recruitment_target !== null) && (
                <tr>
                  <th>★採用ターゲットの年齢</th>
                  <td>{ recruit.age_of_recruitment_target }</td>
                </tr>
                )}
                {(recruit.supplementary_information_about_age !== undefined && recruit.supplementary_information_about_age !== '') && (
                <tr>
                  <th>★年齢についての補足事項</th>
                  <td className="pre-wrap"> { recruit.supplementary_information_about_age } </td>
                </tr>
                )}
                {(recruit.final_academic_background_that_can_be_applied !== undefined && recruit.final_academic_background_that_can_be_applied !== '') && (
                <tr>
                  <th>★応募可能な最終学歴</th>
                  <td>{ recruit.final_academic_background_that_can_be_applied }</td>
                </tr>
                )}
                {(recruit.educational_background !== undefined && recruit.educational_background !== '') && (
                <tr>
                  <th>★学歴文理</th>
                  <td>{ recruit.educational_background }</td>
                </tr>
                )}
                {(recruit.number_of_job_changes !== undefined && recruit.number_of_job_changes !== '') && (
                <tr>
                  <th>★転職回数</th>
                  <td>{ recruit.number_of_job_changes }</td>
                </tr>
                )}
                {(recruit.nationality !== undefined && recruit.nationality !== '') && (
                <tr>
                  <th>★国籍</th>
                  <td>{ recruit.nationality }</td>
                </tr>
                )}
                {(recruit.supplementary_information_about_nationality !== undefined && recruit.supplementary_information_about_nationality !== '') && (
                <tr>
                  <th>★国籍についての補足事項</th>
                  <td className="pre-wrap"> { recruit.supplementary_information_about_nationality } </td>
                </tr>
                )}
                {(recruit.hope_for_language_skills !== undefined && recruit.hope_for_language_skills !== '') && (
                <tr>
                  <th>★語学力の希望</th>
                  <td className="pre-wrap"> { recruit.hope_for_language_skills } </td>
                </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        )}


        <div className="detail">
          <div className="heading block_header_3 align-left">
            <h4 className="h">選考プロセス</h4>
          </div>
          <div className="block_table_3 width_fixed align-left">
            <table>
              <tbody>
                <tr>
                  <th>選考プロセス</th>
                  <td className="pre-wrap"> { recruit.selection_process } </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default RecruitScreening
