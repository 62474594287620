import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RecruitInformation from './RecruitDetailParts/RecruitInformation'
// import RecruitDepartment from './RecruitDetailParts/RecruitDepartment'
// import RecruitJobDescription from './RecruitDetailParts/RecruitJobDescription'
// import RecruitAppealPoints from './RecruitDetailParts/RecruitAppealPoints'
// import RecruitRequirements from './RecruitDetailParts/RecruitRequirements'
// import RecruitScreening from './RecruitDetailParts/RecruitScreening'
// import RecruitTreatment from './RecruitDetailParts/RecruitTreatment'
// import RecruitPersonInCharge from './RecruitDetailParts/RecruitPersonInCharge'

export class RecruitDetails extends Component {
  static propTypes = {
    recruit: PropTypes.object,
  };

  static defaultProps = {
    recruit: {},
  };
  render() {
    const { recruit } = this.props;
    return (
      <div>
        {
          recruit.job_title !== undefined && recruit.job_title !== '' &&
          <div className="heading align-left recruit-title">
            <h3 className="h pre-wrap">{`${recruit.job_title}`}</h3>
          </div>
        }
        <RecruitInformation {...this.props} />
        {/*<RecruitDepartment {...this.props} />*/}
        {/*<RecruitJobDescription {...this.props} />*/}
        {/*<RecruitAppealPoints {...this.props} />*/}
        {/*<RecruitRequirements {...this.props} />*/}
        {/*<RecruitScreening {...this.props} />*/}
        {/*<RecruitTreatment {...this.props} />*/}
        {/*<RecruitPersonInCharge {...this.props} />*/}
      </div>
    )
  }
}

export default RecruitDetails
