import React, { Component } from 'react'
import request from "../../../../untils/request";
import PropTypes from 'prop-types';
import { APIS } from "../../../../untils/constants";
import {connect} from "react-redux";
import Select from 'react-select';
import ImageModalFooter from '../../../Parts/Modal/ImageModalFooter'

export class ChooseImage extends Component {
  static propTypes = {
    isHome: PropTypes.bool,
    isConfig: PropTypes.bool,
    isBlockImage: PropTypes.bool,
    mv_image_id: PropTypes.string,
    positionChild: PropTypes.string
  };

  static defaultProps = {
    isHome: false,
    isConfig: false,
    isBlockImage: false,
    mv_image_id: '',
    positionChild: ''
  };

  constructor(props) {
    super(props);
    this.state = {
      images: [],
      categories: [],
      category_id: '',
      current_page: 0,
      total_pages: 0,
      headerSetting: {...this.props.headerSetting},
      selectedOption: ''
    };
    this.renderImages = this.renderImages.bind(this)
    this.renderCategories = this.renderCategories.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.fetchImage = this.fetchImage.bind(this)
    this.renderPagination = this.renderPagination.bind(this)
    this.handleChooseImage = this.handleChooseImage.bind(this)
    this.stepWidth = this.stepWidth.bind(this)
  }

  async componentDidMount () {
    const {data: response} = await request({
      method: 'get',
      url: APIS.get_images
    })

    this.setState({
      images: response.images,
      categories: response.categories,
      current_page: response.current_page,
      total_pages: response.total_pages
    })
  }

  async handleChange(selectedOption) {
    const categoryId = selectedOption === null ? 0 : selectedOption.value;
    selectedOption = selectedOption === null ? '' : selectedOption;
    const {data: response} = await request({
      method: 'get',
      url: `${APIS.get_images}?image_category_id=${categoryId}`
    })

    this.setState({
      images: response.images,
      category_id: categoryId,
      current_page: response.current_page,
      total_pages: response.total_pages,
      selectedOption: selectedOption
    })
  }

  async fetchImage(page) {
    const {data: response} = await request({
      method: 'get',
      url: `${APIS.get_images}?image_category_id=${this.state.category_id}&page=${page}`
    })

    this.setState({
      images: response.images,
      current_page: response.current_page,
      total_pages: response.total_pages
    })
  }

  renderImages() {
    return (
      <React.Fragment>
        {this.state.images.map((item, index) => {
          let imageClassName = "media-image"
          let mv_image_field = `mv_image_id`
          if(this.props.positionChild !== "") {
            mv_image_field = `mv_image0${this.props.positionChild+1}_id`
          }

          if(this.props[mv_image_field] == item.id) {
            imageClassName += " selected"
          }
          return (
            <div className={imageClassName} key={index} data-id={item.id}
                 style={{backgroundImage: `url(${item.url})`}} onClick={(event) => this.props.handleImage(event, this.props.positionChild)}/>
          )
        })}
      </React.Fragment>
    )
  }

  renderCategories() {
    let options = [];
    options.push({ value: '0', label: '未選択' });

    this.state.categories.map(item => {
      options.push({value: item.id, label: item.name})
    })

    return(
      <Select
        name="form-field-name"
        value={this.state.selectedOption}
        onChange={this.handleChange}
        options={options}
        searchable={false}
        clearable={false}
        optionClassName='custom-option'
        placeholder='未選択'
      />
    )
  }

  range(start, end) {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
  }

  handleChooseImage({target}) {
    const formData = new FormData();
    let thisState = this;
    if (target.files.length > 20) {
      alert("20枚以下の画像をアップロードしてください。");
      return;
    }

    const _validFileExtensions = [".jpg", ".jpeg", ".bmp", ".png", ".gif"];

    for (let i = 0; i < target.files.length; i++) {
      const file = target.files[i];
      const sFileName = file.name;

      if (sFileName.length > 0) {
        let blnValid = false;
        for (let j = 0; j < _validFileExtensions.length; j++) {
          const sCurExtension = _validFileExtensions[j];
          if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() === sCurExtension.toLowerCase()) {
            blnValid = true;
            break;
          }
        }

        if (!blnValid) {
          alert("画像ファイルで使用できる拡張子は、jpg/jpeg/gif/png/bmpです。");
          return;
        }
      }

      if (file.size > 2 * 1024 * 1024) {
        alert("アップロード画像のサイズが2MBを超えています");
        $("#upload_image").val("");
        return;
      }

      formData.append("img[]", file);
      formData.append("image_category_id", this.state.category_id);
    }

    request({
      url: APIS.image_upload,
      method: 'POST',
      data: formData,
    }).then(function(data) {
      if (!data.errors) {
        thisState.fetchImage();
      }
    })
  }

  renderPagination() {
    if (this.state.total_pages > 0)  {
      return (
        <ul className="pagination" role="navigation" aria-label="pager">
          {
            this.state.current_page > 1  &&
            <React.Fragment>
              <li data-image-start="-2">
                <a className={`page-link ${this.state.current_page === 1 && "nav-btn-disabled"}`}
                   onClick={() => this.fetchImage(1)}>
                  ««
                </a>
              </li>
              <li data-image-start="-1">
                <a className={`page-link ${this.state.current_page === 1 && "nav-btn-disabled"}`}
                   onClick={() => this.fetchImage(this.state.current_page - 1)}>
                  «
                </a>
              </li>
            </React.Fragment>
          }
          {
            this.range(1, this.state.total_pages).map((item, index) => {
              let classNameLi = (item === this.state.current_page) ? "active" : ""
              if (item < this.state.current_page) {
                classNameLi = "active_p1"
              } else if (item > this.state.current_page) {
                classNameLi = "active_n1"
              }

              return (
                <li className={classNameLi} key={index} data-image-start={index+1}>
                  {this.state.current_page === item && <a href="#">{item}</a>}
                  {
                    this.state.current_page !== item &&
                    <a href="#" onClick={() => this.fetchImage(item)}>{item}</a>
                  }
                </li>
              )
            })
          }
          {
            this.state.total_pages > this.state.current_page &&
            <React.Fragment>
              <li data-image-start="+1">
                <a href="#" className={`page-link ${this.state.current_page === this.state.total_pages && "nav-btn-disabled"}`}
                   onClick={() => this.fetchImage(this.state.current_page + 1)}>»</a>
              </li>
              <li data-image-start="+2">
                <a href="#" className={`page-link ${this.state.current_page === this.state.total_pages && "nav-btn-disabled"}`}
                   onClick={() => this.fetchImage( this.state.total_pages)}>»»</a>
              </li>
            </React.Fragment>
          }
        </ul>
      )
    }
    return (<div/>)
  }

  stepWidth () {
    const { isHome, isConfig, isBlockImage } = this.props;
    if (isHome || isConfig) {
      return "33.3333%"
    }
    else if (isBlockImage) {
      return "50%"
    }

    return "100%"
  }

  render() {
    const { isHome, positionChild, isBlockImage } = this.props;
    let mv_image_field = `mv_image_id`
    if (positionChild !== "") {
      mv_image_field = `mv_image0${positionChild+1}_id`
    }
    const mv_image_id = this.props[mv_image_field];
    return (
      <div id={isHome ? 'block_mv' : 'block_lv'} className="step" style={{width: this.stepWidth()}}>
        <div className="wrap" id="img_modal">
          <div id="loaded_img_modal">
            <div className="item f">
              <span className="custom-select">
                { this.renderCategories() }
              </span>
              <label className="images-upload">
                アップロード
                <input type="file" name="image" id="image" style={{ display: "none"}} accept="image/*" multiple="multiple"
                      onChange={this.handleChooseImage}/>
              </label>
            </div>
            <div className="item">
              <div className="modal-body">
                <div className={`media-image none-image ${mv_image_id == '' ? 'selected' : ''}`} data-name="no_image.jpg" onClick={this.props.removeImage}><span><i>✕</i><p>画像無し</p></span></div>
                {this.state.images.length > 0 && this.renderImages()}
                <nav className="col-xs-12 text-center">
                  {this.state.images.length > 0 && this.state.total_pages > 1 && this.renderPagination()}
                </nav>
              </div>
            </div>
          </div>
        </div>
        {isBlockImage && <ImageModalFooter isOpenImageAdditional={this.props.isOpenImageAdditional} handleDisplaySetting={this.props.handleDisplaySetting}/>}

        {isHome && <div className="modal_footer" style={{display: 'flex'}}><a href="#" data-step="1" onClick={this.props.handleScreen}>« 画像設定へ</a></div>}
      </div>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);

  newState.headerSetting = storeState.headerSetting;

  return newState;
}

export default connect(mapStateToProps)(ChooseImage);
