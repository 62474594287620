import React, { Component } from 'react';
import { connect } from 'react-redux';
import MvImage from './MvImage';
import MvMovie from './MvMovie';
import ContentEditable from 'react-contenteditable';
import { updateSetting } from "../../../Lib/redux/actions";

export class MvSliderImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {...this.props.settings},
      isEntryTitleEdit: false,
      isEntryTitle2Edit: false
    };
    this.switchingPattern = [
      'slide01',
      'slide02',
      'slide03',
      'slide04',
      'slide05',
      'slide06',
      'slide07',
      'slide08',
      'slide09',
      'slide10',
      'slide01',
    ];
    this.contentEditable = React.createRef();
    this.contentEditable2 = React.createRef();
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeAlign = this.handleChangeAlign.bind(this)
    this.handleDisplayAlign = this.handleDisplayAlign.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      settings: nextProps.settings
    })
  }

  handleChange(event, field) {
    const { settings } = this.state;

    settings[field] = event.target.value;
    this.setState({ settings }, () => { this.props.processSetting(this.state.settings) } );
  }

  handleChangeAlign(field, value) {
    const { settings } = this.state;

    settings[field] = value;
    this.setState({ settings }, () => { this.props.processSetting(this.state.settings) } );
  }

  handleDisplayAlign(field) {
    if (field === "entry_title") {
      this.setState({ isEntryTitleEdit: true })
    } else if (field === "entry_title2") {
      this.setState({ isEntryTitle2Edit: true })
    }
  }

  handleOnBlurDisplayAlign(field) {
    if (field === "entry_title") {
      this.setState({ isEntryTitleEdit: false })
    } else if (field === "entry_title2") {
      this.setState({ isEntryTitle2Edit:  false })
    }
  }

  renderContentEditableMenu() {
    return(
      <ul className="contenteditable_menu active" style={{top: "-40px", left: "0"}}>
        <li key={1} className="contenteditable_menu_justifyleft" style={{padding: "0px" }}
            title="左揃え" onMouseDown={() => this.handleChangeAlign("entry_title_class", "align-left")} />
        <li key={2} className="contenteditable_menu_justifycenter" style={{padding: "0px" }}
            title="中央揃え" onMouseDown={() => this.handleChangeAlign("entry_title_class", "align-center")} />
        <li key={3} className="contenteditable_menu_justifyright" style={{padding: "0px" }}
            title="右揃え" onMouseDown={() => this.handleChangeAlign("entry_title_class", "align-right")} />
      </ul>
    )
  }

  render() {
    const { slide_pattern, slide_speed, mv_youtubeid, character_set, mv_type, settings } = this.props;
    let editEntryTitle = this.state.isEntryTitleEdit ? "active" : "";
    let editEntryTitle2 = this.state.isEntryTitleEdit2 ? "active" : "";
    let new_slide_speed = slide_pattern == 11 ? 0 : slide_speed;

    return (
      <div id="mv" className={this.switchingPattern[slide_pattern - 1]} data-animation-duration={new_slide_speed} style={{opacity: 1, minHeight: '264px'}}>
        <React.Fragment>
          {mv_type == 1 && <MvImage slide_speed={new_slide_speed} />}
          {mv_type == 2 && <MvMovie youtubeId={mv_youtubeid} />}
        </React.Fragment>

        <div className={`mv_text t_align_center ${character_set == 1 && 'wmode_horizontal'}`}>
          <div>
            <h2
              className={`contenteditable ${ editEntryTitle } ${settings.entry_title_class}`}
              style={{ fontSize: '44.4167px' }}
            >
              <ContentEditable
                innerRef={this.contentEditable}
                html={settings.entry_title} // innerHTML of the editable div
                disabled={!this.state.isEntryTitleEdit}       // use true to disable editing
                onChange={(event) => this.handleChange(event, 'entry_title')} // handle innerHTML change
                tagName='h2' // Use a custom HTML tag (uses a div by default)
                tabIndex={0}
                onClick={() => this.handleDisplayAlign("entry_title")}
                onBlur={() => this.handleOnBlurDisplayAlign("entry_title")}
              />
              {this.state.isEntryTitleEdit && this.renderContentEditableMenu()}
            </h2>
            <p
              className={`static contenteditable ${ editEntryTitle2 }`}
            >
              <ContentEditable
                innerRef={this.contentEditable2}
                html={settings.entry_title2} // innerHTML of the editable div
                disabled={!this.state.isEntryTitle2Edit}       // use true to disable editing
                onChange={(event) => this.handleChange(event, 'entry_title2')} // handle innerHTML change
                tagName='p' // Use a custom HTML tag (uses a div by default)
                className={`static pt0 ${settings.entry_title_class}`}
                tabIndex={1}
                onClick={() => this.handleDisplayAlign("entry_title2")}
                onBlur={() => this.handleOnBlurDisplayAlign("entry_title2")}
              />
              {this.state.isEntryTitle2Edit && this.renderContentEditableMenu()}
            </p>
          </div>
        </div>
        <div className="mv_nav">
          <div className="mv_scroll"><a href="#main" draggable="false">SCROLL DOWN</a></div>
          <ul className="mv_pointer"></ul>
          <ul className="mv_arrow">
            <li className="mv_arrow_prev"><span>Forward</span></li>
            <li className="mv_arrow_next"><span>next</span></li>
          </ul>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.slide_pattern = storeState.settings.slide_pattern
  newState.slide_speed = storeState.settings.slide_speed
  newState.mv_type = storeState.settings.mv_type
  newState.mv_youtubeid = storeState.settings.mv_youtubeid
  newState.character_set = storeState.settings.character_set
  newState.settings = storeState.settings

  return newState;
}


const mapDispatchToProps = (dispatch) => {
  return {
    processSetting: (data) => {dispatch(updateSetting(data))},
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MvSliderImages);
