import React, { Component } from 'react'

export class ImageModalFooter extends Component {
  render() {
    return (
      <div className="modal_footer">
        <a href="#" onClick={this.props.handleDisplaySetting}>{this.props.isOpenImageAdditional ? '« 画像選択へ' : 'その他設定へ »'}</a>
      </div>
    )
  }
}

export default ImageModalFooter
