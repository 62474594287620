import React, { Component } from 'react';
import request from "../../../untils/request";
import {APIS} from "../../../untils/constants";
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';

export class FavoriteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      favorite: {
        name: '',
        favorite_category_id: '',
      }
    };
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeCategories = this.handleChangeCategories.bind(this)
    this.handleDataForm = this.handleDataForm.bind(this)
  }

  async componentDidMount() {
    const {data: response} = await request({
      method: 'get',
      url: APIS.get_favorite_categories
    })

    this.setState({
      categories: response.categories
    })
  }

  handleChange(event) {
    let {favorite} = this.state;
    favorite[event.target.name] = event.target.value;

    this.setState({
      favorite: favorite
    })
  }

  handleChangeCategories(selectedOption) {
    if (selectedOption.value !== 0) {
      let {favorite} = this.state;
      favorite["favorite_category_id"] = selectedOption.value;

      this.setState({
        favorite: favorite
      })
    }
  }

  handleDataForm() {
    let formData = this.state.favorite;

    let url = APIS.favorites_path;
    let contents = this.props.content.contents;
    request.post(url, {
      data: {
        form_data: formData,
        content: contents,
        css: this.props.content.css,
        block_type: this.props.content.block_type,
        kind_of_design: this.props.content.kind_of_design,
        block_image_url: this.props.content.block_image_url,
        block_image_id: this.props.content.block_image_id,
        block_image0_url: this.props.content.block_image0_url,
        block_image0_id: this.props.content.block_image0_id,
        block_image1_url: this.props.content.block_image1_url,
        block_image1_id: this.props.content.block_image1_id,
        block_image2_url: this.props.content.block_image2_url,
        block_image2_id: this.props.content.block_image2_id,
        block_image3_url: this.props.content.block_image3_url,
        block_image3_id: this.props.content.block_image3_id,
        block_image4_url: this.props.content.block_image4_url,
        block_image4_id: this.props.content.block_image4_id,
        block_image_text2_other: this.props.content.block_image_text2_other,
        blog_number: this.props.content.blog_number,
        categories: this.props.content.categories,
        block_link_url: this.props.content.block_link_url
      },
      headers: {'Content-Type': 'application/json'}
    }).then(res => {
      this.props.handleShowModalFavorite(null)

      toast.info("お気に入りを登録しました。", {
        position: toast.POSITION.TOP_RIGHT
      });
    }).catch(e => {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })
  }

  renderChooseCategories() {
    let options = [];
    options.push({ value: '0', label: '-- 選択してください --' });

    this.state.categories.map(item => {
      options.push({value: `${item.id}`, label: item.name})
    })

    return(
      <Select
        name="form-field-name"
        onChange={this.handleChangeCategories}
        options={options}
        searchable={false}
        clearable={false}
        optionClassName='custom-option'
        placeholder='-- 選択してください --'
      />
    )
  }

  render() {
    const { indexBlockData } = this.props
    return (
      <div id="setting_modal" data-step="#block_header" className="active admin_edit_menu_add admin_edit_extended_settings loaded">
        <div className="wrapper">
          <div id="contents" data-type="admin_edit_add">
            <p className="modal_header">
              <i>お気に入り</i>
              <span className="modal_header_btn cancel first_button" onClick={() => this.props.handleShowModalFavorite(null)}>キャンセル</span>
              <span className="modal_header_btn ok" onClick={this.handleDataForm} >決定</span>
            </p>
            <div id="content_r" style={{opacity: 1}}>
              <div className="wrap">
                <h3><span>新規登録</span></h3>
                <div className="item">
                  <table className="table table-striped table-bordered table-hover" id="dataTables-example" width="100%">
                    <tbody>
                      <tr>
                        <th width="30%">タイトル  <span className="caution">※</span></th>
                        <td>
                          <input type="text" name="name" id="favorite_title" className="form-control"
                                 value={this.state.favorite.name} onChange={this.handleChange} />
                        </td>
                      </tr>
                      <tr>
                        <th>カテゴリー</th>
                        <td>
                          <span className="custom-select" value={this.state.favorite.favorite_category_id}>
                            {this.renderChooseCategories()}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    )
  }
}

export default FavoriteModal
