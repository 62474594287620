require('datatables.net-bs4');

$( document ).ready(function() {
  if($('[id^=comparison_categories_datatable_wrapper]').length == 0 && $('#comparison_categories_datatable').length > 0) {
    table = $('#comparison_categories_datatable').DataTable({
      dom: "<'row'<'col-6'l><'#comparison_category_btn_add.col-6 text-right'>>" +
        "<'row'<'#comparison_category_note.col-sm-12 text-right pt-3'>>" +
        "<'row'<'col-sm-12 mt-3'tr>>" +
        "<'float-right mb-3'p>",
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        emptyTable: "カテゴリーは登録されておりません",
        zeroRecords: "カテゴリーは登録されておりません",
        lengthMenu: "_MENU_ を表示する",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        }
      },
      order: [],
      ordering: false,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
          width: "10%",
        },
        {
          targets: 2,
          orderable: false,
          width: "20%",
        },
      ],
      drawCallback: function(settings) {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);
      },
      stateSave: true
    });

    $('#comparison_category_note').append("<span class='text-danger'>※データが存在するカテゴリーは削除できません</span>")
    $('#comparison_category_btn_add').append(`<a href='${$('#comparison_categories_datatable').data("add-new-link")}' class='btn btn-info'>新規追加</a>`);
  }
});
