import React, { Component } from 'react'

export class MvTypeSlider extends Component {
  render() {
    const { settings } = this.props
    return (
      <div className="mvtype mvtype_slide">
        <div className="item">
          <p>切替パターン</p>
          <input type="radio"
            name="slide_pattern"
            value="1"
            id="pages-slide-pattern_1"
            defaultChecked={ settings.slide_pattern == 1 ? 'checked' : '' }
            onChange={this.props.handleChange}
          />
          <label htmlFor="pages-slide-pattern_1" className="radio-inline">ズームイン</label>
          <input type="radio"
            name="slide_pattern"
            value="2"
            id="pages-slide-pattern_2"
            defaultChecked={ settings.slide_pattern == 2 ? 'checked' : '' }
            onChange={this.props.handleChange}
          />
          <label htmlFor="pages-slide-pattern_2" className="radio-inline">ズームアウト</label>
          <input type="radio"
            name="slide_pattern"
            value="3"
            id="pages-slide-pattern_3"
            defaultChecked={ settings.slide_pattern == 3 ? 'checked' : '' }
            onChange={this.props.handleChange}
          />
          <label htmlFor="pages-slide-pattern_3" className="radio-inline">横スライド</label>
          <input type="radio"
            name="slide_pattern"
            value="5"
            id="pages-slide-pattern_5"
            defaultChecked={ settings.slide_pattern == 4 ? 'checked' : '' }
            onChange={this.props.handleChange}
          />
          <label htmlFor="pages-slide-pattern_5" className="radio-inline">横スライド (トリミングなし)</label>
          <input type="radio"
            name="slide_pattern"
            value="4"
            id="pages-slide-pattern_4"
            defaultChecked={ settings.slide_pattern == 5 ? 'checked' : '' }
            onChange={this.props.handleChange}
          />
          <label htmlFor="pages-slide-pattern_4" className="radio-inline">フェードイン (トリミングなし)</label>
          <input type="radio"
            name="slide_pattern"
            value="6"
            id="pages-slide-pattern_6"
            defaultChecked={ settings.slide_pattern == 6 ? 'checked' : '' }
            onChange={this.props.handleChange}
          />
          <label htmlFor="pages-slide-pattern_6" className="radio-inline">2枚スライド ズームイン</label>
          <input type="radio"
            name="slide_pattern"
            value="7"
            id="pages-slide-pattern_7"
            defaultChecked={ settings.slide_pattern == 7 ? 'checked' : '' }
            onChange={this.props.handleChange}
          />
          <label htmlFor="pages-slide-pattern_7" className="radio-inline">2枚スライド ズームアウト</label>
          <input type="radio"
            name="slide_pattern"
            value="8"
            id="pages-slide-pattern_8"
            defaultChecked={ settings.slide_pattern == 8 ? 'checked' : '' }
            onChange={this.props.handleChange}
          />
          <label htmlFor="pages-slide-pattern_8" className="radio-inline">カルーセルスライド</label>
          <input type="radio"
            name="slide_pattern"
            value="9"
            id="pages-slide-pattern_9"
            defaultChecked={ settings.slide_pattern == 9 ? 'checked' : '' }
            onChange={this.props.handleChange}
          />
          <label htmlFor="pages-slide-pattern_9" className="radio-inline">横スライドズームイン</label>
          <input type="radio"
            name="slide_pattern"
            value="10"
            id="pages-slide-pattern_10"
            defaultChecked={ settings.slide_pattern == 10 ? 'checked' : '' }
            onChange={this.props.handleChange}
          />
          <label htmlFor="pages-slide-pattern_10" className="radio-inline">横スライドズームアウト</label>
          <input type="radio"
                 name="slide_pattern"
                 value="11"
                 id="pages-slide-pattern_11"
                 defaultChecked={ settings.slide_pattern == 11 ? 'checked' : '' }
                 onChange={this.props.handleChange}
          />
          <label htmlFor="pages-slide-pattern_11" className="radio-inline">切替パターンなし</label>
          <p><small>トリミングなしのパターンを選択する場合は各MV画像のサイズを統一することをおすすめします。</small>
          </p>
        </div>
        <div className="item">
          <p>切替スピード</p>
          <span className="select">
            <select name="slide_speed" defaultValue={settings.slide_speed} onChange={this.props.handleChange}>
              <option value="1">1秒</option>
              <option value="2">2秒</option>
              <option value="3">3秒</option>
              <option value="4">4秒</option>
              <option value="5">5秒</option>
              <option value="6">6秒</option>
              <option value="7">7秒</option>
              <option value="8">8秒</option>
              <option value="9">9秒</option>
              <option value="10">10秒</option>
            </select>
          </span>
        </div>
      </div>
    )
  }
}

export default MvTypeSlider
