import React, { Component } from 'react'
import ImageModalFooter from '../../Parts/Modal/ImageModalFooter'

export default class ImageAdditional extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const {name, value} = this.props
    return (
      <>
        <div className="step">
          <div className="wrap">
            <div className="item">
              <p>画像代替テキスト</p>
              <input name={name} defaultValue={this.props.value} type={value} onChange={this.props.changeAlt}/>
            </div>
          </div>
          <ImageModalFooter isOpenImageAdditional={this.props.isOpenImageAdditional} handleDisplaySetting={this.props.handleDisplaySetting} />
        </div>
      </>
    )
  }
}
