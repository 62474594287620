require('datatables.net-bs4');

$( document ).ready(function() {
  if($('[id^=menu_categories_datatable_wrapper]').length == 0 && $('#menu_categories_datatable').length > 0) {
    table = $('#menu_categories_datatable').DataTable({
      dom: "<'row'<'col-6'l><'#menu_category_btn_add.col-6 text-right'>>" +
        "<'row'<'#menu_category_note.col-sm-12 text-right pt-3'>>" +
        "<'row'<'col-sm-12 mt-3'tr>>" +
        "<'float-right mb-3'p>",
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        emptyTable: "カテゴリーは登録されておりません",
        zeroRecords: "カテゴリーは登録されておりません",
        lengthMenu: "_MENU_ を表示する",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        }
      },
      order: [],
      ordering: false,
      autoWidth: false,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
        },
        {
          targets: 2,
          orderable: false,
          width: "10%"
        },
      ],
      drawCallback: function(settings) {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);

        reload_checkbox_all_state();
      },
      stateSave: true
    });

    $('#menu_category_note').append("<span class='text-danger'>※データが存在するカテゴリーは削除できません</span>")
    $('#menu_category_btn_add').append(`<a href='${$('#menu_categories_datatable').data("add-new-link")}' class='btn btn-info'>新規追加</a>`);
  }

  $('#menu_categories_check_all').on('click', function() {
    if (this.checked) {
      $('.checkbox_menu_category').not(':disabled').prop('checked', true);
    } else {
      $('.checkbox_menu_category').not(':disabled').prop('checked', false);
    }
  });

  $('.checkbox_menu_category').on('click', function() {
    number_of_checkbox_unchecked = $('.checkbox_menu_category:not(:checked)').length;
    if (number_of_checkbox_unchecked > 0) {
      $('#menu_categories_check_all').prop('checked', false);
    } else {
      $('#menu_categories_check_all').prop('checked', true);
    }
  });
});

function reload_checkbox_all_state() {
  number_of_checkbox_enable = $('.checkbox_menu_category').not(':disabled').length;
  number_of_checkbox_unchecked = $('.checkbox_menu_category:not(:checked)').not(':disabled').length;

  if (number_of_checkbox_enable == 0 || number_of_checkbox_unchecked > 0) {
    $('#menu_categories_check_all').prop('checked', false);
  } else {
    $('#menu_categories_check_all').prop('checked', true);
  }
}

