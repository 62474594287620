$(document).ready(function() {
  $(document).on('change', 'input[name="navigation[header_link_type]"]', function() {
    const valueInput = this.value;
    if(valueInput == 1) {
      $('#navigation_header_link01').addClass('display-none');
      $('#label_header_link_target_0').addClass('display-none');
      $('#header_link_input01').removeClass('display-none');
      $("#navigation_header_link_target_1").prop("checked", true);
    } else {
      $('#navigation_header_link01').removeClass('display-none');
      $('#label_header_link_target_0').removeClass('display-none');
      $('#header_link_input01').addClass('display-none');
    }
  });
  $(document).on('change', 'input[name="navigation[footer_link_type]"]', function() {
    const valueInput = this.value
    if(valueInput == 1) {
      $('#navigation_footer_link01').addClass('display-none');
      $('#label_footer_link_target_0').addClass('display-none');
      $('#footer_link_input01').removeClass('display-none');
      $("#navigation_footer_link_target_1").prop("checked", true);
    } else {
      $('#navigation_footer_link01').removeClass('display-none');
      $('#label_footer_link_target_0').removeClass('display-none');
      $('#footer_link_input01').addClass('display-none');
    }
  });
});
