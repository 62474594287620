import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ModdablePart from '../ModdablePart';
import { IMAGES } from '../../../../../untils/constants';
import { resetCategories } from '../../../../../functions/resetCategories';

export class RecruitItems extends Component {
  static propTypes = {
    recruits: PropTypes.array,
  };

  static defaultProps = {
    recruits: [],
  };

  constructor(props) {
    super(props);
    this.title = 'リクルートデザイン';
    this.handleRecruitData = this.handleRecruitData.bind(this);
  }

  componentDidMount() {
    const newData = resetCategories(this.props.itemChecked, this.props.recruitCategories)
    this.props.handleData(newData)   
  }

  handleRecruitData(item, contents) {
    const { recruitCategories, recruits } = this.props;
    const newData = resetCategories(item, recruitCategories)
    if (recruits.length > 0) {
      contents.recruit_id = recruits[0].id

      newData.contents = JSON.stringify(contents)
    }

    return newData
  }

  render() {
    return (
      <ModdablePart title={this.title}>
        <ul className="no_select">
          {
            this.props.data.map((item, index) => {
              let contents = JSON.parse(item.contents)
              return(
                <li key={index}>
                  <input name="block_recruit_pattern" id={`block_recruit_pattern${item.kind_of_design}`}  type="radio"
                    defaultChecked={this.props.itemChecked.kind_of_design == item.kind_of_design} />
                  <label
                    htmlFor={`block_recruit_pattern${item.kind_of_design}`}
                    onClick={() => this.props.handleDoubleClickItem(this.handleRecruitData(item, contents))}
                  >
                    <img src={IMAGES[contents.src]}
                  />
                  </label>
                </li>
              )
            })
          }
        </ul>
      </ModdablePart>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.recruits = storeState.recruits
  
  return newState;
}

export default connect(mapStateToProps)(RecruitItems)
