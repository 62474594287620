import React, { Component } from 'react';
import SlideToggle from "react-slide-toggle";

export class BasicInformation extends Component {
  constructor(props) {
    super(props);
  }

  suggestList() {
    const {showSuggests, newpageTags, tagsList} = this.props;
    if (!showSuggests) return [];
    let suggestList = [];

    tagsList.forEach(tag => {
      let related = true;

      newpageTags.forEach(choosen_tag => {
        if (tag.name === choosen_tag || !tag.name.includes(choosen_tag))
          related = false;
      })

      if (related) suggestList.push(tag.name);
    })

    return suggestList.slice(0, 10);
  }

  render() {
    const { pageKind, tenants } = this.props
    return (
      <div className="step">
        <div className="wrap">
          <h3><span>基本設定</span></h3>
          <div className="item">
            <table className="table table-striped table-bordered table-hover" id="dataTables-example" width="100%">
              <tbody>
                {pageKind == 'copy_template' && (
                  <tr>
                    <th>クライアント名<span className="caution">※</span></th>
                    <td>
                      <select
                        name="tenant_id"
                        defaultValue={this.props.settings.tenant_id}
                        onChange={this.props.handleChange}
                        className="form-control"
                      >
                        <option value="">-- 選択してください --</option>
                        {tenants.map(item => {
                          let item_name = item.name
                          if(item.name.length > 50) {
                            item_name = item.name.slice(0, 50) + "..."
                          }
                          return (
                            <option key={item.id} value={item.id}>{item_name}</option>
                          )
                        })}
                      </select>
                      {this.props.errorMessages.tenant_id.message && <p><small className="required">{this.props.errorMessages.tenant_id.message}</small></p>}
                    </td>
                  </tr>
                )}

                <tr>
                  <th>タイトル</th>
                  <td>
                    <input type="text" maxLength="9999" name="title" onChange={this.props.handleChange} value={this.props.settings.title} id="page_title" className="form-control" placeholder="titleタグに使用されます" />
                    {this.props.errorMessages.title.message && <small className="required">{this.props.errorMessages.title.message}</small>}
                    <p className="help-block text-right"><span id="title_count" style={{color: this.props.settings.title.length > 35 ? 'red' : 'black'}}>{this.props.settings.title.length} 文字</span> / 35文字</p>
                  </td>
                </tr>
                <tr>
                  <th>h1テキスト</th>
                  <td>
                    <input type="text" maxLength="9999" name="h1_text" onChange={this.props.handleChange} value={this.props.settings.h1_text} id="page_h1" className="form-control" placeholder="ページ上部にあるh1タグテキストに使用されます" />
                    {this.props.errorMessages.h1_text.message && <small className="required">{this.props.errorMessages.h1_text.message}</small>}
                    <p className="help-block text-right"><span id="h1_count" style={{color: this.props.settings.h1_text.length > 40 ? 'red' : 'black'}}>{this.props.settings.h1_text.length} 文字</span> / 40文字</p>
                  </td>
                </tr>
                <tr>
                  <th>ページ名 <span className="caution">※</span></th>
                  <td>
                    <input type="text" maxLength="1024" name="name" onChange={this.props.handleChange} value={this.props.settings.name} className="form-control" placeholder="ナビメニュー及びパンクズリストに使用されます" />
                    {this.props.errorMessages.name.message && <small className="required">{this.props.errorMessages.name.message}</small>}
                  </td>
                </tr>
                <tr>
                  <th>ディスクリプション</th>
                  <td>
                    <input type="text" maxLength="250" name="description" onChange={this.props.handleChange} value={this.props.settings.description} id="page_description" className="form-control" placeholder="meta description及び関連記事リンクの説明文に使用されます" />
                    {this.props.errorMessages.description.message && <small className="required">{this.props.errorMessages.description.message}</small>}
                    <p className="help-block text-right"><span id="description_count">{this.props.settings.description.length}</span> 文字 / 250文字</p>
                  </td>
                </tr>
                <tr>
                  <th>ディレクトリ名 <span className="caution">※</span></th>
                  <td>
                    <input type="text" maxLength="50" disabled={this.props.pageKind === 'home'} name="slug" id="slug" onChange={this.props.handleChange} value={this.props.settings.slug}
                           className="form-control" placeholder="URL及びキービジュアル上のテキストに使用されます" />
                    {this.props.errorMessages.slug.message && <small className="required">{this.props.errorMessages.slug.message}</small>}
                  </td>
                </tr>
                <tr>
                  <th width="30%">キーワード（タグ）</th>
                  <td>
                      <div className="input-group">
                        <input maxLength="50" type="text" id="inputTag" name="input_tag" className="form-control" placeholder="タグを入力してください" value={this.props.settings.input_tag} onChange={this.props.handleChange} />
                        <span className="input-group-btn">
                        <button type="button" className="btn btn-default addTag" onClick={() => this.props.addTag(this.props.settings.input_tag, true)}>追加</button>
                        </span>
                        {this.props.errorMessages.input_tag.message && <small className="required">{this.props.errorMessages.input_tag.message}</small>}
                      </div>
                      <p className="help-block">他のページと関連付けに使用されます</p>
                      <div className="tagGroup" parent-id="">
                        {
                          this.props.newpageTags && this.props.newpageTags.map((tag, index) => {
                            return (
                              <div key={index} className="tagItem">
                                <span className="glyphicon glyphicon-remove removeTag" aria-hidden="true" onClick={() => this.props.removeTag(tag)}></span>
                                <span className="spanTag">{tag}</span>
                              </div>
                            )
                          })
                        }
                      </div>

                      {
                        this.props.errorMessages.tag.message &&
                        <small className="required">{this.props.errorMessages.tag.message}</small>
                      }

                      <div id="suggests" className="btn-group btn-group-sm suggests" role="group" style={{marginBottom: '10px'}}>
                        {
                          this.suggestList().map((tag, index) => {
                            return (
                              <button key={index} type="button" className="btn btn-default suggestTag" style={{ margin: '0 5px 5px 0', borderRadius: '2px' }} onClick={() => this.props.addTag(tag, false)}>{tag}</button>
                            )
                          })
                        }
                      </div>
                      <SlideToggle
                        duration={400}
                        render={({ onToggle, setCollapsibleElement }) => (
                          <div className="box">
                            <p onClick={onToggle}>最近登録されたキーワード（タグ）</p>
                            <div id="recently_tag" className="btn-group btn-group-sm suggests" role="group" ref={setCollapsibleElement}>
                              {
                                this.props.tagsList && this.props.tagsList.slice(0, 10).map((tag, index) => {
                                  return (
                                    <button key={index} type="button" className="btn btn-default suggestTag" style={{ margin: '0 5px 5px 0', borderRadius: '2px' }} onClick={() => this.props.addTag(tag.name, false)}>{tag.name}</button>
                                  )
                                })
                              }
                            </div>
                          </div>
                        )}
                      />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default BasicInformation
