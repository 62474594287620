import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ContentEditable from 'react-contenteditable';

export class HeadingComponent extends Component {
  static propTypes = {
    isDynamicClass: PropTypes.bool,
  };

  static defaultProps = {
    isDynamicClass: true,
  };

  constructor(props) {
    super(props);
    this.contentEditable = React.createRef()
    this.contentEditable2 = React.createRef()
    this.renderHeadline = this.renderHeadline.bind(this)
    this.renderSubHeadline = this.renderSubHeadline.bind(this)
    this.handleDisplayAlign = this.handleDisplayAlign.bind(this)
    this.state = {
      isHeadingEdit: false,
      isSubHeadingEdit: false
    }
  }

  handleDisplayAlign(flag) {
    if (flag === "headline") {
      this.setState({ isHeadingEdit: !this.state.isHeadingEdit })
    } else if (flag === "subHeadline") {
      this.setState({ isSubHeadingEdit: !this.state.isSubHeadingEdit })
    }

  }

  renderContentEditableMenu() {
    return(
      <ul className="contenteditable_menu active" style={{top: "-40px", left: "0"}}>
        <li key={1} className="contenteditable_menu_justifyleft" style={{padding: "0px" }}
            title="左揃え" onMouseDown={(event) => this.props.handleChangeAlign(this.props.index, "align-left", "headingClass", event)} />
        <li key={2} className="contenteditable_menu_justifycenter" style={{padding: "0px" }}
            title="中央揃え" onMouseDown={(event) => this.props.handleChangeAlign(this.props.index, "align-center", "headingClass", event)} />
        <li key={3} className="contenteditable_menu_justifyright" style={{padding: "0px" }}
            title="右揃え" onMouseDown={(event) => this.props.handleChangeAlign(this.props.index, "align-right", "headingClass", event)} />
      </ul>
    )
  }

  renderHeadline() {
    let editable = this.props.isEdit ? "contenteditable" : "";
    editable += this.state.isHeadingEdit ? " active" : ""

    if (this.props.isEdit) {
      return (
        <h2 className={`h ${editable}`} onFocus={() => this.handleDisplayAlign("headline")}
            onBlur={() => this.handleDisplayAlign("headline")}>
          <ContentEditable
            innerRef={this.contentEditable}
            html={this.props.content.headline} // innerHTML of the editable div
            disabled={false}       // use true to disable editing
            onKeyUp={(event) => this.props.handleChangeHeadline(this.props.index, event, "headline")} // handle innerHTML change
            tagName='span' // Use a custom HTML tag (uses a div by default)
          />
          {this.state.isHeadingEdit && this.renderContentEditableMenu()}
        </h2>
      )
    }

    return (<h2 className={`h ${editable} pre-wrap`} dangerouslySetInnerHTML={{__html: this.props.content.headline}} />)
  }

  renderSubHeadline() {
    let editable = this.props.isEdit ? "contenteditable" : "";
    editable += this.state.isSubHeadingEdit ? " active" : ""

    if (this.props.isEdit) {
      return (
        <p className={editable} onFocus={() => this.handleDisplayAlign("subHeadline")}
           onBlur={() => this.handleDisplayAlign("subHeadline")}>
          <ContentEditable
            innerRef={this.contentEditable2}
            html={this.props.content.subheadline} // innerHTML of the editable div
            disabled={false}       // use true to disable editing
            onKeyUp={(event) => this.props.handleChangeHeadline(this.props.index, event, "subheadline")} // handle innerHTML change
            tagName='span' // Use a custom HTML tag (uses a div by default)
          />
          {this.state.isSubHeadingEdit && this.renderContentEditableMenu()}
        </p>
      )
    }

    return (<p className={`${editable} pre-wrap`} dangerouslySetInnerHTML={{__html: this.props.content.subheadline}} />)
  }

  render() {
    let headingClass = this.props.isDynamicClass ? this.props.content.headingClass : 'align-center'

    return (
      <div className={`heading block_header_${this.props.type} ${headingClass}`}>
        {this.renderHeadline()}
        {this.props.headlineType === "2" && this.renderSubHeadline()}
      </div>
    )
  }
}

export default HeadingComponent
