import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import MainNoData from './MainNoData';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {
  isDisplayModalAdd,
  savePageDataUi,
  updateSetting,
  saveImageUrl,
  tagsList
} from '../../Lib/redux/actions';
import LoadableHeadingPart from '../Parts/Modal/EmbeddedParts/BlockHeading/LoadableHeadingPart';
import LoadableImagePart from '../Parts/Modal/EmbeddedParts/BlockImage/LoadableImagePart';
import LoadableMoviePart from '../Parts/Modal/EmbeddedParts/BlockMovie/LoadableMoviePart';
import LoadableMapPart from '../Parts/Modal/EmbeddedParts/BlockMap/LoadableMapPart';
import LoadableTextPart from '../Parts/Modal/EmbeddedParts/BlockText/LoadableTextPart';
import LoadableLinePart from '../Parts/Modal/EmbeddedParts/BlockLine/LoadableLinePart';
import LoadableImageTextPart from '../Parts/Modal/EmbeddedParts/BlockImageText/LoadableImageTextPart';
import LoadableImageText2Part from '../Parts/Modal/EmbeddedParts/BlockImageText2/LoadableImageText2Part';
import LoadableTelPart from '../Parts/Modal/EmbeddedParts/BlockTel/LoadableTelPart';
import LoadableTablePart from '../Parts/Modal/EmbeddedParts/BlockTable/LoadableTablePart';
import Cssable from '../Parts/Css/Cssable';
import LoadableLinkPart from '../Parts/Modal/EmbeddedParts/BlockLink/LoadableLinkPart';
import LoadableBlogPart from '../Parts/Modal/EmbeddedParts/BlockBlog/LoadableBlogPart'
import { changeText } from '../../functions';
import LoadableComparisonPart from '../Parts/Modal/EmbeddedParts/BlockComparison/LoadableComparisonPart';
import LoadableQuestionPart from '../Parts/Modal/EmbeddedParts/BlockQuestion/LoadableQuestionPart'
import LoadableMenuPart from '../Parts/Modal/EmbeddedParts/BlockMenu/LoadableMenuPart'
import LoadableGalleryPart from '../Parts/Modal/EmbeddedParts/BlockGallery/LoadableGalleryPart'
import LoadableButtonPart from '../Parts/Modal/EmbeddedParts/BlockButton/LoadableButtonPart';
import LoadableFormPart from '../Parts/Modal/EmbeddedParts/BlockForm/LoadableFormPart'
import LoadableShopPart from "../Parts/Modal/EmbeddedParts/BlockShop/LoadableShopPart";
import LoadableChangeLogPart from '../Parts/Modal/EmbeddedParts/BlockChangeLog/LoadableChangeLogPart';
import LoadableNewsPart from '../Parts/Modal/EmbeddedParts/BlockNews/LoadableNewsPart';
import LoadableCouponPart from '../Parts/Modal/EmbeddedParts/BlockCoupon/LoadableCouponPart';
import LoadableHtmlPart from '../Parts/Modal/EmbeddedParts/BlockHtml/LoadableHtmlPart';
import LoadableStaffPart from "../Parts/Modal/EmbeddedParts/BlockStaff/LoadableStaffPart";
import LoadableRecruitPart from "../Parts/Modal/EmbeddedParts/BlockRecruit/LoadableRecruitPart";
import LoadableConversationPart from "../Parts/Modal/EmbeddedParts/BlockConversation/LoadableConversationPart";
import FavoriteModal from "../Parts/Modal/FavoriteModal";
import request from "../../untils/request";
import { APIS } from "../../untils/constants";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { fetchSectionStyle } from '../../functions/fetchSectionStyle';
import SectionSameCategory from './SectionSameCategory';

const SortableItem = SortableElement(({value, content, displayModalAdd, items, handleSwitchComponents, indexItem}) => {
  return(
    <li tabIndex={0} className="list-component">
      <Cssable css={value.css}>
        <div className="admin_edit" title="パーツを追加" id={`insertion_position_${indexItem + 1}`}
            data-type={`insertion_position_${indexItem + 1}`}
            onClick={() => displayModalAdd(true, indexItem)}>
          <i className="material-icons">
            <FontAwesomeIcon icon={faPlus}/>
          </i>
        </div>

        {handleSwitchComponents(content, value, indexItem, items)}
      </Cssable>
    </li>
  )
});

const SortableList = SortableContainer(({items, displayModalAdd, handleSwitchComponents}) => {
  return (
    <ul>
      {
        items.map((item, index) => {
          let content = JSON.parse(item.contents);
          return (
            <SortableItem key={item.sort} value={item} content={content}
                          displayModalAdd={displayModalAdd} items={items}
                          handleSwitchComponents={handleSwitchComponents}
                          indexItem={index} index={index}/>
          )
        })
      }
    </ul>
  );
});

class SectionNewPage extends Component {
  static propTypes = {
    data: PropTypes.array
  };

  static defaultProps = {
    data: []
  };

  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      templateId: null,
      parent_setting: null,
      settings: this.props.settings
    };
    this.parallaxNone = 1;
    this.handleSwitchComponents = this.handleSwitchComponents.bind(this);
    this.handleChangeHeadline = this.handleChangeHeadline.bind(this);
    this.handleChangeText = this.handleChangeText.bind(this);
    this.handleDeleteComponent = this.handleDeleteComponent.bind(this);
    this.handleChangeAlign = this.handleChangeAlign.bind(this);
    this.handleChangeTable = this.handleChangeTable.bind(this);
    this.handleChangeWidth = this.handleChangeWidth.bind(this);
    this.handleChangeImageWidth = this.handleChangeImageWidth.bind(this);
    this.handleChangeLabel = this.handleChangeLabel.bind(this);
    this.handeChangeCssSetting = this.handeChangeCssSetting.bind(this)
    this.handleChangeChangeColor = this.handleChangeChangeColor.bind(this)
    this.handleChangeDelay = this.handleChangeDelay.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.renderPageName = this.renderPageName.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({data: nextProps.data});
  }

  async componentDidMount() {
    document.addEventListener('paste', function(e){
      if((e.target.parentElement !== null && e.target.parentElement.className.indexOf('contenteditable') != -1) ||
         e.target.className.indexOf('contenteditable') != -1 ||
         (e.target.offsetParent !== null && e.target.offsetParent.className.indexOf('contenteditable') != -1)){
        e.preventDefault();
        var text;
        if(window.clipboardData && window.clipboardData.getData){//IE
          //text = window.clipboardData.getData('Text');
        }else if(e.clipboardData && e.clipboardData.getData){
          text = (e.originalEvent || e).clipboardData.getData('text/plain');
          document.execCommand('insertHTML', false, text);
        }
      }
    });

    if (this.props.pageKind === 'blog' && this.props.pageStatus === 'edit') {
      const {data: response} = await request({
        method: 'get',
        url: APIS.get_blog_setting
      })

      this.setState({
        parent_setting: response.blog_setting,
      })
    }

    if (this.props.pageKind === 'news' && this.props.pageStatus === 'edit') {
      const {data: response} = await request({
        method: 'get',
        url: APIS.get_news_setting
      })

      this.setState({
        parent_setting: response.news_setting,
      })
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if(this.props.templateId !== null && this.state.templateId !== this.props.templateId) {
      const {data: response} = await request({
        method: 'post',
        url: APIS.get_data,
        data: {
          newpage_id: this.props.templateId,
          page_kind: 'template'
        }
      })

      let newSettings = response.settings;
      newSettings['name'] = this.state.settings['name']
      newSettings['slug'] = this.state.settings['slug']
      newSettings['slug_plus'] = this.state.settings['slug_plus']
      newSettings['title'] = this.state.settings['title']

      this.setState({
        data: response.newpages,
        templateId: this.props.templateId,
        settings: newSettings,
      }, () => {
        this.props.savePageDataUi(this.state.data);
        this.props.updateSetting(this.state.settings);
        this.props.saveImageUrl(response.image_urls);
        this.props.tagsList(response);
      })
    }
  }

  handleChangeHeadline(index, event, field) {
    const {data} = this.state;
    let contents = JSON.parse(data[index].contents);
    contents[field] = event.currentTarget.innerHTML;

    data[index].contents = JSON.stringify(contents);
    this.setState({
      data: data
    }, () => this.props.savePageDataUi(this.state.data))
  }

  handleChangeText(index, event, positionChild) {
    const {data} = this.state;
    let contents = JSON.parse(data[index].contents);
    let text = `text`
    if(positionChild !== "" && positionChild !== undefined) { text = `text${positionChild}` }
    data[index].contents = JSON.stringify(changeText(contents, text, event));
    this.setState({
      data: data
    }, () => this.props.savePageDataUi(this.state.data))
  }

  handleChangeAlign(index, align, field) {
    const {data} = this.state;
    let contents = JSON.parse(data[index].contents);
    contents[field] = align;

    data[index].contents = JSON.stringify(contents);
    this.setState({
      data: data
    }, () => this.props.savePageDataUi(this.state.data))
  }

  handleDeleteComponent(index) {
    if (window.confirm('本当に削除してよろしいですか？')) {
      const {data} = this.state;
      if (index > -1 && index < data.length) {
        data.splice(index, 1);
      }
      this.setState({
        data: data
      }, () => this.props.savePageDataUi(this.state.data))
    }
  }

  handleChangeTable(content, index) {
    const {data} = this.state;
    let contents = JSON.parse(data[index].contents);
    contents.table = content
    data[index].contents = JSON.stringify(contents);
    this.setState({
      data: data
    }, () => this.props.savePageDataUi(this.state.data))
  }

  handleChangeWidth(value, index) {
    const {data} = this.state;
    let contents = JSON.parse(data[index].contents);
    contents.widthTable = value
    data[index].contents = JSON.stringify(contents);
    this.setState({
      data: data
    }, () => this.props.savePageDataUi(this.state.data))
  }

  handleChangeImageWidth(value, index) {
    const {data} = this.state;
    let contents = JSON.parse(data[index].contents);
    contents.widthImage = value
    data[index].contents = JSON.stringify(contents);
    this.setState({
      data: data
    }, () => this.props.savePageDataUi(this.state.data))
  }

  handleChangeLabel(event, index, field) {
    const {data} = this.state;
    let contents = JSON.parse(data[index].contents);
    contents[field] = event.target.value;
    data[index].contents = JSON.stringify(contents);
    this.setState({
      data: data
    }, () => this.props.savePageDataUi(this.state.data))
  }

  handeChangeCssSetting(index, field, event = {}) {
    const {data} = this.state;
    let contents = JSON.parse(data[index].contents);
    const fieldEvents = ["top_padding_class", "bottom_padding_class", "anchor_tag"]
    if(fieldEvents.indexOf(field) > -1) {
      contents[field] = event.target.value
    }
    switch (field) {
      case "width_class":
        if(contents.width_class == "width_full")
          contents.width_class = "width_fixed"
        else
          contents.width_class = "width_full"

        setTimeout(function() {
          let array = document.getElementsByClassName('admin_edit_img_w');
          for (let i = 0; i < array.length; i++) {
            let section = array[i].closest('section').classList;
            let wrapper_width = array[i].parentElement.parentElement.offsetWidth;

            if (section.contains('block_image_1') || section.contains('block_image_2') || section.contains('block_image_text_1') ||
              section.contains('block_image_text_2') || section.contains('block_image_text_5') || section.contains('block_image_text_6')) {

              array[i].style.width = (wrapper_width * 0.45) + 'px'
              array[i].style.marginLeft = (wrapper_width * 0.05) + 'px';

            } else if (section.contains('block_image_text_3') || section.contains('block_image_text_7') || section.contains('block_image_text_9')) {

              array[i].style.width = (wrapper_width * 0.9) + 'px';
              array[i].style.left = (wrapper_width * 0.1) + 'px';
              array[i].style.right = 'auto';

            } else if (section.contains('block_image_text_4') || section.contains('block_image_text_8') || section.contains('block_image_text_10')) {

              array[i].style.width = (wrapper_width * 0.9) + 'px';
              array[i].style.left = 'auto';
              array[i].style.right = (wrapper_width * 0.1) + 'px';
            }
            array[i].style.bottom = (array[i].parentElement.offsetHeight / 2) + 'px';
          }
        }, 10);
      default:
        break;
    }
    data[index].contents = JSON.stringify(contents);
    this.setState({
      data: data
    }, () => this.props.savePageDataUi(this.state.data))

  }

  handleChangeDelay(event, index) {
    const {data} = this.state;
    const value = event.target.value;

    let contents = JSON.parse(data[index].contents);
    contents.delay = value;
    data[index].contents = JSON.stringify(contents);
    this.setState({
      data: data
    }, () => this.props.savePageDataUi(this.state.data))

    let block = event.target.closest("section");
    block.classList.remove("delay1", "delay2", "delay3", "delay4", "delay1Active", "delay2Active", "delay3Active", "delay4Active");
    if (value !== '') {
      block.classList.add(value);
      setTimeout(() => {
        block.classList.add(`${value}Active`);
      }, 800);
    }
  }

  onSortEnd({oldIndex, newIndex}) {
    const newDatas = arrayMove(this.state.data, oldIndex, newIndex);
    this.setState({
      data: newDatas
    }, () => this.props.savePageDataUi(this.state.data))
  }

  handleChangeChangeColor(index, color, colorType) {
    const {data} = this.state;
    let contents = JSON.parse(data[index].contents);
    contents[`r_${colorType}`] = color.rgb.r
    contents[`g_${colorType}`] = color.rgb.g
    contents[`b_${colorType}`] = color.rgb.b
    contents[`a_${colorType}`] = color.rgb.a
    data[index].contents = JSON.stringify(contents);
    this.setState({
      data: data
    }, () => this.props.savePageDataUi(this.state.data))
  }

  handleSwitchComponents(content, item, index, data) {
    const sectionStyle = fetchSectionStyle(content)
    switch (item.block_type) {
      case 'block_header':
        return <LoadableHeadingPart content={content} kindOfDesign={item.kind_of_design} index={index}
                                    handleChangeHeadline={this.handleChangeHeadline}
                                    handleDeleteComponent={this.handleDeleteComponent}
                                    handleChangeAlign={this.handleChangeAlign}
                                    handleChangeText={this.handleChangeText}
                                    handleChangeDelay={this.handleChangeDelay}
                                    blockType={item.block_type}
                                    data={data} {...this.props}
                                    handeChangeCssSetting={this.handeChangeCssSetting}
                                    sectionStyle={sectionStyle}
                                    handleChangeChangeColor={this.handleChangeChangeColor}/>
        break;
      case 'block_movie':
        return <LoadableMoviePart content={content} kindOfDesign={item.kind_of_design} index={index}
                                  handleChangeHeadline={this.handleChangeHeadline}
                                  handleDeleteComponent={this.handleDeleteComponent}
                                  handleChangeAlign={this.handleChangeAlign}
                                  handleChangeText={this.handleChangeText}
                                  handleChangeDelay={this.handleChangeDelay}
                                  blockType={item.block_type}
                                  data={data} {...this.props}
                                  handeChangeCssSetting={this.handeChangeCssSetting}
                                  sectionStyle={sectionStyle}
                                  handleChangeChangeColor={this.handleChangeChangeColor}/>
        break;
      case 'block_map':
        return <LoadableMapPart content={content} kindOfDesign={item.kind_of_design} index={index}
                                handleChangeHeadline={this.handleChangeHeadline}
                                handleDeleteComponent={this.handleDeleteComponent}
                                handleChangeAlign={this.handleChangeAlign}
                                handleChangeText={this.handleChangeText}
                                handleChangeDelay={this.handleChangeDelay}
                                blockType={item.block_type}
                                data={data} {...this.props}
                                handeChangeCssSetting={this.handeChangeCssSetting}
                                sectionStyle={sectionStyle}
                                handleChangeChangeColor={this.handleChangeChangeColor}/>
        break;
      case 'block_tel':
        return <LoadableTelPart content={content} kindOfDesign={item.kind_of_design}
                                item={item}
                                index={index}
                                handleChangeHeadline={this.handleChangeHeadline}
                                handleDeleteComponent={this.handleDeleteComponent}
                                handleChangeAlign={this.handleChangeAlign}
                                handleChangeText={this.handleChangeText}
                                handleChangeDelay={this.handleChangeDelay}
                                blockType={item.block_type}
                                data={data} {...this.props}
                                handeChangeCssSetting={this.handeChangeCssSetting}
                                sectionStyle={sectionStyle}
                                handleChangeChangeColor={this.handleChangeChangeColor}/>
        break;
      case 'block_shop':
        return <LoadableShopPart content={content} kindOfDesign={item.kind_of_design}
                                item={item}
                                index={index}
                                handleChangeHeadline={this.handleChangeHeadline}
                                handleDeleteComponent={this.handleDeleteComponent}
                                handleChangeAlign={this.handleChangeAlign}
                                handleChangeText={this.handleChangeText}
                                handleChangeDelay={this.handleChangeDelay}
                                blockType={item.block_type}
                                data={data} {...this.props}
                                handeChangeCssSetting={this.handeChangeCssSetting}
                                sectionStyle={sectionStyle}
                                handleChangeChangeColor={this.handleChangeChangeColor}/>
        break;
      case 'block_conversation':
        return <LoadableConversationPart content={content} kindOfDesign={item.kind_of_design}
                                 item={item}
                                 index={index}
                                 handleChangeHeadline={this.handleChangeHeadline}
                                 handleDeleteComponent={this.handleDeleteComponent}
                                 handleChangeAlign={this.handleChangeAlign}
                                 handleChangeText={this.handleChangeText}
                                 handleChangeDelay={this.handleChangeDelay}
                                 blockType={item.block_type}
                                 data={data} {...this.props}
                                 handeChangeCssSetting={this.handeChangeCssSetting}
                                 sectionStyle={sectionStyle}
                                 handleChangeChangeColor={this.handleChangeChangeColor}/>

        break;
      case 'block_text':
        return <LoadableTextPart content={content} kindOfDesign={item.kind_of_design} index={index}
                                 handleChangeHeadline={this.handleChangeHeadline}
                                 handleDeleteComponent={this.handleDeleteComponent}
                                 handleChangeText={this.handleChangeText}
                                 handleChangeAlign={this.handleChangeAlign}
                                 handleChangeDelay={this.handleChangeDelay}
                                 blockType={item.block_type}
                                 data={data} {...this.props}
                                 handeChangeCssSetting={this.handeChangeCssSetting}
                                 sectionStyle={sectionStyle}
                                 handleChangeChangeColor={this.handleChangeChangeColor}/>
        break;
      case 'block_line':
        return <LoadableLinePart index={index} handleDeleteComponent={this.handleDeleteComponent}
                                 kindOfDesign={item.kind_of_design} data={data} {...this.props}
                                 handeChangeCssSetting={this.handeChangeCssSetting}
                                 sectionStyle={sectionStyle}
                                 handleChangeChangeColor={this.handleChangeChangeColor}
                                 content={content}
                                 handleChangeDelay={this.handleChangeDelay}/>
        break;
      case 'block_image':
        return <LoadableImagePart content={content}
                                  item={item}
                                  index={index}
                                  indexBlockData={this.props.indexBlockData}
                                  handleDeleteComponent={this.handleDeleteComponent}
                                  handleChangeHeadline={this.handleChangeHeadline}
                                  handleChangeSubHeadline={this.handleChangeSubHeadline}
                                  handleChangeHeadlinePattern={this.handleChangeHeadlinePattern}
                                  handleChangeText={this.handleChangeText}
                                  handleChangeAlign={this.handleChangeAlign}
                                  handleChangeDelay={this.handleChangeDelay}
                                  isVisitOuterLink={false}
                                  blockId={`30${index}0${item.kind_of_design}`}
                                  handleChangeImageWidth={this.handleChangeImageWidth}
                                  data={data} {...this.props}
                                  handeChangeCssSetting={this.handeChangeCssSetting}
                                  sectionStyle={sectionStyle}
                                  handleChangeChangeColor={this.handleChangeChangeColor}/>
        break;
      case 'block_image_text':
        return <LoadableImageTextPart content={content} index={index} kindOfDesign={item.kind_of_design} item={item}
                                      data={data} {...this.props}
                                      handleChangeHeadline={this.handleChangeHeadline}
                                      handleDeleteComponent={this.handleDeleteComponent}
                                      handleChangeText={this.handleChangeText}
                                      handleChangeAlign={this.handleChangeAlign}
                                      handleChangeDelay={this.handleChangeDelay}
                                      blockId={`40${index}0${item.kind_of_design}`}
                                      handleChangeImageWidth={this.handleChangeImageWidth}
                                      isVisitOuterLink={false}
                                      handeChangeCssSetting={this.handeChangeCssSetting}
                                      sectionStyle={sectionStyle}
                                      handleChangeChangeColor={this.handleChangeChangeColor}/>
        break;
      case 'block_image_text2':
        return <LoadableImageText2Part content={content} index={index} kindOfDesign={item.kind_of_design} item={item}
                                      data={data} {...this.props}
                                      handleChangeHeadline={this.handleChangeHeadline}
                                      handleDeleteComponent={this.handleDeleteComponent}
                                      handleChangeText={this.handleChangeText}
                                      handleChangeAlign={this.handleChangeAlign}
                                      handleChangeDelay={this.handleChangeDelay}
                                      indexBlockData={this.props.indexBlockData}
                                      isVisitOuterLink={false}
                                      handeChangeCssSetting={this.handeChangeCssSetting}
                                      sectionStyle={sectionStyle}
                                      handleChangeChangeColor={this.handleChangeChangeColor}/>
        break;
      case 'block_table':
        return <LoadableTablePart content={content} index={index} kindOfDesign={item.kind_of_design}
                                  handleChangeHeadline={this.handleChangeHeadline}
                                  handleChangeExplanation={this.handleChangeExplanation}
                                  handleDeleteComponent={this.handleDeleteComponent}
                                  handleChangeAlign={this.handleChangeAlign}
                                  handleChangeTable={this.handleChangeTable}
                                  handleChangeWidth={this.handleChangeWidth}
                                  handeChangeCssSetting={this.handeChangeCssSetting}
                                  sectionStyle={sectionStyle}
                                  handleChangeChangeColor={this.handleChangeChangeColor}
                                  handleChangeDelay={this.handleChangeDelay}
                                  data={data} {...this.props}/>
        break;
        case 'block_link':
          return <LoadableLinkPart content={content}
                                   item={item}
                                   index={index}
                                   data={data} {...this.props}
                                   indexBlockData={this.props.indexBlockData}
                                   handleDeleteComponent={this.handleDeleteComponent}
                                   handleChangeHeadline={this.handleChangeHeadline}
                                   handleChangeSubHeadline={this.handleChangeSubHeadline}
                                   handleChangeHeadlinePattern={this.handleChangeHeadlinePattern}
                                   handleChangeText={this.handleChangeText}
                                   handleChangeAlign={this.handleChangeAlign}
                                   handeChangeCssSetting={this.handeChangeCssSetting}
                                   sectionStyle={sectionStyle}
                                   handleChangeChangeColor={this.handleChangeChangeColor}
                                   handleChangeDelay={this.handleChangeDelay}/>
          break;
        case 'block_blog':
          return <LoadableBlogPart content={content}
                                    item={item}
                                    index={index}
                                    data={data}
                                    {...this.props}
                                    indexBlockData={this.props.indexBlockData}
                                    handleDeleteComponent={this.handleDeleteComponent}
                                    handleChangeHeadline={this.handleChangeHeadline}
                                    handleChangeSubHeadline={this.handleChangeSubHeadline}
                                    handleChangeHeadlinePattern={this.handleChangeHeadlinePattern}
                                    handleChangeText={this.handleChangeText}
                                    handleChangeAlign={this.handleChangeAlign}
                                    handleChangeLabel={this.handleChangeLabel}
                                    handeChangeCssSetting={this.handeChangeCssSetting}
                                    sectionStyle={sectionStyle}
                                    handleChangeChangeColor={this.handleChangeChangeColor}
                                    handleChangeDelay={this.handleChangeDelay}/>
          break;
        case 'block_comparison':
          return <LoadableComparisonPart index={index} handleDeleteComponent={this.handleDeleteComponent}
                                          kindOfDesign={item.kind_of_design} data={data} {...this.props}
                                          content={content}
                                          item={item}
                                          handleChangeHeadline={this.handleChangeHeadline}
                                          handleChangeSubHeadline={this.handleChangeSubHeadline}
                                          handleChangeAlign={this.handleChangeAlign}
                                          handleChangeExplanation={this.handleChangeExplanation}
                                          handeChangeCssSetting={this.handeChangeCssSetting}
                                          sectionStyle={sectionStyle}
                                          handleChangeChangeColor={this.handleChangeChangeColor}
                                          handleChangeExplanation={this.handleChangeExplanation}
                                          handleChangeDelay={this.handleChangeDelay}/>
        case 'block_question':
          return <LoadableQuestionPart content={content}
                                    item={item}
                                    index={index}
                                    data={data}
                                    {...this.props}
                                    handleDeleteComponent={this.handleDeleteComponent}
                                    handleChangeHeadline={this.handleChangeHeadline}
                                    handleChangeSubHeadline={this.handleChangeSubHeadline}
                                    handleChangeHeadlinePattern={this.handleChangeHeadlinePattern}
                                    handleChangeText={this.handleChangeText}
                                    handleChangeAlign={this.handleChangeAlign}
                                    handleChangeLabel={this.handleChangeLabel}
                                    handeChangeCssSetting={this.handeChangeCssSetting}
                                    sectionStyle={sectionStyle}
                                    handleChangeChangeColor={this.handleChangeChangeColor}
                                    handleChangeDelay={this.handleChangeDelay}/>
          break;
        case 'block_menu':
          return <LoadableMenuPart content={content}
                                    item={item}
                                    index={index}
                                    data={data}
                                    {...this.props}
                                    handleDeleteComponent={this.handleDeleteComponent}
                                    handleChangeHeadline={this.handleChangeHeadline}
                                    handleChangeSubHeadline={this.handleChangeSubHeadline}
                                    handleChangeHeadlinePattern={this.handleChangeHeadlinePattern}
                                    handleChangeText={this.handleChangeText}
                                    handleChangeAlign={this.handleChangeAlign}
                                    handleChangeLabel={this.handleChangeLabel}
                                    handeChangeCssSetting={this.handeChangeCssSetting}
                                    sectionStyle={sectionStyle}
                                    handleChangeChangeColor={this.handleChangeChangeColor}
                                    handleChangeDelay={this.handleChangeDelay}/>
          break;
         case 'block_gallery':
          return <LoadableGalleryPart content={content}
                                    item={item}
                                    index={index}
                                    data={data}
                                    {...this.props}
                                    handleDeleteComponent={this.handleDeleteComponent}
                                    handleChangeHeadline={this.handleChangeHeadline}
                                    handleChangeSubHeadline={this.handleChangeSubHeadline}
                                    handleChangeHeadlinePattern={this.handleChangeHeadlinePattern}
                                    handleChangeText={this.handleChangeText}
                                    handleChangeAlign={this.handleChangeAlign}
                                    handleChangeLabel={this.handleChangeLabel}
                                    handeChangeCssSetting={this.handeChangeCssSetting}
                                    sectionStyle={sectionStyle}
                                    handleChangeChangeColor={this.handleChangeChangeColor}
                                    handleChangeDelay={this.handleChangeDelay}/>
          break;
        case 'block_button':
          return <LoadableButtonPart index={index} handleDeleteComponent={this.handleDeleteComponent}
                                          kindOfDesign={item.kind_of_design} data={data} {...this.props}
                                          content={content}
                                          item={item}
                                          handleChangeHeadline={this.handleChangeHeadline}
                                          handleChangeSubHeadline={this.handleChangeSubHeadline}
                                          handleChangeAlign={this.handleChangeAlign}
                                          handleChangeExplanation={this.handleChangeExplanation}
                                          handeChangeCssSetting={this.handeChangeCssSetting}
                                          sectionStyle={sectionStyle}
                                          handleChangeChangeColor={this.handleChangeChangeColor}
                                          handleChangeDelay={this.handleChangeDelay}/>
          break;
          case 'block_form':
            return <LoadableFormPart index={index} handleDeleteComponent={this.handleDeleteComponent}
                                    kindOfDesign={item.kind_of_design} data={data} {...this.props}
                                    content={content}
                                    item={item}
                                    handleChangeHeadline={this.handleChangeHeadline}
                                    handleChangeSubHeadline={this.handleChangeSubHeadline}
                                    handleChangeAlign={this.handleChangeAlign}
                                    handleChangeExplanation={this.handleChangeExplanation}
                                    handeChangeCssSetting={this.handeChangeCssSetting}
                                    sectionStyle={sectionStyle}
                                    handleChangeChangeColor={this.handleChangeChangeColor}
                                    handleChangeDelay={this.handleChangeDelay}/>
            break;
        case 'block_change_log':
          return <LoadableChangeLogPart index={index} handleDeleteComponent={this.handleDeleteComponent}
                                          kindOfDesign={item.kind_of_design} data={data} {...this.props}
                                          content={content}
                                          item={item}
                                          handleChangeHeadline={this.handleChangeHeadline}
                                          handleChangeSubHeadline={this.handleChangeSubHeadline}
                                          handleChangeAlign={this.handleChangeAlign}
                                          handleChangeExplanation={this.handleChangeExplanation}
                                          handeChangeCssSetting={this.handeChangeCssSetting}
                                          sectionStyle={sectionStyle}
                                          handleChangeChangeColor={this.handleChangeChangeColor}
                                          handleChangeDelay={this.handleChangeDelay}/>
          break;
        case 'block_news':
          return <LoadableNewsPart index={index} handleDeleteComponent={this.handleDeleteComponent}
                                          kindOfDesign={item.kind_of_design} data={data} {...this.props}
                                          content={content}
                                          item={item}
                                          handleChangeHeadline={this.handleChangeHeadline}
                                          handleChangeSubHeadline={this.handleChangeSubHeadline}
                                          handleChangeAlign={this.handleChangeAlign}
                                          handleChangeExplanation={this.handleChangeExplanation}
                                          handeChangeCssSetting={this.handeChangeCssSetting}
                                          sectionStyle={sectionStyle}
                                          handleChangeChangeColor={this.handleChangeChangeColor}
                                          handleChangeDelay={this.handleChangeDelay}/>
          break;
        case 'block_coupon':
          return <LoadableCouponPart index={index} handleDeleteComponent={this.handleDeleteComponent}
                                          kindOfDesign={item.kind_of_design} data={data} {...this.props}
                                          content={content}
                                          item={item}
                                          handleChangeHeadline={this.handleChangeHeadline}
                                          handleChangeSubHeadline={this.handleChangeSubHeadline}
                                          handleChangeAlign={this.handleChangeAlign}
                                          handleChangeExplanation={this.handleChangeExplanation}
                                          handeChangeCssSetting={this.handeChangeCssSetting}
                                          sectionStyle={sectionStyle}
                                          handleChangeChangeColor={this.handleChangeChangeColor}
                                          handleChangeDelay={this.handleChangeDelay}/>
          break;
        case 'block_staff':
          return <LoadableStaffPart index={index} handleDeleteComponent={this.handleDeleteComponent}
                                          data={data} {...this.props}
                                          content={content}
                                          item={item}
                                          handleChangeHeadline={this.handleChangeHeadline}
                                          handleChangeSubHeadline={this.handleChangeSubHeadline}
                                          handleChangeAlign={this.handleChangeAlign}
                                          handleChangeExplanation={this.handleChangeExplanation}
                                          handeChangeCssSetting={this.handeChangeCssSetting}
                                          sectionStyle={sectionStyle}
                                          handleChangeChangeColor={this.handleChangeChangeColor}
                                          handleChangeDelay={this.handleChangeDelay}/>
          break;
        case 'block_recruit':
          return <LoadableRecruitPart index={index} handleDeleteComponent={this.handleDeleteComponent}
                                          data={data} {...this.props}
                                          content={content}
                                          item={item}
                                          handleChangeHeadline={this.handleChangeHeadline}
                                          handleChangeSubHeadline={this.handleChangeSubHeadline}
                                          handleChangeAlign={this.handleChangeAlign}
                                          handleChangeExplanation={this.handleChangeExplanation}
                                          handleChangeDelay={this.handleChangeDelay}
                                          handeChangeCssSetting={this.handeChangeCssSetting}
                                          sectionStyle={sectionStyle}
                                          handleChangeChangeColor={this.handleChangeChangeColor}/>
          break;
        case 'block_html':
          return <LoadableHtmlPart index={index} handleDeleteComponent={this.handleDeleteComponent}
                                          kindOfDesign={item.kind_of_design} data={data} {...this.props}
                                          content={content}
                                          item={item}
                                          handleChangeHeadline={this.handleChangeHeadline}
                                          handleChangeSubHeadline={this.handleChangeSubHeadline}
                                          handleChangeAlign={this.handleChangeAlign}
                                          handeChangeCssSetting={this.handeChangeCssSetting}
                                          sectionStyle={sectionStyle}
                                          handleChangeChangeColor={this.handleChangeChangeColor}
                                          handleChangeExplanation={this.handleChangeExplanation}
                                          handleChangeDelay={this.handleChangeDelay}/>
          break;
      default:
        break;
    }
  }

  renderPageName() {
    if ((this.props.pageKind === 'blog' || this.props.pageKind === 'news') && this.props.pageStatus === 'edit') {
      let dt = this.props.releaseDate;
      let releaseDate = `${dt.getFullYear().toString().padStart(4, '0')}/${
                          (dt.getMonth()+1).toString().padStart(2, '0')}/${
                          dt.getDate().toString().padStart(2, '0')}`;
      return (
        <section className="news_single width_fixed exclude without" style={{paddingBottom: 0}}>
          <div className="content_wrapper">
            <div className="entry_header exclude without">
              <div className="heading block_header_2 align-left">
              <h2 className="h" dangerouslySetInnerHTML={{__html: this.props.settings.name}}></h2>
              {
                !this.state.parent_setting.datetime_public_disp_flg &&
                <p className="release_date">{releaseDate}</p>
              }
              </div>
            </div>
          </div>
        </section>
      )
    }

    return (<React.Fragment></React.Fragment>)
  }

  render() {
    let {data} = this.state;
    if (data.length <= 0) {
      return (<MainNoData />);
    }
    return (
      <React.Fragment>
        <Cssable css={this.props.settings.css}>
            <main id="main">
              {this.renderPageName()}
              <SortableList items={data} displayModalAdd={this.props.displayModalAdd} handleSwitchComponents={this.handleSwitchComponents}
                            distance={1} onSortEnd={this.onSortEnd} helperClass='sortable-helper'
                            lockAxis="y" useDragHandle />
              <div className="admin_edit" title="パーツを追加" id={`insertion_position_${data.length + 1}`}
                   data-type={`insertion_position_${data.length + 1}`}
                   onClick={() => this.props.displayModalAdd(true, data.length)}>
                <i className="material-icons">
                  <FontAwesomeIcon icon={faPlus}/>
                </i>
              </div>
            </main>
            {
              this.props.isOpenFavoriteModal && this.props.indexBlockData !== null &&
              <FavoriteModal handleShowModalFavorite={this.props.handleShowModalFavorite} content={data[this.props.indexBlockData]} />
            }
        </Cssable>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.data = storeState.pageDataUi;
  newState.dataBlock = storeState.dataBlock;
  newState.isDisplaySetting = storeState.isDisplaySetting;
  newState.settings = storeState.settings;
  newState.releaseDate = storeState.releaseDate;

  return newState;
}

const mapDispatchToProps = (dispatch) => {
  return {
    savePageDataUi: (data) => dispatch(savePageDataUi(data)),
    displayModalAdd: (isDisplay, index, isDisplaySetting, dataBlock) => dispatch(isDisplayModalAdd({isDisplay, index, isDisplaySetting, dataBlock})),
    updateSetting: (data) => dispatch(updateSetting(data)),
    saveImageUrl: (data) => dispatch(saveImageUrl(data)),
    tagsList: (data) => dispatch(tagsList({tags: {data: {tags: data.tags, newpage_tags: data.newpage_tags}}, page_kind: ''})),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionNewPage)
