import React, { Component } from 'react';
import { IMAGES } from '../../../../untils/constants'

export class OptionForHeadLine extends Component {

  constructor(props) {
    super(props);
  }

  handleSwitchComponent(key) {
    switch (key) {
      case "1":
          let src1 = this.props.onlyTitle ? IMAGES.heading_1 : IMAGES.heading_with_sub_1;
          return <img src={src1} className="pd-0" />
        break;
      case "2":
          let src2 = this.props.onlyTitle ? IMAGES.heading_2 : IMAGES.heading_with_sub_2;
          return <img src={src2} className="pd-0" />
        break;
      case "3":
          let src3 = this.props.onlyTitle ? IMAGES.heading_3 : IMAGES.heading_with_sub_3;
          return <img src={src3} className="pd-0" />
        break;
      case "4":
          let src4 = this.props.onlyTitle ? IMAGES.heading_4 : IMAGES.heading_with_sub_4;
          return <img src={src4} className="pd-0" />
        break;
      case "5":
          let src5 = this.props.onlyTitle ? IMAGES.heading_5 : IMAGES.heading_with_sub_5;
          return <img src={src5} className="pd-0" />
        break;
      case "6":
          let src6 = this.props.onlyTitle ? IMAGES.heading_6 : IMAGES.heading_with_sub_6;
          return <img src={src6} className="pd-0" />
        break;
      case "7":
          let src7 = this.props.onlyTitle ? IMAGES.heading_7 : IMAGES.heading_with_sub_7;
          return <img src={src7} className="pd-0" />
        break;

      default:
        break;
    }
  }

  render() {
    const headlineKindOfDesign = ["1","2","3","4","5","6","7"];
    return (
      <div className="item" style={{display: "block"}}>
        <p style={{marginBottom: "10px"}}>見出しのデザイン</p>
        <div className="sample">
          <ul>
            {
              headlineKindOfDesign.map((item, index) => {
                return (
                  <li key={index}>
                    <input name="block_header_pattern" id={`block_header_pattern_${item}`} value={item}
                           type="radio" defaultChecked={JSON.parse(this.props.itemChecked.contents).headlineKindOfDesign == item || index === 0} />
                    <label
                      htmlFor={`block_header_pattern_${item}`}
                      onClick={ () => this.props.handleSelectHeadlineOption(item) }
                      style={{background: 'none 0% 0% / auto repeat scroll padding-box border-box rgb(255, 255, 255)', color: 'rgb(34, 34, 34)'}}
                      className="block_header_label"
                      data-type={this.props.onlyTitle ? "3" : "2"}
                    >
                      { this.handleSwitchComponent(item) }
                    </label>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    )
  }
}

export default OptionForHeadLine
