require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("admin-lte");

import "jquery";
import "jquery-ui";
import "jquery-ui-touch-punch";
import "bootstrap";
import "../stylesheets/application";
import "@fortawesome/fontawesome-free/css/all.css";
import "overlayscrollbars";
import "./jquery.nestable";
import "./drag_drop_page_structure";
import "./new_template_page";
import "./user_index";
import "./coupons_list_page";
import "./image_category_index";
import "./gallery_category_index";
import "./gallery_index";
import "./question_index";
import "./question_category_index";
import "./shop_page";
import "./contact_index";
import "./menu_index";
import "./menu_category_index";
import "./dictionaries";
import "./bootstrap-datepicker.min";
import "./contacts";
import "./favorite_index";
import "./change_logs";
import "./blog_index";
import "./blog_category_index";
import "./favorite_category_index";
import "./iframe_responsive";
import "./shop_details_form";
import "./recruit_categories";
import "./recruit_index";
import "./staff_categories";
import "./file_index";
import "./staffs";
import "./staff_form";
import "./comparison_page";
import "./comparison_categories_page";
import "select2";
import "select2/dist/css/select2.css";
import "./tag_index";
import "./navigation_index";
import "./navigation";
import "./jquery-linedtextarea";
import "./redirects";
import "./newpage_categories";
import "./news_index";
import "./normal_datatables";
import "./login_logs_index";
import "./phone_access_logs_index";
import "./error_access_log_index";
import "./seo_index";
import "./template/jquery-validate-1.19.3.js";
import "./local_side_nav_index";

document.addEventListener("DOMContentLoaded", function() {
  OverlayScrollbars(document.getElementById('main-sidebar'), { });
});

$( document ).ready(function() {
  $('[data-toggle="tooltip"]').tooltip();
  $('.disable_wrapper :input').prop('disabled', true);
  $(".disable_wrapper #user_btn_add_page a").addClass('disabled')
  $(".disable_wrapper .dataTables_length select").prop("disabled", false);

  OverlayScrollbars(document.getElementById('main-sidebar'), { });

  if($('.lined').length > 0) {
    $(".lined").linedtextarea();
  }

  if (window.performance.navigation.type === 1) {
    $('#loader').remove();
  } else {
    $("#loader").fadeOut(400, function(){ $(this).remove(); });
  }

  setTimeout(function() {
    if (typeof(table) == "object" && table.state() !== undefined) {
      if (window.performance.navigation.type !== 1 && window.location.href != document.referrer) {
        table.state.clear();
        table.order([]);
        table.page.len(10).columns().search('').draw();
      }
      table.on('length', function() {
        location.reload();
      });
      table.on('page', function() {
        if ($(this).attr('id') !== 'recruits_datatable') location.reload();
      })
    }
  }, 200);
});

window.jQuery = window.$ = jQuery;

setTimeout(function() {
  $('.alert').slideUp();
}, 3000)

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

window.fixSortable = function(event , ui) {
  var $target = $(event.target)
  var top = event.pageY - $target.offsetParent().offset().top - (ui.helper.outerHeight(true) / 2);
  ui.helper.css({'top' : top + 'px'});
}

function relocateSettingBar() {
  var iframe = $('.custom-iframe');

  if (iframe.length > 0) {
    var bottom = parseInt(iframe.css('top')) + iframe.height() + 2 - window.innerHeight;

    iframe.contents().find('.extended_settings').css('bottom', bottom + 'px');
    iframe.contents().find('.admin_edit_window').css('bottom', bottom + 'px');
  }
}

$(document).ready(function() {
  $('.release_time').each(function(index) {
    let date = new Date($(this).html().replace(" UTC", "Z"));
    date = date.toLocaleString('ja-JP', { year: 'numeric', month: "numeric",
                                          day: "numeric", hour: "numeric",
                                          minute: "numeric" })
    $(this).html(date)
  })

  $('.menuBtn').on('click', function() {
    $('.main-sidebar').css('top', ($('header').height() + 16 * 2) + 'px');
  })

  if ($('.custom-iframe').length > 0) {
    $('html').css('overflow', 'hidden');
    $('body').css('overflow', 'hidden');
    $('header').css('overflow', 'hidden');
    setInterval(relocateSettingBar, 2000);
  }
})

$(window).on('resize', function() {
  setTimeout(relocateSettingBar, 500);
});
