import React, { Component } from 'react'
import ConfigablePart from "../ConfigablePart";
import PropTypes from 'prop-types';
import { APIS } from "../../../../../untils/constants";
import request from "../../../../../untils/request";
import HeadlineComponent from '../HeadlineComponent';
import ExplanationComponent from '../ExplanationComponent';
import LabelComponent from "../../EmbeddedParts/LabelComponent"
import Tooltip from '../../../Modal/Tooltip'
import { parallaxScroll } from '../../../../../functions';

export default class LoadableQuestionPart extends Component {
  constructor(props) {
    super(props)
    this.showListQuestions = this.showListQuestions.bind(this)
    this.headlineNone = "1"
    this.headlineFull = "2"
    this.state = {
      questions: [],
    }
  }

  static propTypes = {
    isEdit: PropTypes.bool,
    isFavorite: PropTypes.bool
  };

  static defaultProps = {
    isEdit: true,
    isFavorite: false
  };

  async componentDidMount () {
    await request({
      method: 'post',
      url: APIS.get_questions,
      data: {
        categories: this.props.item.categories,
        question_number: this.props.content.question_number
      }
    }).then(response => {
      this.setState({questions: response.data.questions})
    })
    parallaxScroll();
  }

  showListQuestions() {
    const {questions} = this.state
    const {content} = this.props
    return(
     <React.Fragment>
       {
          questions.map((itemQuestion, index) => {
            const category = itemQuestion[0]
            const question_contents = itemQuestion.slice(1)
            return(
              <React.Fragment key={index}>
                { content.display_category === '2' && this.showCategoryContent(category, index)}
                <div className="wrapper_item">
                {
                  question_contents.map((item, i) => {
                    return(
                    <dl key={i}>
                      <dt className="pre-wrap">{item['question_content']}</dt>
                      <dd className="pre-wrap" style={{display: 'block'}}>{item['answer_content']}</dd>
                    </dl>
                    )
                  })
                }
              </div>
              </React.Fragment>
            )
          })
       }
     </React.Fragment> 
    )
  }

  showCategoryContent(category, index) {
    return(
      <React.Fragment key={index}>
        <div className="heading block_header_2">
          <h3>{category.category_name}</h3>
          <p>{category.category_alias}</p>
        </div>
        <div className="pre-wrap">
          {category.category_content}
        </div>
      </React.Fragment>
    )
  }
  render() {
    const {content, isEdit, handleChangeHeadline, handleChangeAlign, item, index, sectionStyle} = this.props;
    return (
      <section key={item.kind_of_design} id={item.kind_of_design} className={`contents_faq01 align-left block_question_1 delay1 ${content.parallax != 1 ? "parallax" : ""} ${content.width_class} blockarea ${content.top_padding_class}  ${content.bottom_padding_class} edit_outer delay4Active editing block_question delay1Active`}
        style={sectionStyle}>
      {this.props.isEdit && <ConfigablePart {...this.props} />}
      {
        content.parallax != 1 &&
        <div className="parallax-wrap">
          <div className="parallax_img" style={{backgroundImage: `url(${content.background_image_url})`, height: '100%', transform: 'translateY(-50%)'}} />
        </div>
      }
      <div className="content_wrapper">
        {
          content.headlineType != this.headlineNone &&
          <HeadlineComponent content={content} index={index}
                            isEdit={isEdit}
                            handleChangeHeadline={handleChangeHeadline}
                            handleChangeAlign={handleChangeAlign} />
        }
        {
          content.headlineType == this.headlineFull &&
          <ExplanationComponent content={content} index={index}
                                isEdit={isEdit}
                                handleChangeHeadline={handleChangeHeadline}
                                handleChangeAlign={handleChangeAlign} />
        }
        {
          <div>{ this.showListQuestions() }</div>
        }
        {
          content.button_label.trim() != "" &&
          <LabelComponent value={content.button_label} handleChangeLabel={this.props.handleChangeLabel} index={index}
            url={content.question_link_type == 1 ? content.question_link_1 : content.question_link_2}
            link_type={content.question_link_type}
            isFavorite={this.props.isFavorite}
            handleChangeLabel={this.props.handleChangeLabel} index={index} isEdit={false}/>
        }
      </div>
    </section>
    )
  }
}
