const pad2 = (n) => {
    return n < 10 ? '0' + n : n
}

export const formatDateTime = (date) => {
    return date.getFullYear().toString() + pad2(date.getMonth() + 1) + pad2(date.getDate()) + pad2(date.getHours()) + pad2(date.getMinutes()) + pad2(date.getSeconds())
}

export const calculateDuration = (second, typeSlide) => {
    var mvSpd = second * 1000 * 1.4;

    if (typeSlide == 'slide06' || typeSlide == 'slide07') {
        if (typeof window !== 'undefined') {
            if (window.innerWidth >= 768) {
                mvSpd = mvSpd * 2
            } else {
                mvSpd = mvSpd * 4
            }
        }
    }

}

export const runYoutube = () => {
    "use strict";
    let tag = document.createElement("script"), firstScriptTag = document.getElementsByTagName("script")[0];
    tag.src = "https://www.youtube.com/iframe_api";
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    function onYouTubeIframeAPIReady(){
        const youtubeId = $('#mv .mv_mov').attr('data-youtubeid');
        new YT.Player("player", {
            width: 560,
            height: 315,
            videoId: youtubeId,
            playerVars:{
                loop: 1,
                playlist: youtubeId,
                controls: 0,
                disablekb: 0,
                fs: 0,
                iv_load_policy: 3,
                modestbranding: 1,
                playsinline: 1,
                rel: 0,
                showinfo: 0
            },
            events:{
                "onReady": onPlayerReady,
                "onStateChange": onPlayerStateChange
            }
        });
    }
    function onPlayerReady(evt){
        evt.target.mute();
        evt.target.playVideo();
    }
    function onPlayerStateChange(evt){
        if(evt.data === YT.PlayerState.ENDED){
            evt.target.playVideo();
        }
    }
    if($('#mv .mv_mov')){
        window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
    }
}

export const movFunc = () => {
    var $mvmov = $('#mv .mv_mov');
    var $yframe = $('#mv .mv_mov iframe');

    function ResizeYT() {
        if ($mvmov.width() * 0.56 > $mvmov.height()) {
            $yframe.css({width: '100%'});
            $yframe.css({height: $yframe.width() * 0.56});
            $yframe.css({marginTop: ($yframe.height() - $mvmov.height()) / -2, marginLeft: 0});
        } else {
            $yframe.css({height: '100%'});
            $yframe.css({width: $yframe.height() / 0.56});
            $yframe.css({marginTop: 0, marginLeft: ($yframe.width() - $mvmov.width()) / -2});
        }
    }

    $(window).on('resize', ResizeYT);
    ResizeYT();
}

export const ResizeCompositex = () => {
    function ResizeComposite() {
        $('.composite_box01').each(function () {
            var $tgtBox = $(this);
            var rate = 1.6;
            var wwidth = $(this).find('.inner_item').width();
            $(this).find('.inner_item_img source').each(function () {
                var source = new Image();
                source.src = $(this).attr('srcset');
                source.onload = function () {
                    var width = source.width;
                    var height = source.height;
                    var thisrate = height / width;
                    if (rate > thisrate && width > 0 && height > 0) {
                        rate = thisrate;
                    }
                    $tgtBox.find('.inner_item_img img').each(function () {
                        $(this).css({width: '100%'});
                        $(this).css({height: wwidth * rate});
                    });
                }
            });
            $(this).find('.inner_item_img img').each(function () {
                var img = new Image();
                img.src = $(this).attr('src');
                img.onload = function () {
                    var width = img.width;
                    var height = img.height;
                    var thisrate = height / width;
                    if (rate > thisrate && width > 0 && height > 0) {
                        rate = thisrate;
                    }
                    $tgtBox.find('.inner_item_img img').each(function () {
                        $(this).css({width: '100%'});
                        $(this).css({height: wwidth * rate});
                    });
                }
            });
        });
    }

    ResizeComposite();
    $(window).on('resize', ResizeComposite());
}

export const slickSlider = () => {
    $('body:not(.ippedit) .widget_gallery01.add_design1:not(.block_slider) .wrapper_item:not(.slick-initialized), body:not(.ippedit) .widget_gallery01.add_design5:not(.block_slider) .wrapper_item:not(.slick-initialized)').slick({
        accessibility: true,
        autoplay: false,
        autoplaySpeed: 3000,
        speed: 400,
        pauseOnHover: true,
        pauseOnDotsHover: true,
        cssEase: 'ease-in-out',
        dots: false,
        dotsClass: 'pointer',
        draggable: true,
        fade: false,
        arrows: true,
        infinite: true,
        initialSlide: 0,
        lazyLoad: 'ondemand',
        slidesToShow: 4,
        slidesToScroll: 1,
        swipe: true,
        vertical: false,
        centerMode: false,
        centerPadding: '0px',
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 2,
                    infinite: true,
                }
            },
        ]
    });

    $('body.ippedit .widget_gallery01.add_design1:not(.block_slider) .wrapper_item:not(.slick-initialized), body.ippedit .widget_gallery01.add_design5:not(.block_slider) .wrapper_item:not(.slick-initialized)').slick({
        accessibility: true,
        autoplay: false,
        autoplaySpeed: 3000,
        speed: 400,
        pauseOnHover: true,
        pauseOnDotsHover: true,
        cssEase: 'ease-in-out',
        dots: false,
        dotsClass: 'pointer',
        draggable: true,
        fade: false,
        arrows: true,
        infinite: true,
        initialSlide: 0,
        lazyLoad: 'ondemand',
        slidesToShow: 4,
        slidesToScroll: 1,
        swipe: true,
        vertical: false,
        centerMode: false,
        centerPadding: '0px',
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 2,
                    infinite: true,
                }
            },
        ]
    });

    if ($('body:not(.ippedit) .gallery_single').length) {
        var $gallery_single_slider = $('body:not(.ippedit) .gallery_single .gallery_single_slider');
        var $gallery_single_slider_thum = $('body:not(.ippedit) .gallery_single .gallery_single_slider_thum');
        $gallery_single_slider_thum.append($gallery_single_slider.contents().clone());
        $gallery_single_slider.slick({
            accessibility: true,
            autoplay: false,
            autoplaySpeed: 3000,
            speed: 400,
            pauseOnHover: true,
            pauseOnDotsHover: true,
            cssEase: 'ease-in-out',
            dots: false,
            dotsClass: 'pointer',
            draggable: false,
            fade: true,
            arrows: true,
            infinite: true,
            initialSlide: 0,
            lazyLoad: 'ondemand',
            slidesToShow: 1,
            slidesToScroll: 1,
            swipe: false,
            vertical: false,
            centerMode: false,
            centerPadding: '0px',
            adaptiveHeight: true,
            asNavFor: $gallery_single_slider_thum,
            responsive: [
                {
                    breakpoint: 450,
                    settings: {
                        draggable: true,
                        swipe: true,
                        fade: false,
                        dots: true,
                    }
                },
            ],
        });

        $gallery_single_slider_thum.slick({
            accessibility: true,
            autoplay: false,
            autoplaySpeed: 3000,
            speed: 400,
            pauseOnHover: true,
            pauseOnDotsHover: true,
            cssEase: 'ease-in-out',
            dots: false,
            dotsClass: '',
            draggable: false,
            fade: false,
            arrows: false,
            infinite: true,
            initialSlide: 0,
            lazyLoad: 'ondemand',
            slidesToShow: 1,
            slidesToScroll: 1,
            swipe: false,
            vertical: false,
            centerMode: true,
            focusOnSelect: true,
            centerPadding: '0',
            asNavFor: $gallery_single_slider
        });

        $('.gallery_single_slider_thum a img').unwrap();
    }
}
export const mvFunc = (second) => {
    var h2fs;
    var mvliw = 0;
    h2fs = parseFloat($('#mv .mv_text h2').css('font-size'));
    if ($('#mv .mv_img').length) {
        mvliw = $('#mv .mv_img li').width();
    }
    if ($('#mv .mv_mov').length) {
        if ($('#mv .mv_mov .youtube_block')) {
            $('#mv .mv_mov iframe').unwrap();
        }
        movFunc();
    }


    if ($('#mv .mv_img').length) {
        var z = 1;
        var flg = 0;
        var mvSpd = second * 1000 * 1.4;
        var mvCount = $('#mv .mv_img > li').length;
        var mvNum = 0;
        // $('#mv').stop().animate({opacity: 1}, 2000, 'easeInOutCubic');
        $("#mv .mv_img li img").css({animationDuration: mvSpd + 'ms'});

        /*------#mv.slide04 05-----*/

        if ($('#mv.slide04').length || $('#mv.slide05').length) {
            var NTrate = 0;
            var NTh = 0;
            $.when(
                $('#mv .mv_img li').each(function () {
                    var NTimg = new Image();
                    NTimg.src = $(this).find('img').attr('src');
                    if (NTrate <= NTimg.height / NTimg.width) {
                        NTrate = NTimg.height / NTimg.width;
                    }
                })
            ).done(function () {
                NTh = mvliw * NTrate;
                $('#mv').css({'cssText': 'height: ' + NTh + 'px!important;'});
            });

            function ResizeNT() {
                NTh = $('#mv .mv_img li img').width() * NTrate;
                $('#mv').css({'cssText': 'height: ' + NTh + 'px!important;'});
            }

            $(window).on('resize', ResizeNT);
            $(window).load(function () {
                $(window).on('resize', ResizeNT);
            });
        }

        /*------#mv.slide06 07-----*/

        if ($('#mv.slide06').length || $('#mv.slide07').length) {
            if (window.innerWidth >= 768) {
                $('#mv .mv_img li:nth-of-type(2)').addClass('show r');
                $("#mv .mv_img li img").css({animationDuration: mvSpd * 2 + 'ms'});
                mvNum = 1;
            } else {
                $("#mv .mv_img li img").css({animationDuration: mvSpd * 4 + 'ms'});
            }
        }

        /*------#mv.slide08-----*/

        var mvNum8 = 0;
        if ($('#mv.slide08').length) {
            mvSpd = mvSpd / 1.4;
            if (mvCount > 1) {
                if (mvCount == 2) {
                    for (var i = 1; i <= 2; i++) {
                        $('#mv .mv_img li:nth-of-type(' + i + ')').clone().appendTo($('#mv .mv_img'));
                    }
                    mvCount = 4;
                } else if (mvCount == 3) {
                    for (var i = 1; i <= 3; i++) {
                        $('#mv .mv_img li:nth-of-type(' + i + ')').clone().appendTo($('#mv .mv_img'));
                    }
                    mvCount = 6;
                }
                if($('#mv .mv_img').length < 2) {
                    $('#mv .mv_img').clone().insertAfter($('#mv .mv_img'));
                }
                $('#mv>ul').wrapAll('<div class="mv_imgs"></div>');
                $('#mv .mv_imgs').css({animationDuration: mvSpd * mvCount + 'ms'});
                $('#mv .mv_imgs li img').css({animationDuration: mvSpd * mvCount + 'ms'});
                for (var i = 1; i <= mvCount; i++) {
                    $('#mv .mv_imgs ul li:nth-of-type(' + i + 'n) img').css({animationDelay: mvSpd * (i - mvCount) + 'ms'});
                }
                if (navigator.userAgent.indexOf('trident') != -1 || navigator.userAgent.indexOf('Trident') != -1) {/*IE11*/
                    $('#mv .mv_imgs').css({
                        animationName: 'slide08',
                        animationDuration: mvSpd * mvCount + 'ms',
                        animationTimingFunction: 'linear',
                        animationIterationCount: 'infinite'
                    });

                    function ResizeIE() {
                        $('#mv .mv_img').css({width: $('#mv .mv_img li').width() * mvCount});
                        $('#mv .mv_imgs').css({width: $('#mv .mv_img li').width() * mvCount * 2});
                    }

                    $(window).on('resize', ResizeIE);
                    ResizeIE();
                }
            } else {
                $('#mv.slide08 .mv_img li img').attr('style', '');
            }
        }

        /*------#mv.slide09 10-----*/

        if ($('#mv.slide09').length || $('#mv.slide10').length) {
            $("#mv .mv_img li").css({animationDuration: mvSpd + 'ms'});
        }

        /*------setting-----*/

        if (mvCount > 1) {
            if ($('#mv.slide06').length || $('#mv.slide07').length) {
                if (window.innerWidth >= 768) {
                    if (mvCount > 2) {
                        clearInterval(mvChangeTimer);
                        StartMVTimer();
                    }
                } else {
                    clearInterval(mvChangeTimer);
                    StartMVTimer();
                }
            } else {
                clearInterval(mvChangeTimer);
                StartMVTimer();
            }
            $('#mv .mv_arrow, #mv .mv_pointer').css({display: "block"});
        } else {
            $('#mv .mv_arrow, #mv .mv_pointer').css({display: "none"});
            $('#mv .mv_img img').css({opacity: 1});
        }
        mvChange(mvNum);//初回

        function mvChange(n) {
            if ($('#mv.slide08').length) {
            } else {
                z++;
                if (mvNum === mvCount) {
                    mvNum = 1;
                } else {
                    mvNum++;
                }
                if (!n) {
                    n = mvNum;
                }
                var $tgtImg = $('#mv .mv_img li:nth-of-type(' + n + ')');
                $tgtImg.removeClass('show r');
                setTimeout(function () {
                    $tgtImg.addClass('show').css({zIndex: z});
                }, 50);

                if ($('#mv.slide06').length || $('#mv.slide07').length) {
                    if (z % 2 == 0 && z > 2) {//偶数
                        $tgtImg.addClass('r');
                    }
                }
            }

            $('#mv .mv_pointer li').removeClass('current');
            $('#mv .mv_pointer li:nth-of-type(' + mvNum + ')').addClass('current');
            var noEventIntTimer;

            function noEventIntST() {
                noEventIntTimer = setInterval(noEventInt, mvSpd * 0.25);
            }

            noEventIntST();

            flg = 0;

            function noEventInt() {
                flg = 1;
                clearInterval(noEventIntTimer);
                if (z > 2) {
                    $('#mv .mv_img li:nth-of-type(1) img').removeClass('no_animation');
                }
            }
        }

        var mvChangeTimer;

        function StartMVTimer() {
            if(mvSpd === 0) {
                return;
            }
            if ($('#mv.slide06').length || $('#mv.slide07').length) {
                if (window.innerWidth >= 768) {
                    mvChangeTimer = setInterval(mvChange, mvSpd * 0.7);
                } else {
                    mvChangeTimer = setInterval(mvChange, mvSpd * 0.7 * 2);
                }
            } else if ($('#mv.slide08').length) {
                //mvChangeTimer = setInterval(mvChange, mvSpd);
            } else {
                mvChangeTimer = setInterval(mvChange, mvSpd * 0.7);
            }
        }

        /*------mv pointer-----*/
        $('#mv .mv_pointer').html('')
        for (var i = 0; i < mvCount; i++) {
            $('#mv .mv_pointer').append('<li></li>');
        }
        $('#mv .mv_pointer li:eq(0)').addClass('current');
        $('#mv .mv_pointer li').click(function () {
            var pointerIndex = $('#mv .mv_pointer li').index(this);
            if (mvNum !== pointerIndex + 1) {
                clearInterval(mvChangeTimer);
                mvNum = pointerIndex;
                mvChange(pointerIndex + 1);
                StartMVTimer();
            }
            return false;
        });

        function ResizeArrow() {
            $('.over+#mv_outer .mv_arrow').css({marginTop: $('header').height() / 2});
        }

        $(window).on('resize', ResizeArrow);
        ResizeArrow();

        /*------mv nav-----*/

        $('#mv .mv_arrow_next span').click(function () {
            if (flg === 1) {
                clearInterval(mvChangeTimer);
                mvChange();
                StartMVTimer();
            }
            return false;
        });
        $('#mv .mv_arrow_prev span').click(function () {
            if (flg === 1) {
                clearInterval(mvChangeTimer);
                if (mvNum === 1) {
                    mvNum = mvCount - 1;
                    mvChange(mvCount);
                } else {
                    mvNum = mvNum - 2;
                    mvChange(mvNum + 1);
                }
                StartMVTimer();
            }
            return false;
        });

    }

    /*------mv p-----*/

    /*.none .static .move*/
    var MvP = $('#mv .mv_text p').html()

    function ResizeMvP() {
        if (MvP !== '' && $('#mv .mv_text p').css('display') == 'none' && $('#mv .mv_text p').hasClass('move')) {
            if ($('.MvP').length == 0) {
                $('#main').prepend('<section class="MvP width_fixed contents_box01 align-center block_text_1"><div class="content_wrapper"><div class="wrapper_item"><div class="inner_item_txt"><p>' + MvP + '</p></div></div></div></section>');
            }
        } else if (MvP !== '' && $('#mv .mv_text p').css('display') == 'none' && !$('#mv .mv_text p').hasClass('none')) {
            if ($('.MvP').length == 0) {
                $('#main').prepend('<section class="MvP width_fixed contents_box01 align-center block_text_1"><div class="content_wrapper"><div class="wrapper_item"><div class="inner_item_txt"><p>' + MvP + '</p></div></div></div></section>');
            }
        } else {
            $('.MvP').remove();
        }
    }

    $(window).on('resize', ResizeMvP);
    ResizeMvP();

    /*------mv h2-----*/

    var h2longest = 0;
    var h2shortest = 24;
    var h2str = $('#mv .mv_text h2').html();
    var h2fs;
    h2fs = parseFloat($('#mv .mv_text h2').css('font-size'));
    if (typeof h2str !== 'undefined') {
        h2str = h2str.replace(/\s+/g, "");
        var h2array = h2str.split('<br>');
        for (var i = 0; i < h2array.length; i++) {
            if (h2longest < h2array[i].length) {
                h2longest = h2array[i].length;
            }
        }
    }

    function ResizeFS() {
        if ($('#mv .mv_text:not(.wmode_horizontal)').length) {
            var maxW = $('#mv .mv_text:not(.wmode_horizontal) h2').width();
            if (h2longest >= 24) {//一行最大24文字
                $('#mv .mv_text:not(.wmode_horizontal) h2').css({fontSize: maxW / 24});
            } else {
                if (maxW < h2fs * (h2longest + 0.1)) {
                    $('#mv .mv_text:not(.wmode_horizontal) h2').css({fontSize: maxW / (h2longest + 0.1)});
                } else {
                    $('#mv .mv_text:not(.wmode_horizontal) h2').css({fontSize: h2fs + 'px'});
                }
            }
        }
        if ($('#mv .mv_text.wmode_horizontal').length) {
            var maxH = $('#mv .mv_text.wmode_horizontal').height() - parseInt($('#mv .mv_text.wmode_horizontal').css('padding-top'), 10) - parseInt($('#mv .mv_text.wmode_horizontal').css('padding-bottom'), 10);
            if (h2longest >= 24) {//一行最大24文字
                $('#mv .mv_text.wmode_horizontal h2').css({fontSize: maxH / 24});
            } else {
                if (maxH < h2fs * (h2longest + 0.1)) {
                    if (maxH / (h2longest + 0.1) <= 15.5) {
                        $('#mv .mv_text.wmode_horizontal h2').css({fontSize: 24});
                    } else {
                        $('#mv .mv_text.wmode_horizontal h2').css({fontSize: maxH / (h2longest + 0.1)});
                    }
                } else {
                    $('#mv .mv_text.wmode_horizontal h2').css({fontSize: h2fs + 'px'});
                }
            }
            var mh = $('#mv .mv_text>div').height() + parseInt($('#mv .mv_text>div').css('padding-top'), 10) + parseInt($('#mv .mv_text>div').css('padding-bottom'), 10) - 10;
            $('#mv .mv_text.wmode_horizontal').css({alignItems: 'stretch', paddingBottom: 50});
            if (mh < $('.mv_text').height()) {
                $('#mv .mv_text.wmode_horizontal').css({alignItems: 'center', paddingBottom: 50, boxSizing: 'border-box'});
            }
        }
    }

    $(window).on('resize', ResizeFS);
    ResizeFS();

    /*------mv minH-----*/

    function ResizeMvminH() {
        if ($('#mv .mv_text:not(.wmode_horizontal)').length) {
            $('#mv').css({minHeight: $('#mv .mv_text:not(.wmode_horizontal)>div').height() + 150});
        }
        if ($('#mv .mv_text.wmode_horizontal').length) {
            if ($('#mv .mv_text.wmode_horizontal').width() < $('#mv .mv_text.wmode_horizontal>div').width()) {
                var sa = $('#mv .mv_text.wmode_horizontal>div').width() - $('#mv .mv_text.wmode_horizontal').width();
                $('#mv').css({minHeight: $('#mv .mv_text.wmode_horizontal>div').height() + sa + 150});
            }
        }
    }

    $(window).on('resize', ResizeMvminH);
    ResizeMvminH();

}

export const customSlider = (props, prevProps) => {
    $('#' + props.blockId + '[class*="block_table"]:not(.without) table').each(function () {
        var $tgt = $(this).parents('.edit_outer');
        var cl = $tgt.attr('class');
        var v = new String(cl.match(/[w]\d{2,3}/));
        v = v.replace('w', '');
        v = Number(v);

        $(this).find('.inputslider').slider({
            min: 20,
            max: 80,
            step: 10,
            value: v,
            change: function (e, ui) {
                var str = 'w' + ui.value;
                $(this).next('input').val(ui.value);
                $tgt.removeClass('w15 w15 w20 w25 w30 w35 w40 w45 w50 w55 w60 w65 w70 w75 w80 w85').addClass(str);
                if (prevProps === null || (prevProps.content.widthTable != str)) {
                    props.handleChangeWidth(str, props.index);
                }
            },
            create: function (e, ui) {
                $(this).next('input').val($(this).slider('option', 'value'));
                $(this).parent('.admin_edit_cell_w').css({width: '60%', marginLeft: '20%', bottom: 0});
            }
        });
    });
}

export const changeText = (contents, text, event) => {
    contents[text] = event.editor.getData();
    if (event.key === 'Enter' && event.target.textContent.charCodeAt(event.target.textContent.length - 1) === 10) {
        contents[text] += "\n";
    } else if (event.key === 'Enter' && event.target.textContent.charCodeAt(event.target.textContent.length - 1) !== 10) {
        contents[text] += "\n\n";
    }
    return contents
}

export const customImageSlider = (props, prevProps) => {
    $('#' + props.blockId + '.block_image_1:not(.without) .inner_item_img, #' + props.blockId + '.block_image_2:not(.without) .inner_item_img, #' + props.blockId + '.block_image_text_1:not(.without) .inner_item_img, #' + props.blockId + '.block_image_text_2:not(.without) .inner_item_img, #' + props.blockId + '.block_image_text_5:not(.without) .inner_item_img, #' + props.blockId + '.block_image_text_6:not(.without) .inner_item_img').each(function () {
        var $tgt = $(this).parents('.edit_outer');
        var cl = $tgt.attr('class');
        var v = new String(cl.match(/[w]\d{2,3}/));
        v = v.replace('w', '');
        v = Number(v);
        $(this).find('.inputslider').slider({
            min: 10,
            max: 100,
            step: 5,
            value: v,
            change: function (e, ui) {
                var str_org = ui.value;
                var str = 'w' + str_org;
                $(this).next('input').val(ui.value);
                $tgt.removeClass('w10 w15 w20 w25 w30 w35 w40 w45 w50 w55 w60 w65 w70 w75 w80 w85 w90 w95 w100').addClass(str);
                if (prevProps === null || (prevProps.content.widthImage != str)) {
                    props.handleChangeImageWidth(str, props.index);
                }
            },
            create: function (e, ui) {
                $(this).next('input').val($(this).slider('option', 'value'));
                $(this).parent('.admin_edit_img_w').css({
                    width: $tgt.find('.wrapper_item').width() * 0.45,
                    marginLeft: $tgt.find('.wrapper_item').width() * 0.05,
                    bottom: $tgt.find('.inner_item_img').height() / 2
                });
            }
        });
    });

    $('#' + props.blockId + '.block_image_text_3:not(.without) .inner_item_img, #' + props.blockId + '.block_image_text_7:not(.without) .inner_item_img, #' + props.blockId + '.block_image_text_9:not(.without) .inner_item_img').each(function () {
        var $tgt = $(this).parents('.edit_outer');
        var cl = $tgt.attr('class');
        var v = new String(cl.match(/[w]\d{2,3}/));
        v = v.replace('w', '');
        v = Number(v);
        $(this).find('.inputslider').slider({
            min: 10,
            max: 100,
            step: 5,
            value: v,
            change: function (e, ui) {
                var str_org = ui.value;
                var str = 'w' + str_org;
                $(this).next('input').val(ui.value);
                $tgt.removeClass('w10 w15 w20 w25 w30 w35 w40 w45 w50 w55 w60 w65 w70 w75 w80 w85 w90 w95 w100').addClass(str);
                if (prevProps === null || (prevProps.content.widthImage != str)) {
                    props.handleChangeImageWidth(str, props.index);
                }
            },
            create: function (e, ui) {
                $(this).next('input').val($(this).slider('option', 'value'));
                $(this).parent('.admin_edit_img_w').css({
                    width: $tgt.find('.wrapper_item').width() * 0.9,
                    bottom: $tgt.find('.inner_item_img').height() / 2,
                    left: $tgt.find('.wrapper_item').width() * 0.1,
                    right: 'auto'
                });
            }
        });
    });


    $('#' + props.blockId + '.block_image_text_4:not(.without) .inner_item_img, #' + props.blockId + '.block_image_text_8:not(.without) .inner_item_img, #' + props.blockId + '.block_image_text_10:not(.without) .inner_item_img').each(function () {
        var $tgt = $(this).parents('.edit_outer');
        var cl = $tgt.attr('class');
        var v = new String(cl.match(/[w]\d{2,3}/));
        v = v.replace('w', '');
        v = Number(v);
        $(this).find('.inputslider').slider({
            min: 0,
            max: 90,
            step: 5,
            value: 100 - v,
            change: function (e, ui) {
                var str = 'w' + ui.value;
                var str_org = new String(100 - Number(ui.value));
                str = 'w' + str_org;
                $(this).next('input').val(100 - Number(ui.value));
                $tgt.removeClass('w10 w15 w20 w25 w30 w35 w40 w45 w50 w55 w60 w65 w70 w75 w80 w85 w90 w95 w100').addClass(str);
                if (prevProps === null || (prevProps.content.widthImage != str)) {
                    props.handleChangeImageWidth(str, props.index);
                }
            },
            create: function (e, ui) {
                $(this).next('input').val($(this).slider('option', 'value'));
                $(this).parent('.admin_edit_img_w').css({
                    width: $tgt.find('.wrapper_item').width() * 0.9,
                    bottom: $tgt.find('.inner_item_img').height() / 2,
                    left: 'auto',
                    right: $tgt.find('.wrapper_item').width() * 0.1
                });
            }
        });
    });
}

export const comparisonPopup = (pageStatus = '', s3Url = '') => {
    /*----custom popup----*/

    var parentSectionClass = "aaaa";
    var $parent;
    var popCount;
    var popIndex;
    var popTxt;

    var CSSselector = '#main>*'

    if (pageStatus == 'new' || pageStatus == 'edit') {
        CSSselector = '#main>ul>li>*'
    } else if (pageStatus == 'commonFooter') {
        CSSselector = '#common_footer>ul>li>*'
    }

    var $popA = `a[href^="${s3Url}"], a[href^="http://"][href*="no_image"][href$=".jpg"], a[href^="https://"][href*="no_image"][href$=".jpg"]`;
    var $popB = `a[href^="http://"][href*="no_image"][href$=".jpg"], a[href^="https://"][href*="no_image"][href$=".jpg"], a[href^="${s3Url}"]`;
    $('body:not(.nopop) .pop').find($popA).on(
        'click', function () {
            var img = new Image();
            img.src = $(this).attr("href");
            if ($(this).find(".infotxt").length) {
                popTxt = $(this).find(".infotxt").html();
            }
            parentSectionClass = $(this).parents(`${CSSselector}, #common_footer > *`).attr('class');
            $parent = $(this).parents('.pop');
            popCount = $parent.find($popA).length;
            popIndex = $parent.find($popA).index(this);
            if (parentSectionClass.indexOf('gallery_archive') !== -1) {
                if (parentSectionClass.indexOf('add_design2') == -1 && parentSectionClass.indexOf('add_design3') == -1 && $(this).find(".infotxt").length) {
                    $('body').append('<div class="popup"><div class="popimg"><div><div class="popimgwrap"><img src="' + img.src + '" alt=""><div class="poptxt">' + popTxt + '</div></div><div class="loader"></div></div></div><div class="popclose">×</div><ul class="popnav"><li class="popprev"></li><li class="popnext"></li></ul></div>');
                } else {
                    $('body').append('<div class="popup"><div class="popimg"><div><div class="popimgwrap"><img src="' + img.src + '" alt=""></div><div class="loader"></div></div></div><div class="popclose">×</div><ul class="popnav"><li class="popprev"></li><li class="popnext"></li></ul></div>');
                }
            } else {
                if ($(this).find(".infotxt").length) {
                    $('body').append('<div class="popup"><div class="popimg"><div><div class="popimgwrap"><img src="' + img.src + '" alt=""><div class="poptxt">' + popTxt + '</div></div><div class="loader"></div></div></div><div class="popclose">×</div><ul class="popnav"><li class="popprev"></li><li class="popnext"></li></ul></div>');
                } else {
                    $('body').append('<div class="popup"><div class="popimg"><div><div class="popimgwrap"><img src="' + img.src + '" alt=""></div><div class="loader"></div></div></div><div class="popclose">×</div><ul class="popnav"><li class="popprev"></li><li class="popnext"></li></ul></div>');
                }
            }
            if (popCount === 1) {
                $('.popnext').css({marginRight: -50});
                $('.popprev').css({marginLeft: -50});
            } else {
                if (popIndex === 0) {
                    $('.popnext').stop().animate({marginRight: 10}, 200, 'easeOutCubic');
                    $('.popprev').css({marginLeft: -50});
                } else if (popIndex === popCount - 1) {
                    $('.popnext').css({marginRight: -50});
                    $('.popprev').stop().animate({marginLeft: 10}, 200, 'easeOutCubic');
                }
            }
            $('.popup').css({opacity: 0}).stop().animate({opacity: 1}, 400, 'easeInOutCubic');
            img.onload = function () {
                $('.popimgwrap').stop().animate({opacity: 1}, 400, 'easeInOutCubic');
            }
            $('.poptxt').stop().animate({bottom: 0}, 5000, 'easeInOutCubic', function () {
                $('.poptxt').addClass("timelimit");
            });
            return false;
        }
    );

    //var myhost = location.host;
    //$('a[href^="/common/"][href$=".jpeg"], a[href^="/common/"][href$=".jpg"], a[href^="/common/"][href$=".gif"], a[href^="/common/"][href$=".png"], a[href^="/common/"][href$=".JPEG"], a[href^="/common/"][href$=".JPG"], a[href^="/common/"][href$=".GIF"], a[href^="/common/"][href$=".PNG"], a[href*="'+myhost+'"][href$=".jpeg"], a[href*="'+myhost+'"][href$=".jpg"], a[href*="'+myhost+'"][href$=".gif"], a[href*="'+myhost+'"][href$=".png"], a[href*="'+myhost+'"][href$=".JPEG"], a[href*="'+myhost+'"][href$=".JPG"], a[href*="'+myhost+'"][href$=".GIF"], a[href*="'+myhost+'"][href$=".PNG"]').on(
    $(`body:not(.nopop) a[href^="http://"][href*="no_image"][href$=".jpg"], body:not(.nopop) a[href^="https://"][href*="no_image"][href$=".jpg"], body:not(.nopop) a[href^="${s3Url}"]`).on(
        'click', function () {
            var img = new Image();
            img.src = $(this).attr("href");
            parentSectionClass = $(this).parents(`${CSSselector}, #common_footer > *`).attr('class');
            $parent = $(this).parents('.content_wrapper');
            popCount = $parent.find($popB).length;
            popIndex = $parent.find($popB).index(this);
            if (parentSectionClass.indexOf('gallery_archive') == -1) {
                $('body').append('<div class="popup"><div class="popimg"><div><div class="popimgwrap"><img src="' + img.src + '" alt=""></div><div class="loader"></div></div></div><div class="popclose">×</div><ul class="popnav"><li class="popprev"></li><li class="popnext"></li></ul></div>');
            }
            if (popCount === 1) {
                $('.popnext').css({marginRight: -50});
                $('.popprev').css({marginLeft: -50});
            } else {
                if (popIndex === 0) {
                    $('.popnext').stop().animate({marginRight: 10}, 200, 'easeOutCubic');
                    $('.popprev').css({marginLeft: -50});
                } else if (popIndex === popCount - 1) {
                    $('.popnext').css({marginRight: -50});
                    $('.popprev').stop().animate({marginLeft: 10}, 200, 'easeOutCubic');
                }
            }
            $('.popup').css({opacity: 0}).stop().animate({opacity: 1}, 400, 'easeInOutCubic');
            img.onload = function () {
                $('.popimgwrap').stop().animate({opacity: 1}, 400, 'easeInOutCubic');
            }
            $('.poptxt').stop().animate({bottom: 0}, 5000, 'easeInOutCubic', function () {
                $('.poptxt').addClass("timelimit");
            });
            return false;
        }
    );
    $(document).on("click", ".popimg, .popclose, .poppayclose", function () {
        $('.popup').stop().animate({opacity: 0}, 400, 'easeInOutCubic', function () {
            $('.popup').remove();
            popTxt = '';
        });
    });
    $(document).on("click", ".popup img:not(.no)", function () {
        return false;
    });

    function popNav() {
        $('.popimgwrap').stop().animate({opacity: 0}, 400, 'easeInOutCubic', function () {
            $('.poptxt').removeClass("timelimit");
            $('.popup img').remove();
            popTxt = '';
            var img = new Image();
            img.src = $parent.find($popA).eq(popIndex).attr("href");
            popTxt = $parent.find($popA).eq(popIndex).find(".infotxt").html();
            $('.popimgwrap').prepend('<img src="' + img.src + '" alt="">');
            $('.poptxt').html(popTxt);
            img.onload = function () {
                $('.popimgwrap').stop().animate({opacity: 1}, 400, 'easeInOutCubic');
            }
        });
        $('.poptxt').stop().animate({bottom: 0}, 5000, 'easeInOutCubic', function () {
            $('.poptxt').addClass("timelimit");
        });
    }

    $(document).on("click", ".popnext", function () {
        popIndex++;
        if (popIndex == popCount - 1) {
            $('.popnext').stop().animate({marginRight: -50}, 200, 'easeInCubic');
            $('.popprev').stop().animate({marginLeft: 10}, 200, 'easeOutCubic');
        } else {
            $('.popprev').stop().animate({marginLeft: 10}, 200, 'easeOutCubic');
        }
        popNav();
        return false;
    });
    $(document).on("click", ".popprev", function () {
        popIndex--;
        if (popIndex == 0) {
            $('.popprev').stop().animate({marginLeft: -50}, 200, 'easeInCubic');
            $('.popnext').stop().animate({marginRight: 10}, 200, 'easeOutCubic');
        } else {
            $('.popnext').stop().animate({marginRight: 10}, 200, 'easeOutCubic');
        }
        popNav();
        return false;
    });

    $(`body.nopop a[href^="http://"][href*="no_image"][href$=".jpg"], body.nopop a[href^="https://"][href*="no_image"][href$=".jpg"], body.nopop a[href^="${s3Url}"]`).each(function () {
        $(this).css({pointerEvents: 'none'});
    });
    $(`body.nopop a[href^="http://"][href*="no_image"][href$=".jpg"], body.nopop a[href^="https://"][href*="no_image"][href$=".jpg"], body.nopop a[href^="${s3Url}"]`).on(
        'click', function () {
            return false;
        }
    );
}

export const parallaxScroll = () => {
    var parallax_spd = 2.5;
    var parallax_scroll = $(this).scrollTop();
    $('.parallax').each(function () {
        var parallax_pos = $(this).offset().top;
        var parallax_h = $(this).height() + parseInt($(this).css('padding-top'), 10) + parseInt($(this).css('padding-bottom'), 10);
        var parallax_ratio = (parallax_pos - parallax_scroll + parallax_h) / ($(window).height() + parallax_h);
        var parallax_A1 = parallax_h * parallax_spd;
        var parallax_A2 = parallax_A1 * parallax_ratio * -1 + parallax_h * parallax_ratio;
        $(this).find('.parallax_img').css({
            height: parallax_A1,
            transform: 'translateY(' + parallax_A2 + 'px)',
        });
    });
}

export const fixbtn = () => {
    var fheight = $('#fixbtn .fixbtnwrap').height();
    if (!($('#fixbtn .fixbtntel').length) && !($('#fixbtn .contents_btn01').length)) {
        $('#fixbtn').addClass('no');
    }

    function funcFixFooter() {
        $('#fixbtn').css({height: fheight});
        if ($('#fixbtn').offset() && $('#fixbtn').offset().top > window.innerHeight * 2) {
            var page_scroll = $(this).scrollTop();

            //▼100vh以上スクロールすると下から出てくる
            if (page_scroll >= window.innerHeight) {
                $('#fixbtn').addClass("scrolled");
                //▼#fixbtnの位置までスクロールするとボタンが固定される
                if (page_scroll + window.innerHeight >= $('#fixbtn').offset().top + $('#fixbtn').height()) {
                    $('#fixbtn').css({position: 'relative'});
                    $('#fixbtn .fixbtnwrap').css({position: 'absolute', bottom: 'auto', top: 0});
                } else {
                    $('#fixbtn').css({position: 'inherit'});
                    $('#fixbtn .fixbtnwrap').css({position: 'fixed', bottom: 0, top: 'auto'});
                }
            } else {
                $('#fixbtn').removeClass("scrolled");
                $('#fixbtn .fixbtnwrap').css({bottom: fheight * -1, height: fheight});
            }
        } else {
            $('#fixbtn').css({position: 'relative'});
            $('#fixbtn .fixbtnwrap').css({position: 'absolute', bottom: 'auto', top: 0});
        }
    };
    funcFixFooter();
    $(window).scroll(function () {
        funcFixFooter();
    });

    function ResizeFixbtn() {
        fheight = $('#fixbtn .fixbtnwrap').height();
        if (window.innerWidth < 700) {
            if ($('.fixbtntel span').length || $('.fixbtntel a').length) {
            } else {
                $('#fixbtn .fixbtntel').css({display: "none"});
            }
        } else {
            $('#fixbtn .fixbtntel').css({display: "inherit"});
        }
    }

    ResizeFixbtn();
    $(window).on('resize', ResizeFixbtn);
}

export const resizeHeader = () => {
    /*---header---*/

    function find_header_height() {
        return $('header').last().height();
    }

    var hh = find_header_height();

    var liw = 0;
    $('.nav_1st>li>a>span').each(function () {
        liw += $(this).width();
    });

    function ResizeNavs() {
        if ($('header nav').width() > liw - 10) {
            $('.nav_1st').css({flexWrap: 'no-wrap'});
            $('.nav_1st>li').css({flexGrow: '1'});
            $('.nav_1st>li>a').css({paddingLeft: 0, paddingRight: 0});
        } else {
            $('.nav_1st').css({flexWrap: 'wrap'});
            $('.nav_1st>li').css({flexGrow: '0'});
            $('.nav_1st>li>a').css({paddingLeft: '1vw', paddingRight: '1vw'});
        }
        hh = find_header_height();
    }

    $(window).on('resize', ResizeNavs);

    function HH() {
        $('header#pattern2').css({minHeight: $('header#pattern2 .logo img').height() + parseInt($('header#pattern2 .logo').css('padding-top'), 10) + parseInt($('header#pattern2 .logo').css('padding-bottom'), 10)});
        $('header#pattern2 nav ul.nav_1st').css({minHeight: $('header#pattern2').height() * 0.8 - $('header#pattern2 .wraper').height() * 0.8});
        if (window.innerWidth > 900) {
            setTimeout(function () {
                $('header#pattern2 .logo').css({minHeight: $('header#pattern2').height()});
            }, 20);
        } else {
            $('header#pattern2 .logo').css({minHeight: "inherit"});
        }
    };

    function ResizeHead() {
        ResizeNavs();
        HH();

        if (window.innerWidth <= 900) {
            $('header.header_class:not(#pattern4)>nav').appendTo('header.header_class .header_contents .inner');
            $('header.header_class:not(#pattern4) .header_contents').css({height: window.innerHeight - $('header').height()});
            if ($('.translate').length) {
                $('#google_translate_element').appendTo('.burger .sp_translate');
            }
        } else {
            $('header.header_class:not(#pattern4) .header_contents .inner nav').appendTo('header.header_class');
            $('header.header_class:not(#pattern4) .header_contents').css({height: 'auto'});
            if ($('.translate').length) {
                $('#google_translate_element').appendTo('.header_col1 .translate');
            }
        }
        $('header#pattern4>nav').appendTo('header.header_class .header_contents .inner');
        $('header#pattern4 .header_contents').css({height: window.innerHeight - $('header').height()});
        $('header#pattern4 #google_translate_element').appendTo('.burger .sp_translate');
        $('body').removeClass('scrolled');
        $('body').removeClass('scrolledHide');
        hh = find_header_height()

        $('header.header_class:not(.over)~#mv_outer, header.header_class:not(.over)~#lv_outer, header.header_class+#main, header.header_class+#pan').css({
            borderTop: 'transparent solid',
            borderTopWidth: hh
        });

        $('header.over~#mv_outer #mv .mv_text').css({paddingTop: hh});
        $('header.over~#lv_outer #lv').css({paddingTop: hh});
        $('header.over~#mv_outer #lv').css({paddingTop: hh});

        if ($('body#comparison_setting_page').length > 0) {
            var lvContentHeight = $('body#comparison_setting_page header.over~#lv_outer #lv p').height();
            $('body#comparison_setting_page header.over~#lv_outer #lv').css({height: hh + lvContentHeight});
        }

        if (window.innerWidth > 900 && $('header .header_contents .header_col1').css('flex-direction') == 'row') {
            $('body.scrolled header .header_col1 .tel').css({display: 'block'});
        }
    }

    $(window).on('resize', ResizeHead);
    ResizeHead();

    function ResizeHeadTel() {
        if (window.innerWidth > 900 && $('header .header_contents .header_col1').css('flex-direction') == 'row') {
            $('body.scrolled header .header_col1 .tel').css({display: 'block'});
        }
    }

    $(window).on('resize', ResizeHeadTel);
    $(window).scroll(function () {
        ResizeHeadTel();
    });
    ResizeHeadTel();

    if (!$('body').hasClass('edit_view')) {
        if (!($('header#pattern4>.wraper>.inner>.btn').length)) {
            $('header#pattern4 .header_contents .btn').clone().insertBefore('.burger');
        }
        if (!($('header#pattern4>.wraper>.inner>.header_sns').length)) {
            $('header#pattern4 .header_contents .header_sns').clone().insertBefore('.burger');
        }
    }
    $('header#pattern4 .header_contents a').on(
        'click', function () {
            $('header#pattern4').removeClass('open');
        });

    /*---scroll---*/

    var start_pos = 0;
    var main_pos = $('main').offset().top;
    $('body').removeClass('scrolled');
    $('body').removeClass('scrolledHide');
    $(window).scroll(function (e) {
        if (!($('header.nofixed').length)) {
            var current_pos = $(this).scrollTop();
            if (current_pos >= hh) {
                $('body').addClass('scrolled');
                $('body').addClass('hHide');
                if (current_pos >= (main_pos + hh) * 1.2 && current_pos < start_pos) {//scrollup
                    $('body').removeClass('hHide');
                }
            } else {
                $('body').removeClass('scrolled');
                $('body').removeClass('hHide');
            }
            start_pos = current_pos;
        }
        $('header#pattern4 .header_contents').css({height: window.innerHeight - $('header').height()});
        HH();
    });
}

export const display_recruit_part = (recruit, fileds) => {
    var is_display = false

    for (let i = 0; i < fileds.length; i++) {
        let item = fileds[i];
        if (recruit[item] !== undefined && recruit[item] !== '' && recruit[item] !== null) {
            is_display = true;
            break;
        }
    }

    return is_display;
}

export const convertDateTimeLocalContent = () => {
    $('.release_time').each(function (index) {
        let date = new Date($(this).html());
        date = date.toLocaleString('ja-JP', {year: 'numeric', month: "numeric", day: "numeric"})
        $(this).html(date)
    })
}

export const showHideMVPointer = (mvType) => {
    if (mvType == 1) {
        $('#mv .mv_arrow, #mv .mv_pointer').css({display: "block"});
    } else {
        $('#mv .mv_arrow, #mv .mv_pointer').css({display: "none"});
    }
}

export const resizeTable = ()=> {
  $(function(){

    /*--block_table_2 組み換え---*/

    function tableResize02(){
      $('.block_table_2 table').each(function(){
        var itemContent2 = '<tbody class="block_table_2_item">';
        var array2 =[];
        var cols2 = $(this).find('thead tr th, thead tr td').size();
        var rows2 = $(this).find('tr').size();
        for(var i = 0; i < cols2; i++){
          itemContent2 += '<tr>';
          array2[i] = [];
          for(var j = 0; j < rows2; j++){
            array2[i][j] = $(this).find('tr:eq('+j+') > *:eq('+i+')').html();
            if(j == 0){
              itemContent2 += '<th>'+array2[i][j]+'</th>';
            }else{
              itemContent2 += '<td>'+array2[i][j]+'</td>';
            }
          }
          itemContent2 += '</tr>';
        }
        itemContent2 += '</tbody>';
        if(window.innerWidth < 500){
          if(!$(this).children().is('tbody.block_table_2_item')){
            $(this).find('tbody').after(itemContent2);
          }
        }else{
          if($(this).children().is('tbody.block_table_2_item')){
            $(this).find('tbody.block_table_2_item').remove();
          }
        }
      });
    }
    tableResize02();
    $(window).on('resize', tableResize02);
  });
}
