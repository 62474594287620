import React, { Component } from 'react';
import WidthClassConfigable from './WidthClassConfigable'
import ColorTextConfigagle from './ColorTextConfigagle'
import PaddingConfigable from './PaddingConfigable'
import AnchorSetting from './AnchorSetting';
import BackGroundImage from './BackGroundImage'
import ColorPicker from './ColorPicker'
import {connect} from "react-redux";
import { sortableHandle } from 'react-sortable-hoc';

const DragHandle = sortableHandle(() => {
  return (
    <li className="admin_edit_menu_move 460879" title="順序変更">
      <i className="material-icons">reorder</i>
    </li>
  )
});

export class ConfigablePart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDisplayColorSetting: false,
      displayAnchorTag: false,
      isDisplayBackgroundSetting: false,
    };
    this.handleOpenAnchorSetting = this.handleOpenAnchorSetting.bind(this)
    this.handleOpenColorPicker = this.handleOpenColorPicker.bind(this)
    this.handleChangeChangeColor = this.handleChangeChangeColor.bind(this)
    this.handleOpenBackroundPicker = this.handleOpenBackroundPicker.bind(this)
    this.handleDisableAllComponent = this.handleDisableAllComponent.bind(this)
  }

  handleOpenAnchorSetting() {
    this.setState({
      displayAnchorTag: !this.state.displayAnchorTag,
      isDisplayColorSetting: false,
      isDisplayBackgroundSetting: false
    })
  }

  handleOpenColorPicker() {
    this.setState({
      displayAnchorTag: false,
      isDisplayColorSetting: !this.state.isDisplayColorSetting,
      isDisplayBackgroundSetting: false
    })
  };

  handleOpenBackroundPicker() {
    this.setState({
      displayAnchorTag: false,
      isDisplayColorSetting: false,
      isDisplayBackgroundSetting: !this.state.isDisplayBackgroundSetting
    })
  }

  handleDisableAllComponent() {
    this.setState({
      displayAnchorTag: false,
      isDisplayColorSetting: false,
      isDisplayBackgroundSetting: false
    })
  }

  handleChangeChangeColor(color) {
    this.setState({color: color.hex})
  }

  handleFavoriteComponent(e, index) {
    this.handleDisableAllComponent()
    if (this.props.pageKind == 'copy_template') return;

    this.props.handleShowModalFavorite(index)
  }

  handleOpenModal(index, data) {
    this.handleDisableAllComponent()
    this.props.displayModalAdd(true, index, true, data[index])
  }

  handleCopyAComponent(index, data) {
    this.handleDisableAllComponent()
    this.props.handleCopyComponent(index, data[index])
  }

  handleDeleteAComponent(index) {
    this.handleDisableAllComponent()
    this.props.handleDeleteComponent(index)
  }

  render() {
    const { index, data, content } = this.props
    const styleInput = {position: 'absolute', zIndex: '999', width: '100%', top: '0', height: '150px', paddingBottom: "100%", right: '0'}
    let contentDelay = content.delay
    if(contentDelay === undefined) {
      switch (this.props.generalSetting.delay_flag) {
      case "0":
        contentDelay = ""
        break;
      case "delay_2":
        contentDelay = "delay2"
        break;
      case "delay_4":
        contentDelay = "delay4"
        break;
      case "delay_1":
        contentDelay = "delay1"
        break;
      case "delay_3":
        contentDelay = "delay3"
        break;
      default:
        contentDelay = "delay3"
        break;
      }
    }
    return (
      <React.Fragment>
        <ul className="admin_edit_menu">
          <WidthClassConfigable {...this.props} handleDisableAllComponent={this.handleDisableAllComponent} />
          <ColorTextConfigagle {...this.props} handleOpenColorPicker={this.handleOpenColorPicker} colorType={"color"} />
          <li className="width-0">
            {
              this.state.isDisplayColorSetting &&
                <ColorPicker {...this.props}  colorType={"color"} handleOpenColorPicker={this.handleOpenColorPicker}/>
            }
            {
              this.state.isDisplayBackgroundSetting &&
                <ColorPicker {...this.props}  colorType={"background"} handleOpenColorPicker={this.handleOpenBackroundPicker}/>
            }
          </li>
          <ColorTextConfigagle {...this.props} handleOpenBackroundPicker={this.handleOpenBackroundPicker} colorType={"background"}/>
          <BackGroundImage handleDisableAllComponent={this.handleDisableAllComponent} index={index} content={content} />
          <PaddingConfigable {...this.props} handleDisableAllComponent={this.handleDisableAllComponent} paddingKind="top"/>
          <PaddingConfigable {...this.props} handleDisableAllComponent={this.handleDisableAllComponent} paddingKind="bottom"/>
          <li className="admin_edit_menu_delay" title="時差表示" onClick={this.handleDisableAllComponent}>
            <i className="material-icons">av_timer</i>
            <select name="admin_edit_menu_delay" onChange={(e) => this.props.handleChangeDelay(e, index)} defaultValue={contentDelay}>
              <option value="">なし</option>
              <option value="delay2">上から</option>
              <option value="delay4">右から</option>
              <option value="delay1">下から</option>
              <option value="delay3">左から</option>
            </select>
          </li>
          <AnchorSetting {...this.props} handleOpenAnchorSetting={this.handleOpenAnchorSetting} displayAnchorTag={this.state.displayAnchorTag}/>
          <li className="width-0">
            { this.state.displayAnchorTag &&
              <div style={styleInput} onMouseLeave={this.handleOpenAnchorSetting} className="anchor_div">
                <input maxLength="255" type="text" name="anchor_tag" placeholder="アンカー名を入力" defaultValue={content.anchor_tag}
                  className="active" onChange={(event) => this.props.handeChangeCssSetting(index, "anchor_tag", event)}/>
              </div>
            }
          </li>
          <li className="admin_edit_menu_setting 460281" title="パーツ設定" onClick={() => this.handleOpenModal(index, data)}>
            <i className="material-icons">settings_applications</i>
          </li>
          <li className="admin_edit_menu_copy 460281" title="複製" onClick={() => this.handleCopyAComponent(index, data)}>
            <i className="material-icons">filter_none</i>
          </li>
          <DragHandle />
          <li className="admin_edit_menu_favorite 216910" title="お気に入りに登録" onClick={(e) => this.handleFavoriteComponent(e, index)}>
            <i className="material-icons">favorite</i>
          </li>
          <li className="admin_edit_menu_delete 216910" title="削除" onClick={() => this.handleDeleteAComponent(index)}>
            <i className="material-icons">delete</i>
          </li>
          <li className="bm 216910" onClick={this.handleDisableAllComponent}>
            <i className="material-icons">dehaze</i>
          </li>
        </ul>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.generalSetting = storeState.generalSetting;

  return newState;
}

export default connect(mapStateToProps) (ConfigablePart)
