import React, { Component } from 'react'

export default class PaddingConfigable extends Component {
  constructor(props) {
    super(props)
    this.renderOptions = this.renderOptions.bind(this)
  }
  renderOptions(prefixClassName) {
    const options = [...Array(11).keys()].reverse()
    return(
      options.map(item => {
        return(
          <option key={item} value={`${prefixClassName}${item * 10}`}>{`${item * 10}px`}</option>
        )
      })
    )
  }
  render() {
    const {paddingKind, content, index} = this.props
    const prefixClassName = paddingKind === "top"  ? "pt" : "pb"
    const className = `${paddingKind}_padding_class`
    return (
      <li className={`admin_edit_menu_${prefixClassName} 457634`} title={paddingKind === "top" ? "上部余白" : "下部余白"} onClick={this.props.handleDisableAllComponent}>
        <i className="material-icons">{`vertical_align_${paddingKind}`}</i>
        <select name={className} className="457634" defaultValue={content[className]} onChange={(event) => this.props.handeChangeCssSetting(index, className, event)}>
          {this.renderOptions(prefixClassName)}
        </select>
      </li>
    )
  }
}
