import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddModalContent from './AddModalContent';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeading,
  faAlignLeft,
  faCode,
} from "@fortawesome/free-solid-svg-icons";

import {
  faBuilding,
  faCommentAlt
} from '@fortawesome/free-regular-svg-icons';
import { loadComparisonData } from "../../../../Lib/redux/actions";
import request from "../../../../untils/request";
import { APIS } from "../../../../untils/constants"
import { addDataUi, addPageDataUi, editDataUi, editPageDataUi, newpageCategories } from '../../../../Lib/redux/actions';
import {toast} from "react-toastify";
import {validateMap, validateMovie, validateTel, validateRequired, validateTextFieldRequired} from "../../../../functions/validateAddModalCss";
import ImageBlocks from './ImageBlocks'
import SitePartBlocks from './SitePartBlocks';
import ContactBlocks from './ContactBlocks';
import HightLevelBlocks from './HightLevelBlocks';

export class AddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemChecked: {},
      itemTree: {},
      dataKind: 'blockHeader',
      isOpenCss: false,
      isOpenDetailData: false,
      isOpenComparisonsInformation: false,
      optionBlockShow: {value: '', isOpen: false},
      errorMessages: {
        tel: null,
        map: null,
        movie: null,
        question: null,
        coupon: null,
        shop: null
      }
    };

    this.displaySetting = this.displaySetting.bind(this);
    this.displayDetailData = this.displayDetailData.bind(this);
    this.handleData = this.handleData.bind(this)
    this.handleDataFavorite = this.handleDataFavorite.bind(this)
    this.appendData = this.appendData.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeAlignment = this.handleChangeAlignment.bind(this)
    this.handleSwitchTabs = this.handleSwitchTabs.bind(this)
    this.handleSelectHeadlineOption = this.handleSelectHeadlineOption.bind(this)
    this.handleChangeContent = this.handleChangeContent.bind(this)
    this.handleChangeSelectedHeadlineType = this.handleChangeSelectedHeadlineType.bind(this);
    this.handleSelectTableCell = this.handleSelectTableCell.bind(this)
    this.onClickCheckAll = this.onClickCheckAll.bind(this)
    this.handleChangeCategory = this.handleChangeCategory.bind(this)
    this.editDataSetting = this.editDataSetting.bind(this)
    this.addDataNew = this.addDataNew.bind(this)
    this.handleDoubleClickItem = this.handleDoubleClickItem.bind(this)
    this.handleChangeSelectPageLink = this.handleChangeSelectPageLink.bind(this)
    this.handleOpenBlockOptions = this.handleOpenBlockOptions.bind(this)
  }

  async fetchComparisonData() {
    const {data: {categories, comparisons}} = await request({
      method: 'get',
      url: `${APIS.get_comparison_categories}?client_id=${this.props.clientId}`
    })

    const comparisonData = {
      categories: categories,
      comparisons: comparisons
    }

    this.props.loadComparisonData(comparisonData)
  }

  componentDidMount() {
    if (this.props.isDisplaySetting){
      let dataBlock = this.props.dataBlock
      const contents = JSON.parse(dataBlock.contents);

      let selectedHeadlineType = contents.headlineType
      if (!dataBlock.hasOwnProperty('selectedHeadlineType')) {
        dataBlock['selectedHeadlineType'] = selectedHeadlineType
      }

      this.setState({
        dataKind: contents.data_kind,
        itemChecked: dataBlock,
        isOpenCss: false
      })
    } else {
      let itemChecked =  Object.assign({}, this.props.templateData[this.state.dataKind][0])
      this.updateItemData(itemChecked)
    }

    this.fetchComparisonData()
  }

  updateItemData(itemChecked) {
    const snakeToCamel = (str) => str.replace(
      /([-_][a-z])/g,
      (group) => group.toUpperCase()
        .replace('-', '')
        .replace('_', '')
    );
    let newItemTree = this.state.itemTree
    newItemTree[snakeToCamel(itemChecked.block_type)] = itemChecked;

    this.setState({ itemChecked: itemChecked, itemTree: newItemTree });
  }

  handleChangeSelectedHeadlineType({ target }) {
    const { itemChecked } = this.state;
    itemChecked.selectedHeadlineType = target.value;
    const contents = JSON.parse(itemChecked.contents);

    if (target.value === "1") {
      contents.headlineKindOfDesign = "0";
    } else if (contents.headlineKindOfDesign === "0") {
      contents.headlineKindOfDesign = "1";
    }
    contents.headlineType = target.value;

    itemChecked.contents = JSON.stringify(contents);
    this.updateItemData(itemChecked);
  }

  handleData(data) {
    const {itemChecked} = this.state;
    let newitemChecked = Object.assign({}, data)

    if (Object.entries(this.state.itemChecked).length !== 0) {
      const nextContents = JSON.parse(data.contents)
      const prevContents = JSON.parse(itemChecked.contents)
      let newContent = {...nextContents, ...prevContents}
      newContent = this.handleSpecialField(data, newContent, nextContents)
      newitemChecked = Object.assign({}, {...data, ...itemChecked})
      newitemChecked.kind_of_design = data.kind_of_design
      newitemChecked.contents = JSON.stringify(newContent);
    }

    this.updateItemData(newitemChecked)
  }

  handleSpecialField(data, newContent, nextContents) {
    if(data.block_type == 'block_table') {
      newContent.table = nextContents.table
    } else if (data.block_type == 'block_recruit') {
      newContent.recruit_flag = nextContents.recruit_flag
      newContent.recruit_id = nextContents.recruit_id
    }
    newContent.src = nextContents.src

    return newContent
  }

  handleDataFavorite(data) {
    const {itemChecked} = this.state;
    let newitemChecked = Object.assign({}, data)
    if (Object.entries(this.state.itemChecked).length !== 0) {
      newitemChecked.css = itemChecked.css
    }
    this.updateItemDataFavorite(newitemChecked)
  }

  updateItemDataFavorite(itemChecked) {
    let newItemTree = this.state.itemTree
    newItemTree["blockFavorite"] = itemChecked;
    this.setState({ itemChecked });
    this.setState({itemTree: newItemTree});
  }

  handleChange({ target }) {
    const { itemChecked } = this.state;
    itemChecked[target.name] = target.value
    this.updateItemData(itemChecked)
  }

  handleChangeAlignment({ target }) {
    const { itemChecked } = this.state;
    itemChecked.block_image_alignment = target.value
    let contents = JSON.parse(itemChecked.contents)
    switch (itemChecked.block_image_alignment) {
      case "top":
        contents.top_padding_class = "pt0"
        contents.bottom_padding_class = "pt60"
        break;
      case "center":
        contents.top_padding_class = "pt60"
        contents.bottom_padding_class = "pt60"
        break;
      default:
        contents.top_padding_class = "pt60"
        contents.bottom_padding_class = "pt0"
        break;
    }
    itemChecked.contents = JSON.stringify(contents)
    this.updateItemData(itemChecked)
  }

  handleSelectHeadlineOption(headlineKindOfDesign) {
    const { itemChecked } = this.state;

    let contents = JSON.parse(itemChecked.contents);
    contents.headlineType = event.target.dataset.type;
    contents.headlineKindOfDesign = headlineKindOfDesign;

    itemChecked.contents = JSON.stringify(contents)
    this.updateItemData(itemChecked)
  }


  handleSelectTableCell({ target }) {
    const { itemChecked } = this.state;
    let contents = JSON.parse(itemChecked.contents);
    contents.wOption = target.value;

    itemChecked.contents = JSON.stringify(contents)

    this.setState({ itemChecked })
  }

  handleChangeContent({ target }) {
    const { itemChecked } = this.state;
    let contents = JSON.parse(itemChecked.contents);
    contents[target.name] = target.value
    itemChecked.contents = JSON.stringify(contents)
    this.updateItemData(itemChecked)
  }

  handleChangeSelectPageLink({ target }) {
    const { itemChecked } = this.state;
    let contents = JSON.parse(itemChecked.contents);
    const page_id = target.options[target.selectedIndex].getAttribute('data_page_id');
    contents[target.name] = target.value
    contents['button_page_id'] = page_id
    itemChecked.contents = JSON.stringify(contents)
    this.updateItemData(itemChecked)
  }

  validateCssInput(itemChecked) {
    let errorMessages = null;
    const contents = JSON.parse(itemChecked.contents)
    switch (itemChecked.block_type) {
      case 'block_movie':
        this.handleChangeContent({target: {name: 'block_movie_text', value: JSON.parse(itemChecked.contents).block_movie_text.trim()}});
        errorMessages = validateMovie(JSON.parse(itemChecked.contents).block_movie_text);
        this.setState({errorMessages: {...{movie: errorMessages}}});
        break;
      case 'block_map':
        this.handleChangeContent({target: {name: 'block_map_text', value: JSON.parse(itemChecked.contents).block_map_text.trim()}})
        errorMessages = validateMap(JSON.parse(itemChecked.contents).block_map_text);
        this.setState({errorMessages: {...{map: errorMessages}}});
        break;
      case 'block_tel':
        this.handleChangeContent({target: {name: 'block_tel_other1', value: JSON.parse(itemChecked.contents).block_tel_other1.trim()}})
        errorMessages = validateTel(JSON.parse(itemChecked.contents).block_tel_other1);
        this.setState({errorMessages: {...{tel: errorMessages}}});
        break;
      case 'block_question':
        errorMessages = validateRequired(itemChecked.categories, "カテゴリー");
        this.setState({errorMessages: {...{question: errorMessages}}});
        break;
      case 'block_blog':
        this.setState({errorMessages: {...{blog: errorMessages}}});
        break;
      case 'block_staff':
        errorMessages = validateRequired(itemChecked.categories, "部署・役職");
        this.setState({errorMessages: {...{staff: errorMessages}}});
        break;
      case 'block_menu':
        errorMessages = validateRequired(itemChecked.categories, "カテゴリー");
        this.setState({errorMessages: {...{menu: errorMessages}}});
        break;
      case 'block_gallery':
        errorMessages = validateRequired(itemChecked.categories, "カテゴリー");
        this.setState({errorMessages: {...{gallery: errorMessages}}});
        break;
      case 'block_comparison':
        errorMessages = validateRequired(itemChecked.categories, "カテゴリー");
        this.setState({errorMessages: {...{comparison: errorMessages}}});
        break;
      case 'block_coupon':
        errorMessages = validateRequired(itemChecked.categories, "クーポン");
        this.setState({errorMessages: {...{coupon: errorMessages}}});
        break;
      case 'block_shop':
        errorMessages = validateRequired(contents.shop_id, "店舗名");
        this.setState({errorMessages: {...{shop: errorMessages}}});
        break;
      case 'block_recruit':
        this.setState({errorMessages: {...{recruit: errorMessages}}});
        break;
      case 'block_form':
        errorMessages = validateRequired(contents.contact_id, "フォーム");
        this.setState({errorMessages: {...{form: errorMessages}}});
        break;
      case 'block_link':
        this.handleChange({target: {name: 'block_link_url', value: itemChecked.block_link_url.trim()}})
        errorMessages = validateTextFieldRequired(itemChecked.block_link_url, "URL");
        this.setState({errorMessages: {...{block_link_url: errorMessages}}});
        break;
      default:
        break;
    }
    return errorMessages;
  }

  componentWillMount() {
    this.clickTimeout = null
  }

  handleDoubleClickItem(data = null) {
    if (this.clickTimeout !== null) {
      if (data != null) this.handleData(data)
      this.appendData()
      clearTimeout(this.clickTimeout)
      this.clickTimeout = null
    } else {
      if (data != null) this.handleData(data)
      this.clickTimeout = setTimeout(()=>{
      clearTimeout(this.clickTimeout)
        this.clickTimeout = null
      }, 300)
    }
  }

  appendData() {
    if (this.validateCssInput(this.state.itemChecked)) {
      toast.error("入力内容をご確認ください", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    if (Object.entries(this.state.itemChecked).length !== 0){
      if (this.props.isDisplaySetting === true) {
        this.editDataSetting()
      } else {
        this.addDataNew()
      }
    }
    this.props.handleShowModalAdd()
    if (this.state.itemChecked.block_type == 'block_comparison') {
      this.fetchComparisonData()
    }
  }

  editDataSetting() {
    if (this.props.isNewpageAdd === true) {
      this.props.editPageDataUi(this.state.itemChecked);
    } else {
      this.props.editDataUi(this.state.itemChecked);
    }
  }

  addDataNew() {
    const {generalSetting} = this.props
    const {itemChecked} = this.state
    let content = JSON.parse(this.state.itemChecked.contents)
    let contentDelay = content.delay
    if(contentDelay === undefined) {
      if(generalSetting.delay_flag == 0){
        contentDelay = ""
      }
      else {
        contentDelay = generalSetting.delay_flag.replace("_", "")
      }
      content.delay = contentDelay
      itemChecked.contents = JSON.stringify(content)
    }
    this.setState({itemChecked: itemChecked})
    if (this.props.isNewpageAdd === true) {
      this.props.appendPageDataUi(this.state.itemChecked);
    } else {
      this.props.appendDataUi(this.state.itemChecked);
    }
  }

  handleSwitchTabs({ target }) {
    let itemChecked;
    if (this.state.itemTree[target.dataset.type]) {
      itemChecked = this.state.itemTree[target.dataset.type];
    } else {
      itemChecked = Object.assign({}, this.props.templateData[target.dataset.type][0]);
    }

    this.setState({
      dataKind: target.dataset.type,
      itemChecked: itemChecked,
      isOpenCss: false,
      isOpenDetailData: false
    })

    window.innerWidth < 480 && $("#dehaze").click();
  }

  displaySetting() {
    if (this.state.isOpenDetailData) {
      this.setState({ isOpenDetailData: false })
    }
    this.setState({
      isOpenCss: !this.state.isOpenCss,
    })
  }

  onClickCheckAll(kindOfCategory) {
    const { itemChecked } = this.state;
    let categories = []
    switch (kindOfCategory) {
      case "blogCategories":
        categories = this.props.newpageCategories
        break;
      case "questionCategories":
        categories = this.props.questionCategories
        break;
      case "newsCategories":
        categories = this.props.newsCategories
        break;
      case "menuCategories":
        categories = this.props.menuCategories
        break;
      case "couponCategories":
        categories = this.props.coupons
        break;
      case "staffCategories":
        categories = this.props.staffCategories
        break;
      case "recruitCategories":
        categories = this.props.recruitCategories
        break;
      case "galleryCategories":
        categories = this.props.galleryCategories
        break;
      case "comparisonCategories":
        categories = this.props.comparisonCategories
        break;
      default:
        break;
    }
    const newCategories = categories.map((item) => { return(item.id) })
    itemChecked.categories = newCategories
    this.updateItemData(itemChecked)
  }

  handleChangeCategory({target}) {
    const { itemChecked } = this.state;
    let categories = itemChecked.categories
    if(target.checked) {
      categories.push(Number(target.value))
    }
    else {
      const index = categories.indexOf(Number(target.value))
      if (index > -1) {
        categories.splice(index, 1);
      }
    }
    itemChecked.categories = categories
    this.updateItemData(itemChecked)
  }

  displayDetailData() {
    this.setState({
      isOpenCss: false,
      isOpenDetailData: true,
    })
  }

  handleOpenBlockOptions(value) {
    const {optionBlockShow} = this.state
    optionBlockShow.isOpen = !optionBlockShow.isOpen
    if(optionBlockShow.value != value) {
      optionBlockShow.isOpen = true
    }
    optionBlockShow.value = value
    this.setState({optionBlockShow: optionBlockShow})
  }

  render() {
    const { dataKind } = this.state;
    let loadClass = this.props.isRequesting ? 'load' : 'loaded';

    return (
      <div id="setting_modal" data-step="#block_header" className={`active admin_edit_menu_add ${loadClass}`}>
        <div className="wrapper">
          <div id="contents" data-type="admin_edit_add">
            <p className="modal_header">
              <i>パーツ設定</i>
              <span className="modal_header_btn cancel first_button" onClick={this.props.handleShowModalAdd}>キャンセル</span>
              <span className="modal_header_btn ok" onClick={this.appendData}>決定</span>
            </p>
            {
              !this.props.isDisplaySetting &&
              <div id="content_l" style={{ outline: 'none' }} tabIndex="1">
                <div className="nav">
                    <ul style={{padding: "5px"}}>
                      <li><a href="#!" onClick={this.handleSwitchTabs} data-type="blockHeader" className={dataKind == 'blockHeader' ? 'selected': ''}>
                        <FontAwesomeIcon icon={faHeading} />見出し</a>
                      </li>
                      <li><a href="#!" onClick={this.handleSwitchTabs} data-type="blockText" className={dataKind == 'blockText' ? 'selected': ''}>
                        <FontAwesomeIcon icon={faAlignLeft} />本文</a>
                      </li>
                      <ImageBlocks handleSwitchTabs={this.handleSwitchTabs} dataKind={dataKind} optionBlockShow={this.state.optionBlockShow}
                        handleOpenBlockOptions={this.handleOpenBlockOptions}/>

                      <SitePartBlocks handleSwitchTabs={this.handleSwitchTabs} dataKind={dataKind} optionBlockShow={this.state.optionBlockShow}
                        handleOpenBlockOptions={this.handleOpenBlockOptions}/>

                      <ContactBlocks handleSwitchTabs={this.handleSwitchTabs} dataKind={dataKind} optionBlockShow={this.state.optionBlockShow}
                        handleOpenBlockOptions={this.handleOpenBlockOptions}/>

                      <HightLevelBlocks handleSwitchTabs={this.handleSwitchTabs} dataKind={dataKind} optionBlockShow={this.state.optionBlockShow}
                        handleOpenBlockOptions={this.handleOpenBlockOptions}/>
                    </ul>
                </div>
                <div id="dehaze" className="bm">
                  <i className="material-icons">dehaze</i>
                </div>
              </div>
            }

            <div id="content_r" style={this.props.isDisplaySetting == true ? {opacity: 1, width: 'calc(100%)'} : {opacity: 1}}>
              <AddModalContent
                blockType={dataKind}
                data={this.props.templateData[dataKind]}
                itemChecked={this.state.itemChecked}
                handleData={(item) => this.handleData(item)}
                handleDataFavorite={this.handleDataFavorite}
                handleChange={this.handleChange}
                handleChangeAlignment={this.handleChangeAlignment}
                handleSelectHeadlineOption={this.handleSelectHeadlineOption}
                handleChangeContent={this.handleChangeContent}
                handleChangeSelectedHeadlineType={this.handleChangeSelectedHeadlineType}
                isOpenCss={this.state.isOpenCss}
                displaySetting={this.displaySetting}
                handleSelectTableCell={this.handleSelectTableCell}
                onClickCheckAll={this.onClickCheckAll}
                handleChangeCategory={this.handleChangeCategory}
                handleDoubleClickItem={this.handleDoubleClickItem}
                isOpenDetailData={this.state.isOpenDetailData}
                displayDetailData={this.displayDetailData}
                errorMessages={this.state.errorMessages}
                handleChangeSelectPageLink={this.handleChangeSelectPageLink}
                {...this.props}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.templateData = storeState.templateData;
  newState.newpageCategories = storeState.newpageCategories;
  newState.questionCategories = storeState.questionCategories;
  newState.newsCategories = storeState.newsCategories;
  newState.menuCategories = storeState.menuCategories;
  newState.coupons = storeState.coupons;
  newState.galleryCategories = storeState.galleryCategories;
  newState.comparisonCategories = storeState.loadComparisonCategories;
  newState.staffCategories = storeState.staffCategories;
  newState.recruitCategories = storeState.recruitCategories;
  newState.shops = storeState.shops;
  newState.isRequesting = storeState.isRequesting;
  newState.generalSetting = storeState.generalSetting
  newState.clientId = storeState.clientId;
  return newState
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadComparisonData: (data) => dispatch(loadComparisonData(data)),
    appendDataUi: (data) => dispatch(addDataUi(data)),
    appendPageDataUi: (data) => dispatch(addPageDataUi(data)),
    editDataUi: (data) => dispatch(editDataUi(data)),
    editPageDataUi: (data) => dispatch(editPageDataUi(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddModal)
