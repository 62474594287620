import React, { Component } from 'react';
import { connect } from 'react-redux';
import request from "../../../../untils/request";
import { APIS } from '../../../../untils/constants';
import { updateSetting, saveReleaseDate, savePageCategoryIds } from "../../../../Lib/redux/actions";
import { ToastContainer, toast } from 'react-toastify';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import { updateErrorMessages } from '../../../../Lib/redux/actions';
import moment from "moment-timezone";
import 'moment/locale/ja';

export class ConfirmModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: this.props.settings,
      display_flag: props.settings.display_flag,
      template_flag: false,
      selectedCategoryIds: this.props.pageCategoryIds,
      releaseDate: props.releaseDate || new Date(),
      releaseDateValid: props.releaseDate || new Date()
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDataForm = this.handleDataForm.bind(this);
    this.renderSnsBox = this.renderSnsBox.bind(this);
    this.handleSelectCategory = this.handleSelectCategory.bind(this);
    this.handleChangeReleaseDate = this.handleChangeReleaseDate.bind(this);
    this.handleBlurReleaseDate = this.handleBlurReleaseDate.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({settings: nextProps.settings});
  }

  handleChange({ target }) {
    const value = target.type === "checkbox" ? target.checked : target.value
    this.setState({ [target.name]: value })
  }

  handleSelectCategory(event) {
    let {selectedCategoryIds} = this.state;
    if(event.target.checked) {
      selectedCategoryIds.push(parseInt(event.target.value));
    } else {
      selectedCategoryIds = selectedCategoryIds.filter(value => {
        return value !== parseInt(event.target.value);
      })
    }
    this.setState({selectedCategoryIds: selectedCategoryIds});
  }

  handleChangeReleaseDate(event) {
    let date;
    if(event._d === undefined) {
      date = event
    } else {
      date = event.format('YYYY-MM-DD HH:mm')

      this.setState({
        releaseDateValid: date,
      })
    }

    this.setState({
      releaseDate: date
    })
  }

  handleBlurReleaseDate(event) {
    let date = event.target.value
    if(!(new Date(date.replace(/\-/g, "/")) instanceof Date && isFinite(new Date(date.replace(/\-/g, "/"))))) {
      date = this.state.releaseDateValid
    }

    this.setState({
      releaseDate: date
    })
  }

  async handleDataForm() {
    let formData = this.props.settings;
    let dataUi = this.props.dataUi;
    let pageKindUrl = '';

    formData.facebook_flag = this.state.facebook_flag
    formData.ameblo_flag = this.state.ameblo_flag
    formData.twitter_flag = this.state.twitter_flag
    formData.display_flag = this.state.display_flag
    formData.slug_plus = `/${formData.slug}`
    formData.newpage_category_ids = this.state.selectedCategoryIds

    if(this.props.pageKind === 'blog' || this.props.pageKind === 'news' || this.props.pageKind === 'blog_template') {
      const dt = this.state.releaseDate
      formData.release_date = new Date(dt)
    }

    let url = APIS.newpage_edit
    if (this.props.pageStatus === 'new') {
      url = APIS.newpage_add
    }
    let pageKindValue = this.props.pageKind
    if (this.props.pageKind === 'blog_template') {
      pageKindValue = 'template';
      pageKindUrl = 'blog_template'
    }
    await request.post(url, {
      data: {
        form_data: formData,
        data_ui: dataUi,
        page_kind: pageKindValue,
        display_flag: this.state.display_flag,
        template_flag: this.state.template_flag,
        tag_names: this.props.newpageTags,
        page_kind_url: pageKindUrl
      },
      headers: {'Content-Type': 'application/json'}
    }).then(res => {
      this.props.handleShowModalConfirm()
      this.props.updateSetting(formData)
      this.props.saveReleaseDate(moment(this.state.releaseDate).format('YYYY-MM-DD HH:mm'))
      this.props.savePageCategoryIds(this.state.selectedCategoryIds)
      this.props.updateErrorMessages({
        description: null,
        h1_text: null,
        title: null,
        name: null,
        slug: null,
        basic_account: null,
        basic_password: null
      })
      if (this.props.pageStatus == 'edit') {
        toast.info("ページを編集しました。", {
          position: toast.POSITION.TOP_RIGHT
        });

        if(this.state.facebook_flag || this.state.twitter_flag) {
          this.props.handleShowModalShareSns();
        }
      }
      let url = res.data.url;
      if(this.state.facebook_flag && this.state.twitter_flag) {
        url += '?facebook_flag=true&twitter_flag=true'
      } else if(this.state.facebook_flag) {
        url += '?facebook_flag=true'
      } else if(this.state.twitter_flag) {
        url += '?twitter_flag=true'
      }
      window.parent.postMessage({
        'func': 'parentFunc',
        'message': url
      }, "*");
    }).catch(e => {
      if(e.response.status === 422) {
        let errorMessage = this.props.settings.errorMessages
        let toastMessage = '入力内容をご確認ください'
        for (const key in errorMessage) {
          if(e.response.data.message.hasOwnProperty('no_content')){
            toastMessage = e.response.data.message['no_content']
          }

          if(e.response.data.message.hasOwnProperty(key)) {
            errorMessage[key] = e.response.data.message[key]
          } else {
            errorMessage[key] = null
          }
        }

        this.props.updateErrorMessages(errorMessage)
        toast.error(toastMessage, {
          position: toast.POSITION.TOP_RIGHT
        });
      } else {
        toast.error(e.response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    })
  }

  renderSnsBox() {
    const { settings, display_flag } = this.state;

    if (display_flag == 0 && this.props.pageKind !== "home") {
      return <p className="caution">公開状態でのみ行なえます。</p>
    } else {
      return (
        <React.Fragment>
          <input type="checkbox" name="facebook_flag" value="1" id="facebook_flag"
                 defaultChecked={''}
                 onChange={this.handleChange} />
          <label htmlFor="facebook_flag" style={{display: "inline-block"}}>Facebook</label>
          <input type="checkbox" name="twitter_flag" value="1" id="twitter_flag"
                 defaultChecked={''}
                 onChange={this.handleChange} />
          <label htmlFor="twitter_flag" style={{display: "inline-block"}}>Twitter</label>
        </React.Fragment>
      )
    }
  }

  renderPublicDate() {
    let inputProps = {
      onBlur: (e) => this.handleBlurReleaseDate(e)
    }

    let time
    if(typeof(this.state.releaseDate) === 'string') {
      time = this.state.releaseDate
    } else {
      time = moment(this.state.releaseDate).format('YYYY-MM-DD HH:mm')
    }

    return(
      <Datetime value={time} inputProps={ inputProps }
                timeFormat="HH:mm" dateFormat="YYYY-MM-DD" onChange={(e) => this.handleChangeReleaseDate(e)}
                className='form-control' />
    )
  }

  renderListCategories() {
    if(this.props.pageKind === 'blog' || this.props.pageKind === 'blog_template') {
      return (
        <React.Fragment>
          {
            this.props.newpageCategories.map((item, index) => {
              if (!item.display_flag) { return null }

              return (
                <div key={index}>
                  <input type='checkbox' name='newpage_categories' value={item.id} id={`newpage_categories_${item.id}`}
                         defaultChecked={this.state.selectedCategoryIds.includes(item.id) ? 'checked' : ''} onChange={this.handleSelectCategory} />
                  <label htmlFor={`newpage_categories_${item.id}`} style={{display: "inline-block"}}>{item.name}</label>
                </div>
              )
            })
          }
        </React.Fragment>
      )
    } else if (this.props.pageKind === 'news') {
      return (
        <React.Fragment>
          {
            this.props.newsCategories.map((item, index) => {
              if (!item.display_flag) { return null }

              return (
                <div key={index}>
                  <input type='checkbox' name='newpage_categories' value={item.id} id={`newpage_categories_${item.id}`}
                         defaultChecked={this.state.selectedCategoryIds.includes(item.id) ? 'checked' : ''} onChange={this.handleSelectCategory} />
                  <label htmlFor={`newpage_categories_${item.id}`} style={{display: "inline-block"}}>{item.name}</label>
                </div>
              )
            })
          }
        </React.Fragment>
      )
    } else {
      return <div />
    }
  }

  render() {
    const { display_flag } = this.state;
    let loadClass = this.props.isRequesting ? 'load' : 'loaded';

    return (
      <div id="setting_modal" data-step="#block_header" className={`active admin_edit_menu_add ${loadClass}`}>
        <div className="wrapper">
          <div id="contents">
            <div className="modal_header">
              <i>公開設定</i>
              <span className="modal_header_btn cancel first_button" onClick={() => this.props.handleShowModalConfirm()}>キャンセル</span>
              <span className="modal_header_btn ok" onClick={this.handleDataForm} >保存</span>
            </div>
            <div className="wrap fix_height">
              {this.props.pageKind !== "home" && this.props.pageKind !== "template" && this.props.pageKind !== "blog_template" && (
                <>
                  <h3><span>公開設定</span></h3>
                  <div className="item">
                    <input name="display_flag" id="display_flag0" value="0" type="radio" onChange={this.handleChange} defaultChecked={ display_flag == 0 ? 'checked' : '' } />
                    <label htmlFor="display_flag0">非公開</label>
                    <input name="display_flag" id="display_flag1" value="1" type="radio" onChange={this.handleChange} defaultChecked={ display_flag == 1 ? 'checked' : '' } />
                    <label htmlFor="display_flag1">公開</label>
                  </div>
                </>
              )}
              {this.props.pageKind !== "template" && this.props.pageKind !== "blog_template" && (
                <>
                  <h3><span>テンプレート</span></h3>
                  <div className="item">
                    <input type="hidden" name="template_flag" value="0" />
                    <input type="checkbox" name="template_flag" value="1"
                           id="template_flag" onChange={this.handleChange}
                           checked={this.state.template_flag} />
                    <label htmlFor="template_flag">新規テンプレートとして登録</label>
                  </div>
                </>
              )}

              {(this.props.pageKind === "blog" || this.props.pageKind === "news" || this.props.pageKind === 'blog_template') && (
                <>
                  <h3><span>公開日時</span></h3>
                  <div className="item">
                    {this.renderPublicDate()}
                  </div>
                </>
              )}

              <h3><span>SNS連動</span></h3>
              <div className="item snsselect">
                {this.renderSnsBox()}
              </div>

              {(this.props.pageKind === "blog" || this.props.pageKind === "news" || this.props.pageKind === 'blog_template') && (
                <>
                  <h3><span>カテゴリ</span></h3>
                  <div className="item">
                    {this.renderListCategories()}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.settings = storeState.settings;
  newState.dataUi = storeState.pageDataUi;
  newState.newpageCategories = storeState.newpageCategories;
  newState.newsCategories = storeState.newsCategories;
  newState.releaseDate = storeState.releaseDate;
  newState.newpageTags = storeState.newpageTags;
  newState.pageCategoryIds = storeState.pageCategoryIds;
  newState.isRequesting = storeState.isRequesting;

  return newState
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateErrorMessages: (data) => {dispatch(updateErrorMessages(data))},
    updateSetting: (data) => dispatch(updateSetting(data)),
    saveReleaseDate: (data) => dispatch(saveReleaseDate(data)),
    savePageCategoryIds: (data) => dispatch(savePageCategoryIds(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal)
