import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { APIS } from "../../../../../untils/constants";
import request from "../../../../../untils/request";
import CategoryOptions from '../../../Modal/CategoryOptions';
import ChooseNumberItem from '../../../Modal/ChooseNumberItem';
import OptionUrl from '../../OptionUrl'

export default class ModalSettingMenuPart extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { itemChecked } = this.props
    const contents = JSON.parse(itemChecked.contents)
    const numberMenuValues = [5, 10, 15, -1]
    return (
      <React.Fragment>
        <h3><span>その他設定</span></h3>
        <CategoryOptions categories={this.props.menuCategories} kindOfItem="menu" isShowDetail={true}
          errorMessages={this.props.errorMessages} {...this.props}/>
        <div className="item">
          <p>カテゴリー名の表示</p>
          <input name="display_category" id="display_category_1" defaultValue={1} type="radio"
            defaultChecked={contents.display_category == 1} onChange={this.props.handleChangeContent}/>
          <label htmlFor="display_category_1">表示しない</label>
          <input name="display_category" id="display_category_2" defaultValue={2} type="radio"
            defaultChecked={contents.display_category == 2} onChange={this.props.handleChangeContent} />
          <label htmlFor="display_category_2">表示する</label>
        </div>

        <div className="item">
          <p>カテゴリー説明文の表示</p>
          <input name="menu_other3" id="menu_other31" defaultValue={0} type="radio" defaultChecked={contents.menu_other3 == 0} onChange={this.props.handleChangeContent} /><label htmlFor="menu_other31">常に表示</label>
          <input name="menu_other3" id="menu_other32" defaultValue={1} type="radio" defaultChecked={contents.menu_other3 == 1} onChange={this.props.handleChangeContent} /><label htmlFor="menu_other32">常に折りたたむ</label>
          <input name="menu_other3" id="menu_other33" defaultValue={2} type="radio" defaultChecked={contents.menu_other3 == 2} onChange={this.props.handleChangeContent} /><label htmlFor="menu_other33">スマホの時のみ折りたたむ</label>
          <div className="mt10"><small>表示させる場合は「カテゴリー名の表示」は「表示する」を選択してください。</small></div>
        </div>

        <ChooseNumberItem contents={contents} kindOfItem="menu" numberValues={numberMenuValues} handleChangeContent={this.props.handleChangeContent}/>
        <h3><span>ボタン</span></h3>
        <div className="item">
          <p>ボタンラベル</p>
          <input name="button_label" type="text" defaultValue={contents.button_label} onChange={this.props.handleChangeContent} />
          <div className="mt10"><small>ボタンラベルが未入力の場合はボタンが表示されません。</small></div>
        </div>
        <div className="item">
          <p>リンク</p>
          <div className="link">
            <input name="menu_link_type" id="menu_link_type_1" defaultValue={1} type="radio" defaultChecked={contents.menu_link_type == 1} onChange={this.props.handleChangeContent}/>
            <label htmlFor="menu_link_type_1">内部リンク</label>
            <input name="menu_link_type" id="menu_link_type_2" defaultValue={2} type="radio" defaultChecked={contents.menu_link_type == 2} onChange={this.props.handleChangeContent} />
            <label htmlFor="menu_link_type_2">外部リンク</label>
            <div className="menu_linkinput1" style={{display: contents.menu_link_type == 1 ? 'block' : 'none'}}>
              <span className="select">
                <select name="menu_link_1" onChange={this.props.handleChangeSelectPageLink} defaultValue={contents.menu_link_1}>
                  <OptionUrl />
                </select>
              </span>
            </div>
            <div className="menu_link_2" style={{display: contents.menu_link_type == 2 ? 'block' : 'none'}}>
              <input name="menu_link_2" placeholder="外部リンクURL" type="text" defaultValue={contents.menu_link_2} onChange={this.props.handleChangeContent} /></div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
