import React, {Component} from 'react';
import { IMAGES } from '../../../../../untils/constants'
import {isOpenImageModal, isOpenImageSettingModal} from "../../../../../Lib/redux/actions";
import {connect} from "react-redux";

export class Tel2 extends Component {
  constructor(props) {
    super(props);
    this.showOperatorImageModal = this.showOperatorImageModal.bind(this);
  }

  showOperatorImageModal() {
    this.props.handleShowImageModal(
      {isOpenImageModal: !this.props.isOpenImageModal, index: this.props.index}
    )
  };

  iconsEditImage() {
    return(
      <>
        <span className="admin_edit_img 372659" title="画像を変更" onClick={this.showOperatorImageModal}>
          <i className="material-icons">collections</i>
        </span>
      </>
    )
  }

  imageUrl() {
    const {item} = this.props
    return item.block_image_url.length == 0 ? IMAGES.no_image : item.block_image_url
  }

  render() {
    return (
      <React.Fragment>
        <a>
          <div className="inner_item_img">
            {this.props.isEdit && this.iconsEditImage()}
            <img src={this.imageUrl()} />
          </div>
        </a>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.isOpenImageModal = storeState.isOpenImageModal
  return newState;
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleShowImageModal: (data) => {dispatch(isOpenImageModal(data))}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tel2)
