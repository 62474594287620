import React, { Component } from 'react';
import { connect } from 'react-redux';

export class MvCustomSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderHtml = this.renderHtml.bind(this);
    this.renderStyle = this.renderStyle.bind(this);
    this.renderScript = this.renderScript.bind(this);
  }


  renderHtml() {
    return {__html: this.props.mv_original_html}
  }

  renderStyle() {
    return {__html: `<style type="text/css">${this.props.mv_original_css}</style>`}
  }

  renderScript() {
    return {__html: `<script type="text/javascript"> ${this.props.mv_original_js} </script>`}
  }

  render() {
    return (
      <React.Fragment>
        {this.props.mv_original_html && <div dangerouslySetInnerHTML={this.renderHtml()} />}
        {this.props.mv_original_css && <div dangerouslySetInnerHTML={this.renderStyle()} />}
        {this.props.mv_original_js && <div dangerouslySetInnerHTML={this.renderScript()} />}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.mv_original_html = storeState.settings.mv_original_html
  newState.mv_original_css = storeState.settings.mv_original_css
  newState.mv_original_js = storeState.settings.mv_original_js

  return newState;
}

export default connect(mapStateToProps)(MvCustomSlider)
