require('datatables.net-bs4');

$(document).ready(function() {
  if($('[id^=staff_categories_datatable_wrapper]').length == 0 && $('#staff_categories_datatable').length > 0) {
    table = $('#staff_categories_datatable').DataTable({
      dom: "<'row'<'col-6'l><'#staff_category_btn_add_page.col-6 text-right'>>" +
        "<'row'<'#staff_category_note.col-sm-12'>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'float-right mb-3'p>",
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        emptyTable: "部署・役職は登録されておりません",
        zeroRecords: "部署・役職は登録されておりません",
        lengthMenu: "_MENU_ を表示する",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        }
      },
      order: [],
      ordering: false,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
        },
        {
          targets: 2,
          orderable: false,
        }
      ],
      drawCallback: function() {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);
      },
      stateSave: true
    });
    $('#staff_category_btn_add_page').append('')
    $('#staff_category_note').append('')
    $('#staff_category_btn_add_page').append(`<a href='/staff_categories/add' class='btn btn-info'>新規追加</a>`);
    $('#staff_category_note').append("<p class='text-danger text-right mt-2'>※データが存在する部署・役職は削除できません</p>")
  }
});
