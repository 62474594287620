import React, {Component} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faToggleOn,
  faMinus,
  faBorderAll,
  faLink,
  faRss,
  faNewspaper,
  faImages,
  faLaugh,
  faQuestionCircle,
  faMicrochip,
  faAward,
  faChild,
  faChartLine,
  faHeart,
  faBook,
  faAngleRight,
  faAngleDown,
  faBuilding,
  faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons";

import {
  faYoutubeSquare
} from '@fortawesome/free-brands-svg-icons';

import {
  faCommentAlt
} from '@fortawesome/free-regular-svg-icons';


export default class SitePartBlocks extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {dataKind, optionBlockShow} = this.props
    const isOpened = optionBlockShow.value === "sitePart" && optionBlockShow.isOpen
    return (
      window.innerWidth > 480 ?
        <li>
          <a href="#!" style={{backgroundColor: isOpened ? "#eee" : "#fff"}}
             onClick={() => this.props.handleOpenBlockOptions("sitePart")}>
            <FontAwesomeIcon icon={isOpened ? faAngleDown : faAngleRight}/>
            <p>サイトパーツ</p>
          </a>
          {isOpened &&
          <ul>
            <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockShop"
                   className={dataKind == 'blockShop' ? 'selected' : ''}>
              <FontAwesomeIcon icon={faBuilding}/>企業・店舗情報</a>
            </li>
            <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockMenu"
                   className={dataKind == 'blockMenu' ? 'selected' : ''}>
              <FontAwesomeIcon icon={faBook}/>メニュー</a>
            </li>
            <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockCoupon"
                   className={dataKind == 'blockCoupon' ? 'selected' : ''}>
              <FontAwesomeIcon icon={faAward}/>クーポン</a>
            </li>
            <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockGallery"
                   className={dataKind == 'blockGallery' ? 'selected' : ''}>
              <FontAwesomeIcon icon={faImages}/>ギャラリー</a>
            </li>
            <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockStaff"
                   className={dataKind == 'blockStaff' ? 'selected' : ''}>
              <FontAwesomeIcon icon={faLaugh}/>スタッフ</a>
            </li>
            <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockQuestion"
                   className={dataKind == 'blockQuestion' ? 'selected' : ''}>
              <FontAwesomeIcon icon={faQuestionCircle}/>よくある質問</a>
            </li>
            <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockConversation"
                   className={dataKind == 'blockConversation' ? 'selected' : ''}>
              <FontAwesomeIcon icon={faCommentAlt}/>会話</a>
            </li>
            <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockComparison"
                   className={dataKind == 'blockComparison' ? 'selected' : ''}>
              <FontAwesomeIcon icon={faMicrochip}/>ビフォーアフター</a>
            </li>
            <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockRecruit"
                   className={dataKind == 'blockRecruit' ? 'selected' : ''}>
              <FontAwesomeIcon icon={faChild}/>リクルート</a>
            </li>
            <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockFavorite"
                   className={dataKind == 'blockFavorite' ? 'selected' : ''}>
              <FontAwesomeIcon icon={faHeart}/>お気に入り</a>
            </li>
            <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockButton"
                   className={dataKind == 'blockButton' ? 'selected' : ''}>
              <FontAwesomeIcon icon={faToggleOn}/>ボタン</a>
            </li>
            <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockLine"
                   className={dataKind == 'blockLine' ? 'selected' : ''}>
              <FontAwesomeIcon icon={faMinus}/>区切り線</a>
            </li>
            <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockTable"
                   className={dataKind == 'blockTable' ? 'selected' : ''}>
              <FontAwesomeIcon icon={faBorderAll}/>テーブル</a>
            </li>
            <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockMap"
                   className={dataKind == 'blockMap' ? 'selected' : ''}>
              <FontAwesomeIcon icon={faMapMarkerAlt}/>Map</a>
            </li>
            <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockLink"
                   className={dataKind == 'blockLink' ? 'selected' : ''}>
              <FontAwesomeIcon icon={faLink}/>外部参照</a>
            </li>
            <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockMovie"
                   className={dataKind == 'blockMovie' ? 'selected' : ''}>
              <FontAwesomeIcon icon={faYoutubeSquare}/>Youtube</a>
            </li>
            <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockBlog"
                   className={dataKind == 'blockBlog' ? 'selected' : ''}>
              <FontAwesomeIcon icon={faRss}/>ブログ</a>
            </li>
            <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockNews"
                   className={dataKind == 'blockNews' ? 'selected' : ''}>
              <FontAwesomeIcon icon={faNewspaper}/>新着情報</a>
            </li>
            <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockHistory"
                   className={dataKind == 'blockHistory' ? 'selected' : ''}>
              <FontAwesomeIcon icon={faChartLine}/>更新履歴</a>
            </li>
          </ul>
          }
        </li>
        :
        <>
          <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockShop"
                 className={dataKind == 'blockShop' ? 'selected' : ''}>
            <FontAwesomeIcon icon={faBuilding}/>企業・店舗情報</a>
          </li>
          <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockMenu"
                 className={dataKind == 'blockMenu' ? 'selected' : ''}>
            <FontAwesomeIcon icon={faBook}/>メニュー</a>
          </li>
          <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockCoupon"
                 className={dataKind == 'blockCoupon' ? 'selected' : ''}>
            <FontAwesomeIcon icon={faAward}/>クーポン</a>
          </li>
          <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockGallery"
                 className={dataKind == 'blockGallery' ? 'selected' : ''}>
            <FontAwesomeIcon icon={faImages}/>ギャラリー</a>
          </li>
          <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockStaff"
                 className={dataKind == 'blockStaff' ? 'selected' : ''}>
            <FontAwesomeIcon icon={faLaugh}/>スタッフ</a>
          </li>
          <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockQuestion"
                 className={dataKind == 'blockQuestion' ? 'selected' : ''}>
            <FontAwesomeIcon icon={faQuestionCircle}/>よくある質問</a>
          </li>
          <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockConversation"
                 className={dataKind == 'blockConversation' ? 'selected' : ''}>
            <FontAwesomeIcon icon={faCommentAlt}/>会話</a>
          </li>
          <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockComparison"
                 className={dataKind == 'blockComparison' ? 'selected' : ''}>
            <FontAwesomeIcon icon={faMicrochip}/>ビフォーアフター</a>
          </li>
          <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockRecruit"
                 className={dataKind == 'blockRecruit' ? 'selected' : ''}>
            <FontAwesomeIcon icon={faChild}/>リクルート</a>
          </li>
          <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockFavorite"
                 className={dataKind == 'blockFavorite' ? 'selected' : ''}>
            <FontAwesomeIcon icon={faHeart}/>お気に入り</a>
          </li>
          <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockButton"
                 className={dataKind == 'blockButton' ? 'selected' : ''}>
            <FontAwesomeIcon icon={faToggleOn}/>ボタン</a>
          </li>
          <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockLine"
                 className={dataKind == 'blockLine' ? 'selected' : ''}>
            <FontAwesomeIcon icon={faMinus}/>区切り線</a>
          </li>
          <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockTable"
                 className={dataKind == 'blockTable' ? 'selected' : ''}>
            <FontAwesomeIcon icon={faBorderAll}/>テーブル</a>
          </li>
          <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockMap"
                 className={dataKind == 'blockMap' ? 'selected' : ''}>
            <FontAwesomeIcon icon={faMapMarkerAlt}/>Map</a>
          </li>
          <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockLink"
                 className={dataKind == 'blockLink' ? 'selected' : ''}>
            <FontAwesomeIcon icon={faLink}/>外部参照</a>
          </li>
          <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockMovie"
                 className={dataKind == 'blockMovie' ? 'selected' : ''}>
            <FontAwesomeIcon icon={faYoutubeSquare}/>Youtube</a>
          </li>
          <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockBlog"
                 className={dataKind == 'blockBlog' ? 'selected' : ''}>
            <FontAwesomeIcon icon={faRss}/>ブログ</a>
          </li>
          <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockNews"
                 className={dataKind == 'blockNews' ? 'selected' : ''}>
            <FontAwesomeIcon icon={faNewspaper}/>新着情報</a>
          </li>
          <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockHistory"
                 className={dataKind == 'blockHistory' ? 'selected' : ''}>
            <FontAwesomeIcon icon={faChartLine}/>更新履歴</a>
          </li>
        </>
    )
  }
}
