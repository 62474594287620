import React, { Component } from 'react'

export class CustomSlider extends Component {
  render() {
    const { settings } = this.props;
    return (
      <div className="mvoriginal mvoriginal_2">
        <div className="item">
          <p>HTML</p>
          <textarea onChange={this.props.handleChange} name="mv_original_html" placeholder="<div class=&quot;your_parts_class&quot;><p>テキストテキストテキストテキストテキスト</p></div>" className="form-control rightclickarea" rows="5">
            { settings.mv_original_html }
          </textarea>
          <p><small>オリジナルで作成したMVは編集画面では崩れる場合があります。保存後、実際のホームページでご確認ください。</small></p>
        </div>
        <div className="item">
          <p>CSS</p>
          <textarea onChange={this.props.handleChange} name="mv_original_css" placeholder=".selector{
            color: blue;
            }" className="form-control rightclickarea" rows="5">
            { settings.mv_original_css }
          </textarea>
        </div>
        <div className="item">
          <p>JS</p>
          <textarea onChange={this.props.handleChange} name="mv_original_js" placeholder="jQueryが使えます。" className="form-control rightclickarea" rows="5">
            { settings.mv_original_js }
          </textarea>
        </div>
      </div>
    )
  }
}

export default CustomSlider
