import React, { Component } from 'react'
import ModdablePart from '../ModdablePart';
import { IMAGES } from '../../../../../untils/constants'
import { connect } from 'react-redux';
import { resetCategories } from '../../../../../functions/resetCategories'
export class StaffItems extends Component {
  constructor(props) {
    super(props);
    this.title = "スタッフデザイン"
  }

  componentDidMount() {
    const newData = resetCategories(this.props.itemChecked, this.props.staffCategories)
    this.props.handleData(newData)
  }
  
  render() {
    return (
      <ModdablePart title={this.title}>
        <ul className="no_select">
          {
            this.props.data.map((item, index) => {
              let content = JSON.parse(item.contents)
              return(
                <li key={index}>
                  <input name="block_staff_pattern" id={`block_staff_pattern_${item.kind_of_design}`}  type="radio"
                    defaultChecked={this.props.itemChecked.kind_of_design == item.kind_of_design} />
                  <label htmlFor={`block_staff_pattern_${item.kind_of_design}`}
                    onClick={ () => this.props.handleDoubleClickItem(resetCategories(item, this.props.staffCategories)) }>
                    <img src={IMAGES[content.src]}
                    />
                  </label>
                </li>
              )
            })
          }
        </ul>
      </ModdablePart>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.staffCategories = storeState.staffCategories
  return newState
}

export default connect(mapStateToProps)(StaffItems)
