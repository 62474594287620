import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Youtube from './Youtube';
import ConfigablePart from "../ConfigablePart";
import HeadlineComponent from "../HeadlineComponent";
import ExplanationComponent from "../ExplanationComponent";
import { parallaxScroll } from '../../../../../functions';

class LoadableMoviePart extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    isEdit: true,
  };

  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    parallaxScroll();
  }

  render() {
    const { content, kindOfDesign, sectionStyle } = this.props;
    return (
      <section key={kindOfDesign} id={kindOfDesign}
               className={`widget_youtube align-left block_movie_1 delay3 ${content.width_class} ${content.parallax != 1 ? "parallax" : ""} blockarea ${content.top_padding_class}  ${content.bottom_padding_class} edit_outer delay4Active editing block_movie without delay3Active`}
               style={sectionStyle}>
        {this.props.isEdit && <ConfigablePart {...this.props} />}
        {
          content.parallax != 1 &&
          <div className="parallax-wrap">
            <div className="parallax_img" style={{backgroundImage: `url(${content.background_image_url})`, height: '100%', transform: 'translateY(-50%)'}} />
          </div>
        }
        <div className="content_wrapper">
          <HeadlineComponent content={this.props.content} index={this.props.index}
                             isEdit={this.props.isEdit}
                             handleChangeHeadline={this.props.handleChangeHeadline}
                             handleChangeAlign={this.props.handleChangeAlign}
                             handleChangeText={this.props.handleChangeText}
                             blockType={this.props.blockType} />
          {
            this.props.content.headlineType == "2" &&
            <ExplanationComponent content={this.props.content} index={this.props.index}
                                  isEdit={this.props.isEdit}
                                  handleChangeHeadline={this.props.handleChangeHeadline}
                                  handleChangeAlign={this.props.handleChangeAlign} />
          }
          <Youtube content={content} />
        </div>
      </section>
    )
  }
}

export default LoadableMoviePart
