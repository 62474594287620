import React, { Component } from 'react'

export default class SelectField extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const {item, remarks} = this.props
    return (
      <dl>
        <dt>{item.input_name}</dt>
        <dd>
          <select>
            {
              item.input_content.split(',').map((item) => {
                return(<option value={item}>{item}</option>)
              })
            }
          </select>
          <p className="cap">{remarks}</p>
        </dd>
      </dl>
    )
  }
}
