$(document).ready(function() {
  //並び替え
  $(function () {
    $('#shop-detail-sortable-table tbody').sortable({
      cursor: 'move',
      items: 'tr',
      axis: 'Y',
      cancel: '#non-sort',
      handle: '.handle',
      // ドラッグ開始時に呼ばれる
      start: function (event, ui) {},
      // 並び替え中に、DOM要素の位置が変更されたときに呼び出される
      change: function (event, ui) {},
      // 並び替えが終了し、DOM要素の位置が変更されたときに呼び出される
      update: function (event, ui) {
        shopDetailLineSort();
      },
      // 他の Sortable 要素からアイテムを受け取ったときに呼び出される
      receive: function (event, ui) {},
      // ドラッグ終了時に呼ばれる
      stop: function (event, ui) {},
    });
  });

  $('#shop-detail-add-line').on('click', function(e) {
    var index = $("#item-tbody").children().length
     $.ajax({
      url: `/shops/append_detail?index=${index}`,
      type: "GET",
      dataType: 'json',
      success: function(data) {
        $('#item-tbody').append(data.html);
      }
    })
  })

  $('#item-tbody').on('click', '.shop-detail-delete-item', function() {
    $(this).closest('tr').remove();
    shopDetailLineSort();
  });
});

function shopDetailLineSort() {
  $("#item-tbody tr").each(function (i) {
    $(this).attr('id', 'tr_' + i);
    $(this).find('.target-01').attr('id', `shop_shop_details_attributes_${i}_name`);
    $(this).find('.target-01').attr('name', `shop[shop_details_attributes][${i}][name]`);
    $(this).find('.target-02').attr('id', `shop_shop_details_attributes_${i}_content`);
    $(this).find('.target-02').attr('name', `shop[shop_details_attributes][${i}][content]`);
  });
}

