import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConfigablePart from "../ConfigablePart";
import { connect } from 'react-redux';
import HeadlineComponent from '../HeadlineComponent';
import {isOpenImageModal, isOpenImageSettingModal} from '../../../../../Lib/redux/actions';
import HeadingPatternComponent from '../BlockImageText/HeadingPatternComponent';
import ContentComponent from '../ContentComponent';
import ExplanationComponent from '../ExplanationComponent';
import { IMAGES, FRONT_END_HOST } from '../../../../../untils/constants';
import { customImageSlider, parallaxScroll } from '../../../../../functions';

class LoadableImageTextPart extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    isVisitOuterLink: PropTypes.bool,
    idString: PropTypes.string,
  };

  static defaultProps = {
    isEdit: true,
    isVisitOuterLink: false,
    idString: null,
  };

  constructor(props) {
    super(props);
    this.state = {block_image_alignment: props.item.block_image_alignment}
    this.blockType = 'block_image_text'
    this.headlineNone = "1"
    this.headlineFull = "2"
    this.noneImage = IMAGES.no_image
    this.showOperatorImageModal = this.showOperatorImageModal.bind(this);
    this.showBlockImgSettingModal = this.showBlockImgSettingModal.bind(this)
    this.imageUrl = this.imageUrl.bind(this)
  }

  componentDidMount() {
    customImageSlider(this.props, null);
    parallaxScroll();
  }

  componentDidUpdate(prevProps, prevState) {
    customImageSlider(this.props, prevProps);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      block_image_alignment: nextProps.item.block_image_alignment
    })
  }

  showOperatorImageModal = () => {
    this.props.handleShowImageModal(
      {isOpenImageModal: !this.props.isOpenImageModal, index: this.props.index}
    )
  };

  showBlockImgSettingModal = () => {
    this.props.handleShowSetting(
      { isOpenImageSettingModal: !this.props.isOpenImageSettingModal, index: this.props.index }
    )
  }

  hrefValue() {
    const content = this.props.content
    switch (parseInt(content.block_image_link)) {
      case 1:
        return '#!'
      case 2:
        if (this.props.idString === null) { return '#!' }
        const homeUrl = `${FRONT_END_HOST}/${this.props.idString}`
        if (content.block_image_inner_link === '/') { return homeUrl }
        return `${homeUrl}${content.block_image_inner_link}.html`
      case 3:
        return content.block_image_outer_link
      default:
        break;
    }
  }

  imageUrl() {
    const {item} = this.props
    return item.block_image_url.length == 0 ? IMAGES.no_image : item.block_image_url
  }

  showBlockImage() {
    if(this.props.isVisitOuterLink) {
      const {content} = this.props;
      const target = (content.block_image_target && parseInt(content.block_image_link) !== 1) ? "_blank" : "_self"

      return(
        <a href={this.hrefValue()} target={target}>
          <picture>
            <img src={this.imageUrl()} alt={this.props.content.block_image_alt} />
          </picture>
        </a>
      )
    }
    return(
      <span>
        <picture>
          <img src={this.imageUrl()} alt={this.props.content.block_image_alt} />
        </picture>
      </span>
    )
  }

  render() {
    const { content, kindOfDesign, isEdit, item, index, handleChangeHeadline, handleChangeAlign, handleChangeText, blockId, sectionStyle} = this.props;
    const block_image_left = [3, 7, 9]
    const block_image_except = [11, 12]
    return (
      <section key={kindOfDesign} id={blockId}
               className={`contents_box01 align-left block_image_text_${kindOfDesign} delay3 ${content.parallax != 1 ? "parallax" : ""} ${content.width_class} ${content.widthImage} blockarea edit_outer delay4Active editing block_image_text delay3Active ${content.top_padding_class} ${content.bottom_padding_class}`}
               style={sectionStyle}>
        {isEdit && <ConfigablePart {...this.props} />}
        {
          content.parallax != 1 &&
          <div className="parallax-wrap">
            <div className="parallax_img" style={{backgroundImage: `url(${content.background_image_url})`, height: '100%', transform: 'translateY(-50%)'}} />
          </div>
        }
        <div className="content_wrapper">
          {
            content.headlineType != this.headlineNone &&
            <HeadlineComponent content={content} index={index}
                               isEdit={isEdit}
                               handleChangeHeadline={handleChangeHeadline}
                               handleChangeAlign={handleChangeAlign} />
          }
          {
            content.headlineType == this.headlineFull &&
            <ExplanationComponent content={content} index={index}
                                  isEdit={isEdit}
                                  handleChangeHeadline={handleChangeHeadline}
                                  handleChangeAlign={handleChangeAlign} />
          }
          <div className="wrapper_item">
            <div className="inner_item_img">
              { isEdit &&
                (
                  <React.Fragment>
                    <span className="admin_edit_link 378534" title="リンク設定" onClick={this.showBlockImgSettingModal}>
                      <i className="material-icons">link</i>
                    </span>
                    <span className="admin_edit_img 378534" title="画像を変更" onClick={this.showOperatorImageModal}>
                      <i className="material-icons">collections</i>
                    </span>
                  </React.Fragment>
                )
              }
              {this.showBlockImage()}
              { isEdit && !block_image_except.includes(kindOfDesign) && !block_image_left.includes(kindOfDesign) &&
                (<span className="admin_edit_img_w" title="画像幅を変更0" style={{width: "791.55px", marginLeft: "87.95px", bottom: "264px"}}>
                  <div className="inputslider ui-slider ui-corner-all ui-slider-horizontal ui-widget ui-widget-content">
                    <span tabIndex="0" className="ui-slider-handle ui-corner-all ui-state-default" style={{left: "33.3333%"}}></span>
                  </div>
                  <input type="number" min="10" max="100" step="5" />
                </span>)
              }
              { isEdit && block_image_left.includes(kindOfDesign) &&
                (<span className="admin_edit_img_w" title="画像幅を変更1" style={{width: "791.55px", bottom: "264px"}}>
                  <div className="inputslider ui-slider ui-corner-all ui-slider-horizontal ui-widget ui-widget-content">
                    <span tabIndex="0" className="ui-slider-handle ui-corner-all ui-state-default" style={{left: "33.3333%"}}></span>
                  </div>
                  <input type="number" min="10" max="100" step="5" />
                </span>)
              }
            </div>

            <div className="inner_item_txt align-left">
              <div className="heading block_header_2 align-left" data-target="1">
                <HeadingPatternComponent content={content} index={index}
                             isEdit={isEdit}
                             handleChangeHeadline={handleChangeHeadline}
                             handleChangeAlign={handleChangeAlign} />
              </div>
              <ContentComponent text={content.text} contentClass={content.contentClass} index={index}
                             isEdit={isEdit}
                             handleChangeText={handleChangeText}
                             handleChangeAlign={handleChangeAlign} />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.isOpenImageModal = storeState.isOpenImageModal
  newState.isOpenImageSettingModal = storeState.isOpenImageSettingModal

  return newState;
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleShowImageModal: (data) => {dispatch(isOpenImageModal(data))},
    handleShowSetting: (data) => {dispatch(isOpenImageSettingModal(data))}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadableImageTextPart)
