import React, { Component } from 'react'
import OptionUrl from '../../OptionUrl'
import CategoryOptions from '../../../Modal/CategoryOptions'

export default class StaffOptions extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { contents } = this.props
    return (
      <React.Fragment>
        <h3><span>その他設定</span></h3>
        <CategoryOptions categories={this.props.staffCategories} kindOfItem="staff" isShowDetail={true} title="部署・役職" {...this.props} />

        <div className="item">
          <p>部署・役職名の表示</p>
          <input name="display_title" id="display_title_1" defaultValue={1} type="radio"
            defaultChecked={contents.display_title == 1} onChange={this.props.handleChangeContent}/>
          <label htmlFor="display_title_1">表示しない</label>
          <input name="display_title" id="display_title_2" defaultValue={2} type="radio"
            defaultChecked={contents.display_title == 2} onChange={this.props.handleChangeContent} />
          <label htmlFor="display_title_2">表示する</label>
        </div>

        <div className="item">
          <p>自由項目の表示</p>
          <input name="display_free" id="display_free_1" defaultValue={1} type="radio"
            defaultChecked={contents.display_free == 1} onChange={this.props.handleChangeContent}/>
          <label htmlFor="display_free_1">常に表示</label>
          <input name="display_free" id="display_free_2" defaultValue={2} type="radio"
            defaultChecked={contents.display_free == 2} onChange={this.props.handleChangeContent}/>
          <label htmlFor="display_free_2">常に折りたたむ</label>
          <input name="display_free" id="display_free_3" defaultValue={3} type="radio"
            defaultChecked={contents.display_free == 3} onChange={this.props.handleChangeContent}/>
          <label htmlFor="display_free_3">スマホの時のみ折りたたむ</label>
        </div>

        <div className="item">
          <p>表示形式</p>
          <input name="display_format" id="display_format_1" defaultValue={1} type="radio"
            defaultChecked={contents.display_format == 1} onChange={this.props.handleChangeContent}/>
          <label htmlFor="display_format_1">カテゴリーで分けて表示</label>
          <input name="display_format" id="display_format_2" defaultValue={2} type="radio"
            defaultChecked={contents.display_format == 2} onChange={this.props.handleChangeContent}/>
          <label htmlFor="display_format_2">一括表示</label>
        </div>

        <div className="item">
          <p>表示件数（部署・役職毎）</p>
          <input name="display_number" id="display_number_1" defaultValue={1} type="radio"
            defaultChecked={contents.display_number == 1} onChange={this.props.handleChangeContent}/>
          <label htmlFor="display_number_1">3件</label>
          <input name="display_number" id="display_number_2" defaultValue={2} type="radio"
            defaultChecked={contents.display_number == 2} onChange={this.props.handleChangeContent}/>
          <label htmlFor="display_number_2">6件</label>
          <input name="display_number" id="display_number_3" defaultValue={3} type="radio"
            defaultChecked={contents.display_number == 3} onChange={this.props.handleChangeContent}/>
          <label htmlFor="display_number_3">9件</label>
          <input name="display_number" id="display_number_4" defaultValue={4} type="radio"
            defaultChecked={contents.display_number == 4} onChange={this.props.handleChangeContent}/>
          <label htmlFor="display_number_4">すべて</label>
        </div>

        <h3><span>ボタン</span></h3>
        <div className="item">
          <p>ボタンラベル</p>
          <input name="button_label" type="text" defaultValue={contents.button_label} onChange={this.props.handleChangeContent} />
          <div className="mt10"><small>ボタンラベルが未入力の場合はボタンが表示されません。</small></div>
        </div>
        <div className="item">
          <p>リンク</p>
          <div className="link">
            <input name="button_link_type" id="button_link_type_1" defaultValue={1} type="radio" defaultChecked={contents.button_link_type == 1} onChange={this.props.handleChangeContent}/>
            <label htmlFor="button_link_type_1">内部リンク</label>
            <input name="button_link_type" id="button_link_type_2" defaultValue={2} type="radio" defaultChecked={contents.button_link_type == 2} onChange={this.props.handleChangeContent} />
            <label htmlFor="button_link_type_2">外部リンク</label>
            <div className="blog_linkinput1" style={{display: contents.button_link_type == 1 ? 'block' : 'none'}}>
              <span className="select">
                <select name="button_link_1" onChange={this.props.handleChangeSelectPageLink} defaultValue={contents.button_link_1}>
                  <OptionUrl />
                </select>
              </span>
            </div>
            <div className="blog_link2" style={{display: contents.button_link_type == 2 ? 'block' : 'none'}}>
              <input name="button_link_2" placeholder="外部リンクURL" type="text" defaultValue={contents.button_link_2} onChange={this.props.handleChangeContent} /></div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
