import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConfigablePart from "../ConfigablePart";
import ContentEditable from 'react-contenteditable';
import HeadlineComponent from '../HeadlineComponent';
import ExplanationComponent from '../ExplanationComponent';
import { IMAGES } from '../../../../../untils/constants';
import { connect } from 'react-redux';
import {isOpenImageModal, isOpenImageSettingModal} from '../../../../../Lib/redux/actions';
import { FRONT_END_HOST } from "../../../../../untils/constants";
import { parallaxScroll } from '../../../../../functions';

class LoadableButtonPart extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    isEditContentable: PropTypes.bool,
  };

  static defaultProps = {
    isEdit: true,
    isEditContentable: false,
  };

  constructor(props) {
    super(props);
    this.state = {}
    this.headlineNone = "1"
    this.headlineFull = "2"
    this.showBlockImgSettingModal = this.showBlockImgSettingModal.bind(this);
    this.showOperatorImageModal = this.showOperatorImageModal.bind(this);
    this.HandleSwitchComponent = this.HandleSwitchComponent.bind(this);
  }

  async componentDidMount () {
    parallaxScroll();
  }

  showBlockImgSettingModal(positionChild) {
    this.props.handleShowSetting(
      { isOpenImageSettingModal: !this.props.isOpenImageSettingModal, index: this.props.index, positionChild: positionChild }
    )
  }

  showOperatorImageModal(positionChild) {
    this.props.handleShowImageModal(
      {isOpenImageModal: !this.props.isOpenImageModal, index: this.props.index, positionChild: positionChild}
    )
  };

  getButtonUrl(content, buttonIndex) {
    const idString = this.props.idString
    if(parseInt(content[`block_image${buttonIndex}_link`]) === 2) {
      if (content[`block_image${buttonIndex}_inner_link`] === '/') { return `${FRONT_END_HOST}/${idString}` }
      return `${FRONT_END_HOST}/${idString}${content[`block_image${buttonIndex}_inner_link`]}.html`
    }
    else {
      return content[`block_image${buttonIndex}_outer_link`]
    }
  }

  handleClick(event, link, isOpenInNewTab) {
    event.preventDefault();
    if (isOpenInNewTab) {
      window.open(link);
    } else {
      window.open(link, '_self');
    }
  }

  HandleSwitchComponent(key, content) {
    const { item, isEdit } = this.props;
    switch (key) {
      case 1:
        return (
          <React.Fragment>
            {
              [...Array(parseInt(content.block_button_other))].map((el, i) => {
                return (
                  <a href='#!' onClick={(event) => {!isEdit && parseInt(content[`block_image${i}_link`]) !== 1 && this.handleClick(event, this.getButtonUrl(content, i), content[`block_image${i}_target`])}} key={i}>
                    {
                      isEdit && <span className="admin_edit_link 422133" title="リンク設定" onClick={() => this.showBlockImgSettingModal(i)}>
                        <i className="material-icons">link</i>
                      </span>
                    }
                    {
                      isEdit &&
                      <ContentEditable
                        innerRef={this.contentEditable}
                        html={this.props.content[`button_${i}_name`]} // innerHTML of the editable div
                        disabled={false}       // use true to disable editing
                        onKeyUp={(event) => this.props.handleChangeHeadline(this.props.index, event, `button_${i}_name`)} // handle innerHTML change
                        tagName='span' // Use a custom HTML tag (uses a div by default)
                        className='contenteditable'
                      />
                    }
                    {
                      !isEdit &&
                      <span dangerouslySetInnerHTML={{__html: this.props.content[`button_${i}_name`]}} />
                    }
                  </a>
                )
              })
            }
          </React.Fragment>
        )
        break;
      case 2:
        return (
          <React.Fragment>
            {
              [...Array(parseInt(content.block_button_other))].map((el, i) => {
                return (
                  <span key={i} className="inner_item_img">
                    {
                      isEdit && <span className="admin_edit_img 422100" title="画像を変更" onClick={() => this.showOperatorImageModal(i)}>
                        <i className="material-icons">collections</i>
                      </span>
                    }
                    <a href='#!' onClick={(event) => {!isEdit && parseInt(content[`block_image${i}_link`]) !== 1 && this.handleClick(event, this.getButtonUrl(content, i), content[`block_image${i}_target`])}}>
                      {
                        isEdit && <span className="admin_edit_link 422100" title="リンク設定" onClick={() => this.showBlockImgSettingModal(i)}>
                          <i className="material-icons">link</i>
                        </span>
                      }
                      <img src={item[`block_image${i}_url`].length === 0 ? IMAGES.no_image : item[`block_image${i}_url`]} alt={content[`block_image${i}_alt`]} data-target="01"></img>
                    </a>
                  </span>
                )
              })
            }
          </React.Fragment>
        )
        break;

      default:
        break;
    }
  }

  render() {
    const { content, kindOfDesign, index, sectionStyle, isEdit } = this.props;
    const className = (kindOfDesign === 1) ? "delay4Active" : "Active"
    return (
      <section key={kindOfDesign} id={kindOfDesign}
           className={`contents_btn01 align-left block_button_${kindOfDesign} delay1 ${className} ${content.width_class} blockarea ${content.top_padding_class}  ${content.bottom_padding_class} edit_outer editing block_button delay1Active`}
           style={sectionStyle}>

        {isEdit && <ConfigablePart {...this.props} />}
        {
          content.parallax != 1 &&
          <div className="parallax-wrap">
            <div className="parallax_img" style={{backgroundImage: `url(${content.background_image_url})`, height: '100%', transform: 'translateY(-50%)'}} />
          </div>
        }
        <div className="content_wrapper">
          {
            content.headlineType !== this.headlineNone &&
            <HeadlineComponent content={content} index={this.props.index}
                              isEdit={isEdit}
                              handleChangeHeadline={this.props.handleChangeHeadline}
                              handleChangeSubHeadline={this.props.handleChangeSubHeadline}
                              handleChangeAlign={this.props.handleChangeAlign} />
          }

          {
            content.headlineType === this.headlineFull &&
            <ExplanationComponent content={content} index={index}
                                  isEdit={isEdit}
                                  handleChangeHeadline={this.props.handleChangeHeadline}
                                  handleChangeAlign={this.props.handleChangeAlign} />
          }

          { this.HandleSwitchComponent(kindOfDesign, content) }
        </div>
      </section>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.isOpenImageModal = storeState.isOpenImageModal
  newState.isOpenImageSettingModal = storeState.isOpenImageSettingModal
  return newState;
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleShowImageModal: (data) => {dispatch(isOpenImageModal(data))},
    handleShowSetting: (data) => {dispatch(isOpenImageSettingModal(data))}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadableButtonPart)
