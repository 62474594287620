import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RecruitDetails from './RecruitDetails';
import RecruitListOfItems from './RecruitListOfItems';
import { APIS } from "../../../../../untils/constants";
import request from "../../../../../untils/request";
import HeadlineComponent from '../HeadlineComponent';
import ExplanationComponent from '../ExplanationComponent';
import LabelComponent from "../../EmbeddedParts/LabelComponent";
import ConfigablePart from "../ConfigablePart";
import { parallaxScroll } from '../../../../../functions';
import LoadableCouponPart from "../BlockCoupon/LoadableCouponPart";

export class LoadableRecruitPart extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    recruits: PropTypes.array,
    isFavorite: PropTypes.bool
  };

  static defaultProps = {
    isEdit: true,
    recruits: [],
    isFavorite: false
  };

  constructor(props) {
    super(props);
    this.state = {
      recruit_categories: {},
      recruit_all: {},
      recruit_category_ids: []
    }
    this.headlineNone = "1"
    this.headlineFull = "2"
  }
  async componentDidMount() {
    await request({
      method: 'get',
      url: `${APIS.get_categories_recruits}?client_id=${this.props.clientId}`,
    }).then(response => {
      this.setState({recruit_categories: response.data.recruits})
      this.setState({recruit_all: response.data.recruit_all})
      this.setState({recruit_category_ids: response.data.recruit_category_ids})
    })
    parallaxScroll();
  }

  filterRecruitData(content) {
    const { recruits } = this.props;
    const newarr = recruits.filter(item => item.id == content.recruit_id)

    return newarr[0]
  }

  render() {
    const {content, isEdit, handleChangeHeadline, handleChangeAlign, item, index, sectionStyle} = this.props;
    let recruitClasses = item.kind_of_design === 1 ? 'recruit_archive add_design1' : 'recruit_info';
    return (
      <section
        key={item.kind_of_design}
        id={`recruit_${item.kind_of_design}`}
        className={`recruit ${recruitClasses} align-left delay3 ${content.width_class} blockarea ${content.top_padding_class} ${content.parallax != 1 ? "parallax" : ""}  ${content.bottom_padding_class} edit_outer without delay4Active editing block_recruit delay3Active`}
        style={sectionStyle}>
        {this.props.isEdit && <ConfigablePart {...this.props} />}
        {
          content.parallax != 1 &&
          <div className="parallax-wrap">
            <div className="parallax_img" style={{backgroundImage: `url(${content.background_image_url})`, height: '100%', transform: 'translateY(-50%)'}} />
          </div>
        }
        <div className="content_wrapper">
          {
            content.headlineType != this.headlineNone &&
            <HeadlineComponent content={content} index={index}
                              isEdit={isEdit}
                              handleChangeHeadline={handleChangeHeadline}
                              handleChangeAlign={handleChangeAlign} />
          }
          {
            content.headlineType == this.headlineFull &&
            <ExplanationComponent content={content} index={index}
                                  isEdit={isEdit}
                                  handleChangeHeadline={handleChangeHeadline}
                                  handleChangeAlign={handleChangeAlign} />
          }
          {
            item.kind_of_design === 1 ? <RecruitListOfItems {...this.props} recruit_categories={this.state.recruit_categories} recruit_all={this.state.recruit_all} recruit_category_ids={this.state.recruit_category_ids} /> : <RecruitDetails recruit={this.filterRecruitData(content)} />
          }
          {
            content.button_label.trim() !== '' &&
            <LabelComponent value={content.button_label} handleChangeLabel={this.props.handleChangeLabel} index={index}
              url={content.recruit_link_type == 1 ? content.recruit_link_1 : content.recruit_link_2}
              link_type={content.recruit_link_type}
              isFavorite={this.props.isFavorite}
              idString={this.props.idString}
              handleChangeLabel={this.props.handleChangeLabel} index={index} isEdit={false}/>
          }
        </div>
      </section>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.recruits = storeState.recruits
  newState.clientId = storeState.clientId
  return newState;
}

export default connect(mapStateToProps)(LoadableRecruitPart)
