$(document).ready(function() {
  $('#add-line').on('click', function(e) {
    var index = $("#item-tbody").children().length
     $.ajax({
      url: `/contacts/append_detail?index=${index}`,
      type: "GET",
      dataType: 'json',
      success: function(data) {
        $('#item-tbody').append(data.html);
        lineSort()
      }
    })
  })

  $('#item-tbody').on('click', '.delete-item', function() {
    var tr = $(this).closest('tr')
    tr.find('input.delete-flag')[0].value = '1'
    tr.hide()
    lineSort()
  })

  $('.copy-tag').on('click', function(e) {
    e.preventDefault()
    var text = $(this).val()
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text)
    }
  })

  $('#contacts_table tbody').sortable({
    cursor: 'move',
    items: 'tr',
    axis: 'Y',
    cancel: '#non-sort',
    handle: '.handle',
    start : function (event , ui){},
    change : function (event , ui){},
    update : function (event , ui){
      $("#item-tbody tr").each(function (i) {
        $(this).find('input.contact-sort')[0].value = i
      });
    },
    receive : function (event , ui){},
    stop : function (event , ui){},
  });
})

function lineSort() {
  $("#item-tbody tr:visible").each(function (i) {
    $(this).find('input.contact-sort')[0].value = i
  });
}
