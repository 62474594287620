import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConfigablePart from "../ConfigablePart";
import ContentEditable from 'react-contenteditable';
import HeadlineComponent from '../HeadlineComponent';
import ExplanationComponent from '../ExplanationComponent';
import { APIS, FRONT_END_HOST } from "../../../../../untils/constants";
import request from "../../../../../untils/request";
import { IMAGES } from '../../../../../untils/constants';
import { parallaxScroll, convertDateTimeLocalContent } from '../../../../../functions';
import { connect } from 'react-redux';

class LoadableNewsPart extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    isEditContentable: PropTypes.bool,
    isFavorite: PropTypes.bool
  };

  static defaultProps = {
    isEdit: true,
    isEditContentable: false,
    isFavorite: false
  };

  constructor(props) {
    super(props);
    this.state = {
      news: [],
    }
    this.headlineNone = "1"
    this.headlineFull = "2"
    this.emptyContent = ""

    this.newsFilterProcess = this.newsFilterProcess.bind(this);
  }

  async componentDidMount () {
    const newpage_id = (this.props.isFavorite || this.props.pageStatus === "commonFooter") ? '' : this.props.settings.newpage_id
    await request({
      method: 'post',
      url: APIS.get_news,
      data: { newpage_id: newpage_id, client_id: this.props.clientId }
    }).then(response => {
      this.setState({
        news: response.data.news
      })
    })
    parallaxScroll();
    convertDateTimeLocalContent();
  }

  showListNews(content, kindOfDesign) {
    const news = this.newsFilterProcess()
    const {isFavorite} = this.props
    return(
      news.map((value, index) => {
        if(index >= parseInt(content.news_number)) return
        const imgStyle = (value.image_class === "no_img") ? {filter: "contrast(0%)", opacity: 0.3} : {}
        return (
          <li key={index}>
            <a href={isFavorite ? `${FRONT_END_HOST}/${value.url}`: "#!"} className={value.image_class == "no_img" ? "noimg" : ""}>
              {
                kindOfDesign !== 1 && kindOfDesign !== 2 && kindOfDesign !== 3 && kindOfDesign !== 11 && kindOfDesign !== 12 &&
                <span className={value.image_class}><img style={imgStyle} src={value.image_url !== "" ? value.image_url : IMAGES.no_image} alt={value.name} /></span>
              }

              {
                kindOfDesign !== 10 && kindOfDesign !== 5 && kindOfDesign !== 7 &&
                <React.Fragment>
                  <p className="release_time">{value.date}</p>
                  <p dangerouslySetInnerHTML={{__html: value.name}}></p>
                </React.Fragment>
              }

              {
                kindOfDesign === 10 &&
                <div className="pwrap">
                  <span className={value.image_class}>
                    <img src={value.image_url !== "" ? value.image_url : IMAGES.no_image} alt={value.name} />
                  </span>
                  <p className="release_time">{value.date}</p>
                  <p dangerouslySetInnerHTML={{__html: value.name}}></p>
                </div>
              }
            </a>
          </li>
        )
      })
    )
  }

  newsFilterProcess() {
    const item = this.props.item
    let filterNews = []

    if (item.categories.length === 0) {
      filterNews = this.state.news.filter(newsItem => {
        return (newsItem.newpage_category_ids.length === 0)
      });
    } else {
      filterNews = this.state.news.filter(newsItem => {
        return newsItem.newpage_category_ids.some(category_id => item.categories.includes(category_id));
      });
    }

    return filterNews
  }

  addDesignClass(item) {
    const kindOfDesign = item.kind_of_design
    if (kindOfDesign === 1) {
      return 'block_news_1'
    }

    if (kindOfDesign === 2) {
      return 'block_news_2'
    }

    if (kindOfDesign === 3) {
      return 'block_news_1 add_design2'
    }

    if (kindOfDesign === 4 || kindOfDesign === 5) {
      return 'block_news_1 add_design3'
    }

    if (kindOfDesign === 6 || kindOfDesign === 7) {
      return 'block_news_1 add_design4'
    }

    if (kindOfDesign === 8) {
      return 'block_news_1 add_design5'
    }

    if (kindOfDesign === 9) {
      return 'block_news_1 add_design6'
    }

    if (kindOfDesign === 10 || kindOfDesign === 11 || kindOfDesign === 12 || kindOfDesign === 13) {
      return `block_news_1 add_design${kindOfDesign}`
    }

    return ''
  }

  getButtonUrl(content) {
    const idString = this.props.idString

    if(content.news_link1 === '1'){
      if (content['news_link1-select'] === '/') { return `${FRONT_END_HOST}/${idString}` }
      return `${FRONT_END_HOST}/${idString}${content['news_link1-select']}.html`
    } else {
      return content['news_link1-input']
    }
  }

  render() {
    const { content, kindOfDesign, index, sectionStyle, isEdit, item } = this.props;
    return (
      <section key={kindOfDesign} id={kindOfDesign}
           className={`${this.addDesignClass(item)} ${content.top_padding_class}  ${content.bottom_padding_class} ${content.parallax != 1 ? "parallax" : ""} delay4 Active ${content.width_class} blockarea edit_outer without delay4Active editing block_news`}
           style={sectionStyle}>

        {isEdit && <ConfigablePart {...this.props} />}
        {
          content.parallax != 1 &&
          <div className="parallax-wrap">
            <div className="parallax_img" style={{backgroundImage: `url(${content.background_image_url})`, height: '100%', transform: 'translateY(-50%)'}} />
          </div>
        }
        <div className="content_wrapper">
          {
            content.headlineType !== this.headlineNone &&
            <HeadlineComponent content={content} index={this.props.index}
                              isEdit={isEdit}
                              handleChangeHeadline={this.props.handleChangeHeadline}
                              handleChangeSubHeadline={this.props.handleChangeSubHeadline}
                              handleChangeAlign={this.props.handleChangeAlign} />
          }

          {
            content.headlineType === this.headlineFull &&
            <ExplanationComponent content={content} index={index}
                                  isEdit={isEdit}
                                  handleChangeHeadline={this.props.handleChangeHeadline}
                                  handleChangeAlign={this.props.handleChangeAlign} />
          }

          <ul>{ this.showListNews(content, kindOfDesign) }</ul>

          {
            content.news_button_label != this.emptyContent &&
            <div className="contents_btn01 block_button_1">
              <div className="content_wrapper">
                <a href={isEdit ? '#!' : this.getButtonUrl(content)} target={isEdit || content.news_link1 == '1' ? '_self' : '_blank'}>
                  <span>{content.news_button_label.trim()}</span>
                </a>
              </div>
            </div>
          }
        </div>
      </section>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);

  newState.headerSetting = storeState.headerSetting;
  newState.clientId = storeState.clientId
  return newState;
}

export default connect(mapStateToProps)(LoadableNewsPart)
