import React, { Component } from 'react';
import Authority from '../Parts/Auth/Authority';

export class LayoutEditComponent extends Component {
  render() {
    return (
      <Authority>
        { this.props.children }
      </Authority>
    )
  }
}

export default LayoutEditComponent
