import ModdablePart from '../ModdablePart';
import { IMAGES } from '../../../../../untils/constants'
import React, { Component } from 'react'

export default class FormItems extends Component {
  constructor(props) {
    super(props);
    this.title = "お問い合わせデザイン"
  }

  componentDidMount() {
    const {itemChecked, contactOptions} = this.props
    if(contactOptions.length > 0) {
      let contents = JSON.parse(itemChecked.contents)
      if(contents.contact_id === "") contents.contact_id = contactOptions[0].id
      itemChecked.contents = JSON.stringify(contents)
      this.props.handleData(itemChecked)
    }
  }

  render() {
    return (
      <ModdablePart title={this.title}>
        { !this.props.isOpenDetailData &&
          <ul className="no_select">
            {
              this.props.data.map((item, index) => {
                let content = JSON.parse(item.contents)
                return(
                  <li key={index}>
                    <input name="block_form_pattern" id={`block_form_pattern${item.kind_of_design}`}  type="radio"
                      defaultChecked={this.props.itemChecked.kind_of_design == item.kind_of_design} />
                    <label htmlFor={`block_form_pattern${item.kind_of_design}`}
                      onClick={ () => this.props.handleDoubleClickItem() }
                      style={{background: 'none 0% 0% / auto repeat scroll padding-box border-box rgb(255, 255, 255)', color: 'rgb(34, 34, 34)'}}>
                      <img src={IMAGES[content.src]}
                    />
                    </label>
                  </li>
                )
              })
            }
          </ul>
        }
      </ModdablePart>
    )
  }
}
