require('datatables.net-bs4');

$(document).ready(function() {
  if($('[id^=redirects_datatable_wrapper]').length == 0 && $('#redirects_datatable').length > 0) {
    table = $('#redirects_datatable').DataTable({
      dom: "<'row'<'col-6'l><'#redirect_btn_add_page.col-6 text-right'>>" +
        "<'row'<'#redirect_btn_batch_delete.col-sm-12'>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'float-right mb-3'p>",
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        lengthMenu: "_MENU_ を表示する",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        },
        emptyTable: "リダイレクト設定は登録されておりません",
        zeroRecords: "リダイレクト設定は登録されておりません",
      },
      order: [],
      columnDefs: [
        {
          targets: 0,
          orderable: false,
          width: "10%",
        },
        {
          targets: 3,
          orderable: false,
          width: "15%",
        },
        {
          targets: 4,
          orderable: false,
          width: "10%",
        }
      ],
      drawCallback: function(settings) {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);
        
        reload_checkbox_all_state()
      },
      stateSave: true
    });

    $('#redirect_btn_add_page').append(`<a href='${$('#redirects_datatable').data("add-new-link")}' class='btn btn-info'>新規追加</a>`);
    $('#redirect_btn_batch_delete').append("<input type='submit' id='redirect_batch_delete_redirect_btn' value='一括削除' class='btn btn-info my-3' data-confirm='削除後のデータは復旧できません。よろしいですか？' data-disable-with='一括削除' />");
  } else {
    localStorage.removeItem('checked_redirects')
  }

  $('#redirect_checkbox_check_all').on('click', function() {
    if (this.checked) {
      $('.checkbox_redirect').not(':disabled').prop('checked', true);
    } else {
      $('.checkbox_redirect').not(':disabled').prop('checked', false);
    }
    $('.checkbox_redirect').first().trigger('change');
  });
  $('#redirects_datatable').on('click', '.checkbox_redirect', function() {
    number_of_checkbox_unchecked = $('.checkbox_redirect:checkbox:not(:checked)').not(':disabled').length;
    if (number_of_checkbox_unchecked > 0) {
      $('#redirect_checkbox_check_all').prop('checked', false);
    } else {
      $('#redirect_checkbox_check_all').prop('checked', true);
    }
  });

  $('#redirect_batch_delete_redirect_btn').on('click', function(event) {
    if ($('.checkbox_redirect:checked').not(':disabled').length == 0) {
      event.preventDefault();
      alert('チェックボックスが選択されていません');
      return false;
    } else {
      let deleteItem = []
      $('.checkbox_redirect:checked').each(function() {
        deleteItem.push($(this).val());
      });
      $('#redirects_delete').val(deleteItem);
    }
  });

  $('.checkbox_redirect').on('change', function() {
    var checked_redirects = String(localStorage.getItem('checked_redirects')).split(',');

    $('.checkbox_redirect').each(function() {
      var index = checked_redirects.indexOf($(this).val());
      if (index !== -1) checked_redirects.splice(index, 1);
    });

    $('.checkbox_redirect:checked').each(function() {
      checked_redirects.push($(this).val());
    });

    localStorage.setItem('checked_redirects', checked_redirects.join(','));
  })
});

function reload_checkbox_all_state() {
  String(localStorage.getItem('checked_redirects')).split(',').forEach(function(value) {
    $('.checkbox_redirect[value=' + value + ']').prop('checked', true);
  });

  number_of_checkbox_enable = $('.checkbox_redirect').not(':disabled').length;
  number_of_checkbox_unchecked = $('.checkbox_redirect:not(:checked)').not(':disabled').length;

  if (number_of_checkbox_enable == 0 || number_of_checkbox_unchecked > 0) {
    $('#redirect_checkbox_check_all').prop('checked', false);
  } else {
    $('#redirect_checkbox_check_all').prop('checked', true);
  }
}
