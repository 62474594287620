import React, { Component } from 'react';
import CategoryOptions from '../../CategoryOptions';
import RecruitSelection from './RecruitSelection';
import OptionUrl from '../../OptionUrl';
import { resetCategories } from '../../../../../functions/resetCategories';

export class RecruitOptionSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderOptionCategory() {
    return (
      <div id="recruit_list_area">
        <CategoryOptions categories={this.props.recruitCategories} isRequired={false} kindOfItem="recruit" {...this.props}/>
        <div className="item">
          <p>表示件数</p>
          <span className="select">
            <select
              name="recruit_number"
              id="recruit_number"
              defaultValue={this.props.contents.recruit_number}
              onChange={this.props.handleChangeContent}
            >
              <option value="3">3件</option>
              <option value="6">6件</option>
              <option value="8">8件</option>
              <option value="10">10件</option>
              <option value="16">16件</option>
              <option value="20">20件</option>
              <option value="25">25件</option>
            </select>
          </span>
        </div>
      </div>
    )
  }

  render() {
    const { contents, data, recruitCategories } = this.props;
    return (
      <React.Fragment>
        <h3><span>その他設定</span></h3>
        <div className="item">
          <p>表示形式</p>
          <input name="recruit_flag"
            id="recruit_flag1"
            value="1"
            type="radio"
            defaultChecked={contents.recruit_flag == 1}
            onChange={() => this.props.handleData(resetCategories(data[0], recruitCategories))}
          />
          <label htmlFor="recruit_flag1">一覧表示</label>
          <input name="recruit_flag"
            id="recruit_flag2"
            value="2"
            type="radio"
            defaultChecked={contents.recruit_flag == 2}
            onChange={() => this.props.handleData(resetCategories(data[1], recruitCategories))}
          />
          <label htmlFor="recruit_flag2">詳細表示</label>
        </div>

        {contents.recruit_flag == 1 && this.renderOptionCategory()}
        {contents.recruit_flag == 2 && <RecruitSelection {...this.props} />}

        <h3><span>ボタン</span></h3>
        <div className="item">
          <p>ボタンラベル</p>
          <input name="button_label" type="text" defaultValue={contents.button_label} onChange={this.props.handleChangeContent} />
          <div className="mt10"><small>ボタンラベルが未入力の場合はボタンが表示されません。</small></div>
        </div>
        <div className="item">
          <p>リンク</p>
          <div className="link">
            <input name="recruit_link_type" id="recruit_link_type_1" defaultValue={1} type="radio" defaultChecked={contents.recruit_link_type == 1} onChange={this.props.handleChangeContent}/>
            <label htmlFor="recruit_link_type_1">内部リンク</label>
            <input name="recruit_link_type" id="recruit_link_type_2" defaultValue={2} type="radio" defaultChecked={contents.recruit_link_type == 2} onChange={this.props.handleChangeContent} />
            <label htmlFor="recruit_link_type_2">外部リンク</label>
            <div className="recruit_linkinput1" style={{display: contents.recruit_link_type == 1 ? 'block' : 'none'}}>
              <span className="select">
                <select name="recruit_link_1" onChange={this.props.handleChangeSelectPageLink} defaultValue={contents.recruit_link_1}>
                  <OptionUrl />
                </select>
              </span>
            </div>
            <div className="recruit_link_2" style={{display: contents.recruit_link_type == 2 ? 'block' : 'none'}}>
              <input name="recruit_link_2" placeholder="外部リンクURL" type="text" onChange={this.props.handleChangeContent} defaultValue={contents.recruit_link_2} />
            </div>
          </div>
        </div>
        
      </React.Fragment>
    )
  }
}

export default RecruitOptionSettings
