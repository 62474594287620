import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

class LoggingInComponent extends Component {
  render() {
    return (
      <div id="logging_in">
        <p className="">こんにちは、管理者さん。現在ログインしています。</p>
        <p>
          <a href="#" target="_blank">
            <FontAwesomeIcon icon={faPencilAlt} />
            このページを編集する
          </a>
        </p>
      </div>

    )
  }
}

export default LoggingInComponent
