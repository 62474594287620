import React, { Component } from 'react'
import HeadingComponent from './BlockHeading/HeadingComponent';
import { CKEditor } from "ckeditor4-react";

export class HeadlineComponent extends Component {
  constructor(props) {
    super(props);
    this.handleSwitchComponent = this.handleSwitchComponent.bind(this);
  }

  handleSwitchComponent(key, content, headlineType) {
    const { isEdit } = this.props;

    if (["1","2","3","4","5","6","7"].includes(key)) {
      return <HeadingComponent content={content} isEdit={isEdit} type={key}
                               index={this.props.index}
                               handleChangeHeadline={this.props.handleChangeHeadline}
                               handleChangeAlign={this.props.handleChangeAlign}
                               headlineType={headlineType} />
    }
    return
  }
  
  render() {
    const content = this.props.content
    return (
      <React.Fragment>
        { this.handleSwitchComponent(content.headlineKindOfDesign, content, content.headlineType) }
      </React.Fragment>
    )
  }
}

export default HeadlineComponent
