import React, { Component } from 'react'
import ConfigablePart from "../ConfigablePart";
import PropTypes from 'prop-types';
import { APIS, FRONT_END_HOST } from "../../../../../untils/constants";
import request from "../../../../../untils/request";
import HeadlineComponent from '../HeadlineComponent';
import ExplanationComponent from '../ExplanationComponent';
import FavoriteModal from "../../FavoriteModal";
import { slickSlider } from '../../../../../functions';
import LabelComponent from "../../EmbeddedParts/LabelComponent"
import { parallaxScroll } from '../../../../../functions';
import {connect} from 'react-redux';

class LoadableGalleryPart extends Component {
  constructor(props) {
    super(props)
    this.showListGallery = this.showListGallery.bind(this)
    this.headlineNone = "1"
    this.headlineFull = "2"
    this.state = { galleries: {} }
  }

  static propTypes = {
    isEdit: PropTypes.bool,
    isOpenFavoriteModal: PropTypes.bool,
    isFavorite: PropTypes.bool
  };

  static defaultProps = {
    isEdit: true,
    isOpenFavoriteModal: false,
    isFavorite: false
  };

  async componentDidMount () {
    await request({
      method: 'get',
      url: `${APIS.get_galleries}?client_id=${this.props.clientId}`,
    }).then(response => {
      this.setState({galleries: response.data.galleries})
    })
    parallaxScroll();
  }

  async componentDidUpdate() {
    if (Object.keys(this.state.galleries).length > 0){
      slickSlider()
    }
  }

  showListGallery() {
    const {galleries} = this.state
    const {content} = this.props
    const { kind_of_design, categories} = this.props.item
    const display_type = content.gallery_other3
    const gallery_number = content.gallery_number
    if(display_type == 1){
      return(
        Object.keys(galleries).map((key, index) => {
          const category_id = galleries[key].category_id
          if(categories.indexOf(category_id) > -1) {
            let gallery_contents = galleries[key].gallery
            gallery_contents.sort((prev, next) => prev.sort - next.sort);
            if(gallery_contents.length > 0 && gallery_number > 0) {
              gallery_contents = gallery_contents.slice(0, gallery_number)
            }
            return (
              <React.Fragment key={index}>
                { content.gallery_other2 == "2" &&
                  <React.Fragment>
                    <div className="heading block_header_2 without">
                      <h3 className="h">{galleries[key].category_name}</h3>
                      <p>
                        <font style={{verticalAlign: 'inherit'}}>{galleries[key].category_alias}
                        </font>
                      </p>
                    </div>
                  </React.Fragment>
                }
                { gallery_contents.length > 0 &&
                  <div className="wrapper_item">
                    {
                      gallery_contents.map((item, index) => {
                        return (
                          <React.Fragment>
                            <div className="inner_item" aria-hidden="false">
                              <a href={this.props.isFavorite ? `${FRONT_END_HOST}/${item.url}`: "#!"} tabIndex={0}>
                                <img src={item.feature_image_url} alt="My Title" />
                              </a>
                              <div className="heading block_header_2">
                                <h4 className="h">{item.name}</h4>
                                { (kind_of_design == '3' || kind_of_design == '4' || kind_of_design == '5') &&
                                <React.Fragment>
                                  <p>{item.sub_name}</p>
                                  <div className="ck-content">
                                    <div dangerouslySetInnerHTML={{__html: item.content}} />
                                  </div>
                                </React.Fragment>
                                }
                              </div>
                            </div>
                          </React.Fragment>
                        )
                      })
                    }
                  </div>
                }
              </React.Fragment>
            )
          }
        })
      )
    }

    let gallery_contents = []
    Object.keys(galleries).map((key, index) => {
      const category_id = galleries[key].category_id
      if(categories.indexOf(category_id) > -1) {
        gallery_contents = [...gallery_contents, ...galleries[key].gallery]
      }
    })

    if(gallery_contents.length === 0) { return }

    gallery_contents.sort((prev, next) => prev.sort - next.sort);
    if(gallery_number > 0) {
      gallery_contents = gallery_contents.slice(0, gallery_number)
    }
    return (
      <React.Fragment>
        <div className="wrapper_item">
          {
            gallery_contents.map((item, index) => {
              return (
                <React.Fragment key={index} >
                  <div className="inner_item" aria-hidden="false">
                    <a href={this.props.isFavorite ? `${FRONT_END_HOST}/${item.url}`: "#!"} tabIndex={0}>
                      <img src={item.feature_image_url} alt="My Title" />
                    </a>
                    <div className="heading block_header_2">
                      <h4 className="h">{item.name}</h4>
                      { (kind_of_design == '3' || kind_of_design == '4' || kind_of_design == '5') &&
                        <React.Fragment>
                          <p>{item.sub_name}</p>
                          <div className="ck-content">
                            <div dangerouslySetInnerHTML={{__html: item.content}} />
                          </div>
                        </React.Fragment>
                      }
                    </div>
                  </div>
                </React.Fragment>
              )
            })
          }
        </div>
      </React.Fragment>
    )
  }

  render() {
    const {content, isEdit, handleChangeHeadline, handleChangeAlign, item, indexBlockData, index, data, sectionStyle} = this.props;
    return (
      <section key={item.kind_of_design} id={item.kind_of_design} className={`widget_gallery01 add_design${item.kind_of_design}  ${content.parallax != 1 ? "parallax" : ""}delay3 ${content.width_class} align-left  blockarea ${content.top_padding_class}  ${content.bottom_padding_class} edit_outer without delay4Active editing block_gallery delay3Active`}
        style={sectionStyle}>
        {this.props.isEdit && <ConfigablePart {...this.props} />}
        {
          content.parallax != 1 &&
          <div className="parallax-wrap">
            <div className="parallax_img" style={{backgroundImage: `url(${content.background_image_url})`, height: '100%', transform: 'translateY(-50%)'}} />
          </div>
        }
        <div className="content_wrapper">
          {
            content.headlineType != this.headlineNone &&
            <HeadlineComponent content={content} index={index}
                              isEdit={isEdit}
                              handleChangeHeadline={handleChangeHeadline}
                              handleChangeAlign={handleChangeAlign} />
          }
          {
            content.headlineType == this.headlineFull &&
            <ExplanationComponent content={content} index={index}
                                  isEdit={isEdit}
                                  handleChangeHeadline={handleChangeHeadline}
                                  handleChangeAlign={handleChangeAlign} />
          }
          {
            this.showListGallery()
          }
          {
            content.button_label.trim() != "" &&
            <LabelComponent value={content.button_label}
              index={index} {...this.props} link_type={content.gallery_link_type}
              url={content.gallery_link_type == 1 ? content.gallery_link_1 : content.gallery_link_2}
              isFavorite={this.props.isFavorite}
              handleChangeLabel={this.props.handleChangeLabel} index={index} isEdit={false} />
          }
        </div>
        {
          this.props.isOpenFavoriteModal && indexBlockData !== null &&
          <FavoriteModal {...this.props} content={data[indexBlockData]} />
        }
      </section>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.clientId = storeState.clientId;
  return newState;
}

export default connect(mapStateToProps, null)(LoadableGalleryPart)
