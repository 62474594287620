import React, { Component } from 'react';
import { connect } from 'react-redux';
import {isDisplayModalAdd, isOpenImageModal} from '../../../Lib/redux/actions'
import Configable from './Settings/Configable';
import AddModal from './AddNew/AddModal'
import ConfirmModal from './Settings/ConfirmModal'
import SettingModal from './Settings/SettingModal'
import OperatorImageModal from './OperatorImageModal'
import request from "../../../untils/request";
import {APIS} from "../../../untils/constants";
import OperatorImageSettingModal from './OperatorImageSettingModal';

export class OperableModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenAdd: false,
      isOpenConfigured: props.pageStatus == 'new' || props.pageKind == 'copy_template' ? true : false,
      isOpenConfirm: false,
      isOpenOperatorImageModal: false
    }
    this.handleShowModalAdd = this.handleShowModalAdd.bind(this)
    this.handleShowModalConfig = this.handleShowModalConfig.bind(this)
    this.handleShowModalConfirm = this.handleShowModalConfirm.bind(this)
    this.renderConfigable = this.renderConfigable.bind(this)
    this.handleCheckResponsive = this.handleCheckResponsive.bind(this)
    this.handleShowOperatorImageModal = this.handleShowOperatorImageModal.bind(this)
    this.handleShowBockImgModal = this.handleShowBockImgModal.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({isOpenAdd: nextProps.isOpenAdd});
  }

  handleShowModalAdd() {
    this.setState({ isOpenAdd: !this.state.isOpenAdd });
    this.props.displayModalAdd(!this.state.isOpenAdd)
  }

  handleShowModalConfig() {
    this.setState({ isOpenConfigured: !this.state.isOpenConfigured });
  }

  handleShowModalConfirm() {
    this.setState({ isOpenConfirm: !this.state.isOpenConfirm });
  }

  handleShowOperatorImageModal() {
    this.setState({ isOpenImageModal: !this.state.isOpenImageModal });
  }

  handleShowBockImgModal() {
    this.setState({ isOpenImageSettingModal: !this.state.isOpenImageSettingModal })
  }

  async handleCheckResponsive(message) {
    let url = APIS.responsive
    await request.get(url, {
      headers: {'Content-Type': 'application/json'}
    }).then(res => {
      if(typeof window !== undefined) {
        window.parent.postMessage({
          'func': 'openModal',
          'message': message
        }, "*")
      }
    }).catch(e => {})
  }

  renderConfigable() {
    if(!this.state.isOpenAdd && !this.state.isOpenConfigured && !this.state.isOpenConfirm &&
       !this.props.isOpenEditParallax && !this.props.isOpen && !this.props.isOpenFavoriteModal) {
      this.handleCheckResponsive('close')
      return(
        <React.Fragment>
          <Configable handleShowModal={this.handleShowModalConfig}
                      isNewpageAdd={this.props.isNewpageAdd}
                      pageKind={this.props.pageKind}
                      handleShowModalConfirm={this.handleShowModalConfirm} />
        </React.Fragment>
      )
    } else {
      this.handleCheckResponsive('open')
      return(<div />)
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.renderConfigable()}
        {this.state.isOpenAdd && <AddModal handleShowModalAdd={this.handleShowModalAdd} isNewpageAdd={this.props.isNewpageAdd} isDisplaySetting={this.props.isDisplaySetting} dataBlock={this.props.dataBlock} />}
        {
          this.state.isOpenConfigured &&
          <SettingModal handleShowModalConfig={this.handleShowModalConfig} isNewpageAdd={this.props.isNewpageAdd} pageKind={this.props.pageKind}
                        handleUseTemplate={this.props.handleUseTemplate} pageStatus={this.props.pageStatus} templateId={this.props.templateId} />
        }
        {
          this.state.isOpenConfirm &&
          <ConfirmModal handleShowModalConfirm={this.handleShowModalConfirm} pageKind={this.props.pageKind} pageStatus={this.props.pageStatus}
                        handleShowModalShareSns={this.props.handleShowModalShareSns} />
        }
        {
          this.props.isOpenImageModal &&
            <OperatorImageModal showOperatorImageModal={this.handleShowOperatorImageModal} pageStatus={this.props.pageStatus} />
        }
        {
          this.props.isOpenImageSettingModal &&
            <OperatorImageSettingModal showBockImgModal={this.handleShowBockImgModal} pageStatus={this.props.pageStatus}/>
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);

  newState.isOpenAdd = storeState.isOpenAdd;
  newState.isOpenImageModal = storeState.isOpenImageModal
  newState.isOpenImageSettingModal = storeState.isOpenImageSettingModal
  newState.dataBlock = storeState.dataBlock;
  newState.isDisplaySetting = storeState.isDisplaySetting
  return newState;
}

const mapDispatchToProps = (dispatch) => {
  return {
    displayModalAdd: (isOpen) => dispatch(isDisplayModalAdd(isOpen))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OperableModal)
