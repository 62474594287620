$( document ).ready(function() {
  if($(".dataTables_wrapper").length == 0 && $('#blog_category_dataTable').length > 0) {
    table = $('#blog_category_dataTable').DataTable({
      dom: "<'row'<'col-6'l><'.col-6 text-right'>>" +
        "<'row'<'col-sm-12'>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'float-right mb-3'p>",
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        emptyTable: "ブログカテゴリーは登録されておりません",
        zeroRecords: "ブログカテゴリーは登録されておりません",
        lengthMenu: "_MENU_ を表示する",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        }
      },
      order: [],
      ordering: false,
      columnDefs: [
        {
          targets: 0,
        },
        {
          targets: 3,
        }
      ],
      drawCallback: function(settings) {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);
      },
      stateSave: true
    });
  }
});
