import React, { Component } from 'react';
import ModdablePart from '../ModdablePart';
import { IMAGES } from '../../../../../untils/constants'

export class TextItems extends Component {
  constructor(props) {
    super(props);
    this.title = 'テキストデザイン'
  }

  HandleSwitchComponent(key) {
    switch (key) {
      case 1:
          return <img src={ IMAGES.text_type_1 } />
        break;
      case 2:
          return <img src={ IMAGES.text_type_2 } />
        break;
      case 3:
          return <img src={ IMAGES.text_type_3 } />
        break;
      case 4:
          return <img src={ IMAGES.text_type_4 } />
        break;

      default:
        break;
    }
  }

  render() {
    return (
      <ModdablePart title={this.title}>
        <ul className="no_select">
          {
            this.props.data.map((item, index) => {
              let classNameItem = 'block_header_label'
              if(item.kind_of_design === this.props.itemChecked.kind_of_design &&
                  item.block_type === this.props.itemChecked.block_type) {
                classNameItem += " item_active"
              }

              return (
                <li key={index}>
                  <input name="block_text_pattern" id={`block_text_pattern_${item.kind_of_design}`} value={item.kind_of_design} type="radio" />
                  <label
                    htmlFor={`block_text_pattern_${item.kind_of_design}`}
                    onClick={ () => this.props.handleDoubleClickItem(item) }
                    style={{background: 'none 0% 0% / auto repeat scroll padding-box border-box rgb(255, 255, 255)', color: 'rgb(34, 34, 34)'}}
                    className={classNameItem}
                  >
                      { this.HandleSwitchComponent(item.kind_of_design) }
                  </label>
                </li>
              )
            })
          }
        </ul>
      </ModdablePart>
    )
  }
}

export default TextItems
