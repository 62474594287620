require('datatables.net-bs4');

$(document).ready(function() {
  if($('[id^=question_categories_datatable_wrapper]').length == 0 && $('#question_categories_datatable').length > 0) {
    table = $('#question_categories_datatable').DataTable({
      dom: "<'row'<'col-6'l><'#question_category_btn_add_page.col-6 text-right'>>" +
        "<'row'<'#question_category_btn_batch_delete.col-sm-12'>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'float-right mb-3'p>",
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        lengthMenu: "_MENU_ を表示する",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        },
        emptyTable: "カテゴリーは登録されておりません",
        zeroRecords: "カテゴリーは登録されておりません",
      },
      order: [],
      ordering: false,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
        },
        {
          targets: 2,
          orderable: false,
        }
      ],
      drawCallback: function() {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);
        
        number_of_checkbox_unchecked = $('.checkbox_question_category:checkbox:not(:checked)').not(':disabled').length;
        if (number_of_checkbox_unchecked > 0) {
          $('#question_category_checkbox_check_all').prop('checked', false);
        } else {
          $('#question_category_checkbox_check_all').prop('checked', true);
        }
      },
      stateSave: true
    });

    $('#question_category_btn_add_page').append(`<a href='/question_categories/new' class='btn btn-info'>新規追加</a>`);
    $('#question_category_btn_batch_delete').append("<p class='text-danger text-right mt-2'>※データが存在するカテゴリーは削除できません</p>")
  }

  $('#question_category_checkbox_check_all').on('click', function() {
    if (this.checked) {
      $('.checkbox_question_category').not(':disabled').prop('checked', true);
    } else {
      $('.checkbox_question_category').not(':disabled').prop('checked', false);
    }
  });

  $('.checkbox_question_category').on('click', function() {
    number_of_checkbox_unchecked = $('.checkbox_question_category:checkbox:not(:checked)').not(':disabled').length;
    if (number_of_checkbox_unchecked > 0) {
      $('#question_category_checkbox_check_all').prop('checked', false);
    } else {
      $('#question_category_checkbox_check_all').prop('checked', true);
    }
  });

  $('#question_category_batch_delete_category_btn').on('click', function(event) {
    if ($('.checkbox_question_category:checked').not(':disabled').length == 0) {
      event.preventDefault();
      alert('チェックボックスが選択されていません');
      return false;
    }
  });
});
