import React, { Component } from 'react';
import { connect } from 'react-redux';
import ModdablePart from '../ModdablePart';
import ModalDetailData from '../../AddNew/ModalDetailData';
import { IMAGES } from '../../../../../untils/constants';
import { APIS } from "../../../../../untils/constants";
import request from "../../../../../untils/request";
import { loadComparisonCategories } from "../../../../../Lib/redux/actions";
import { resetCategories } from '../../../../../functions/resetCategories'

export class CouponItems extends Component {
  constructor(props) {
    super(props);
    this.title = 'クーポンデザイン'
  }

  componentDidMount() {
    const newData = resetCategories(this.props.itemChecked, this.props.coupons)
    this.props.handleData(newData)
  }

  render() {
    return (
      <ModdablePart title={this.title}>
        { 
          !this.props.isOpenDetailData &&
          <ul className="no_select">
            {
              this.props.data.map((item) => {
                return(
                  <li key={item.kind_of_design}>
                    <input name="block_coupon_pattern" id="block_coupon_pattern_1" value="1" type="radio" defaultChecked={true} />
                    <label htmlFor="block_coupon_pattern_1" 
                           onClick={ () => this.props.handleDoubleClickItem(resetCategories(item, this.props.coupons)) }
                           style={{background: 'none 0% 0% / auto repeat scroll padding-box border-box rgb(255, 255, 255)', color: 'rgb(34, 34, 34)'}}>
                      <img src={IMAGES[item.src]} alt="" />
                    </label>
                  </li>
                )
              })
            }
          </ul>
        }
      </ModdablePart>
    )
  }
}

export default CouponItems
