import React, { Component } from 'react'
import HeadlineComponent from '../HeadlineComponent';
import PropTypes from 'prop-types';
import ConfigablePart from "../ConfigablePart";
import ExplanationComponent from '../ExplanationComponent';
import { APIS } from "../../../../../untils/constants";
import request from "../../../../../untils/request";
import { IMAGES } from '../../../../../untils/constants';
import { parallaxScroll } from '../../../../../functions';

export default class LoadableLinkPart extends Component {
  constructor(props) {
    super(props)
    this.headlineNone = "1"
    this.headlineFull = "2"
    this.state = {
      data: this.props.data,
      content: this.props.content
    }
  }

  static propTypes = {
    isEdit: PropTypes.bool,
    isFavorite: PropTypes.bool
  };

  static defaultProps = {
    isEdit: true,
    isFavorite: false
  };

  async componentDidMount () {
    const {data, content} = this.state
    const {index} = this.props
    if(this.props.item.block_link_url != '') {
      await request({
        method: 'post',
        url: APIS.web_content,
        data: {
          url: this.props.item.block_link_url
        }
      }).then(response => {
        const web_content = response.data.web_content
        content.web_title = web_content.title

        content.web_description = web_content.description
        data[index].contents = JSON.stringify(content)
        this.setState({data: data, content: content}, () => {
          this.props.pageStatus == "commonFooter" ? this.props.saveDataUi(this.state.data) : this.props.savePageDataUi(this.state.data)
        })
      }).catch(() => {
        content.web_title = ''
        content.web_description = ''
        data[index].contents = JSON.stringify(content)
        this.setState({data: data, content: content}, () => {
          this.props.pageStatus == "commonFooter" ? this.props.saveDataUi(this.state.data) : this.props.savePageDataUi(this.state.data)
        })
      })
    }
    else {
      data[index].block_link_url = IMAGES.no_image
      this.setState({data: data, content: content}, () => {
        this.props.pageStatus == "commonFooter" ? this.props.saveDataUi(this.state.data) : this.props.savePageDataUi(this.state.data)
      })
    }
    parallaxScroll();
  }

  render() {
    const {content, isEdit, index, handleChangeHeadline, handleChangeAlign, item, sectionStyle, isFavorite} = this.props;
    return (
      <>
      <section key={item.kind_of_design} id={item.kind_of_design} className={`widget_link align-left block_link_${item.kind_of_design} ${content.parallax != 1 ? "parallax" : ""} delay3 ${content.width_class} blockarea ${content.top_padding_class}  ${content.bottom_padding_class} edit_outer delay4Active editing block_link delay3Active`}
        style={sectionStyle}>
        {this.props.isEdit && <ConfigablePart {...this.props} />}
        {
          content.parallax != 1 &&
          <div className="parallax-wrap">
            <div className="parallax_img" style={{backgroundImage: `url(${content.background_image_url})`, height: '100%', transform: 'translateY(-50%)'}} />
          </div>
        }
        <div className="content_wrapper">
          {
            content.headlineType != this.headlineNone &&
            <HeadlineComponent content={content} index={index}
                              isEdit={isEdit}
                              handleChangeHeadline={handleChangeHeadline}
                              handleChangeAlign={handleChangeAlign} />
          }
          {
            content.headlineType == this.headlineFull &&
            <ExplanationComponent content={content} index={index}
                                  isEdit={isEdit}
                                  handleChangeHeadline={handleChangeHeadline}
                                  handleChangeAlign={handleChangeAlign} />
          }
          <div className="wrapper_item">
            <a href={isFavorite ? item.block_link_url : "#!"} target={isFavorite ? "_blank" : "_self"}>
              <div className="inner_item_txt">{content.web_title}</div>
              { isFavorite && <div className="inner_item_url">{item.block_link_url}</div> }
              {item.id > 0 && <div className="inner_item_url">{"{link01}"}</div>}
              <div className="inner_item_discreption">{content.web_description}</div>
            </a>
          </div>
        </div>
      </section>
    </>
    )
  }
}
