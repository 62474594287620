import React, { Component } from 'react';
import NotImplementPart from '../EmbeddedParts/NotImplementPart';
import HeadingItems from '../EmbeddedParts/BlockHeading/HeadingItems'
import ImageItems from '../EmbeddedParts/BlockImage/ImageItems';
import MovieItems from "../EmbeddedParts/BlockMovie/MovieItems";
import MapItems from "../EmbeddedParts/BlockMap/MapItems";
import TextItems from '../EmbeddedParts/BlockText/TextItems';
import LineItems from '../EmbeddedParts/BlockLine/LineItems';
import ImageTextItems from '../EmbeddedParts/BlockImageText/ImageTextItems';
import ImageText2Items from '../EmbeddedParts/BlockImageText2/ImageText2Items';
import TelItems from '../EmbeddedParts/BlockTel/TelItems'
import TableItems from '../EmbeddedParts/BlockTable/TableItems'
import LinkItems from '../EmbeddedParts/BlockLink/LinkItems'
import BlogItems from '../EmbeddedParts/BlockBlog/BlogItems'
import FavoriteItems from '../EmbeddedParts/BlockFavorite/FavoriteItems'
import ComparisonItems from '../EmbeddedParts/BlockComparison/ComparisonItems';
import QuestionItems from '../EmbeddedParts/BlockQuestion/QuestionItems';
import ShopItems from "../EmbeddedParts/BlockShop/ShopItems";
import ButtonItems from '../EmbeddedParts/BlockButton/ButtonItems';
import FormItems from '../EmbeddedParts/BlockForm/FormItems';
import ChangeLogItems from '../EmbeddedParts/BlockChangeLog/ChangeLogItems';
import NewsItems from '../EmbeddedParts/BlockNews/NewsItems';
import MenuItems from '../EmbeddedParts/BlockMenu/MenuItems';
import CouponItems from '../EmbeddedParts/BlockCoupon/CouponItems';
import StaffItems from '../EmbeddedParts/BlockStaff/StaffItems';
import RecruitItems from '../EmbeddedParts/BlockRecruit/RecruitItems';
import GalleryItems from '../EmbeddedParts/BlockGallery/GalleryItems';
import HtmlItems from '../EmbeddedParts/BlockHtml/HtmlItems';
import ConversationItems from "../EmbeddedParts/BlockConversation/ConversationItems";

export class AddModalItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleComponent() {
    const { blockType } = this.props;
    switch (blockType) {
      case 'blockHeader': return <HeadingItems {...this.props} />
        break;
      case 'blockImage': return <ImageItems {...this.props} />
        break;
      case 'blockMovie': return <MovieItems {...this.props} />
        break;
      case 'blockMap': return <MapItems {...this.props} />
        break;
      case 'blockText': return <TextItems {...this.props} />
        break;
      case 'blockLine': return <LineItems {...this.props} />
        break;
      case 'blockImageText': return <ImageTextItems {...this.props} />
        break;
      case 'blockImageText2': return <ImageText2Items {...this.props} />
        break;
      case 'blockTel': return <TelItems {...this.props} />
        break;
      case 'blockTable': return <TableItems {...this.props} />
        break;
      case 'blockLink': return <LinkItems {...this.props} />
        break;
      case 'blockBlog': return <BlogItems {...this.props} />
        break;
      case 'blockComparison': return <ComparisonItems {...this.props} />
        break;
      case 'blockQuestion': return <QuestionItems {...this.props} />
        break;
      case 'blockShop': return <ShopItems {...this.props} />
        break;
      case 'blockConversation': return <ConversationItems {...this.props} />
        break;
      case 'blockFavorite': return <FavoriteItems {...this.props} />
        break;
      case 'blockButton': return <ButtonItems {...this.props} />
        break;
      case 'blockForm': return <FormItems {...this.props} />
        break;
      case 'blockChangeLog': return <ChangeLogItems {...this.props} />
        break;
      case 'blockHistory': return <ChangeLogItems {...this.props} />
        break;
      case 'blockNews': return <NewsItems {...this.props} />
        break;
      case 'blockMenu': return <MenuItems {...this.props} />
        break;
      case 'blockCoupon': return <CouponItems {...this.props} />
        break;
      case 'blockStaff': return <StaffItems {...this.props} />
        break;
      case 'blockRecruit': return <RecruitItems {...this.props} />
        break;
      case 'blockGallery': return <GalleryItems {...this.props} />
        break;
      case 'blockHtml': return <HtmlItems {...this.props} />
        break;
      default: return <NotImplementPart />
        break;
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.handleComponent()}
      </React.Fragment>
    )
  }
}

export default AddModalItem
