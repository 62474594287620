import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextComponent from './TextComponent';
import ConfigablePart from "../ConfigablePart";
import { parallaxScroll } from '../../../../../functions';

class LoadableTextPart extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    isEdit: true
  };

  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    parallaxScroll();
  }

  render() {
    const { content, kindOfDesign, sectionStyle, isEdit } = this.props;
    return (
      <section key={kindOfDesign} id={kindOfDesign} className={`contents_box01 block_text_${kindOfDesign} delay3  ${content.parallax != 1 ? "parallax" : ""} ${content.width_class} blockarea ${content.top_padding_class}  ${content.bottom_padding_class} edit_outer delay4Active editing block_text delay3Active`}
        style={sectionStyle}>
        {isEdit && <ConfigablePart {...this.props} />}
        {
          content.parallax != 1 &&
          <div className="parallax-wrap">
            <div className="parallax_img" style={{backgroundImage: `url(${content.background_image_url})`, height: '100%', transform: 'translateY(-50%)'}} />
          </div>
        }
        <div className="content_wrapper">
          <TextComponent content={content} isEdit={isEdit} index={this.props.index}
                         handleChangeText={this.props.handleChangeText}
                         handleChangeHeadline={this.props.handleChangeHeadline}
                         handleChangeAlign={this.props.handleChangeAlign}
                         blockType={this.props.blockType} />
        </div>
      </section>
    )
  }
}

export default (LoadableTextPart)
