import React, { Component } from 'react'
import { connect } from 'react-redux';
import ConfigablePart from "../ConfigablePart";
import PropTypes from 'prop-types';
import { APIS, S3_URL } from "../../../../../untils/constants";
import request from "../../../../../untils/request";
import HeadlineComponent from '../HeadlineComponent';
import ExplanationComponent from '../ExplanationComponent';
import LabelComponent from "../../EmbeddedParts/LabelComponent"
import { comparisonPopup } from '../../../../../functions';
import { parallaxScroll } from '../../../../../functions';

export class LoadableStaffPart extends Component {
  constructor(props) {
    super(props)
    this.showListStaffs = this.showListStaffs.bind(this)
    this.renderTitle = this.renderTitle.bind(this)
    this.renderImage = this.renderImage.bind(this)
    this.renderStaffDetails = this.renderStaffDetails.bind(this)
    this.toggleDetails = this.toggleDetails.bind(this)
    this.headlineNone = "1"
    this.headlineFull = "2"
    this.state = { staffs: {} }
  }

  static propTypes = {
    isEdit: PropTypes.bool,
    staffCategories: PropTypes.array,
    isFavorite: PropTypes.bool
  };

  static defaultProps = {
    isEdit: true,
    staffCategories: [],
    isFavorite: false
  };

  async componentDidMount () {
    await request({
      method: 'get',
      url: `${APIS.get_staffs}?categories=${this.props.item.categories}&client_id=${this.props.clientId}`,
    }).then(response => {
      this.setState({staffs: response.data.staffs})
    })
    parallaxScroll();
    comparisonPopup(this.props.pageStatus, S3_URL)
  }

  showListStaffs(category_id, staffs, slice) {
    return(
      <div>
        {this.renderTitle(category_id)}
        <div className="wrapper_item">
          {
            staffs.slice(0, slice).map((staff, index) => {
              return (
                <div className="inner_item" key={index}>
                  {this.renderImage(staff)}

                  <div className="inner_item_txt">
                    <div className="heading block_header_4">
                      <h3 className="h">{staff.name}</h3>
                      <p>{staff.name_alias}</p>
                    </div>
                    {this.renderStaffDetails(staff.staff_details)}
                    <p className="hidetgl contents_btn01">
                      <a href="#" className="no" onClick={this.toggleDetails} style={{"color": "white"}}>もっと見る</a>
                    </p>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }

  renderTitle(category_id) {
    const {content, staffCategories} = this.props;

    if (content.display_title == "2" && category_id != '0' && staffCategories.length > 0) {
      const category = staffCategories.find(cat => cat.id.toString() == category_id);
      return (
        <div className="heading block_header_2">
          <h3 className="h contenteditable">
            <font style={{"verticalAlign": "inherit"}}>{category.name}</font>
          </h3>
          <p className="contenteditable">
            <font style={{"verticalAlign": "inherit"}}>{category.name_alias}</font>
          </p>
        </div>
      )
    }
  }

  renderImage(staff) {
    let generalSetting = this.props.generalSetting
    let popup_flag = this.props.item.popup_flag
    let staff_image_url = "#!"
    if(generalSetting != undefined){
      staff_image_url = generalSetting.popup_flag === 0 ? staff.staff_image_url : "#!"
    } else {
      staff_image_url = popup_flag === 0 ? staff.staff_image_url : "#!"
    }
    if (staff.staff_image_url)
      return (
        <div className="inner_item_img" style={{height: "318px"}}>
          <a href={staff_image_url}>
            <img src={staff.staff_image_url} alt={staff.name} />
          </a>
        </div>
      )
  }

  renderStaffDetails(staff_details) {
      return staff_details.map((detail, i) => {
        if (detail.name.trim() !== '' && detail.content.trim() !== '') {
          return (
            <dl key={i} style={{"borderBottom": "1px solid rgb(204, 204, 204)", "borderTop": "1px solid rgb(204, 204, 204)"}}>
              <dt style={{"borderBottom": "none"}}>{detail.name}</dt>
              <dd className="pre-wrap">{detail.content}</dd>
            </dl>
          )
        }
      })
  }

  toggleDetails(e) {
    e.preventDefault();
    const list = e.target.parentElement.parentElement.querySelectorAll("dl");

    if (e.target.text == "もっと見る") {
      e.target.text = "閉じる"
      list.forEach(e => e.setAttribute("style", "border-bottom: 1px solid rgb(204, 204, 204); border-top: 1px solid rgb(204, 204, 204); display: table !important;"))
    } else {
      e.target.text = "もっと見る"
      list.forEach(e => e.setAttribute("style", "border-bottom: 1px solid rgb(204, 204, 204); border-top: 1px solid rgb(204, 204, 204); display: none !important;"))
    }
  }

  render() {
    const {content, isEdit, handleChangeHeadline, handleChangeAlign, item, index, staffCategories, sectionStyle} = this.props;
    const {staffs} = this.state;
    const addDesign = content.src == 'staff_1' ? 'add_design1' : 'add_design2';

    let freeContentHide = '';
    switch (content.display_free) {
      case "2": freeContentHide = 'cotents_hide'; break;
      case "3": freeContentHide = 'cotents_hide_sp'; break;
    }

    let slice = staffs.length;
    switch (content.display_number) {
      case "1": slice = 3; break;
      case "2": slice = 6; break;
      case "3": slice = 9; break;
    }

    let group_staffs = {};
    if (content.display_format == 1) {
      Object.values(staffs).forEach((staff) => {
        group_staffs[staff.staff_category_id.toString()] ||= []
        group_staffs[staff.staff_category_id.toString()].push(staff)
      })
    } else {
      group_staffs = {'0': Object.values(staffs)};
    }

    return (
      <section key={item.kind_of_design} id={item.kind_of_design} className={`widget_staff01 align-left delay3 ${content.width_class} ${content.parallax != 1 ? "parallax" : ""} blockarea ${content.top_padding_class}  ${content.bottom_padding_class} edit_outer without delay4Active editing block_staff delay3Active ${addDesign} ${freeContentHide}`}
        style={sectionStyle}>
      {this.props.isEdit && <ConfigablePart {...this.props} />}
      {
        content.parallax != 1 &&
        <div className="parallax-wrap">
          <div className="parallax_img" style={{backgroundImage: `url(${content.background_image_url})`, height: '100%', transform: 'translateY(-50%)'}} />
        </div>
      }
        <div className="content_wrapper">
          {
            content.headlineType != this.headlineNone &&
            <HeadlineComponent content={content} index={index}
                              isEdit={isEdit}
                              handleChangeHeadline={handleChangeHeadline}
                              handleChangeAlign={handleChangeAlign} />
          }
          {
            content.headlineType == this.headlineFull &&
            <ExplanationComponent content={content} index={index}
                                  isEdit={isEdit}
                                  handleChangeHeadline={handleChangeHeadline}
                                  handleChangeAlign={handleChangeAlign} />
          }
          {
            content.display_format == 1 && staffCategories.map((category, index) => {
              if (group_staffs[category.id]) {
                return this.showListStaffs(category.id, group_staffs[category.id], slice)
              }
            })
          }
          {
            content.display_format != 1 && this.showListStaffs('0', group_staffs['0'], slice)
          }
          {
            content.button_label.trim() != "" &&
            <LabelComponent value={content.button_label} handleChangeLabel={this.props.handleChangeLabel} index={index}
              url={content.button_link_type == 1 ? content.button_link_1 : content.button_link_2}
              link_type={content.button_link_type}
              isFavorite={this.props.isFavorite}
              handleChangeLabel={this.props.handleChangeLabel} index={index} isEdit={false}/>
          }
        </div>
      </section>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.staffCategories = storeState.staffCategories
  newState.generalSetting = storeState.generalSetting
  newState.clientId = storeState.clientId;
  return newState
}

export default connect(mapStateToProps)(LoadableStaffPart)
