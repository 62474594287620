require('datatables.net-bs4');

$( document ).ready(function() {
  if($('[id^=news_datatable_wrapper]').length == 0 && $('#news_datatable').length > 0) {
    table = $('#news_datatable').DataTable({
      dom: "<'row'<'col-6'l><'#news_btn_add.col-6 text-right'>>" +
        "<'row'<'#news_btn_batch_delete.col-sm-12 mt-3'>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'float-right mb-3'p>",
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        emptyTable: "お知らせは登録されておりません",
        zeroRecords: "お知らせは登録されておりません",
        lengthMenu: "_MENU_ を表示する",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        }
      },
      order: [],
      columnDefs: [
        {
          targets: 0,
          orderable: false,
          width: "10%",
        },
        {
          targets: 1,
          width: "30%",
        },
        {
          targets: 2,
          width: "30%",
        },
        {
          targets: 3,
          width: "10%",
        },
        {
          targets: 4,
          width: "10%",
        },
        {
          targets: 5,
          width: "10%",
          orderable: false,
        },
        {
          targets: 6,
          visible: false,
        },
      ],
      autoWidth: false,
      drawCallback: function(settings) {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);

        reload_checkbox_all_state();

        if($('.release_time').length > 0) {
          $('.release_time').each(function(index) {
            let dateString = $(this).html().replaceAll('-', '/');
            let date = new Date(dateString);
            date = date.toLocaleString('ja-JP', { year: 'numeric', month: "numeric",
                                                  day: "numeric", hour: "numeric",
                                                  minute: "numeric" })
            $(this).html(date)
          })
        }
      },
      stateSave: true
    });

    selectNewsCategoryElement($('#news_datatable').data("news-categories"));
    if (!/iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent)) {
      $('#select_category').select2({
        width: 'element',
        minimumResultsForSearch: -1
      });
    }

    $('#news_btn_batch_delete').append( "<input type='submit' id='btn-batch-delete-news' value='一括削除' class='btn btn-info my-3' data-confirm='削除後のデータは復旧できません。よろしいですか？' data-disable-with='一括削除' />" );
    $('#news_btn_add').append(`<a href='${$('#news_datatable').data("add-new-link")}' class='btn btn-info'>新規追加</a>`);

    $('#select_category').on('change', function(){
      if ($(this).val() !== '') {
        table.column(6).search("\\b" + parseInt($(this).val()) + "\\b", true, false).draw();
      } else {
        table.column(6).search('').draw();
      }
    });

    $('#btn-batch-delete-news').on('click', function(event) {
      if ($('.checkbox_news:checked').length == 0) {
        event.preventDefault();
        alert('チェックボックスが選択されていません');
        return false;
      } else {
        let deleteItem = []
        $('.checkbox_news:checked').each(function() {
          deleteItem.push($(this).val());
        });
          $('#news_delete').val(deleteItem);
        }
    });

    $('#news_check_all').on('click', function() {
      if (this.checked) {
        $('.checkbox_news').not(':disabled').prop('checked', true);
      } else {
        $('.checkbox_news').not(':disabled').prop('checked', false);
      }
      $('.checkbox_news').first().trigger('change');
    });

    $('#news_datatable').on('click', '.checkbox_news', function() {
      number_of_checkbox_unchecked = $('.checkbox_news:not(:checked)').length;
      if (number_of_checkbox_unchecked > 0) {
        $('#news_check_all').prop('checked', false);
      } else {
        $('#news_check_all').prop('checked', true);
      }
    });

    $('.checkbox_news').on('change', function() {
      var checked_news = String(localStorage.getItem('checked_news')).split(',');

      $('.checkbox_news').each(function() {
        var index = checked_news.indexOf($(this).val());
        if (index !== -1) checked_news.splice(index, 1);
      });

      $('.checkbox_news:checked').each(function() {
        checked_news.push($(this).val());
      });

      localStorage.setItem('checked_news', checked_news.join(','));
    })
  } else {
    localStorage.removeItem('checked_news')
  }
});

function selectNewsCategoryElement(newsCategories) {
  var $labelElm = $("<label>", {class: "mr-2"});
  var $selectElm = $("<select>", {class: "custom-select custom-select-sm form-control form-control-sm", id: "select_category"});
  var $option = $("<option>", {text: "カテゴリー一覧", value: ""});
  $selectElm.append($option);
  $.each(newsCategories, function( index, value ) {
    var $option = $("<option>", {text: value["name"], value: value["id"]});
    $selectElm.append($option);
  });

  $labelElm.append($selectElm);
  $("#news_datatable_length").before($labelElm);
}

function reload_checkbox_all_state() {
  String(localStorage.getItem('checked_news')).split(',').forEach(function(value) {
    $('.checkbox_news[value=' + value + ']').prop('checked', true);
  });

  number_of_checkbox_enable = $('.checkbox_news').not(':disabled').length;
  number_of_checkbox_unchecked = $('.checkbox_news:not(:checked)').not(':disabled').length;

  if (number_of_checkbox_enable == 0 || number_of_checkbox_unchecked > 0) {
    $('#news_check_all').prop('checked', false);
  } else {
    $('#news_check_all').prop('checked', true);
  }
}
