export default (storeState, {payload}) => {
  let newState = Object.assign({}, storeState);

  newState.tagsList = payload.tags.data.tags;
  let newpage_tags = payload.tags.data.newpage_tags;
  if (payload.page_kind === 'copy_template') {
    newpage_tags = []
  }
  newState.newpageTags = newpage_tags;

  return newState;
};
