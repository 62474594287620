import React, { Component } from 'react'

export default class FormOption extends Component {
  render() {
    const { contents, errorMessages } = this.props
    return(
      <React.Fragment>
        <h3><span>その他設定</span></h3>
        <div className="item">
          <p>フォーム<small className="required"> *</small></p>
          <span className="select"> 
            <select name="contact_id" onChange={this.props.handleChangeContent} defaultValue={contents.contact_id}>
              {
                this.props.contactOptions.map((item, index) => {
                  let title = item.name
                  if(title.length > this.maxLength) {
                    title = title.slice(0, this.maxLength) + "..."
                  }
                  return(<option key={index} value={item.id}>{title}</option>)
                })
              }
            </select>
          </span>
          <button type="button" className="button-iframe" iframevalue="question" data-remodal-target="partsModal" onClick={this.props.displayDetailData}>情報の追加・編集</button>
          {errorMessages.form && <div><small className="required">{errorMessages.form}</small></div>}
        </div>
      </React.Fragment>
    )
  }
}
