import React, { Component } from 'react'
import { connect } from 'react-redux';
import {isOpenImageModal, isCssSetting} from '../../../../Lib/redux/actions';

class BackGroundImage extends Component {
  constructor(props) {
    super(props)
    this.showOperatorImageModal = this.showOperatorImageModal.bind(this)
  }

  showOperatorImageModal() {
    this.props.handleShowImageModal(
      {isOpenImageModal: !this.props.isOpenImageModal, index: this.props.index}
    )
    this.props.handleCssSetting(true)
    this.props.handleDisableAllComponent()
  };

  render() {
    return (
      <li className="admin_edit_menu_bg 449814" title="背景設定" onClick={this.showOperatorImageModal}>
        <i className="material-icons">gradient</i>
      </li>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newProps = Object.assign({}, ownProps);
  newProps.isOpenImageModal = storeState.isOpenImageModal
  newProps.isCssSetting = storeState.isCssSetting
  return newProps;
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleShowImageModal: (data) => {dispatch(isOpenImageModal(data))},
    handleShowSetting: (data) => {dispatch(isOpenImageSettingModal(data))},
    handleCssSetting: (data) => {dispatch(isCssSetting(data))}
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(BackGroundImage)
