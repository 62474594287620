import React, { Component } from 'react';
import ModdablePart from '../ModdablePart';
import request from "../../../../../untils/request";
import { APIS } from "../../../../../untils/constants";
import { IMAGES } from '../../../../../untils/constants'

export class FavoriteItems extends Component {
  constructor(props) {
    super(props);
    this.title = 'お気に入りデザイン'
    this.state = {
      favorites: []
    }
  }

  async componentDidMount() {
    await request({
      method: 'get',
      url: APIS.favorites_path
    }).then(response => {
      this.setState({
        favorites: response.data.favorites
      })   
    })
  }

  render() {
    return (
      <ModdablePart title={this.title}>
        <div className="item">
          <table width="100%" className="table table-striped table-bordered table-hover" style={{ wordBreak: 'break-word' }}>
            <thead>
              <tr>
                <th>お気に入り名</th>
                <th>カテゴリー名</th>
              </tr>
            </thead>
            <tbody id="item-tbody">
              {
                this.state.favorites.map((favorite, index) => {
                  return(
                    <tr key={index}>
                      <td width="66%">
                        <input type="hidden" name={`block_newfavorite_blocktype${favorite.id}`} defaultValue="block_image" />
                        <input type="hidden" name={`block_newfavorite_blockdesign${favorite.id}`} defaultValue={favorite.kind_of_design} />
                        <div><input name="block_newfavorite_id" defaultChecked={this.props.itemChecked.id == favorite.id} id={`block_newfavorite_id${favorite.id}`} defaultValue={favorite.id} type="radio" /><label htmlFor={`block_newfavorite_id${favorite.id}`} onClick={ () => this.props.handleDataFavorite(favorite) }>{favorite.name}</label></div>
                      </td>
                      <td>{favorite.category}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>

      </ModdablePart>
    )
  }
}

export default FavoriteItems
