import React, { Component } from "react";
import { ChromePicker } from "react-color";

export default class ColorPicker extends Component{
  constructor(props) {
    super(props)
    this.state =
    { color: {
        r: props.content[`r_${props.colorType}`],
        g: props.content[`g_${props.colorType}`],
        b: props.content[`b_${props.colorType}`],
        a: props.content[`a_${props.colorType}`] || 0
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({color:{
        r: nextProps.content[`r_${nextProps.colorType}`],
        g: nextProps.content[`g_${nextProps.colorType}`],
        b: nextProps.content[`b_${nextProps.colorType}`],
        a: nextProps.content[`a_${nextProps.colorType}`]
      }
    });
  }

  handlePickColor(index, color, colorType) {
    if (this.state.color.a === 0) {
      color.rgb.a = 1;
    }
    this.props.handleChangeChangeColor(index, color, colorType)
  }

  handleClearColor(index, color, colorType) {
    this.props.handleChangeChangeColor(index, color, colorType)
  }

  render(){
    const {index, colorType} = this.props
    let color = {
      rgb: {
        r: 34,
        g: 34,
        b: 34,
        a: 1
      }
    }
    if(colorType === "background") {
      color = {
        rgb: {
          r: 0,
          g: 0,
          b: 0,
          a: 0
        }
      }
    }
    const stylePicker = {position: 'absolute', zIndex: '9999', width: 'auto', top: '40px', paddingBottom: "100%", left: '0'}
    return (
      <div style={stylePicker} onMouseLeave={this.props.handleOpenColorPicker}>
        <ChromePicker color={this.state.color} onChange={(p_color) => this.handlePickColor(index, p_color, colorType)} />
        <button onClick={() => this.handleClearColor(index, color, colorType)}>
          <i className="material-icons">clear</i>
        </button>
      </div>
    );
  }
}
