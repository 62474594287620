import React, { Component } from 'react'
import PageDirectory from './PageDirectory'

export class ExtendedSettings extends Component {
  constructor(props) {
    super(props);

    this.renderOptionCommonFooter = this.renderOptionCommonFooter.bind(this);
  }

  renderOptionCommonFooter() {
    if(this.props.pageKind !== 'blog' && this.props.pageKind !== 'news' && this.props.pageKind !== 'blog_template') {
      return (
        <React.Fragment>
          <input type="checkbox" name="common_footer_flag" checked={this.props.settings.common_footer_flag}
                 onChange={this.props.handleChange} id="common_footer_flag" />
          <label htmlFor="common_footer_flag">共通フッターを表示する</label>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <input name="common_footer_flag" id="common_footer_other_flag" value="other_flag" type="radio"
                 onChange={this.props.handleChange}
                 defaultChecked={this.props.settings.other_flag}/>
          <label htmlFor="common_footer_other_flag">全体設定を継承する</label>

          <input name="common_footer_flag" id="common_footer_flag_true" value={true} type="radio"
                 onChange={this.props.handleChange}
                 defaultChecked={this.props.settings.common_footer_flag}/>
          <label htmlFor="common_footer_flag_true">表示する</label>

          <input name="common_footer_flag" id="common_footer_flag_false" value={false} type="radio"
                 onChange={this.props.handleChange}
                 defaultChecked={this.props.settings.common_footer_flag === false && this.props.settings.other_flag === false}/>
          <label htmlFor="common_footer_flag_false">表示しない</label>
        </React.Fragment>
      )
    }
  }

  render() {
    const { pageStatus, isNewpageAdd, pageKind, parent_id, settings, eyecatch_image_url, subNavigations, localSideNavigations } = this.props;
    const isImage = eyecatch_image_url.length > 0
    const specialPageKind = ['blog', 'news', 'blog_template']

    return (
      <div className="step">
        <div className="wrap">
          <h3><span>拡張設定</span></h3>
          <div className="item">
            <table className="table table-striped table-bordered table-hover" id="dataTables-example" width="100%">
              <tbody>
                {
                  (pageStatus !== 'edit' && isNewpageAdd && !specialPageKind.includes(pageKind) && pageKind !== 'home' && pageKind !== 'template') &&
                  <PageDirectory handleChange={this.props.handleChange} parent_id={parent_id} settings={settings} />
                }
                {
                  (!specialPageKind.includes(pageKind)) &&
                  <React.Fragment>
                    <tr id="navi_flag_area">
                      <th>ナビメニュー</th>
                      <td>
                        <input type="checkbox" name="navi_flag" checked={settings.navi_flag} onChange={this.props.handleChange} id="newpage_navi_flag" /><label htmlFor="newpage_navi_flag">上部ナビメニューに表示する</label><br />
                        <input type="checkbox" name="snavi_flag" checked={settings.snavi_flag} onChange={this.props.handleChange} id="newpage_snavi_flag" /><label htmlFor="newpage_snavi_flag">サイドナビに表示する</label><br />
                        <input type="checkbox" name="fnavi_flag" checked={settings.fnavi_flag} onChange={this.props.handleChange} id="newpage_fnavi_flag" /><label htmlFor="newpage_fnavi_flag">下部ナビメニューに表示する</label>
                        <p className="help-block">上部メニュー、サイドナビ、下部メニューへの表示設定です</p>
                      </td>
                    </tr>
                    <tr id="same_category_flag_area">
                      <th>同じカテゴリーのページ</th>
                      <td>
                        <input type="checkbox" name="same_category_flag" checked={settings.same_category_flag}
                               onChange={this.props.handleChange} id="newpage_same_category_flag" />
                        <label htmlFor="newpage_same_category_flag">同じカテゴリーのページを表示する</label>
                      </td>
                    </tr>
                  </React.Fragment>
                }
                <tr id="related_posts_flag_area">
                    <th>関連ページ</th>
                    <td>
                      <input type="checkbox" name="related_posts_flag" checked={settings.related_posts_flag}
                             onChange={this.props.handleChange} id="newpage_related_posts_flag" />
                      <label htmlFor="newpage_related_posts_flag">関連ページを表示する</label>
                    </td>
                  </tr>
                <tr id="related_tags_flag_area">
                  <th>関連タグ</th>
                  <td>
                    <input type="checkbox" name="related_tags_flag" checked={settings.related_tags_flag}
                           onChange={this.props.handleChange} id="newpage_related_tags_flag" />
                    <label htmlFor="newpage_related_tags_flag">関連タグを表示する</label>
                  </td>
                </tr>
                {
                  (!specialPageKind.includes(pageKind)) &&
                  <React.Fragment>
                    <tr id="sitemap_flag_area">
                      <th>サイトマップ</th>
                      <td>
                        <input type="checkbox" name="sitemap_flag" checked={settings.sitemap_flag}
                               onChange={this.props.handleChange} id="newpage_sitemap_flag" />
                        <label htmlFor="newpage_sitemap_flag">サイトマップに載せる</label>
                      </td>
                    </tr>
                    <tr id="common_sidenavi_flag_area">
                      <th>共通サイドナビ</th>
                      <td>
                        <input type="checkbox" name="common_sidenavi_flag" checked={settings.common_sidenavi_flag}
                               onChange={this.props.handleChange} id="common_sidenavi_flag" />
                        <label htmlFor="common_sidenavi_flag">共通サイドナビを表示する</label>
                      </td>
                    </tr>
                  </React.Fragment>
                }
                <tr>
                  <th>共通フッター</th>
                  <td>
                    {
                      this.renderOptionCommonFooter()
                    }
                  </td>
                </tr>
                {
                  (!specialPageKind.includes(pageKind)) &&
                  <React.Fragment>
                    <tr id="seo_flag_area">
                      <th>SEO診断</th>
                      <td>
                        <input type="checkbox" name="seo_flag" checked={settings.seo_flag}
                               onChange={this.props.handleChange} id="newpage_seo_flag" />
                        <label htmlFor="newpage_seo_flag">SEO診断に表示する</label>
                      </td>
                    </tr>
                  </React.Fragment>
                }
                <tr>
                  <th>アクセス制限</th>
                  <td>
                    <div className="form-inline">
                      <div className="form-group"> アカウント：
                        <input type="text" maxLength="128" name="basic_account" value={settings.basic_account}
                               onChange={this.props.handleChange} className="form-control" placeholder="" />
                        {this.props.errorMessages.basic_account.message && <small className="required">{`  ${this.props.errorMessages.basic_account.message}`}</small>}
                      </div>
                      <div className="form-group"> パスワード：
                        <input type="text" maxLength="64" name="basic_password" value={settings.basic_password}
                               onChange={this.props.handleChange} className="form-control" placeholder="" />
                        {this.props.errorMessages.basic_password.message && <small className="required">{`  ${this.props.errorMessages.basic_password.message}`}</small>}
                      </div>
                    </div>
                    <p className="help-block">ページ毎にパスワードでロックすることが出来ます</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div id="eyecatch_image_area">
            <h3><span>画像関連</span></h3>
            <div className="item">
                <table className="table table-striped table-bordered table-hover" id="dataTables-example" width="100%">
                  <tbody>
                    <tr>
                      <th width="30%">アイキャッチ画像</th>
                      <td id="eyecatch_image">
                          { isImage && <img src={eyecatch_image_url} style={{ display: 'block', width: 'inherit', maxWidth: '240px', height: 'auto', margin: '0 0 10px 0' }} id="" /> }
                          <button className="media-select2 button btn btn-default" onClick={this.props.handleSwitchImageTab} type="button" data-toggle="modal" data-target="#imagesModal2" view-id="01">メディア</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
          </div>

          <h3><span>ヘッダー・フッター</span></h3>
          <div className="item">
            <table className="table table-striped table-bordered table-hover" id="dataTables-example" width="100%">
              <tbody>
                <tr>
                  <th width="30%">ヘッダー</th>
                  <td>
                    <input type="checkbox" name="h_d_flag" checked={settings.h_d_flag}
                           onChange={this.props.handleChange} id="newpage_h_d_flag" />
                    <label htmlFor="newpage_h_d_flag">ヘッダーを表示する</label><br />
                    <input type="checkbox" name="h_d_logo_flag" checked={settings.h_d_logo_flag}
                           onChange={this.props.handleChange} id="newpage_h_d_logo_flag" />
                    <label htmlFor="newpage_h_d_logo_flag">ロゴを表示する</label><br />
                    <input type="checkbox" name="h_d_navi_flag" checked={settings.h_d_navi_flag}
                           onChange={this.props.handleChange} id="newpage_h_d_navi_flag" />
                    <label htmlFor="newpage_h_d_navi_flag">ナビメニューを表示する</label><br />
                    <input type="checkbox" name="h_d_mv_flag" checked={settings.h_d_mv_flag}
                           onChange={this.props.handleChange} id="newpage_h_d_mv_flag" />
                    <label htmlFor="newpage_h_d_mv_flag">MVを表示する</label>
                  </td>
                </tr>
                <tr>
                  <th>フッター</th>
                  <td>
                    <input type="checkbox" name="f_d_flag" checked={settings.f_d_flag}
                           onChange={this.props.handleChange} id="newpage_f_d_flag" />
                    <label htmlFor="newpage_f_d_flag">フッターを表示する</label><br />
                    <input type="checkbox" name="f_d_logo_flag" checked={settings.f_d_logo_flag}
                           onChange={this.props.handleChange} id="newpage_f_d_logo_flag" />
                    <label htmlFor="newpage_f_d_logo_flag">ロゴを表示する</label><br />
                    <input type="checkbox" name="f_d_navi_flag" checked={settings.f_d_navi_flag}
                           onChange={this.props.handleChange} id="newpage_f_d_navi_flag" />
                    <label htmlFor="newpage_f_d_navi_flag">ナビメニューを表示する</label><br />
                    <input type="checkbox" name="f_d_sns_flag" checked={settings.f_d_sns_flag}
                           onChange={this.props.handleChange} id="newpage_f_d_sns_flag" />
                    <label htmlFor="newpage_f_d_sns_flag">SNSボタンを表示する</label>
                  </td>
                </tr>
                {
                  (!specialPageKind.includes(pageKind)) &&
                  <React.Fragment>
                    <tr>
                      <th>サブナビゲーション</th>
                      <td>
                        <select
                          name="navigation_id"
                          defaultValue={settings.navigation_id}
                          onChange={this.props.handleChange}
                          className="form-control"
                        >
                          <option value="">-- 選択してください --</option>
                          {subNavigations.map(item => {
                            let item_name = item.name
                            if(item.name.length > 50) {
                              item_name = item.name.slice(0, 50) + "..."
                            }
                            return (
                              <option key={item.id} value={item.id}>{item_name}</option>
                            )
                          })}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th>ローカルサイドナビ</th>
                      <td>
                        <select
                          name="local_side_navigation_id"
                          defaultValue={settings.local_side_navigation_id}
                          onChange={this.props.handleChange}
                          className="form-control"
                        >
                          <option value="">-- 選択してください --</option>
                          {localSideNavigations.map(item => {
                            let item_name = item.name
                            if(item.name.length > 50) {
                              item_name = item.name.slice(0, 50) + "..."
                            }
                            return (
                              <option key={item.id} value={item.id}>{item_name}</option>
                            )
                          })}
                        </select>
                      </td>
                    </tr>
                  </React.Fragment>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default ExtendedSettings
