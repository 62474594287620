require('datatables.net-bs4');

$( document ).ready(function() {
  if($('[id^=templates_datatable_wrapper]').length == 0 && $('#templates_datatable').length > 0) {
    table = $('#templates_datatable').DataTable({
      dom: "<'row'<'col-6'l><'#template_btn_add_page.col-6 text-right'>>" +
        "<'row'<'#template_btn_batch_delete.col-sm-12'>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'float-right mb-3'p>",
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        emptyTable: "テンプレートは登録されておりません",
        lengthMenu: "_MENU_ を表示する",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        }
      },
      order: [[ 1, 'asc' ]],
      columnDefs: [
        {
          targets: 0,
          orderable: false,
        },
        {
          targets: 2,
          orderable: false,
        },
        {
          targets: 3,
          orderable: false,
        }
      ],
      drawCallback: function(settings) {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);

        reload_checkbox_all_state();
      },
      stateSave: true
    });

    let newpageUrl = $('#templates_datatable').data('url');
    $('#template_btn_add_page').append( `<a href=${newpageUrl} class='btn btn-info'>新規追加</a>` );
    $('#template_btn_batch_delete').append( "<input type='submit' id='template_batch_delete_template_btn' value='一括削除' class='btn btn-info my-3' data-confirm='削除後のデータは復旧できません。よろしいですか？' data-disable-with='一括削除' />" );
  } else {
    localStorage.removeItem('checked_templates')
  }

  $('#template_batch_delete_template_btn').on('click', function(event) {
    if ($('.checkbox_template:checked').not(':disabled').length == 0) {
      event.preventDefault();
      alert('チェックボックスが選択されていません');
      return false;
    } else {
      let deleteItem = []
      $('.checkbox_template:checked').each(function() {
        deleteItem.push($(this).val());
      });
      $('#templates_delete').val(deleteItem);
    }
  });

  $('#template_checkbox_check_all').on('click', function() {
    if (this.checked) {
      $('.checkbox_template').not(':disabled').prop('checked', true);
    } else {
      $('.checkbox_template').not(':disabled').prop('checked', false);
    }
    $('.checkbox_template').first().trigger('change');
  });

  $('.checkbox_template').on('click', function() {
    number_of_checkbox_unchecked = $('.checkbox_template:checkbox:not(:checked)').not(':disabled').length;
    if (number_of_checkbox_unchecked > 0) {
      $('#template_checkbox_check_all').prop('checked', false);
    } else {
      $('#template_checkbox_check_all').prop('checked', true);
    }
  });

  $('.checkbox_template').on('change', function() {
    var checked_templates = String(localStorage.getItem('checked_templates')).split(',');

    $('.checkbox_template').each(function() {
      var index = checked_templates.indexOf($(this).val());
      if (index !== -1) checked_templates.splice(index, 1);
    });

    $('.checkbox_template:checked').each(function() {
      checked_templates.push($(this).val());
    });

    localStorage.setItem('checked_templates', checked_templates.join(','));
  })
});

function reload_checkbox_all_state() {
  String(localStorage.getItem('checked_templates')).split(',').forEach(function(value) {
    $('.checkbox_template[value=' + value + ']').prop('checked', true);
  });

  number_of_checkbox_enable = $('.checkbox_template').not(':disabled').length;
  number_of_checkbox_unchecked = $('.checkbox_template:not(:checked)').not(':disabled').length;

  if (number_of_checkbox_enable == 0 || number_of_checkbox_unchecked > 0) {
    $('#template_checkbox_check_all').prop('checked', false);
  } else {
    $('#template_checkbox_check_all').prop('checked', true);
  }
}
