import React, { Component } from 'react';
import { display_recruit_part } from '../../../../../../functions'

export class RecruitTreatment extends Component {
  constructor(props) {
    super(props);
    this.fields = [
      'lower_limit_estimated_annual_income',
      'upper_limit_estimated_annual_income',
      'annual_income_example',
      'wage_system',
      'monthly_salary',
      'discretionary_labor_system_fixed_overtime_pay_system',
      'working_hours',
      'break_time',
      'overtime_work',
      'estimated_time_for_overtime_work',
      'supplementary_information_about_overtime',
      'increase_bonus',
      'allowance',
      'welfare',
      'holiday_vacation',
      'work_location',
      'work_address',
      'smoking_environment',
      'whether_or_not_there_is_a_transfer',
      'supplementary_information_regarding_ot'
    ]
  }
  render() {
    const { recruit } = this.props;
    let is_display = display_recruit_part(recruit, this.fields)
    return (
      is_display && (
      <div className="detail">
        <div className="heading block_header_3 align-left">
          <h4 className="h">待遇・昇給・賞与</h4>
        </div>
        <div className="block_table_3 width_fixed align-left">
          <table>
            <tbody>
              {(recruit.lower_limit_estimated_annual_income !== undefined && recruit.lower_limit_estimated_annual_income !== '') && (
              <tr>
                <th>（下限）入社時想定年収</th>
                <td>{ recruit.lower_limit_estimated_annual_income } 万円</td>
              </tr>
              )}
              {(recruit.upper_limit_estimated_annual_income !== undefined && recruit.upper_limit_estimated_annual_income !== '') && (
              <tr>
                <th>（上限）入社時想定年収</th>
                <td>{ recruit.upper_limit_estimated_annual_income } 万円</td>
              </tr>
              )}
              {(recruit.annual_income_example !== undefined && recruit.annual_income_example !== '') && (
              <tr>
                <th>収入例</th>
                <td>{ recruit.annual_income_example }</td>
              </tr>
              )}
              {(recruit.wage_system !== undefined && recruit.wage_system !== '') && (
              <tr>
                <th>賃金制度</th>
                <td>{ recruit.wage_system }</td>
              </tr>
              )}
              {(recruit.monthly_salary !== undefined && recruit.monthly_salary !== '') && (
              <tr>
                <th>給与</th>
                <td>{ recruit.monthly_salary }</td>
              </tr>
              )}
              {(recruit.discretionary_labor_system_fixed_overtime_pay_system !== undefined && recruit.discretionary_labor_system_fixed_overtime_pay_system !== '') && (
              <tr>
                <th>裁量労働制・固定残業代制</th>
                <td>{ recruit.discretionary_labor_system_fixed_overtime_pay_system }</td>
              </tr>
              )}
              {(recruit.working_hours !== undefined && recruit.working_hours !== '') && (
              <tr>
                <th>勤務時間</th>
                <td>{ recruit.working_hours }</td>
              </tr>
              )}
              {(recruit.break_time !== undefined && recruit.break_time !== '') && (
              <tr>
                <th>休憩時間</th>
                <td>{ recruit.break_time } 分</td>
              </tr>
              )}
              {(recruit.overtime_work !== undefined && recruit.overtime_work !== '') && (
              <tr>
                <th>時間外労働</th>
                <td>{ recruit.overtime_work }</td>
              </tr>
              )}
              {(recruit.estimated_time_for_overtime_work !== undefined && recruit.estimated_time_for_overtime_work !== '') && (
              <tr>
                <th>時間外労働の目安の時間</th>
                <td>{ recruit.estimated_time_for_overtime_work }h程度/月</td>
              </tr>
              )}
              {(recruit.supplementary_information_about_overtime !== undefined && recruit.supplementary_information_about_overtime !== '') && (
              <tr>
                <th>★残業についての補足事項</th>
                <td className="pre-wrap"> { recruit.supplementary_information_about_overtime } </td>
              </tr>
              )}
              {(recruit.increase_bonus !== undefined && recruit.increase_bonus !== '') && (
              <tr>
                <th>昇給賞与・諸手当</th>
                <td className="pre-wrap"> { recruit.increase_bonus } </td>
              </tr>
              )}
              {(recruit.welfare !== undefined && recruit.welfare !== '') && (
              <tr>
                <th>福利厚生</th>
                <td className="pre-wrap"> { recruit.welfare } </td>
              </tr>
              )}
              {(recruit.holiday_vacation !== undefined && recruit.holiday_vacation !== '') && (
              <tr>
                <th>休日休暇</th>
                <td className="pre-wrap"> { recruit.holiday_vacation } </td>
              </tr>
              )}
              {(recruit.work_location !== undefined && recruit.work_location !== '') && (
              <tr>
                <th>勤務地（都道府県）</th>
                <td>{ recruit.work_location }</td>
              </tr>
              )}
              {(recruit.work_address !== undefined && recruit.work_address !== '') && (
              <tr>
                <th>勤務地</th>
                <td className="pre-wrap"> { recruit.work_address } </td>
              </tr>
              )}
              {(recruit.smoking_environment !== undefined && recruit.smoking_environment !== '') && (
              <tr>
                <th>喫煙環境</th>
                <td>{ recruit.smoking_environment }</td>
              </tr>
              )}
              {(recruit.whether_or_not_there_is_a_transfer !== undefined && recruit.whether_or_not_there_is_a_transfer !== '') && (
              <tr>
                <th>転勤の有無</th>
                <td>{ recruit.whether_or_not_there_is_a_transfer }</td>
              </tr>
              )}
              {(recruit.supplementary_information_regarding_ot !== undefined && recruit.supplementary_information_regarding_ot !== '') && (
              <tr>
                <th>★転勤の有無に関する補足事項</th>
                <td className="pre-wrap"> { recruit.supplementary_information_regarding_ot } </td>
              </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      )
    )
  }
}

export default RecruitTreatment
