import React, {Component} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faImage,
  faGripHorizontal,
  faThList,
  faAngleRight,
  faAngleDown
} from "@fortawesome/free-solid-svg-icons";

export default class ImageBlocks extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {dataKind, optionBlockShow} = this.props
    const isOpened = optionBlockShow.value === "image" && optionBlockShow.isOpen
    return (
      window.innerWidth > 480 ?
        <li>
          <a href="#!" style={{backgroundColor: isOpened ? "#eee" : "#fff"}}
             onClick={() => this.props.handleOpenBlockOptions("image")}>
            <FontAwesomeIcon icon={isOpened ? faAngleDown : faAngleRight}/>
            <p>画像</p>
          </a>
          {isOpened &&
          <ul>
            <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockImage"
                   className={dataKind == 'blockImage' ? 'selected' : ''}>
              <FontAwesomeIcon icon={faImage}/>画像</a>
            </li>
            <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockImageText"
                   className={dataKind == 'blockImageText' ? 'selected' : ''}>
              <FontAwesomeIcon icon={faThList}/>画像+テキスト</a>
            </li>
            <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockImageText2"
                   className={dataKind == 'blockImageText2' ? 'selected' : ''}>
              <FontAwesomeIcon icon={faGripHorizontal}/>画像+テキスト×n</a>
            </li>
          </ul>
          }
        </li>
        :
        <>
          <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockImage"
                 className={dataKind == 'blockImage' ? 'selected' : ''}>
            <FontAwesomeIcon icon={faImage}/>画像</a>
          </li>
          <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockImageText"
                 className={dataKind == 'blockImageText' ? 'selected' : ''}>
            <FontAwesomeIcon icon={faThList}/>画像+テキスト</a>
          </li>
          <li><a href="#!" onClick={this.props.handleSwitchTabs} data-type="blockImageText2"
                 className={dataKind == 'blockImageText2' ? 'selected' : ''}>
            <FontAwesomeIcon icon={faGripHorizontal}/>画像+テキスト×n</a>
          </li>
        </>
    )
  }
}
