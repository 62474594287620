require('datatables.net-bs4');

$(document).ready(function() {
  if($('[id^=staffs_datatable_wrapper]').length == 0 && $('#staffs_datatable').length > 0) {
    table = $('#staffs_datatable').DataTable({
      dom: "<'row'<'col-6'l><'#staff_btn_add.col-6 text-right'>>" +
        "<'row'<'#staff_btn_batch_delete.col-sm-12'>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'float-right mb-3'p>",
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        lengthMenu: "_MENU_ を表示する",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        },
        emptyTable: "スタッフは登録されておりません",
        zeroRecords: "スタッフは登録されておりません"
      },
      order: [],
      ordering: false,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
          width: "10%",
        },
        {
          targets: 2,
          width: "15%",
        },
        {
          targets: 3,
          orderable: false,
          width: "10%",
        },
        {
          targets: 4,
          orderable: false,
          visible: false
        }
      ],
      drawCallback: function() {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);
      },
      stateSave: true
    });

    $('#staffs_datatable_length').before('<label class="mr-2">' +
      '<select id="staff_select_display_flag" class="custom-select custom-select-sm form-control form-control-sm">' +
        '<option value="">-- 表示・非表示 --</option>' +
        '<option value="show">表示</option>' +
        '<option value="hidden">非表示</option>' +
      '</select>' +
    '</label>');
    selectStaffCategoryElement($('.show-data').data("staff-category-options"));
    if (!/iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent)) {
      $('#select_category').select2({
        width: 'element',
        minimumResultsForSearch: -1
      });
    }
    $('#staff_btn_add').append(`<a href='/staffs/add?iframe=${$("#iframe").val()}' class='btn btn-info mb-2'>新規追加</a>`);

    setTimeout(function() {
      search = table.state().columns[4].search.search
      $('#staff_select_display_flag').val(search);
    }, 450);
    $('#staff_select_display_flag').on( 'change', function () {
      table.columns(4).search(this.value).draw();
    });
    $('#select_category').on('change', function(){
      const val = $.fn.dataTable.util.escapeRegex(
        $(this).val()
      );
      table.column(2).search(val ? '^'+val+'$' : '', true, false).draw();
    })

  }
});

function selectStaffCategoryElement(menuCategories) {
  var $labelElm = $("<label>", {class: "mr-2"});
  var $selectElm = $("<select>", {class: "custom-select custom-select-sm form-control form-control-sm", id: "select_category"});
  var $option = $("<option>", {text: "カテゴリー一覧", value: ""});
  $selectElm.append($option);
  $.each(menuCategories, function( index, value ) {
    var $option = $("<option>", {text: value["name"], value: value["id"]});
    $selectElm.append($option);
  });
  $labelElm.append($selectElm);
  $("#staff_select_display_flag").parent().before($labelElm);
}
