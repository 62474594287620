import React, { Component } from 'react';
import { display_recruit_part } from '../../../../../../functions'

export class RecruitAppealPoints extends Component {
  constructor(props) {
    super(props);
    this.fields = [
      'corporate_appeal_points',
      'position_appeal_point',
      'career_plan_after_joining_the_company',
      'skills_gained_through_work',
    ]
  }
  render() {
    const { recruit } = this.props;
    let is_display = display_recruit_part(recruit, this.fields)
    return (
      is_display && (
        <div className="detail">
          <div className="heading block_header_3 align-left">
            <h4 className="h">訴求ポイント</h4>
          </div>
          <div className="block_table_3 width_fixed align-left">
            <table>
              <tbody>
                { (recruit.corporate_appeal_points !== undefined && recruit.corporate_appeal_points !== '') &&
                <tr>
                  <th>★企業のアピールポイント</th>
                  <td className="pre-wrap">{ recruit.corporate_appeal_points }</td>
                </tr>
                }
                { (recruit.position_appeal_point !== undefined && recruit.position_appeal_point !== '') &&
                <tr>
                  <th>★ポジションのアピールポイント</th>
                  <td className="pre-wrap">{ recruit.position_appeal_point }</td>
                </tr>
                }
                { (recruit.career_plan_after_joining_the_company !== undefined && recruit.career_plan_after_joining_the_company !== '') &&
                <tr>
                  <th>★入社後のキャリアプラン</th>
                  <td className="pre-wrap">{ recruit.career_plan_after_joining_the_company }</td>
                </tr>
                }
                { (recruit.skills_gained_through_work !== undefined && recruit.skills_gained_through_work !== '') &&
                <tr>
                  <th>★仕事を通して得られるスキル</th>
                  <td className="pre-wrap">{ recruit.skills_gained_through_work }</td>
                </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      )
    )
  }
}

export default RecruitAppealPoints
