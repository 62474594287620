// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Slider-module__mv_outer___2hxNh {\n  border-top: '348px solid transparent';\n}\n\n.Slider-module__mv___-A_1D {\n  opacity: 1;\n  min-height: '264px';\n}\n\n.Slider-module__h_title___ck0Sv {\n  font-size: '44.4167px';\n}", "",{"version":3,"sources":["Slider.module.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;AACvC;;AAEA;EACE,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;AACxB","file":"Slider.module.css","sourcesContent":[".mv_outer {\n  border-top: '348px solid transparent';\n}\n\n.mv {\n  opacity: 1;\n  min-height: '264px';\n}\n\n.h_title {\n  font-size: '44.4167px';\n}"]}]);
// Exports
exports.locals = {
	"mv_outer": "Slider-module__mv_outer___2hxNh",
	"mv": "Slider-module__mv___-A_1D",
	"h_title": "Slider-module__h_title___ck0Sv"
};
module.exports = exports;
