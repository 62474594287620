import React, { Component } from 'react';
import { connect } from 'react-redux'
import axios from 'axios';
import { APIS } from '../../../untils/constants';
import { setTokenHttpClient, updateSetting } from '../../../Lib/redux/actions'

class AuthorityContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthorization: false,
    }
  }

  async UNSAFE_componentWillMount() {
    const thisComponent = this
    let url = APIS.get_access_token
    if (this.props.page_kind === 'copy_template') {
      url = APIS.get_account_access_token
      thisComponent.props.saveUpdateSetting(this.props.settings)
    }
    await axios.get(url).then(function (response) {
      thisComponent.props.updateToken(response.data)
      thisComponent.setState({isAuthorization: true})
    })
  }

  render() {
    return (
      <React.Fragment>
        { this.state.isAuthorization && this.props.children}
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateToken: (data) => dispatch(setTokenHttpClient(data)),
    saveUpdateSetting: (data) => dispatch(updateSetting(data))
  }
}

export default connect(null, mapDispatchToProps)(AuthorityContainer)
