export default (storeState, {payload}) => {
  let newState = Object.assign({}, storeState);

  let index = newState.index

  if(payload.indexBlockData !== undefined) {
    index = payload.indexBlockData + 1
    payload = Object.assign({}, payload)
  }
  payload.sort = Math.max.apply(Math, newState.asideData.map(function(o) { return o.sort; })) + 1
  newState.asideData.splice(index, 0, payload);
  
  newState.asideData = [...newState.asideData]

  return newState;
};
