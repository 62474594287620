var newpage_id;
var updatePageStructure = function(e) {
  let result = JSON.stringify($('#nestable').nestable('serialize'));
  $.ajax({
    url: '/page_structures/update_structure',
    type: 'PUT',
    data: {
      data: result,
      newpage_id: newpage_id
    }
  });
};

var updateUnpublishPage = function(e) {
  let result = JSON.stringify($('#nestable2').nestable('serialize'));
  $.ajax({
    url: '/page_structures/update_unpublish_page',
    type: 'PUT',
    data: {
      data: result,
      newpage_id: newpage_id
    }
  });
};

var handleItemMoved = function(e) {
  newpage_id = e.data("newpage-id");
};

$( document ).ready(function() {
  $('#swith-open').on('change', function() {
    if(this.checked) {
      $('.dd').nestable('collapseAll');
    } else {
      $('.dd').nestable('expandAll');
    }
  });

  $('#nestable').nestable({
    group: 1,
    maxDepth: 3,
    beforeDragStop: function(l,e, p){
      handleItemMoved(e)
    }
  }).on('change', updatePageStructure);

  $('#nestable2').nestable({
    group: 1,
    maxDepth: 1,
    beforeDragStop: function(l,e, p){
      handleItemMoved(e)
    }
  }).on('change', updateUnpublishPage);
})
