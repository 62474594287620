import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import { withRouter } from "react-router";

export class PageDirectory extends Component {
  static propTypes = {
    pageStructureInSetting: PropTypes.array
  };

  static defaultProps = {
    pageStructureInSetting: []
  };
  constructor (props) {
    super(props);

    this.renderPageStructureTree = this.renderPageStructureTree.bind(this)
  }

  renderPageStructureTree() {
    const { parent_id, match } = this.props;
    const {newpage_id} = match.params;

    return (
      <React.Fragment>
        <br/>
        <div id="site_structure_result">
          <div className="text-danger text-center">関連記事が無い為に新規ディレクトリの設定をお勧めします</div>
          {this.props.pageStructureInSetting.map(item => {
            if (item.id == newpage_id) return

            let classNameDirectory = "pull-right"
            if (item.page_kind === "home") {
              classNameDirectory += " col-xs-12"
            } else {
              classNameDirectory += " col-xs-11"
            }

            let root_url = `/${item.url_decode}/`
            if (item.page_kind === "home") { root_url = '/' }

            return (
              <React.Fragment key={item.id}>
                <div className={classNameDirectory} key={item.id}>
                  <input type="radio" className="alert alert-info" id={`parent_id_${item.id}`}
                         name="parent_id" value={item.page_kind === "home" ? null : item.id}
                         onChange={this.props.handleChange}/>
                  <label htmlFor={`parent_id_${item.id}`} className={`label btn-info ${parent_id == item.id ? 'parent_checked' : ''}`}>{item.page_title}<br/>{ root_url }<br/></label>
                </div>
                {item.children.map(childFirstGeneration => {
                  if (childFirstGeneration.id == newpage_id) return

                  return (
                    <React.Fragment key={childFirstGeneration.id}>
                      <div className="col-xs-10 pull-right" key={childFirstGeneration.id}>
                        <input type="radio" className="alert alert-info" id={`parent_id_${childFirstGeneration.id}`}
                               name="parent_id" value={childFirstGeneration.id}
                               onChange={this.props.handleChange}/>
                        <label htmlFor={`parent_id_${childFirstGeneration.id}`} className={`label btn-danger ${parent_id == childFirstGeneration.id ? 'parent_checked' : ''}`}>
                          {childFirstGeneration.page_title}<br/>/{item.url_decode}/{childFirstGeneration.url}/<br/>
                        </label>
                      </div>
                    </React.Fragment>
                  )
                })}
              </React.Fragment>
            )
          })}
        </div>
        <p className="help-block">ページを配置する階層を指定できます。関連度の高いページの下に配置しましょう</p>
      </React.Fragment>
    )
  }

  render() {
    return (
      <tr id="parent_id_area">
        <th width="30%">親ディレクトリ</th>
        <td>
          {this.renderPageStructureTree()}
        </td>
      </tr>
    )
  }
}


const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);

  newState.pageStructureInSetting = storeState.pageStructureInSetting;

  return newState;
}

export default connect(mapStateToProps)(withRouter(PageDirectory));
