import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConfigablePart from "../ConfigablePart";
import HeadlineComponent from '../HeadlineComponent';
import ContentEditable from 'react-contenteditable';
import { APIS, FRONT_END_HOST } from "../../../../../untils/constants";
import request from "../../../../../untils/request";
import ExplanationComponent from '../ExplanationComponent';
import { parallaxScroll } from '../../../../../functions';
import {connect} from 'react-redux';

class LoadableCouponPart extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
  };

  static defaultProps = {
    isEdit: true
  };

  constructor(props) {
    super(props);
    this.headlineNone = "1"
    this.headlineFull = "2"
    this.groupComparsionsByCategory = "1"
    this.onlyShow1Comparison = "3"
    this.showCategoryName = "2"
    this.emptyContent = ""
    this.state = {
      coupons: [],
    }
    this.handleClick = this.handleClick.bind(this)
  }

  async componentDidMount () {
    await request({
      method: 'get',
      url: `${APIS.get_coupon}?client_id=${this.props.clientId}`
    }).then(response => {
      this.setState({
        coupons: response.data.coupons
      })
    })
    parallaxScroll();
  }

  renderListCoupon() {
    const item = this.props.item
    return (
      <React.Fragment>
        {
          this.state.coupons.map(coupon => {
            if(item.categories.includes(coupon.id)) {
              return (
                <div key={coupon.id} className="inner_item">
                  <h3>{coupon.name}</h3>
                  {
                    coupon.content !== '' &&
                    <div>
                      <p className='pre-wrap'> {coupon.content} </p>
                    </div>
                  }
                  {
                    coupon.terms_of_use !== '' &&
                    <dl>
                      <dt>利用条件</dt>
                      <dd className='pre-wrap'>{coupon.terms_of_use}</dd>
                    </dl>
                  }
                  {
                    coupon.term !== null && coupon.term !== '' &&
                    <dl className="coupon_limit">
                      <dt>有効期限</dt>
                      <dd>{coupon.term}</dd>
                      <dd></dd>
                    </dl>
                  }
                </div>
              )
            }
          })
        }
      </React.Fragment>
    )
  }

  handleClick(event, content) {
    event.preventDefault();
    const {idString, isEdit} = this.props;
    if (isEdit) return

    let url = '';
    if(content.coupon_link1 === '1'){
      const homeUrl = `${FRONT_END_HOST}/${idString}`
      if (content['coupon_link1-select'] === '/') {
        url = homeUrl
      } else {
        url = `${homeUrl}${content['coupon_link1-select']}.html`
      }
    } else {
      url = content['coupon_link1-input']
    }

    if (content.coupon_link1 === '1') {
      window.open(url, '_self');
    } else {
      window.open(url);
    }
  }

  render() {
    const { content, kindOfDesign, index, isEdit, sectionStyle } = this.props;
    return (
      <section key={kindOfDesign} id={kindOfDesign} className={`widget_coupon align-left  block_coupon_1 ${content.parallax != 1 ? "parallax" : ""} ${content.top_padding_class}  ${content.bottom_padding_class} delay3 Active width_full blockarea edit_outer editing block_coupon without delay3Active`}
        style={sectionStyle}>

        {isEdit && <ConfigablePart {...this.props} />}
        {
          content.parallax != 1 &&
          <div className="parallax-wrap">
            <div className="parallax_img" style={{backgroundImage: `url(${content.background_image_url})`, height: '100%', transform: 'translateY(-50%)'}} />
          </div>
        }
        <div className="content_wrapper">
          {
            content.headlineType !== this.headlineNone &&
            <HeadlineComponent content={content} index={this.props.index}
                              isEdit={isEdit}
                              handleChangeHeadline={this.props.handleChangeHeadline}
                              handleChangeSubHeadline={this.props.handleChangeSubHeadline}
                              handleChangeAlign={this.props.handleChangeAlign} />
          }

          {
            content.headlineType === this.headlineFull &&
            <ExplanationComponent content={content} index={index}
                                  isEdit={isEdit}
                                  handleChangeHeadline={this.props.handleChangeHeadline}
                                  handleChangeAlign={this.props.handleChangeAlign} />
          }

          { this.renderListCoupon() }

          {
            content.coupon_button_label != this.emptyContent &&
            <div className="contents_btn01 block_button_1">
              <div className="content_wrapper">
                <a href="#" onClick={(event) => {this.handleClick(event, content)}}>
                  <span>{content.coupon_button_label.trim()}</span>
                </a>
              </div>
            </div>
          }
        </div>
      </section>
    )
  }
}

const mapStateToProps = (storeState, ownProps) => {
  let newState = Object.assign({}, ownProps);
  newState.clientId = storeState.clientId;
  return newState
}

export default connect(mapStateToProps)(LoadableCouponPart)
