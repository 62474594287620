import React, { Component } from 'react'
import { parallaxScroll } from '../../../../../functions';
import { alertContent } from '../../../../../functions/alertContent';

export default class RadioField extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const {item, remarks} = this.props
    return (
      <dl>
        <dt>{item.input_name}</dt>
        <dd>
          {
            item.input_content.split(',').map((item, index) => {
              if(alertContent(item)) {
                return(
                  <React.Fragment key={index}>
                    <input type="radio" id={`form_radio_${index}`}/>
                    <label htmlFor={index}>{item}</label>
                  </React.Fragment>
                )
              }
              return(
                <React.Fragment key={index}>
                  <input type="radio" id={`form_radio_${index}`}/>
                  <label htmlFor={index}>{item}</label>
                </React.Fragment>
              )
            })
          }
          <p className="cap">{remarks}</p>
        </dd>
      </dl>
    )
  }
}
